import frLocale from "date-fns/locale/fr";
import enLocale from "date-fns/locale/en-US";
import moment from "moment";
import React from "react";

import FiltreExportLignes from "../components/mesStatistiques/exports/exportLignesEan";
import FiltreMensualisation from "../components/mesStatistiques/exports/exportMensualisationChiffres";
import { LIBELLE_EXPORT_LIGNES_EAN, LIBELLE_EXPORT_MENSUALISATION } from "./libelleConstantes";


export const CLIENT_AUTH_GRANT_TYPE = "password";
export const CLIENT_AUTH_USERNAME = "siteDeclarantClientId";
export const CLIENT_AUTH_PASSWORD = "siteDeclarantSI2018!";

// Session variables
export const SessionVariablesEnum = {
    TOKEN_NAME: "access_token",
    USER_INFOS: "userInfos",
    DECLARANT_USURPE: "declarantUsurpe"
};

export const PROFIL_FOURNISSEUR = "F";
export const PROFIL_BIBLIOTHEQUE = "B";

export const PROFIL_FOURNISSEUR_COLOR = "#d88700";
export const PROFIL_FOURNISSEUR_COLOR_DARKER = "#c17b06";
export const PROFIL_BIBLIOTHEQUE_COLOR = "#a3bf2a";
export const PROFIL_BIBLIOTHEQUE_COLOR_DARKER = "#8fa827";
export const MAX_HT = 8000000;
export const MAX_TTC = 10000000;
export const MAX_PPHT = 100000;
export const MAX_QUANTITE = 3000;

export const ROLES = {
    FOURNISSEUR: "FOURNISSEUR",
    BIBLIOTHEQUE: "BIBLIOTHEQUE",
    ADMIN: "ADMINISTRATEUR"
};

/**
 * Code DDP pour les functions des contacts
 * @type {{DESTINATAIRE_FORMULAIRE_PAPIER: string, RESPONSABLE_SUIVI_FACTURES: string, SIGNATAIRE_DEMANDE: string, DESINATAIRE_MOT_DE_PASSE: string, REPRESENTANT_LEGAL: string, RESPONSABLE_DROIT_DE_PRET: string, AUTRE: string, DEMANDEUR_REFERENCEMENT: string}}
 */
export const CONTACT_FONCTION = {
    REPRESENTANT_LEGAL: "RL",
    RESPONSABLE_SUIVI_FACTURES: "DFA",
    RESPONSABLE_DROIT_DE_PRET: "RDP",
    AUTRE: "AUT",
    DEMANDEUR_REFERENCEMENT: "CORR",
    DESTINATAIRE_FORMULAIRE_PAPIER: "DFP",
    DESINATAIRE_MOT_DE_PASSE: "DMDP",
    SIGNATAIRE_DEMANDE: "SD"
};

export const INSCRIPTION_ENTITE_CONTENT = "Step1Content";
export const REPRESENTANT_LEGAL_CONTENT = "Step2Content";
export const RESPONSABLE_DROIT_PRET_CONTENT = "Step3Content";
export const RECHERCHEGLN_CONTENT = "Step4Content";
export const CONFIRMATION_CONTENT = "Step5Content";
export const DEFAULT_MESSAGE_CONFIRMATION_INSCRIPTION = "inscriptionVeuillezPatienter";

export const RECHERCHE_PARTENAIRE_FORMULAIRE = "Step1Content";
export const RECHERCHE_GLN_AVANCEE = "Step2Content";

export const DEMANDE_REFERENCEMENT = "Step3Content";
export const REFERENCEMENT_CONFIRMATION = "Step4Content";


export const MAX_LENGTH_RAISON_SOCIALE1 = 38;
export const MAX_LENGTH_RAISON_SOCIALE2 = 38;
export const MAX_LENGTH_ADRESSE_1 = 38;
export const MAX_LENGTH_ADRESSE_2 = 38;
export const MAX_LENGTH_PRE_ADRESSE = 38;
export const MAX_LENGTH_NUMERO_VOIE = 4;
export const MAX_LENGTH_MAIL = 80;
export const MAX_LENGTH_TELEPHONE = 25;
export const MAX_LENGTH_VILLE = 38;
export const MAX_LENGTH_CODE_POSTAL = 15;
export const MAX_LENGTH_GLN = 13;
export const MAX_LENGTH_FORME_JURIDIQUE_AUTRE = 150;
export const MAX_LENGTH_APE = 5;
export const MAX_LENGTH_COURRIEL = 100;
export const MAX_LENGTH_SITE_WEB = 100;
export const MAX_LENGTH_EDI = 150;
export const MAX_LENGTH_TYPE_AUTRE = 150;
export const MAX_LENGTH_SIRET = 14;
export const MIN_LENGTH_SIRET = 9;
export const MAX_LENGTH_NOM = 50;
export const MAX_LENGTH_PRENOM = 50;
export const MAX_LENGTH_QUALITE = 50;
export const MAX_LENGTH_TITRE = 100;


export const CHOIX_DECLARANT_ACCORDION_ITEM_CODE = "choixDeclarantPanel";
export const CHOIX_PARTENAIRE_ACCORDION_ITEM_CODE = "choixPartenairePanel";
export const ENTETE_DECLARATION_ACCORDION_ITEM_CODE = "entetesDeclarationPanel";
export const DETAILS_DECLARATION_ACCORDION_ITEM_CODE = "detailsDeclarationPanel";

export const GLOBAL_DIALOG_TYPES = {
    ERROR: "ERROR",
    WARNING: "WARNING",
    INFO: "INFO"
};

export const DECLARATION_DATATABLE_ITEM_TYPES = {
    ENTETES: "entetes",
    LIGNES: "lignes"
};


// Events
export const ENTETE_ACCORDION_ITEM_EXPANDED_EVENT = "ENTETE_ACCORDION_ITEM_EXPANDED_EVENT";
export const LANGUAGE_CHANGED_EVENT = "LANGUAGE_CHANGED_EVENT";

export const localeMap = {
    en: enLocale,
    fr: frLocale
};

export const LOCALE = "locale";

export const DB_BNF = "BNF";
export const DB_FEL = "FEL";

export const TYPE_EXCEL = "XLS";
export const TYPE_WEB = "WEB";

export const STATUS_FORBIDDEN = "Request failed with status code 403";
export const STATUS_BAD_REQUEST = "Request failed with status code 400";

export const EDITEUR = "editeur.cellValue";
export const TITRE = "titre.cellValue";


// Date
export const DATE_FORMAT = "dd/MM/yy";
export const DATE_DELIMITER = "/";
export const MASK_CHAR = "_";
export const MASK_TEMPLATE = "__/__/__";

export const DATE_FORMAT_YEAR_MONTH = "yyyy-MM";


// Media type
export const IMPORT_TABLEUR_ACCEPTED_MEDIA_TYPE = [
    "text/plain",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
    "application/vnd.ms-excel.sheet.macroEnabled.12",
    "application/vnd.ms-excel.template.macroEnabled.12",
    "xlsx",
    "xltm",
    "xltx",
    "xlsm",
    "application/vnd.ms-excel",
    ".xlt",
    ".xla",
    "application/vnd.oasis.opendocument.spreadsheet",
    ".ods",
    "text/csv",
    ".csv"
];

// Max file size
export const MAX_FILE_SIZE = "5MB";

// Type de déclaration
export const DECLARATION_TYPE = {
    GLOBALE: "declarationTypeGlobale",
    DETAILLEE: "declarationTypeDetaillee"
};

// Status d'integration pour faire la rediréction
export const UPLOAD_TERMINE = 2;
export const RECONNAISSANCE_COLONNES_AUTO_EN_COURS = 3;
export const RECONNAISSANCE_COLONNES_AUTO_EXCEPTION = 4;
export const RECONNAISSANCE_COLONNES_AUTO_TERMINE = 5;
export const RECONNAISSANCE_COLONNES_UTILISATEUR_EN_COURS = 6;
export const RECONNAISSANCE_COLONNES_UTILISATEUR_EXCEPTION = 7;
export const RECONNAISSANCE_COLONNES_UTILISATEUR_TERMINE = 8;
export const ANALYSE_FICHIER_EN_COURS = 9;
export const ANALYSE_FICHIER_EXCEPTION = 10;
export const ANALYSE_FICHIER_TERMINE = 11;
export const CONVERSION_LIGNES_DECLARATION_EN_COURS = 12;
export const CONVERSION_LIGNES_DECLARATION_EXCEPTION = 13;
export const CONVERSION_LIGNES_DECLARATION_TERMINE = 14;
export const ENVOI_EN_ETAT_EN_COURS = 15;
export const ENVOI_EN_ETAT_EXCEPTION = 16;
export const ENVOI_EN_ETAT_TERMINE = 17;

// Erreur et classeNames pour la grid de l'ecran de correction
export const INVALID_LINE = "invalidLine";
export const DUPLICATED_LINE = "duplicatedLine";
export const CLASSENAME_CELL_DUPLICATED_LINE = "duplicatedLineClassName";
export const CLASSENAME_CELL_NUMLINE_WITH_ERROR = "ligneWithError";
export const CLASSENAME_CELL_WITH_ERROR = "cellWithError";
export const CLASSENAME_INACTIF_CELL = "inactifCell";

export const DECLARATION_ENTETE_NUMERO_PIECE = "integrationDeclarationEnteteNumeroPiece";
export const DECLARATION_ENTETE_NUMERO_PIECE_TRUNC = "integrationDeclarationEnteteNumeroPieceTrunc";
export const DECLARATION_ENTETE_DATE_FACTURE = "integrationDeclarationEnteteDateFacture";
export const DECLARATION_ENTETE_TOTAL_HT = "integrationDeclarationEnteteTotalHt";
export const DECLARATION_ENTETE_TOTAL_TTC = "integrationDeclarationEnteteTotalTtc";
export const DECLARATION_MONTANT_DDP = "montantDDP";
export const DECLARATION_ENTETE_GENCOD = "integrationDeclarationEnteteGencod";
export const DECLARATION_ENTETE_REFERENCE_FACTURE = "integrationDeclarationEnteteReferenceFacture";
export const DECLARATION_ENTETE_EAN = "integrationDeclarationEnteteEan";
export const DECLARATION_ENTETE_QUANTITE = "integrationDeclarationEnteteQuantite";
export const DECLARATION_ENTETE_PRIX_PUBLIC_HT = "integrationDeclarationEntetePrixPublicHt";
export const DECLARATION_ENTETE_PRIX_PUBLIC_TTC = "integrationDeclarationEntetePrixPublicTtc";
export const DECLARATION_ENTETE_TRONCATURE_LABEL = "integrationDeclarationTroncatureLabel";
export const DECLARATION_DATE_DERNIERE_MODIFICATION = "dateModificationEntete";
export const DECLARATION_DATE_ENVOI_SOFIA = "dateEnvoiEntete";
export const ENTETE_NON_RECONNU = "integrationDeclarationEnteteInconnu";
export const ENTETE_VIDE = "";
export const SEPARATEUR_VIRGULE = ", ";


export const ENTETES_MONTANTS = [
    DECLARATION_ENTETE_TOTAL_HT,
    DECLARATION_ENTETE_TOTAL_TTC,
    DECLARATION_ENTETE_PRIX_PUBLIC_HT,
    DECLARATION_ENTETE_PRIX_PUBLIC_TTC
];
export const ENTETES_NOMBRES = [
    DECLARATION_ENTETE_GENCOD,
    DECLARATION_ENTETE_EAN,
    DECLARATION_ENTETE_QUANTITE
];
export const ENTETES_OBLIG_DETAILLEES = [
    DECLARATION_ENTETE_EAN,
    DECLARATION_ENTETE_QUANTITE,
    DECLARATION_ENTETE_PRIX_PUBLIC_HT
];

export function isEnteteMontant(entete) {
    return ENTETES_MONTANTS.includes(entete);
}

export function isEnteteNombre(entete) {
    return ENTETES_NOMBRES.includes(entete);
}

export const limitBeginDeclarationDate = "01/01/13";
export const LOWER_LIMIT_OF_DECLARATION = moment(limitBeginDeclarationDate, "DD/MM/YY");
export const limitBeginEntiteDateCreation = "01/01/1753";
export const LOWER_LIMIT_OF_ENTITE_DATE_CREATION = moment(limitBeginEntiteDateCreation, "DD/MM/YYYY");

// Filtre mes declarations
export const ANNEE_ENVOI_SOFIA = "E";
export const ANNEE_PIECE = "P";
export const ANNEE_FACTURATION = "F";

export const DOUTEUSE = "D";
export const SAUVEGARDE = "S";
export const ENVOYEE = "E";
export const FACTUREE = "F";
export const ABANDONNEE = "A";
export const MES_DECLARATIONS_DOUTEUSE = "douteuse";
export const MES_DECLARATIONS_EN_ATTENTE = "enAttente";
export const MES_DECLARATIONS_ENVOYEE = "envoyee";
export const MES_DECLARATIONS_ENVOYEE_BIB = "envoyeeBib";
export const MES_DECLARATIONS_FACTUREE = "facturee";

export const DEFAULT_SIZE_PER_PAGE = 50;

// Tri des colonnes
export const SORT_DESC = "DESC";
export const SORT_ASC = "ASC";
export const SORT_NULL = "retraitColonneDuTri";

const STATISTIQUES_EXPORTS_COMMONS = [
    {
        title: LIBELLE_EXPORT_MENSUALISATION,
        component: <FiltreMensualisation/>
    }
];

export const STATISTIQUES_EXPORTS_FOURNISSEUR = [
    {
        title: LIBELLE_EXPORT_LIGNES_EAN,
        component: <FiltreExportLignes/>
    },
    ...STATISTIQUES_EXPORTS_COMMONS
];

export const STATISTIQUES_EXPORTS_BIBLIOTHEQUE = STATISTIQUES_EXPORTS_COMMONS;


export const MES_FACTURES_STATUT_AUCUN = 0;
export const MES_FACTURES_STATUT_A_REGLER = 1;
export const MES_FACTURES_STATUT_REGLE_PARTIELLEMENT = 2;
export const MES_FACTURES_STATUT_PAYE = 3;
export const MES_FACTURES_STATUT_ANNULEE = 4;
export const MES_FACTURES_STATUT_ABANDON_DE_CREANCE = 5;

export const etatReglementOptions = [
    {
        label: "mesFacturesStatutARegle",
        value: MES_FACTURES_STATUT_A_REGLER
    },
    {
        label: "mesFacturesStatutReglePartiellement",
        value: MES_FACTURES_STATUT_REGLE_PARTIELLEMENT
    }, {
        label: "mesFacturesStatutPaye",
        value: MES_FACTURES_STATUT_PAYE
    }, {
        label: "mesFacturesStatutAnnulee",
        value: MES_FACTURES_STATUT_ANNULEE
    },
    {
        label: "mesFacturesStatutAbandonDeCreance",
        value: MES_FACTURES_STATUT_ABANDON_DE_CREANCE
    }
];


export const DISABLED_USER = "disabledUser";
export const DISABLED_USER_FOR_ADMIN = "disabledUserForAdmin";
export const USER_NOT_FOUND = "userNotFound";
export const USER_PNB = "userPNB";
export const USER_PNB_WITH_PARENT = "userPNBWithParent";

export const USER_COMPTE_CLOS = "userCompteClos";
export const INCORRECT_GLN_ERROR = "incorrectGLNError";
export const CHECK_PWD_ERROR = "checkPasswordError";
export const SUCCES_SEND_MAIL_FOR_PWD = "successSendMailForPwd";
export const INCORRECT_GLN_FOR_PWD = "incorrectGLNForPwd";
export const FAILURE_SEND_MAIL_PWD = "failureSendMailForPwd";
export const CHECK_GLN_ERROR = "checkGLNError";
export const ODP_NON_ASSUJETTI = "odpNonAssujetti";
export const BAD_CREDENTIALS = "badCredentials";
export const ERREUR_TEMPORARILY_DISABLED_USER = "userTemporarilyDisabled";
export const MDP_NULL_MESSAGE = "mdpNullMessage";
export const ERROR_SEND_MAIL_FOR_PWD = "errorSendMailForPwd";

export const CIVILITE_CODE_MME = "MME";
export const CIVILITE_CODE_MONSIEUR = "M";

export const ERREUR_SIGN_UP = "erreurSignUp";
export const ERREUR_SIGN_INTERN = "erreurSignIntern";
export const ERREUR_DISABLED_USER_LIBELLE = "disabledTemp";

export const LOW_PADDING_CELL_STYLE = { verticalAlign: "middle", padding: "0 10px" };


// Les noms des champs formulaire de type texte pour une entité
export const GENCOD = "gencod";
export const RAISON_SOCIALE_1 = "raisonSociale1";
export const RAISON_SOCIALE_2 = "raisonSociale2";
export const COURRIEL_ENTITE = "courriel";
export const SITE = "site";
export const EDISSII = "ediSsii";
export const FORME_JURIDIQUE = "formeJuridique";
export const FORME_JURIDIQUE_AUTRE = "formeJuridiqueAutre";
export const SIRET = "siret";
export const APE = "ape";
export const DATE_CREATION = "dateCreation";
export const TELEPHONE = "telephone";
export const TYPE_ORGANISME = "typeOrganisme";
export const TYPE_ORGANISME_AUTRE = "typeAutre";
export const SIEGE_SOCIAL = "siegeSocial";
export const MAIL_DEMANDEUR = "mailDemandeur";

// // Les noms des champs formulaire de type texte pour Adresse
export const PRE_ADRESSE = "preAdresse";
export const NUMERO_VOIE = "numeroVoie";
export const ADRESSE_1 = "adresse1";
export const ADRESSE_2 = "adresse2";
export const CODE_POSTAL = "codePostal";
export const VILLE = "ville";
export const CODE_PAYS = "codePays";


// Les noms des champs formulaire de type texte pour un contact mon profil
export const GLN = "gln";
export const CIVILITE = "civilite";
export const NOM = "nom";
export const PRENOM = "prenom";
export const QUALITE = "qualite";
export const MAIL_CONTACT = "email";

export const INSCRIPTION_ENTITE = "inscriptionStepEntite";
export const INSCRIPTION_RDP = "inscriptionStepRDP";
export const INSCRIPTION_RL = "inscriptionStepRL";
export const INSCRIPTION_RECHERCHE_GLN = "inscriptionStepRechercheGLN";
export const INSCRIPTION_CONFIRMATION = "inscriptionStepConfirmation";

export const DEMANDE_REFERENCEMENT_LABEL = "demandeReferencement";

export const MON_PROFIL_SAUVEGARDE_CONTACT = "monProfilSauvegardeContact";

// Les noms des champs formulaire nous contacter
export const TYPE_ENTITE = "typeEntite";
export const MESSAGE = "message";
export const RAISON_SOCIALE = "raisonSociale";

export const NB_ERREURS_MAX = 50;
