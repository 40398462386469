import axios from "axios";

export const getUtilisateur = gencod => axios.post("/utilisateur/getById", gencod, { headers: { "Content-Type": "text/plain" } });

export const getMailForUtilisateur = gencod => axios.post("/utilisateur/getEmail", gencod, { headers: { "Content-Type": "text/plain" } });

export const updatePreferenceSizePagination = (gencod, preferenceSizePagination) => {
    axios.post("/utilisateur/updatePreferenceSizePagination", {
        gencod,
        preferenceSizePagination
    });
};

export const updateLocaleForUser = (gencod, locale) => axios.post("/utilisateur/updateLocaleForUser", {
    gencod,
    locale
});

export const isValidSirenOrSiret = value => axios.get("/utilisateur/public/isValidSirenOrSiret", {
    params: {
        value
    }
});
