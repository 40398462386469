import React from "react";
import { Trans } from "@lingui/macro";
import classes from "./translationMultipeKey.module.css";
function prepareKey (key) {
    const keyString = key ? key.toString() : "";
    return `integrationDeclarationDoute${keyString}`;
}

const TranslationMultipeKeyContainer = props => <div className={classes.spanClass}>
    {props.keyMessageArray.map((key, index) => (
        <span key={index} ><Trans id={prepareKey(key)}/></span>
    ))}
</div>;

export default TranslationMultipeKeyContainer;
