import React, { useState } from "react";
import * as VilleInseeService from "../../../services/ville.service";
import { LIST_CODE_PAYS_FRANCE } from "../../../utils/paysConstantes";
import CodeAndVilleAutoSuggestBase from "../../autoSuggest/CodeAndVilleAutoSuggestBase";

function VilleInseeAutosuggest(props) {
    const [suggestions, setSuggestions] = useState([]);
    const [appelId, setAppelId] = useState(null);

    const { codePostal, codePays, changeCodePostalAndVille, InputComponent, inputProps: initialInputProps } = { ...props };

    function shouldRenderSuggestions(value) {
        const lengthLimiter = value.trim().length >= 3;
        const enFrance = LIST_CODE_PAYS_FRANCE.includes(codePays);
        const showSuggestions = suggestions && enFrance; // On affiche les suggestions de villes pour la france uniquement

        // On supprime le controle de taille si le codePostal est déjà présent
        if (codePostal && codePostal.length === 5) {
            return showSuggestions;
        }
        return showSuggestions && lengthLimiter;
    }


    function onSuggestionsFetchRequested({ value }) {
        if (value !== "" && LIST_CODE_PAYS_FRANCE.includes(codePays)) {
            // Pour éviter de surcharger le back, si la chaine à rechercher change alors qu'une recherche est déjà en cours alors on l'annule
            if (appelId !== null) {
                clearTimeout(appelId);
            }
            const id = setTimeout(async () => {
                let response;
                // Si on a un code postal on s'en sert pour la recherche
                if (codePostal && codePostal.length === 5) {
                    response = await VilleInseeService.getVillesByNomAndCodePostal(value, codePostal);
                } else {
                    response = await VilleInseeService.getVillesByNom(value);
                }
                setSuggestions(response && response.data ? response.data : []);
            }, 750);
            setAppelId(id);
        }
    }

    return (
        <CodeAndVilleAutoSuggestBase
            suggestions={suggestions}
            setSuggestions={setSuggestions}
            inputProps={initialInputProps}
            changeCodePostalAndVille={changeCodePostalAndVille}
            InputComponent={InputComponent}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            shouldRenderSuggestions={shouldRenderSuggestions}
        />
    );
}

export default VilleInseeAutosuggest;
