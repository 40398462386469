import React from "react";
import ConfirmDialog from "../dialog/confirmDialog/confirmDialog.component";
import { globalI18n } from "../../i18n.conf";
import { LIBELLE_NON, LIBELLE_OUI } from "../../utils/libelleConstantes";

export default function LinkToLaSofia({ to = "", children, isDialogOpen, setDialogOpen }) {
    const urlSofia = "https://www.la-sofia.org/";

    const onAgreeClick = () => {
        setDialogOpen(false);
        window.open(urlSofia + to);
    };

    return (
        <div>
            <ConfirmDialog
                open={isDialogOpen}
                title={"Attention"}
                agreeCode={globalI18n._(LIBELLE_OUI)}
                onAgreeClick={onAgreeClick}
                onDisagreeClick={() => setDialogOpen(false)}
                disagreeCode={globalI18n._(LIBELLE_NON)}>
                <p>{globalI18n._("redirectionSiteInstitutionnel")}</p>
            </ConfirmDialog>
            {children}
        </div>
    );
}
