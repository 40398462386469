import React from "react";
import { URL_COMMUNICATION_ACTUS, URL_COMMUNICATION_DDP } from "../../utils/siteInstitutionnelConstantes";
import Iframe from "../../commonComponents/iframe/iframe.component";

export function CommunicationDpp() {
    return (
        <Iframe src={URL_COMMUNICATION_DDP}/>
    );
}

export function CommunicationActus() {
    return (
        <Iframe src={URL_COMMUNICATION_ACTUS}/>
    );
}
