import React, { Component } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Trans } from "@lingui/macro";
import { Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index.action";
import {
    LIBELLE_ADRESSE_MAIL,
    LIBELLE_ERREUR_GLN_LENGTH,
    LIBELLE_GLN,
    LIBELLE_GLN_MDP_OUBLIE,
    LIBELLE_GLN_OBLIGATOIRE,
    LIBELLE_MAIL_OBLIGATOIRE,
    LIBELLE_MAIL_REFERENCE,
    LIBELLE_SEND_REQUEST
} from "../../utils/libelleConstantes";
import { GLN_REGEX, REQUIRED } from "../../utils/validatorUtil";
import { LabelInputTextWithChildren } from "../../commonComponents/input/fieldInput";
import { enableInstantValidate, formatInputGencod } from "../../utils/utility";
import classes from "./mdpOublie.module.css";

class MdpOublie extends Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
    }

    state = {
        adressemail: ""
    };

    onChangeInputField = event => {
        this.setState({
            adressemail: event.target.value
        });
    };

    render() {
        const { gln, disableButton, onChangeGencod, onSendMailForPasswordReset } = this.props;
        const { adressemail } = this.state;
        return (
            <ValidatorForm ref={this.formRef} onSubmit={() => onSendMailForPasswordReset(gln, adressemail)} className={classes.form} instantValidate={false}>
                <div>
                    <Trans id={LIBELLE_GLN_MDP_OUBLIE}/>
                </div>
                <LabelInputTextWithChildren labelKey={LIBELLE_GLN} required>
                    <TextValidator
                        id="GLN"
                        name="gln"
                        value={gln}
                        onInput={formatInputGencod}
                        onChange={onChangeGencod}
                        className={classes.TextValidator}
                        validators={[
                            REQUIRED,
                            GLN_REGEX
                        ]}
                        errorMessages={[
                            <Trans id={LIBELLE_GLN_OBLIGATOIRE}/>,
                            <Trans id={LIBELLE_ERREUR_GLN_LENGTH}/>
                        ]}
                    />
                </LabelInputTextWithChildren>
                <LabelInputTextWithChildren labelKey={LIBELLE_ADRESSE_MAIL} required>
                    <TextValidator
                        id="mail"
                        name="adressemail"
                        value={adressemail}
                        type="text"
                        onChange={this.onChangeInputField}
                        className={classes.TextValidator}
                        validators={[
                            "required",
                            "isEmail"
                        ]}
                        errorMessages={[
                            <Trans id={LIBELLE_MAIL_OBLIGATOIRE}/>,
                            <Trans id={LIBELLE_MAIL_REFERENCE}/>
                        ]}
                    />
                </LabelInputTextWithChildren>
                <div className={classes.submitButton}>
                    <Button
                        variant="contained"
                        className="submitButton"
                        color="primary"
                        type="submit"
                        onClick={() => enableInstantValidate(this.formRef)}
                        disabled={disableButton}>
                        <Trans id={LIBELLE_SEND_REQUEST}/>
                    </Button>
                </div>
            </ValidatorForm>
        );
    }
}


const mapStateToProps = state => ({
    gln: state.login.username,
    disableButton: state.common.disabledButton
});

const mapDispatchToProps = dispatch => ({
    onChangeGencod: event => dispatch(actions.onChangeLogin(event.target.value)),
    onSendMailForPasswordReset: (genCode, mail, locale) => dispatch(actions.sendMailForPasswordReset(genCode, mail, locale))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MdpOublie));
export { MdpOublie };
