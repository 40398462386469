import { Trans } from "@lingui/macro";
import React from "react";
import { Info } from "@material-ui/icons";
import { MenuItem } from "@material-ui/core";
import { PATH_MENU_FACTURES } from "../../../routes/paths/paths.util";
import BaseNotification from "../../../commonComponents/layout/notification/base/baseNotification";
import { LIBELLE_NOTIFICATION_NOUVELLE_FACTURE } from "../../../utils/libelleConstantes";

export function DetectionNewFactureNotification({ notifications, callBack }) {
    return notifications.length > 0 && notifications.map(n => <MenuItem key={n.id}><BaseNotification
        suffixPath={PATH_MENU_FACTURES}
        color={"orange"}
        icon={<Info/>}
        callBack={callBack}
        dateCreation={n.dateCreation}
        label={<Trans id={LIBELLE_NOTIFICATION_NOUVELLE_FACTURE} values={{ value: n.compteNouvelleFacture }}/>}/></MenuItem>);
}
