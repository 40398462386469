import { Trans } from "@lingui/macro";
import { DialogContent } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import ConfirmDialog from "../confirmDialog.component";

class ContentConfirmDialog extends React.Component {
    constructor (props) {
        super(props);
        this.confirmDialogRef = null;
        this.state = {
            dynamicContent: null,
            translatedContent: null,
            paramValue: ""
        };
    }

    openConfirmDialogWithDynamicContent = (dynamicContent, title = null, showAgreeButton = true) => {
        this.setDynamicContent(dynamicContent);
        this.confirmDialogRef.setTitle(title);
        this.confirmDialogRef.setShowAgreeButton(showAgreeButton);
        this.confirmDialogRef.openDialog();
    };

    openConfirmDialogWithDynamicContentAndParam = (dynamicContent, paramValue) => {
        this.setDynamicContentAndParamValue(dynamicContent, paramValue);
        this.confirmDialogRef.openDialog();
    };

    openConfirmDialogWithTranslatedContent = translatedContent => {
        this.setTranslatedContent(translatedContent);
        this.confirmDialogRef.openDialog();
    };

    setDynamicContent = dynamicContent => {
        this.setState({
            dynamicContent
        });
    };

    setTranslatedContent = translatedContent => {
        this.setState({
            translatedContent
        });
    };

    setDynamicContentAndParamValue = (dynamicContent, paramValue) => {
        this.setState({
            dynamicContent,
            paramValue
        });
    };

    setOnAgreeClick = func => {
        this.confirmDialogRef.setOnAgreeClick(func);
    };

    setCloseAlertDialogClick = func => {
        this.confirmDialogRef.setCloseAlertDialogClick(func);
    }

    setConfirmDialogRef = ref => {
        this.confirmDialogRef = ref;
    };

    render () {
        const { dynamicContent, paramValue, translatedContent } = this.state;
        const content = dynamicContent
            ? <Trans id={dynamicContent}>{paramValue}</Trans>
            : translatedContent;
        return (
            <ConfirmDialog
                ref={this.setConfirmDialogRef}
                { ...this.props }>
                <DialogContent>
                    <p style={{ textAlign: "center", whiteSpace: "pre-line" }}>{content}</p>
                </DialogContent>
            </ConfirmDialog>
        );
    }
}

ContentConfirmDialog.propTypes = {
    onAgreeClick: PropTypes.func,
    onDisagreeClick: PropTypes.func
};

export default ContentConfirmDialog;
