import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import React from "react";
import { withI18n } from "@lingui/react";
import { localeMap } from "../../utils/constantes";
import { defaultDatePickerProps } from "../../utils/objectUtil";

function DatePicker(props) {
    const { dateProps, i18n } = props;
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[i18n.locale]}>
            <KeyboardDatePicker
                {...defaultDatePickerProps(dateProps.disableMinDate)}
                {...dateProps}
            />
        </MuiPickersUtilsProvider>);
}

export default withI18n(DatePicker);
