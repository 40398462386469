import React, { useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import { IconButton } from "@material-ui/core";
import { useDispatch } from "react-redux";
import RechercheAnnuaireDialog from "../../dialog/rechercheAnnuaireDialog";
import StyledTooltip from "../../tooltip/styledTooltip.component";
import { LIBELLE_RECHERCHE_ANNULAIRE } from "../../../utils/libelleConstantes";
import { initRechercheGLN } from "../../../store/actions/rechercheGLN.action";
export default function RechercheAnnuaire() {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const closeDialog = () => setOpen(false);
    const openDialog = () => {
        dispatch(initRechercheGLN());
        setOpen(true);
    };

    return <div>
        <StyledTooltip show={true} keyMessage={LIBELLE_RECHERCHE_ANNULAIRE}><IconButton onClick={openDialog}><SearchIcon/></IconButton></StyledTooltip>
        <RechercheAnnuaireDialog open={open} close={closeDialog} disabledChoice={true}/>
    </div>;
}

RechercheAnnuaire.whyDidYouRender = true;
