import { CircularProgress, TextField } from "@material-ui/core";
import React from "react";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";
import { Trans } from "@lingui/macro";
import { globalI18n } from "../../i18n.conf";
import { GreenCheckbox } from "../checkBox/GreenCheckBox";
import { getLibelle } from "../../utils/libelleUtil";
import { LIBELLE_AUCUNE } from "../../utils/libelleConstantes";


const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

export function getLibelleAndCount(libelle, count) {
    return (typeof count === "number") ? `${libelle}  (${count})` : libelle;
}

export const getOptionLibelleAndCount = option => getLibelleAndCount(getLibelle(option.libelle), option.count);

export const getVilleRaisonSocialeLabel = option => {
    const raisonSociale = option.raisonSociale || `${option.raisonSociale1} ${option.raisonSociale2}`;
    return getLibelleAndCount(`${raisonSociale} | ${option.ville} | ${option.codePostal}`, option.count);
};

const getLabelWithTotal = getLabel => option => {
    if (option && Object.keys(option).length > 0) {
        if (option.title && option.total && option.value === "selectAll") {
            return getLibelleAndCount(globalI18n._(option.title), option.total);
        } else if (option.title) {
            return globalI18n._(option.title);
        }
        return getLabel(option);
    }
    return "";
};

export const getRenderOption = (option, { selected }, func, multipe, allSelected) => {
    const checked = option.value && option.value === "selectAll" ? allSelected : selected;
    return (
        <>
            {multipe && <GreenCheckbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={checked}
            />}
            <span>{func(option)}</span>
        </>
    );
};

function CustomAutoComplete(props) {
    const { getLabel, allSelected, helperText, ...autoCompleteProps } = { ...props };

    let getOptionLabelSelected = "";
    if (getLabel) {
        getOptionLabelSelected = getLabelWithTotal(getLabel);
    }

    const getCustomRenderOption = (option, { selected }) => getRenderOption(option, { selected }, getOptionLabelSelected, props.multiple, allSelected);
    const renderInput = params => <TextField {...params} helperText={helperText} variant="outlined" label={<Trans id={props.label}/>} InputProps={{
        ...params.InputProps,
        endAdornment: (
            <React.Fragment>
                {props.loading ? <CircularProgress color="inherit" size={20}/> : null}
                {params.InputProps.endAdornment}
            </React.Fragment>
        )
    }}/>;
    return (
        <Autocomplete
            noOptionsText={<Trans id={LIBELLE_AUCUNE}/>}
            renderOption={getCustomRenderOption}
            renderInput={renderInput}
            getOptionLabel={getOptionLabelSelected}
            {...autoCompleteProps}
        />
    );
}

export default CustomAutoComplete;

export const AutoCompleteWithAllSelect = ({
    all,
    onClose,
    selected,
    update,
    label,
    allObject,
    getOptionSelected,
    className,
    getLabel,
    disabled,
    loading,
    helperText
}) => {
    const allSelected = all && all.length === selected.length;

    const onChange = (event, options) => {
        let news = options || [];
        // Si la case selectAll était selectionnée et qu'on clique dessus elle est encore selectionnée
        if (allSelected && options && options.length > all.length) {
            news = [];
        } else if (options && !allSelected && options.find(option => option.title && option.value === "selectAll")) {
            news = all;
        }
        update(news);
    };
    return (
        <CustomAutoComplete
            getLabel={getLabel}
            limitTags={2}
            options={[allObject, ...all]}
            onChange={onChange}
            getOptionSelected={getOptionSelected}
            label={label}
            allSelected={allSelected}
            value={selected}
            className={className}
            disabled={disabled}
            loading={loading}
            helperText={helperText}
            onClose={onClose}
        />
    );
};

CustomAutoComplete.propTypes = {
    label: PropTypes.string.isRequired,
    limitTags: PropTypes.number,
    multiple: PropTypes.bool,
    allSelected: PropTypes.bool,
    disableCloseOnSelect: PropTypes.bool,
    disableClearable: PropTypes.bool,
    style: PropTypes.object,
    defaultValue: PropTypes.array,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired
};

CustomAutoComplete.defaultProps = {
    disableCloseOnSelect: true,
    disableClearable: false,
    multiple: true,
    allSelected: false,
    disabled: false,
    loading: false,
    helperText: null,
    onClose: () => {
    }
};
