import { MenuItem } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import React from "react";
import { Info } from "@material-ui/icons";
import { useSelector } from "react-redux";
import BaseNotification from "../../../commonComponents/layout/notification/base/baseNotification";
import { getLibelle } from "../../../utils/libelleUtil";
import { selectLibelle } from "../../../utils/selectors";
import { LIBELLE_TYPE_FONCTION_CONTACT } from "../../../utils/libelleConstantes";

export default function UpdateContactMailNotification({ notifications }) {
    const { libelles } = useSelector(selectLibelle);
    return notifications.length > 0 && notifications.map(n => {
        const libelleContact = libelles.find(l => l.type === LIBELLE_TYPE_FONCTION_CONTACT && l.code === n.fonction);
        return <MenuItem key={n.id}><BaseNotification
            color={"orange"}
            icon={<Info/>}
            dateCreation={n.dateCreation}
            label={<Trans values={{ function: getLibelle(libelleContact) }}>notificationModificationContactMailForContact</Trans>}
            toRedirect={false}/></MenuItem>;
    });
}

