import { call, put, select } from "redux-saga/effects";
import { push } from "connected-react-router";
import {
    getFichierRecapitulatifByIdIntegration,
    getListDeclarationsEnteteByIdIntegration,
    getListDeclarationsLigneByNumeroDeclaration,
    sendIntegrationEnEtat
} from "../../../../services/integrationTableur.service";
import * as actions from "../../../actions/index.action";
import { selectRootPath, selectState } from "../../../../utils/selectors";
import { PATH_MENU_MES_DECLARATIONS } from "../../../../routes/paths/paths.util";
import {
    ERREUR_INTEGRATION_BY_ID,
    ERREUR_INTEGRATION_DECLARATION_SEND,
    ERREUR_INTEGRATION_LIGNE_BY_NUMERODECLARATION,
    INTEGRATION_DECLARATION_SUIVANTE_LABEL
} from "../../../../utils/libelleConstantes";


export function *getListDeclarationsEnteteByIdIntegrationSaga(action) {
    try {
        yield put(actions.setIntegrationsTableurDeclarationLoading(true));
        const data = yield getListDeclarationsEnteteByIdIntegration(action.idIntegration);
        yield put(actions.getListeDeclarationsEnteteByIdIntegrationResult(data.data));
    } catch (error) {
        yield put(actions.openDialog(ERREUR_INTEGRATION_BY_ID));
    }
    yield put(actions.setIntegrationsTableurDeclarationLoading(false));
}

export function *getListDeclarationsLigneByNumeroDeclarationSaga(action) {
    try {
        yield put(actions.setIntegrationsTableurChoixEntetesLoading(true));
        const data = yield getListDeclarationsLigneByNumeroDeclaration(action.numeroDeclaration);
        yield put(actions.getListeDelcarationsLigneByNumeroDeclarationResult(action.rowIndex, Object.values(data.data)));
        yield put(actions.setIntegrationsTableurChoixEntetesLoading(false));
    } catch (error) {
        yield put(actions.setIntegrationsTableurChoixEntetesLoading(false));
        yield put(actions.openDialogNoRedirect(ERREUR_INTEGRATION_LIGNE_BY_NUMERODECLARATION, error.message));
    }
}


export function *validateDeclarationsEnEtatSaga(action) {
    try {
        const state = yield select(selectState);
        yield put(actions.setSentIntegrationLoading(true));
        const response = yield sendIntegrationEnEtat(action.idIntegration);
        yield getFichierRecapitulatifByIdIntegration(action.idIntegration);
        yield put(actions.setIntegrationStatut(response.data.data.integrationStatus));
        yield put(actions.setSentIntegrationLoading(false));
        yield put(actions.checkIfEcartIsPresent(state.utilisateur.informations.gencod));
        const pathRoot = yield select(selectRootPath);
        yield put(push(`${pathRoot}${PATH_MENU_MES_DECLARATIONS}`));
    } catch (error) {
        yield put(actions.setSentIntegrationLoading(false));
        yield put(actions.openDialogNoRedirect(ERREUR_INTEGRATION_DECLARATION_SEND, error.message));
    }
}

export function *showNextIntegrationExcel() {
    const state = yield select(selectState);
    const entetes = state.integrationTableurDeclaration.declarations.entetes;
    const totalSize = entetes.length;
    const rowIndex = state.integrationTableurDeclaration.rowIndex;
    const idIntegration = state.integrationTableur.integration.id;
    yield call(getListDeclarationsEnteteByIdIntegrationSaga, { idIntegration });

    const oneDeclarationLeft = totalSize === 1;
    const rowIndexOnLastDeclaration = rowIndex === totalSize - 1;

    if (!oneDeclarationLeft && !rowIndexOnLastDeclaration) {
        yield put(actions.updateFlagShowAndMessageSnackbar(true, INTEGRATION_DECLARATION_SUIVANTE_LABEL, {}));
    }
    yield put(actions.setDeclarationLoading(false));
}
