import axios from "axios";
import { downloadFileFromResponse, openFileFromResponse } from "../utils/downloadUtil";

export const getFacturesEntetes = data => axios.post("/factureEntete/getFacturesEntetesByFiltre", data);

export const getMesFactures = data => axios.post("/factureEntete/getMesFactures", data);

const getFactureDocument = numeroFacture => axios.get(
    "/factureEntete/getFactureDocument",
    {
        params: {
            numeroFacture
        },
        responseType: "blob"
    }
);

export const downloadFactureDocument = numeroFacture => getFactureDocument(numeroFacture).then(downloadFileFromResponse(`Facture_${numeroFacture}.pdf`));

export const openFactureDocument = numeroFacture => getFactureDocument(numeroFacture).then(openFileFromResponse(`Facture_${numeroFacture}.pdf`));
