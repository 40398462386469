import configureMockStore from "redux-mock-store";
import { runSaga } from "redux-saga";
import thunk from "redux-thunk";
import { act } from "react-dom/test-utils";
import { PATH_BIBLIO_MENU, PATH_FOURNI_MENU } from "../routes/paths/paths.util";
import { MON_PROFIL_STEP_FIN, MON_PROFIL_STEP_MDP, MON_PROFIL_STEPS_BIBLIOTHEQUE, MON_PROFIL_STEPS_FOURNISSEUR } from "../components/monProfil/monProfilUtil";
import { MES_PARTENAIRES_DTO_SOMME_TTC } from "../components/mesPartenaires/util/mesPartenairesUtil";
import { newDeclarationEntete } from "../components/declaration/web/declarationEntete/DeclarationEnteteTemplate";
import {
    CONTACT_FONCTION,
    FACTUREE,
    PROFIL_BIBLIOTHEQUE,
    PROFIL_FOURNISSEUR,
    REPRESENTANT_LEGAL_CONTENT,
    RESPONSABLE_DROIT_PRET_CONTENT,
    ROLES,
    SAUVEGARDE,
    SORT_DESC,
    SORT_NULL,
    TYPE_EXCEL,
    TYPE_WEB
} from "./constantes";
import { getMenuBibliotheque, getMenuFourni } from "./menuUtil";
import * as model from "./model";
import { getSort } from "./bootstrapTableUtil";
import { getInscriptionContactModel } from "./utility";
import { steps_demande_referencement } from "./steps";
import { DATE_ECHEANCE, DATE_FACTURE, MONTANT_RESTE_A_PAYER, NUMERO_FACTURE, STATUT, TOTAL_TTC } from "./libelleConstantes";


const middlewares = [thunk];
export const mockStore = configureMockStore(middlewares);

export const createProps = () => ({
    classes: {},
    hiddenInput: false,
    profil: PROFIL_FOURNISSEUR,
    inscriptionState: {
        inscriptionEntite: model.inscriptionEntite,
        inscriptionRL: { ...getInscriptionContactModel(REPRESENTANT_LEGAL_CONTENT) },
        inscriptionRdp: { ...getInscriptionContactModel(RESPONSABLE_DROIT_PRET_CONTENT) }
    }
});

export async function recordSaga(saga, initialAction, getState = () => ({})) {
    const dispatched = [];

    await runSaga(
        {
            dispatch: action => dispatched.push(action),
            getState
        },
        saga,
        initialAction
    ).done;

    return dispatched;
}

export const getByTypeAndText = (type, text) => node => (node.type() === type && node.name() && node.text() === text);

/**
 * Used to wait for an async part of a function to finish.
 *
 * @param done
 * @param fn test assertions
 * @returns {Function}
 */
export function eventually(done, fn) {
    return (...args) => {
        try {
            fn(...args);
            done();
        } catch (err) {
            done(err);
        }
    };
}


export const getCell = (cellValue, doutes = []) => ({
    cellValue,
    doutes,
    erreur: null,
    erreurParam: null,
    readOnly: false
});

export const libelleStore = {
    libelle: {
        libelles: [
            {
                type: "function_contact",
                code: "RDP",
                libelleFr: "Responsable droit prêt",
                libelleEn: ""
            },
            {
                type: "function_contact",
                code: "RL",
                libelleFr: "Représentant legal",
                libelleEn: ""
            },
            {
                type: "function_contact",
                code: "DFA",
                libelleFr: "Responsable de suivi des factures",
                libelleEn: "Invoice manager"
            },
            {
                type: "civilite",
                code: "M",
                libelleFr: "Monsieur",
                libelleEn: ""
            },
            {
                type: "civilite",
                code: "Mme",
                libelleFr: "Madame",
                libelleEn: ""
            }
        ]
    }
};

export const loginEmptyStore = () => ({
    login: {
        username: "",
        token: null,
        userInfos: {
            sub: null,
            scope: [],
            authorities: [],
            exp: null,
            jti: null,
            aud: []
        },
        loading: false,
        loginRedirectPath: "/",
        menuItems: [],
        reloadFinished: true,
        reloadLogin: true
    }
});

export const monProfilStore = {
    monProfil: {
        loading: false,
        compteUtilisateur: {
            gencod: "",
            raisonSociale1: "",
            raisonSociale2: "",
            preAdresse: "",
            adresse1: "",
            adresse2: "",
            codePostal: "",
            ville: "",
            codePays: "",
            siret: "",
            ape: "",
            formeJuridique: "",
            formeJuridiqueAutre: "",
            dateCreation: null,
            telephone: "",
            courriel: "",
            site: "",
            ediSsii: "",
            siegeSocial: false,
            typeAutre: ""
        },
        contacts: [],
        infoSofia: {
            assujettissementDDP: null,
            regroupe: false,
            entiteGerante: "",
            isGestionnaire: false,
            contentieux: false,
            npai: null,
            compteClos: null,
            deltaDetaille: false
        },
        isCorrectPwd: false,
        skipDFAStep: false,
        steps: []
    }
};

export const mesStatistiquesStore = {
    statistiquesDeclarations: {
        anneeDebut: 2013,
        anneeFin: new Date().getUTCFullYear(),
        anneeCivile: null,
        filtrePartenaires: [],
        selectedPartenaires: [],
        filtreFactures: [],
        selectedFactures: [],
        loadingPartenairesFactures: false,
        loadingFactures: false,
        displayPartenaireHelperText: false,
        displayFactureHelperText: false,
        disabledButton: true,
        disableFiltrePartenaire: false,
        disableFiltreFacture: false,
        countExportEanLigne: 0,
        loadingCountExportEanLigne: false
    }
};

export const utilisateurInformationsPremiereConnexion = (
    stepPremiereConnexion = MON_PROFIL_STEP_FIN,
    steps = [],
    entiteValid = true,
    rdpValid = true,
    rdpMailValid = true,
    dfaValid = true,
    dfaMailValid = true,
    rlValid = true,
    rlMailValid = true,
    bibliothequeEtrangere = false
    // eslint-disable-next-line max-params
) => ({
    premiereConnexion: {
        stepPremiereConnexion,
        steps,
        rdpDuplique: false,
        npai: false,
        entiteValid,
        rdpValid,
        rdpMailValid,
        dfaValid,
        dfaMailValid,
        rlValid,
        rlMailValid,
        bibliothequeEtrangere
    }
});

export const utilisateurStore = (gencod, gerant = false) => ({
    utilisateur: {
        informations: {
            gencod,
            assujettissementDDP: "",
            entiteRattachee: "",
            regroupe: !gerant,
            deltaDetaille: false,
            canMakeDeclarationGlobale: false,
            canMakeDeclarationDetaillee: true,
            preferenceSizePagination: 10,
            ...utilisateurInformationsPremiereConnexion()
        },
        roles: [],
        favoris: [],
        regroupements: []
    }
});

export const userInfoStore = (gencod, role) => ({
    userInfos: {
        aud: ["aud"],
        sub: gencod,
        scope: [
            "read",
            "write"
        ],
        exp: Date.now() + 10,
        authorities: [role],
        jti: "jdti",
        client_id: "cliend_id"
    }
});

export const utilisateurFournisseurStore = (gencod, gerant = true) => ({
    utilisateur: {
        ...utilisateurStore(
            gencod,
            gerant
        ).utilisateur,
        roles: [{ role: 2, type: ROLES.FOURNISSEUR }]
    },
    login: {
        ...userInfoStore(
            gencod,
            ROLES.FOURNISSEUR
        ),
        username: gencod,
        loginRedirectPath: PATH_FOURNI_MENU,
        menuItems: getMenuFourni(gerant),
        reloadFinished: true
    },
    admin: {
        usurperRedirectPath: "/",
        loading: false
    },
    declaration: {
        typeDeclaration: TYPE_WEB,
        declaration: {
            validEntetes: [
                {
                    numeroPiece: getCell("ref1"),
                    statut: getCell(SAUVEGARDE),
                    numeroDeclaration: getCell("1000003501"),
                    glnDeclarant: getCell(gencod),
                    glnPartenaire: getCell("3000000000000"),
                    datePiece: getCell("20/02/20"),
                    dateRecap: getCell(""),
                    totalTtc: getCell("2.00"),
                    totalHt: getCell("2.00"),
                    livreHt: getCell("11"),
                    valide: getCell(true),
                    ddp: "11",
                    tva: 5.5
                }
            ],
            validLignes: [
                {
                    prixPublicTtc: getCell("8.20"),
                    prixUnitaireFel: getCell(null),
                    provenance: getCell("FEL"),
                    ean: getCell("9782070394289"),
                    titre: getCell("RAVAGE"),
                    statut: getCell("S"),
                    prixPublicHt: getCell("7.77"),
                    quantite: getCell("3"),
                    editeur: getCell("GALLIMARD"),
                    canBeMergedByEan: true,
                    canBeMergedByTitleAndEditor: true,
                    numeroLigne: 1
                }
            ],
            readOnly: false,
            enteteInvalid: newDeclarationEntete()
        },
        partenaire: {
            gencod: "3000000000000",
            raisonSociale: "CDI DU LYCEE GENERAL ET TECHNOLOGIQUE JEANNE HACHETTE",
            ville: "BEAUVAIS",
            codePostal: "60000"
        },
        declarant: {
            gencod,
            raisonSociale: "ZOOM EDITIONS",
            ville: "DIFFERDANGE",
            codePostal: "L 462"
        },
        enableToUpdateStore: true,
        loading: false,
        skipSearchInfoPartenaire: false
    },
    inscription: {
        inscriptionEntite: { ...model.inscriptionEntite },
        profilDialog: PROFIL_FOURNISSEUR,
        inscriptionRL: { ...getInscriptionContactModel(REPRESENTANT_LEGAL_CONTENT) },
        inscriptionRdp: { ...getInscriptionContactModel(RESPONSABLE_DROIT_PRET_CONTENT) },
        formMailValide: [{ code: CONTACT_FONCTION.REPRESENTANT_LEGAL, valide: false }, { code: CONTACT_FONCTION.RESPONSABLE_DROIT_DE_PRET, valide: false }]
    },
    ...libelleStore,
    rechercheGLN: {
        results: [],
        filtre: {}
    },
    stepForm: {
        stepsReferencement: steps_demande_referencement,
        activeStep: 0
    },
    demandeReferencement: {
        demandeFormulaire: {
            ...model.demandeReferencement
        }
    },
    common: {
        showSnackbar: false,
        keyMessageSnackbar: "",
        paramMessageSnackbar: {},
        closeSnackBar: false,
        filepond: {
            openFileNameDialog: false,
            fileName: "",
            fileNameDialogErrorMessage: ""
        },
        aideUtilisateur: []
    },
    globalDialog: {
        openDialog: false,
        message: "",
        cause: "",
        message2: "",
        redirect: false
    },
    notifications: {
        nombreNotifications: 0
    },
    integrationTableur: {
        deleteLoading: false
    },
    ...monProfilStore,
    ...mesStatistiquesStore,
    mesPartenaires: {
        favoris: [
            {
                gencodPartenaire: "3056069380000",
                raisonSociale: "CDI DU LYCEE GENERAL ET TECHNOLOGIQUE JEANNE HACHETTE",
                adresse1: "",
                adresse2: "RUE A. DESJARDINS",
                codePostal: "60000",
                ville: "BEAUVAIS",
                ferme: false,
                type: "fakeType"
            },
            {
                gencodPartenaire: "3056000210304",
                raisonSociale: "TEST PIERRE SOFIA",
                adresse1: "",
                adresse2: "BOULEVARD SAINT GERMAIN",
                codePostal: "75007",
                ville: "PARIS",
                ferme: false,
                type: "fakeTYpe"
            },
            {
                gencodPartenaire: "3056000012205",
                raisonSociale: "BIBLIOTHEQUE MUNICIPALE DE TSINGONI",
                adresse1: "",
                adresse2: "RUE DU STADE",
                codePostal: "97680",
                ville: "MROALE",
                ferme: false,
                type: "fakeTYpe"
            }
        ]
    }
});

export const utilisateurFournisseurPremiereConnexionStore = gencod => ({
    ...utilisateurFournisseurStore(gencod),
    utilisateur: {
        ...utilisateurFournisseurStore(gencod).utilisateur,
        informations: {
            ...utilisateurFournisseurStore(gencod).utilisateur.informations,
            ...utilisateurInformationsPremiereConnexion(
                MON_PROFIL_STEP_MDP,
                MON_PROFIL_STEPS_FOURNISSEUR,
                false,
                false,
                false,
                false,
                false
            )
        }
    }
});

export const utilisateurFournisseurUsurpeStore = gencod => ({
    ...utilisateurFournisseurStore(gencod),
    login: {
        ...userInfoStore(
            "",
            ROLES.ADMIN
        ),
        username: "",
        loginRedirectPath: "/admin",
        suffixRedirectPath: "",
        menuItems: utilisateurFournisseurStore(gencod).login.menuItems
    },
    admin: {
        usurperRedirectPath: PATH_FOURNI_MENU,
        loading: false
    }
});

/**
 *  Compléter le store en cas de besoin
 */
export const utilisateurBibliothequeStore = (gencod, gerant = true) => ({
    utilisateur: {
        ...utilisateurStore(gencod).utilisateur,
        roles: [{ role: 1, type: ROLES.BIBLIOTHEQUE }]
    },
    login: {
        ...userInfoStore(
            gencod,
            ROLES.BIBLIOTHEQUE
        ),
        username: gencod,
        loginRedirectPath: PATH_BIBLIO_MENU,
        menuItems: getMenuBibliotheque(gerant),
        reloadFinished: true
    },
    admin: {
        usurperRedirectPath: "/",
        loading: false
    },
    inscription: {
        inscriptionEntite: { ...model.inscriptionEntite },
        profilDialog: PROFIL_BIBLIOTHEQUE
    },
    rechercheGLN: {
        results: [],
        filtre: {}
    },
    stepForm: {
        stepsReferencement: steps_demande_referencement,
        activeStep: 0
    },
    demandeReferencement: {
        demandeFormulaire: {
            ...model.demandeReferencement
        }
    },
    ...monProfilStore,
    ...mesStatistiquesStore,
    ...libelleStore
});

export const utilisateurBibliothequePremiereConnexionStore = gencod => ({
    ...utilisateurBibliothequeStore(gencod),
    utilisateur: {
        ...utilisateurBibliothequeStore(gencod).utilisateur,
        informations: {
            ...utilisateurBibliothequeStore(gencod).utilisateur.informations,
            ...utilisateurInformationsPremiereConnexion(
                MON_PROFIL_STEP_MDP,
                MON_PROFIL_STEPS_BIBLIOTHEQUE,
                false,
                false,
                false,
                false,
                false
            )
        }
    }
});

export const utilisateurBibliothequeUsurpeeStore = gencod => ({
    ...utilisateurBibliothequeStore(gencod),
    login: {
        ...userInfoStore(
            "",
            ROLES.ADMIN
        ),
        username: "",
        loginRedirectPath: "/admin",
        suffixRedirectPath: "",
        menuItems: utilisateurBibliothequeStore(gencod).login.menuItems
    },
    admin: {
        usurperRedirectPath: PATH_BIBLIO_MENU,
        loading: false
    }
});

export const CLASSIC_GENCOD_TEST = "3012405005914";
export const classicStore = {
    ...utilisateurFournisseurStore(CLASSIC_GENCOD_TEST),
    declaration: {
        partenaire: {
            gencod: "3056069380000",
            raisonSociale: "CDI DU LYCEE GENERAL ET TECHNOLOGIQUE JEANNE HACHETTE",
            ville: "BEAUVAIS",
            codePostal: "60000"
        },
        loading: false,
        skipSearchInfoPartenaire: false,
        declarant: {
            gencod: "test",
            raisonSociale: "",
            ville: "",
            codePostal: ""
        },
        declaration: {
            validLignes: [],
            validEntetes: [
                {
                    numeroPiece: {
                        cellValue: "fakeRef",
                        doutes: []
                    },
                    datePiece: {
                        cellValue: "fakeDate",
                        doutes: []
                    },
                    totalHt: {
                        cellValue: "fakeTotalHt",
                        doutes: []
                    },
                    totalTtc: {
                        cellValue: "fakeTotalTtc",
                        doutes: []
                    },
                    livreHt: {
                        cellValue: "fakeLivreHt",
                        doutes: []
                    },
                    numeroDeclaration: {
                        cellValue: "123456",
                        doutes: []
                    },
                    glnPartenaire: {
                        cellValue: "fakeGln",
                        doutes: []
                    },
                    statut: {
                        cellValue: "S",
                        doutes: []
                    },
                    raisonSociale: {
                        cellValue: "raison sociale 1",
                        doutes: []
                    },
                    ville: {
                        cellValue: "ville 1",
                        doutes: []
                    },
                    ddp: {
                        cellValue: "fakeddp"
                    },
                    dateRecap: {
                        cellValue: "",
                        doutes: []
                    }
                }
            ]
        }
    },
    attestationFin: {
        attestations: [
            {
                annee: 2020,
                montantDeclare: 30,
                ecartPresent: false,
                dateCreation: new Date().toDateString(),
                loading: false
            }
        ],
        loading: null
    },
    common: {
        isLoading: false,
        showSnackbar: false,
        keyMessageSnackbar: "",
        paramMessageSnackbar: {},
        closeSnackBar: false,
        aideUtilisateur: []
    },
    integrationTableurDeclaration: {
        numeroDeclaration: "1",
        rowIndex: "1"
    },
    integrationTableurEntete: {
        numeroDeclaration: "1",
        rowIndex: "1"
    },
    rechercheGLN: {
        isCalledFromDeclaration: false
    }
};
export const excelStore = {
    ...classicStore,
    declaration: {
        ...classicStore.declaration,
        typeDeclaration: TYPE_EXCEL
    }
};
export const excelGlobalStore = {
    ...excelStore,
    declaration: {
        ...excelStore.declaration,
        declaration: {
            ...excelStore.declaration.declaration,
            globale: true
        }
    }
};

export const mesFacturesStore = {
    mesFactures: {
        page: 1,
        total: 12,
        factures: [],
        filtre: {
            anneeDebut: 2013,
            anneeFin: new Date().getUTCFullYear(),
            statut: {}
        },
        sortColumns: [
            {
                column: DATE_FACTURE,
                direction: SORT_DESC
            }, {
                column: NUMERO_FACTURE,
                direction: SORT_NULL
            }, {
                column: TOTAL_TTC,
                direction: SORT_NULL
            }, {
                column: MONTANT_RESTE_A_PAYER,
                direction: SORT_NULL
            }, {
                column: STATUT,
                direction: SORT_NULL
            }, {
                column: DATE_ECHEANCE,
                direction: SORT_NULL
            }
        ],
        loading: false
    },
    utilisateur: {
        informations: {
            gencod: "11111111111111",
            preferenceSizePagination: 10,
            premiereConnexion: {
                stepPremiereConnexion: null
            }
        }
    }
};

export const mesDeclarationsEntete = (statut, avoirisee = false, avoirEnCours = undefined, doffice = false, valide = "true") => ({
    entete: {
        ville: {
            cellValue: "LYON",
            doutes: [],
            isReadOnly: false
        },
        dateModification: {
            cellValue: "2021-04-28 07:30:08",
            doutes: [],
            isReadOnly: false
        },
        numeroFactureSofia: {
            cellValue: "10",
            doutes: [],
            isReadOnly: false
        },
        totalTtc: {
            cellValue: "200.00",
            doutes: [],
            isReadOnly: false
        },
        numeroPiece: {
            cellValue: "10",
            doutes: [],
            isReadOnly: false
        },
        glnDeclarant: {
            cellValue: "11111111111111",
            doutes: [],
            isReadOnly: false
        },
        numeroDeclaration: {
            cellValue: "1",
            doutes: [],
            isReadOnly: false
        },
        ddp: {
            cellValue: "15.13",
            doutes: [],
            isReadOnly: false
        },
        datePiece: {
            cellValue: "04/06/20",
            doutes: [],
            isReadOnly: false
        },
        raisonSociale: {
            cellValue: "SERVICE DOCUMENTATION",
            doutes: [],
            isReadOnly: false
        },
        livreHt: {
            cellValue: "252.09",
            doutes: [],
            isReadOnly: false
        },
        glnPartenaire: {
            cellValue: "3056424020008",
            doutes: [],
            isReadOnly: false
        },
        totalHt: {
            cellValue: "242.04",
            doutes: [],
            isReadOnly: false
        },
        dateRecap: {
            cellValue: "2015-08-31",
            doutes: [],
            isReadOnly: false
        },
        statut: {
            cellValue: statut,
            doutes: [],
            isReadOnly: false
        },
        valide: {
            cellValue: valide,
            doutes: [],
            isReadOnly: false
        },
        doffice: getCell(doffice ? "true" : "false"),
        numeroAvoir: avoirisee && !avoirEnCours && {
            cellValue: "20",
            doutes: [],
            isReadOnly: false
        }
    },
    douteuse: false,
    globale: false,
    type: "EDI",
    declarationAvoirisee: avoirisee,
    disableEditing: true,
    integrationExcelEnCours: false
});

export const mesDeclarationsStore = {
    mesDeclarations: {
        page: 1,
        sort: [
            {
                column: "datePiece",
                direction: "retraitColonneDuTri"
            },
            {
                column: "numeroPiece",
                direction: "retraitColonneDuTri"
            },
            {
                column: "totalHt",
                direction: "retraitColonneDuTri"
            },
            {
                column: "totalTtc",
                direction: "retraitColonneDuTri"
            },
            {
                column: "ddp",
                direction: "retraitColonneDuTri"
            },
            {
                column: "dateModification",
                direction: "DESC"
            },
            {
                column: "dateEnvoiSofia",
                direction: "DESC"
            },
            {
                column: "dateDeFacture",
                direction: "DESC"
            },
            {
                column: "raisonSociale1",
                direction: "ASC"
            },
            {
                column: "ville",
                direction: "ASC"
            }
        ],
        typeDeclaration: "F",
        totalSizeRecap: 42,
        nombreDeclarationParType: {
            envoyee: 0,
            facturee: 42,
            douteuse: 0,
            enAttente: 0
        },
        filtresPartenaires: [
            {
                gln: "2222222222222",
                raisonSociale: "Partenaire 1",
                ville: "PARIS",
                count: 1686
            }
        ],
        filtresSource: [
            {
                libelle: "EDI",
                count: 42
            },
            {
                libelle: "WEB",
                count: 0
            },
            {
                libelle: "XLS",
                count: 0
            },
            {
                libelle: "EDI XLS",
                count: 0
            }
        ],
        glnDeclarant: "3027011300400",
        glnPartenaires: [
            {
                gln: "3333333333333",
                raisonSociale: "Partenaire 2",
                ville: "LYON",
                count: 42
            }
        ],
        anneeDebut: 2013,
        anneeFin: 2014,
        typeAnnee: "P",
        sourcesDeclaration: [],
        doutes: [],
        entetes: [mesDeclarationsEntete(FACTUREE)],
        statistiques: {
            mesDeclarationsTotalHT: 10000.00,
            mesDeclarationsTotalTTC: 9500.00,
            mesDeclarationsTotalLivreHT: 10000.00,
            mesDeclarationsTotalDDP: 600.00,
            mesDeclarationsTotalPPHT: 7000.00,
            mesDeclarationsTotalPPHTFEL: 7000.00,
            mesDeclarationsTotalPPTTC: 7000.00,
            mesDeclarationsNombreLivres: 10
        },
        nombreDoutesParType: [],
        nombreTotalDoutes: 0,
        rowIndex: -1,
        showClone: false,
        showOriginale: false,
        openDetailDialog: false,
        loading: false,
        loadingStatistiquesMontants: false,
        showStatistiquesMontants: false,
        numeroFacture: ""
    }
};

export function declarationEntete(statut, avoirisee = false, avoirEnCours = undefined, doffice = false) {
    return mesDeclarationsEntete(
        statut,
        avoirisee,
        avoirEnCours,
        doffice
    ).entete;
}

export const contactStore = fonction => ({
    civilite: "",
    nom: "",
    prenom: "",
    telephone: "",
    qualite: "",
    fonction,
    numeroVoie: "",
    adresse1: "",
    adresse2: "",
    codePostal: "",
    ville: "",
    codePays: "",
    email: ""
});

export const notificationStore = {
    notifications: {
        monProfil: {
            updateMailForContactFunctionList: []
        },
        croisementBibFour: {
            isEcartDetecte: false
        },
        nbNotifications: 0,
        mesFactures: {
            facturesRelancees: 0,
            facturesMiseEnDemeure: 0,
            isDossierEnContentieux: 0
        }
    }
};

export const commonStore = {
    common: {
        isLoading: false,
        disabledButton: false,
        filArianeKeyMessage: "",
        filArianeValue: "",
        showSnackbar: false,
        keyMessageSnackbar: "",
        paramMessageSnackbar: {},
        isErrorSiret: false,
        aideUtilisateur: []
    }
};

export const globalDialogStore = {
    globalDialog: {
        openDialog: false,
        message: "",
        message2: "",
        redirect: false,
        cause: ""
    }
};

export const mesPartenairesStore = {
    mesPartenaires: {
        loadingExport: false,
        loadingFavoris: false,
        favoris: [],
        totalFavoris: 0,
        sortsFavoris: [
            getSort(
                MES_PARTENAIRES_DTO_SOMME_TTC,
                SORT_DESC
            )
        ],
        pageFavoris: 1,
        sizePageFavoris: 20,
        loadingRecents: false,
        recents: [],
        totalRecents: 0,
        sortsRecents: [
            getSort(
                MES_PARTENAIRES_DTO_SOMME_TTC,
                SORT_DESC
            )
        ],
        pageRecents: 1,
        sizePageRecents: 20,
        showDeleteFavoriPopup: false
    }
};

export const waitForComponentToRender = async wrapper => {
    await act(async () => {
        await new Promise(resolve => setTimeout(resolve));
        wrapper.update();
    });
};
