import { put } from "redux-saga/effects";
import { sendMailForPasswordReset } from "../../../services/mdpOublie.service";
import * as actions from "../../actions/index.action";
import {
    CHECK_GLN_ERROR,
    CHECK_PWD_ERROR,
    ERROR_SEND_MAIL_FOR_PWD,
    FAILURE_SEND_MAIL_PWD,
    INCORRECT_GLN_ERROR,
    INCORRECT_GLN_FOR_PWD,
    SUCCES_SEND_MAIL_FOR_PWD,
    USER_COMPTE_CLOS
} from "../../../utils/constantes";

export function *mdpOublieSaga(action) {
    const gln = action.gln;
    const adressemail = action.adressemail;
    yield put(actions.updateDisableValideButton(true));
    try {
        const responseSendMail = yield sendMailForPasswordReset(
            gln,
            adressemail
        ); // 2
        if (responseSendMail.data && responseSendMail.data.data) {
            yield put(actions.openDialog(SUCCES_SEND_MAIL_FOR_PWD, true));
        } else if (
            responseSendMail.data &&
            responseSendMail.data.message.messageKey === CHECK_PWD_ERROR
        ) {
            yield put(actions.openDialog(INCORRECT_GLN_FOR_PWD));
        } else if (
            responseSendMail.data &&
            responseSendMail.data.message.messageKey === CHECK_GLN_ERROR
        ) {
            yield put(actions.openDialog(INCORRECT_GLN_ERROR));
        } else if (
            responseSendMail.data &&
            responseSendMail.data.message.messageKey === USER_COMPTE_CLOS
        ) {
            yield put(actions.openDialog(USER_COMPTE_CLOS));
        } else {
            yield put(actions.openDialog(ERROR_SEND_MAIL_FOR_PWD));
        }
    } catch (error) {
        yield put(actions.openDialog(FAILURE_SEND_MAIL_PWD));
    }
    yield put(actions.updateDisableValideButton(false));
}
