import * as actionTypes from "../../actionTypes/actionTypes";
import { updateObject } from "../../../utils/utility";


const initialState = {
    nombreNotifications: 0,
    facturesRelancees: [],
    facturesMisesEnDemeure: [],
    fournisseursEnContentieux: [],
    modificationsMailContact: [],
    nouvellesFactures: [],
    ecartDeclaration: null,
    declaration2006: null,
    declarationEnAttente: null,
    loading: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.UPDATE_NOTIFICATIONS_SUCCESS:
        return updateObject(state, action.data);
    case actionTypes.UPDATE_ECARTS_DETECTE:
        return updateObject(state, { state: { ecartDeclaration: action.ecartDeclaration } });
    case actionTypes.INCREMENT_NB_NOTIFICATIONS:
        return updateObject(state, { nombreNotifications: state.nombreNotifications + action.nombreNotifications });
    case actionTypes.DECREMENT_NB_NOTIFICATIONS:
        return updateObject(state, { nombreNotifications: state.nombreNotifications - action.nombreNotifications });
    case actionTypes.UPDATE_NOTIFICATIONS_MAIL:
        return updateObject(state, { modificationsMailContact: [...action.notifications] });
    case actionTypes.UPDATE_NOTIFICATIONS_LOADING:
        return updateObject(state, { loading: action.loading });
    default:
        return state;
    }
};
export default reducer;
