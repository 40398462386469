import * as actions from "../actionTypes/actionTypes";


export const updateNotificationsSuccess = data => ({
    type: actions.UPDATE_NOTIFICATIONS_SUCCESS,
    data
});

export const updateEcartDetecte = ecartDeclaration => ({
    type: actions.UPDATE_ECARTS_DETECTE,
    ecartDeclaration
});

export const incrementNbNotifications = (nombreNotifications = 0) => ({
    type: actions.INCREMENT_NB_NOTIFICATIONS,
    nombreNotifications
});

export const decrementNbNotifications = (nombreNotifications = 0) => ({
    type: actions.DECREMENT_NB_NOTIFICATIONS,
    nombreNotifications
});

export const getAllNotifications = (gencod, avecEcart = false) => ({
    type: actions.GET_ALL_NOTIFICATIONS,
    gencod,
    avecEcart
});

export const updateNotificationsVue = () => ({
    type: actions.UPDATE_VUE_NOTIFICATIONS
});

export const updateNotificationsMail = notifications => ({
    type: actions.UPDATE_NOTIFICATIONS_MAIL,
    notifications
});

export const updateNotificationsLoading = loading => ({
    type: actions.UPDATE_NOTIFICATIONS_LOADING,
    loading
});


