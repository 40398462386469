import React from "react";
import { Trans } from "@lingui/macro";
import {
    autoCompleteOnChange,
    getField,
    inputOnChange,
    isPaysFrance
} from "../../../components/monProfil/monProfilUtil";
import {
    LabelInputTextWithChildren,
    PaysLibelleAutoComplete,
    TextValidatorSmallOutlined,
    TextValidatorSmallOutlinedUpperCase
} from "../fieldInput";
import { getNumericValueForInput, isBlank } from "../../../utils/utility";
import {
    ADRESSE_1,
    ADRESSE_2,
    CODE_PAYS,
    CODE_POSTAL,
    MAX_LENGTH_ADRESSE_1,
    MAX_LENGTH_ADRESSE_2,
    MAX_LENGTH_CODE_POSTAL,
    MAX_LENGTH_NUMERO_VOIE,
    MAX_LENGTH_VILLE,
    NUMERO_VOIE,
    VILLE
} from "../../../utils/constantes";
import {
    LIBELLE_ADRESSE_1,
    LIBELLE_ADRESSE_2,
    LIBELLE_CHAMP_OBLIGATOIRE,
    LIBELLE_CODE_POSTAL,
    LIBELLE_CODE_POSTAL_MIN_LENGTH,
    LIBELLE_NUMERO_VOIE,
    LIBELLE_PAYS,
    LIBELLE_VILLE
} from "../../../utils/libelleConstantes";
import VilleInseeAutosuggest from "../../layout/autoCompletion/villeInseeAutosuggest";
import CodePostalAutoSuggest from "../../layout/autoCompletion/codePostalAutoSuggest";
import { REQUIRED } from "../../../utils/validatorUtil";


export default function AdresseFieldGroupComponent({ formulaire, setFormulaire, classes, showOnlyPaysFrance = false, applyAllRequired = true }) {
    // Met à jour le champ modifié dans notre objet de state formulaire
    function onChange(field, value) {
        setFormulaire({
            ...formulaire,
            [field]: value
        });
    }

    function onChangePays(field, value) {
        setFormulaire({
            ...formulaire,
            [field]: value,
            ville: "",
            codePostal: ""
        });
    }

    // Met à jour la ville et le code postal qui en depend
    function onChangeVille(field, value) {
        setFormulaire({
            ...formulaire,
            [field]: value,
            codePostal: isBlank(value) ? "" : formulaire.codePostal
        });
    }

    // Met à jour le code postal et la ville qui en depend
    function onChangeCodePostal(field, value) {
        setFormulaire({
            ...formulaire,
            [field]: value,
            ville: isBlank(value) ? "" : formulaire.ville
        });
    }

    function changeCodePostalAndVille(codePostal, ville) {
        setFormulaire({
            ...formulaire,
            ville,
            codePostal
        });
    }
    function getValidator() {
        return isPaysFrance(formulaire) ? [REQUIRED, "matchRegexp:^\\d{5}$"] : [REQUIRED];
    }

    function getErrorMessage() {
        const errorMessage = [<Trans id={LIBELLE_CHAMP_OBLIGATOIRE}/>];
        if (isPaysFrance(formulaire)) {
            errorMessage.push(<Trans id={LIBELLE_CODE_POSTAL_MIN_LENGTH}/>);
        }
        return errorMessage;
    }

    return <>
        <LabelInputTextWithChildren labelKey={LIBELLE_NUMERO_VOIE}>
            <TextValidatorSmallOutlinedUpperCase
                value={getField(formulaire, NUMERO_VOIE)}
                onChange={inputOnChange(NUMERO_VOIE, onChange)}
                inputProps={{ maxLength: MAX_LENGTH_NUMERO_VOIE }}/>
        </LabelInputTextWithChildren>
        <LabelInputTextWithChildren labelKey={LIBELLE_ADRESSE_1} required={applyAllRequired}>
            <TextValidatorSmallOutlinedUpperCase
                value={getField(formulaire, ADRESSE_1)}
                onChange={inputOnChange(ADRESSE_1, onChange)}
                inputProps={{ maxLength: MAX_LENGTH_ADRESSE_1 }}
                validators={applyAllRequired ? [REQUIRED] : []}
                errorMessages={[<Trans id={LIBELLE_CHAMP_OBLIGATOIRE}/>]}/>
        </LabelInputTextWithChildren>
        <LabelInputTextWithChildren labelKey={LIBELLE_ADRESSE_2}>
            <TextValidatorSmallOutlinedUpperCase
                value={getField(formulaire, ADRESSE_2)}
                onChange={inputOnChange(ADRESSE_2, onChange)}
                inputProps={{ maxLength: MAX_LENGTH_ADRESSE_2 }}/>
        </LabelInputTextWithChildren>
        <LabelInputTextWithChildren labelKey={LIBELLE_CODE_POSTAL} required>
            <CodePostalAutoSuggest
                codePays={formulaire && formulaire.codePays}
                codePostal={formulaire && formulaire.codePostal}
                changeCodePostalAndVille={changeCodePostalAndVille}
                InputComponent={TextValidatorSmallOutlined}
                inputProps={{
                    value: getField(formulaire, CODE_POSTAL),
                    onChange: inputOnChange(CODE_POSTAL, onChangeCodePostal),
                    onInput: isPaysFrance(formulaire) ? e => getNumericValueForInput(e, 5) : null,
                    inputProps: { maxLength: MAX_LENGTH_CODE_POSTAL },
                    validators: getValidator(),
                    errorMessages: getErrorMessage()
                }}/>
        </LabelInputTextWithChildren>
        <LabelInputTextWithChildren labelKey={LIBELLE_VILLE} required>
            <VilleInseeAutosuggest
                codePays={formulaire && formulaire.codePays}
                codePostal={formulaire && formulaire.codePostal}
                changeCodePostalAndVille={changeCodePostalAndVille}
                InputComponent={TextValidatorSmallOutlinedUpperCase}
                inputProps={{
                    value: getField(formulaire, VILLE),
                    onChange: inputOnChange(VILLE, onChangeVille),
                    inputProps: { maxLength: MAX_LENGTH_VILLE },
                    validators: [REQUIRED],
                    errorMessages: [<Trans id={LIBELLE_CHAMP_OBLIGATOIRE}/>]
                }}/>
        </LabelInputTextWithChildren>
        <LabelInputTextWithChildren labelKey={LIBELLE_PAYS} required>
            <PaysLibelleAutoComplete id="paysAutoComplete"
                value={getField(formulaire, CODE_PAYS)}
                onChange={autoCompleteOnChange(CODE_PAYS, onChangePays)}
                showOnlyPaysFrance={showOnlyPaysFrance}
                className={classes.inputFields}
                validators={[REQUIRED]}
                errorMessages={[<Trans id={LIBELLE_CHAMP_OBLIGATOIRE}/>]}/>
        </LabelInputTextWithChildren>
    </>;
}
AdresseFieldGroupComponent.whyDidYouRender = true;
