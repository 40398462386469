import React from "react";
import { Trans } from "@lingui/macro";
import { makeStyles, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { TextValidator } from "react-material-ui-form-validator";
import { selectLibelle } from "../../utils/selectors";
import { compareLibelles, getLibelle } from "../../utils/libelleUtil";
import { inputFields } from "../../components/monProfil/monProfilUtil";
import { LIST_CODE_PAYS_FRANCE } from "../../utils/paysConstantes";
import { LIBELLE_TYPE_PAYS } from "../../utils/libelleConstantes";
import { setSelectionRangeAndUpperCase } from "../../utils/utility";

export const classeNames = {
    parent: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    labelInput: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    labelText: {
        width: "300px"
    },
    requiredLabel: {
        color: "red"
    },
    textareaStyle: {
        width: "500px",
        padding: "5px"
    },
    inputFields,
    sauvegarderButton: {
        display: "flex",
        flexDirection: "row-reverse",
        paddingTop: "10px"
    }
};
const useStyles = makeStyles(classeNames);

export const LabelInputTextWithChildren = ({ labelKey, required, children, classNames }) => {
    const defaultClassNames = useStyles();
    const classes = classNames ? classNames : defaultClassNames;
    return (<div className={classes.parent}>
        <div className={classes.labelText}><Trans id={labelKey}/> {required && <span className={classes.requiredLabel}>*</span>} :</div>
        {children}
    </div>);
};

export const TextValidatorSimple = React.forwardRef((props, ref) => <TextValidator
    ref={ref}
    type="text"
    {...props}
    className={`${useStyles().inputFields} ${props.className}`}/>);

export const TextareaAutosizeSimple = props => <TextValidatorSimple
    rows={8}
    className={useStyles().textareaStyle}
    type="text"
    variant={"outlined"}
    size={"small"}
    multiline
    {...props} />;


export const TextValidatorSmallOutlined = React.forwardRef((props, ref) => <TextValidatorSimple
    {...props}
    ref={ref}
    variant={"outlined"}
    size={"small"}
    value={props.value || ""}/>);

export const TextValidatorSmallOutlinedUpperCase = React.forwardRef((props, ref) => <TextValidatorSimple
    {...props}
    ref={ref}
    variant={"outlined"}
    size={"small"}
    value={props.value || ""}
    onChange={e => {
        setSelectionRangeAndUpperCase(e);
        props.onChange(e);
    }}/>);


export const PaysLibelleAutoComplete = props => {
    const { libelles } = useSelector(selectLibelle);
    let libellesTypes = libelles.filter(l => l.type === "pays");
    const { showOnlyPaysFrance, ...otherProps } = props;
    if (showOnlyPaysFrance) {
        libellesTypes = libellesTypes.filter(l => LIST_CODE_PAYS_FRANCE.includes(l.code));
    }
    return <LibelleAutoCompleteSmallOutlined
        typeLibelle={LIBELLE_TYPE_PAYS}
        name="pays"
        selectedLibelles={libellesTypes}
        {...otherProps} />;
};

export const LibelleAutoComplete = props => {
    const { libelles } = useSelector(selectLibelle);
    const { id, typeLibelle, value, onChange, disabled, selectedLibelles, customCompare, addCustomFilter, className, ...otherProps } = { ...props };
    let libellesTypes = selectedLibelles
        ? selectedLibelles
        : libelles.filter(l => l.type === typeLibelle);
    if (!selectedLibelles && addCustomFilter) {
        libellesTypes = libellesTypes.filter(addCustomFilter);
    }

    customCompare
        ? libellesTypes.sort(customCompare)
    // Tri des libellés en fonction de la locale
        : libellesTypes.sort(compareLibelles);

    const selectedLibelle = libellesTypes.find(l => l.code === value) || null;
    return <Autocomplete
        id={id}
        options={libellesTypes}
        getOptionLabel={getLibelle}
        value={selectedLibelle}
        onChange={onChange}
        disabled={disabled}
        className={className}
        renderInput={params => <TextValidator value={value} {...params} {...otherProps} />}/>;
};

export const LibelleAutoCompleteSmallOutlined = props => <LibelleAutoComplete
    type="text"
    className={useStyles().inputFields}
    variant="outlined"
    size="small"
    {...props}/>;

export const TextFieldDisabled = props => <TextField
    disabled
    id="outlined-disabled"
    label={<Trans id={props.labelKey}/>}
    defaultValue={props.value}
    variant="outlined"
    margin="normal"
    fullWidth
    InputLabelProps={{
        shrink: true
    }}
/>;
