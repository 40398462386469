import { call, put, select } from "redux-saga/effects";
import { getCountDeclarantListEcartDetaille, getListeEcartsForDeclarant } from "../../../services/croisementBibFour.service";
import * as actions from "../../actions/index.action";
import { selectState } from "../../../utils/selectors";
import { ERREUR_RECUPERER_LISTE_ECART, ERREUR_VERIFIER_ECART } from "../../../utils/libelleConstantes";


export function *getListeEcartsFourBib(action) {
    try {
        yield put(actions.updateLoading(true));

        const state = yield select(selectState);
        const { gencod, preferenceSizePagination } = state.utilisateur.informations;
        const filtre = {
            gencodDeclarant: gencod,
            detailedView: action.isDetailedView,
            page: state.croisementBibFour.page - 1,
            size: preferenceSizePagination,
            sortColumn: state.croisementBibFour.sortColumn,
            anneeDeclaree: state.croisementBibFour.annee
        };
        const response = yield call(getListeEcartsForDeclarant, filtre);
        if (action.isDetailedView) {
            yield put(actions.updateListeEcartsDetaille(response.data.ecarts));
        } else {
            yield put(actions.updateListeEcartsGlobal(response.data.ecarts));
        }
    } catch (error) {
        yield put(actions.openDialogNoRedirect(ERREUR_RECUPERER_LISTE_ECART, error.message));
    }
}

export function *getCountDeclarantListEcartFourBib() {
    try {
        const state = yield select(selectState);
        const gencodDeclarant = state.utilisateur.informations.gencod;
        const annee = state.croisementBibFour.annee;
        const response = yield call(getCountDeclarantListEcartDetaille, gencodDeclarant, annee);
        yield put(actions.updateTotalEcartsDetaille(response.data));
        if (response.data) {
            yield call(getListeEcartsFourBib, { isDetailedView: true });
        }
    } catch (error) {
        yield put(actions.openDialogNoRedirect(ERREUR_VERIFIER_ECART, error.message));
    }
}


export function *openCroisementDialogSaga(action) {
    try {
        yield put(actions.updateLoading(true));
        yield put(actions.openCroisementDialog(action.annee));
        yield call(getCountDeclarantListEcartFourBib);
    } catch (error) {
        yield put(actions.openDialogNoRedirect(ERREUR_VERIFIER_ECART, error.message));
    }
}

