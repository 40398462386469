import { Trans } from "@lingui/macro";
import { MenuItem } from "@material-ui/core";
import React from "react";
import { Info } from "@material-ui/icons";
import { PATH_MENU_MES_DECLARATIONS } from "../../../routes/paths/paths.util";
import BaseNotification from "../../../commonComponents/layout/notification/base/baseNotification";

const NotificationDeclaration2006 = ({ notification, callBack }) => {
    if (notification === null) {
        return null;
    }
    return (
        <MenuItem><BaseNotification
            suffixPath={PATH_MENU_MES_DECLARATIONS}
            color={"green"}
            icon={<Info/>}
            callBack={callBack}
            dateCreation={notification.dateCreation}
            label={<Trans id={"notificationDeclaration2006"}/>}/></MenuItem>
    );
};

export default NotificationDeclaration2006;
