import * as actionsType from "../actionTypes/actionTypes";

export const updateLoadingMesPartenaires = loading => ({
    type: actionsType.UPDATE_LOADING_MES_PARTENAIRES,
    loading
});


export const updateLoadingMesPartenairesFavoris = loading => ({
    type: actionsType.UPDATE_LOADING_MES_PARTENAIRES_FAVORIS,
    loading
});

export const updateLoadingMesPartenairesRecents = loading => ({
    type: actionsType.UPDATE_LOADING_MES_PARTENAIRES_RECENTS,
    loading
});


export const getMesPartenaires = () => ({
    type: actionsType.GET_MES_PARTENAIRES
});

export const getMesPartenairesFavoris = () => ({
    type: actionsType.GET_MES_PARTENAIRES_FAVORIS
});

export const getMesPartenairesFavorisSuccess = (partenaires, total) => ({
    type: actionsType.GET_MES_PARTENAIRES_FAVORIS_SUCCESS,
    partenaires,
    total
});

export const getMesPartenairesRecents = () => ({
    type: actionsType.GET_MES_PARTENAIRES_RECENTS
});

export const getMesPartenairesRecentSuccess = (partenaires, total) => ({
    type: actionsType.GET_MES_PARTENAIRES_RECENTS_SUCCESS,
    partenaires,
    total
});

export const addPartenaireToFavoris = (gencodFavoris, isFournisseur) => ({
    type: actionsType.ADD_PARTENAIRE_TO_FAVORIS,
    gencodFavoris,
    isFournisseur
});

export const removePartenaireFromFavoris = gencodFavori => ({
    type: actionsType.REMOVE_PARTENAIRE_FROM_FAVORIS,
    gencodFavori
});

export const exporterPartenaires = () => ({
    type: actionsType.EXPOTER_PARTENAIRES
});

export const updateLoadingExporterPartenaires = loading => ({
    type: actionsType.UPDATE_LOADING_MES_PARTENAIRES_EXPORT,
    loading
});

export const updateSortMesPartenairesFavoris = sort => ({
    type: actionsType.UPDATE_SORT_MES_PARTENAIRES_FAVORIS,
    sort
});

export const updateSortMesPartenairesRecents = sort => ({
    type: actionsType.UPDATE_SORT_MES_PARTENAIRES_RECENTS,
    sort
});

export const updatePageMesPartenairesFavoris = page => ({
    type: actionsType.UPDATE_PAGE_MES_PARTENAIRES_FAVORIS,
    page
});

export const updatePageMesPartenairesRecents = page => ({
    type: actionsType.UPDATE_PAGE_MES_PARTENAIRES_RECENTS,
    page
});

export const updatePageSizeMesPartenairesFavoris = pageSize => ({
    type: actionsType.UPDATE_PAGE_SIZE_MES_PARTENAIRES_FAVORIS,
    pageSize
});

export const updatePageSizeMesPartenairesRecents = pageSize => ({
    type: actionsType.UPDATE_PAGE_SIZE_MES_PARTENAIRES_RECENTS,
    pageSize
});

export const updateShowDeleteFavoriPopup = showDeleteFavoriPopup => ({
    type: actionsType.UPDATE_SHOW_DELETE_FAVORI_POPUP,
    showDeleteFavoriPopup
});

export const checkIfPartenaireRecent = gencodFavori => ({
    type: actionsType.CHECK_IF_PARTENAIRE_RECENT,
    gencodFavori
});
