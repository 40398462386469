import * as actionTypes from "../../actionTypes/actionTypes";
import { updateObject } from "../../../utils/utility";

const initialState = {
    username: "",
    token: null,
    userInfos: {
        sub: null,
        scope: [],
        authorities: [],
        exp: null,
        jti: null,
        aud: []
    },
    loading: false,
    loginRedirectPath: "/",
    suffixRedirectPath: "",
    menuItems: [],
    reloadFinished: true,
    reloadLogin: true
};

const login = state => updateObject(state, { error: null });

const loginSuccess = (state, action) => updateObject(state, {
    token: action.token,
    error: null,
    userInfos: action.userInfos,
    loginRedirectPath: action.loginRedirectPath,
    suffixRedirectPath: action.suffixRedirectPath,
    loading: false
});

const loginFail = state => updateObject(state, { loading: false });

const loginReplace = (state, action) => updateObject(state, { loading: false, loginRedirectPath: "/mdpOublie", username: action.glnRemplacant });

const reinitLoginRedirectPath = state => updateObject(state, { loginRedirectPath: "/" });

const loginStart = state => updateObject(state, { loading: true });

const logout = state => updateObject(state, {
    token: null,
    error: null,
    username: "",
    userInfos: "",
    loginRedirectPath: "/",
    suffixRedirectPath: "",
    loading: false,
    menuItems: []
});

const onChangeInputLogin = (state, action) => updateObject(state, { username: action.value });


const majMenuItems = (state, action) => updateObject(state, { menuItems: action.menuItems });


const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.LOGIN:
        return login(state);
    case actionTypes.LOGIN_SUCCESS:
        return loginSuccess(state, action);
    case actionTypes.LOGIN_FAIL:
        return loginFail(state);
    case actionTypes.LOGIN_REPLACE:
        return loginReplace(state, action);
    case actionTypes.LOGIN_START:
        return loginStart(state);
    case actionTypes.LOGOUT:
        return logout(state);
    case actionTypes.LOGIN_INPUT_CHANGE:
        return onChangeInputLogin(state, action);
    case actionTypes.MAJ_MENU_ITEMS:
        return majMenuItems(state, action);
    case actionTypes.REINIT_LOGIN_REDIRECT_PATH:
        return reinitLoginRedirectPath(state);
    case actionTypes.LOGIN_UPDATE_RELOADING:
        return updateObject(state, { reloadFinished: action.flag });
    case actionTypes.RELOAD_SITE:
        return updateObject(state, { reloadLogin: action.flag });
    default:
        return state;
    }
};

export default reducer;
