import axios from "axios/index";

const GET_INFORMATIONS_MON_PROFIL = "/monProfil/getInformationsMonProfil";
const GET_INFORMATIONS_MON_PROFIL_AVEC_NOTIFICATION = "/monProfil/getInformationsMonProfilAvecNotificationMail";
const SAVE_COMPTE_UTILISATEUR = "/monProfil/saveCompteUtilisateur";
const SAVE_INFO_GLOBAL_CONTACT = "/monProfil/saveGlobalContact";
const SAVE_INFO_CONTACT_AND_DUPLICATE_IT = "/monProfil/saveInfosContactAndDuplicateIt";
const CHECK_MAIL_TOKEN = "/monProfil/public/checkMailToken";
const UPDATE_ACCES_VUE_CROISEMENT = "/monProfil/updateAccesVueCroisement";
const UPDATE_LAST_STEP_FIRST_CO_FOURNI = "/monProfil/updateLastStepPremiereConnexionFournisseur";

// @GetMapping(value = "/getInformationsMonProfil")
export const getInformationsMonProfil = gencodDeclarant => axios.get(GET_INFORMATIONS_MON_PROFIL, { params: { gencodDeclarant } });

export const getInformationsMonProfilAvecNotification = gencodDeclarant => axios.get(GET_INFORMATIONS_MON_PROFIL_AVEC_NOTIFICATION, { params: { gencodDeclarant } });

// @PostMapping(value = "/saveCompteUtilisateur")
export const saveCompteUtilisateur = compteUtilisateur => axios.post(SAVE_COMPTE_UTILISATEUR, compteUtilisateur);

export const saveInfoGlobalContact = infoContact => axios.post(SAVE_INFO_GLOBAL_CONTACT, infoContact);

// @PostMapping(value = "/saveInfosContactAndDuplicateItAsRF")
export const saveInfosContactAndDuplicateIt = infoContact => axios.post(SAVE_INFO_CONTACT_AND_DUPLICATE_IT, infoContact);

// @GetMapping(value = "/checkMailToken")
export const checkMailToken = (gencod, fonction, token, forcage) => axios.get(CHECK_MAIL_TOKEN, { params: { gencod, fonction, token, forcage } });

export const updateLastStepPremiereConnexionFournisseur = gencod => axios.get(UPDATE_LAST_STEP_FIRST_CO_FOURNI, { params: { gencod } });

// @PostMapping(value = "/updateAccesVueCroisement")
export const updateAccesVueCroisement = ({ glnUsurpe, accesVueCroisement }) => axios.post(UPDATE_ACCES_VUE_CROISEMENT, { glnUsurpe, accesVueCroisement });

