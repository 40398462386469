import { put, select, call } from "redux-saga/effects";
import { confirmeEntetesChoisies, getEchantillonEntetesIdentifieesEtAttenduesByIdIntegration } from "../../../../services/integrationTableur.service";
import { transformArrayToArrayOfObject } from "../../../../utils/objectUtil";
import * as actions from "../../../actions/index.action";
import { CONFIRM_ENTETE_ERROR } from "../../../../utils/libelleConstantes";


export function *getEchantillonEtEntetesIdentifieesEtAttenduesSaga (action) {
    yield put(actions.setIntegrationsTableurChoixEntetesLoading(true));
    try {
        const response = yield call(getEchantillonEntetesIdentifieesEtAttenduesByIdIntegration, action.idIntegration);
        yield put(actions.getEchantillonEntetesIdentifieesEtAttenduesByIdIntegrationResults(transformArrayToArrayOfObject(response.data.echantillon), response.data.entetesIdentifiees, response.data.entetesAttendues));
    } catch (error) {
        yield put(actions.openDialogNoRedirect("integrationDeclarationEnteteGetEchantillonEtEntetesIdentifieesError", error.cause));
    }
    yield put(actions.setIntegrationsTableurChoixEntetesLoading(false));
}


export function *confirmEntetesChoisiesSaga(action) {
    try {
        yield put(actions.updatePassToStepCorrectionErreur(false));
        yield put(actions.updateConfimerEnteteLoading(true));
        const response = yield call(confirmeEntetesChoisies, action.entetesSelectionnees, action.idIntegration);
        if (response && response.data) {
            yield put(actions.setIntegrationStatut(response.data.data.integrationStatus));
            yield put(actions.getCorrectionErreurEntetesChoisiesByIdIntegration(action.idIntegration));
            yield put(actions.getCorrectionErreurLignesByIdIntegration(action.idIntegration));
        }
    } catch (error) {
        yield put(actions.openDialogNoRedirect(CONFIRM_ENTETE_ERROR, error.cause));
    }
}

export function *closeDeclarationSaga() {
    const declaration = yield select(state => state.declaration.declaration);
    const partenaire = yield select(state => state.declaration.partenaire);
    const entete = declaration.validEntetes[0];
    const lignes = declaration.validLignes;
    yield put(actions.updateDeclarationEnteteEtLignes(entete, lignes, partenaire));
}
