import { call, put, select } from "redux-saga/effects";
import * as actions from "../../actions/index.action";
import {
    DISABLED_USER_FOR_ADMIN,
    ODP_NON_ASSUJETTI,
    SessionVariablesEnum,
    USER_COMPTE_CLOS,
    USER_NOT_FOUND
} from "../../../utils/constantes";
import { getPathFromDeclarantRoles } from "../../../utils/utility";
import { globalI18n } from "../../../i18n.conf";
import { selectUtilisateurRoles } from "../../../utils/selectors";
import { updateInformationsDeclarant } from "../utilisateur/utilisateur.saga";
import { getInformationsDeclarantByGencod } from "../../../services/declarant.service";


export function *usurperSaga({ gencod }) {
    yield put(actions.usurperStart);
    try {
        const response = yield getInformationsDeclarantByGencod(gencod);

        // Réinitialisation de certains stores lors d’une usurpation
        yield put(actions.reinitializeDeclarationData());
        yield put(actions.clearMDStore());

        // Récupération des informations et notifications du déclarant usurpé
        yield call(updateInformationsDeclarant, response);
        yield put(actions.getAllNotifications(gencod, true));

        // On stocke la page d’accueil de l’usurpé, pour savoir où rediriger après certaines actions comme une première connexion faite par l’admin
        const roles = yield select(selectUtilisateurRoles);
        const rolePath = getPathFromDeclarantRoles(roles);
        yield put(actions.usurperSuccess(rolePath));

        // Sauvegarde dans la session du déclarant usurpé pour pouvoir reconnecter automatiquement
        const declarantUsurpe = {
            gencod,
            roles
        };
        sessionStorage.setItem(SessionVariablesEnum.DECLARANT_USURPE, JSON.stringify(declarantUsurpe));
    } catch (error) {
        if (error.response) {
            const errorMessage = error.response.data.message.messageKey;
            if (errorMessage.includes(USER_COMPTE_CLOS)) {
                yield put(actions.openDialogNoRedirect("attentionTitre", globalI18n._(USER_COMPTE_CLOS)));
                yield put(actions.usurperUnauthorized(true));
            } else if (errorMessage.includes(ODP_NON_ASSUJETTI)) {
                yield put(actions.openDialogNoRedirect("attentionTitre", globalI18n._(ODP_NON_ASSUJETTI)));
                yield put(actions.usurperUnauthorized(true));
            } else if (errorMessage.includes(DISABLED_USER_FOR_ADMIN)) {
                const gencodRemplacant = errorMessage.split(" ")[1];
                const gencodRemplace = errorMessage.split(" ")[2];
                yield put(actions.openDialogNoRedirect("attentionTitre", globalI18n._({
                    id: DISABLED_USER_FOR_ADMIN,
                    values: { gencodRemplacant, gencodRemplace }
                })));
                yield put(actions.updateDisableButton(false));
                yield put(actions.updateGencod(gencodRemplacant));
                yield put(actions.usurper(gencodRemplacant));
            } else if (errorMessage.includes(USER_NOT_FOUND)) {
                yield put(actions.openDialogNoRedirect("attentionTitre", globalI18n._({ id: USER_NOT_FOUND, values: { gencod } })));
                yield put(actions.usurperUnauthorized(true));
            } else {
                yield put(actions.usurperFail(error.response.data.message));
                yield put(actions.openDialogNoRedirect(error.response.data.error, error.response.data.message));
            }
        }
    }
}

