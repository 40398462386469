import { makeStyles } from "@material-ui/core";
import React from "react";
import { createFilterOptions } from "@material-ui/lab";
import { useSelector } from "react-redux";
import _ from "lodash";
import { AutoCompleteWithAllSelect, getVilleRaisonSocialeLabel } from "../autocomplete/Autocomplete";
import { checkIsFournisseurConnected, getPartenaireLibelle } from "../../utils/utility";
import { selectUtilisateurRoles } from "../../utils/selectors";
import { LIBELLE_MES_DECLARATIONS_BIBS, LIBELLE_MES_DECLARATIONS_FOURNIS } from "../../utils/libelleConstantes";

const useStyle = makeStyles({
    autoComplete: {
        width: "100%",
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingBottom: "20px"
    }
});


export function GenericPartenaireFilter({
    update,
    onClose,
    allPartenaires,
    selectedPartenaires,
    getOptionSelected,
    disabled = false,
    loading = false,
    helperText
}) {
    const roles = useSelector(selectUtilisateurRoles);
    const isFournisseur = checkIsFournisseurConnected(roles);
    const classes = useStyle();
    const titre = isFournisseur ? LIBELLE_MES_DECLARATIONS_BIBS : LIBELLE_MES_DECLARATIONS_FOURNIS;
    const allObject = { title: titre, value: "selectAll" };

    const filterOptions = createFilterOptions({
        stringify: ({ raisonSociale, ville, codePostal }) => `${raisonSociale} ${ville} ${codePostal}`
    });

    return (
        <AutoCompleteWithAllSelect
            getLabel={getVilleRaisonSocialeLabel}
            allObject={allObject}
            getOptionSelected={getOptionSelected}
            label={_.capitalize(getPartenaireLibelle(roles, true))}
            all={allPartenaires}
            selected={selectedPartenaires}
            update={update}
            className={classes.autoComplete}
            disabled={disabled}
            loading={loading}
            helperText={helperText}
            onClose={onClose}
            filterOptions={filterOptions}
        />
    );
}

export default GenericPartenaireFilter;
