import Warning from "@material-ui/icons/Warning";
import { Trans } from "@lingui/macro";
import React from "react";
import { MenuItem } from "@material-ui/core";
import { useSelector } from "react-redux";
import { PATH_MENU_ECARTS_DECLARATION } from "../../../routes/paths/paths.util";
import BaseNotification from "../../../commonComponents/layout/notification/base/baseNotification";
import { selectUtilisateurRoles } from "../../../utils/selectors";
import { checkIsFournisseurConnected, getPartenaireIsFourniLibelle } from "../../../utils/utility";

export default function DetectionEcartNotification({ notification, callBack }) {
    const roles = useSelector(selectUtilisateurRoles);
    const isFournisseur = checkIsFournisseurConnected(roles);
    return notification && <MenuItem><BaseNotification
        suffixPath={PATH_MENU_ECARTS_DECLARATION}
        color={"red"}
        icon={<Warning/>}
        callBack={callBack}
        dateCreation={notification.dateCreation}
        label={<Trans id={"detectionEcart"} values={{ partenaire: getPartenaireIsFourniLibelle(isFournisseur, true) }}/>}/></MenuItem>;
}

