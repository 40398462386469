import { withStyles } from "@material-ui/styles";
import { Switch } from "@material-ui/core";
import React from "react";

export const ColoredSwitch = props => {
    const { color, ...otherProps } = { ...props };
    const CustomSwitch = withStyles({
        switchBase: {
            "&$checked": {
                color
            },
            "&$checked + $track": {
                backgroundColor: color
            }
        },
        // Si le checked et track n'est pas à vide, la couleur rose s'applique sur tout le composant
        checked: {},
        track: {}
    })(Switch);
    return <CustomSwitch {...otherProps}/>;
};


