import { I18nProvider } from "@lingui/react";
import "bootstrap/dist/css/bootstrap.min.css";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import React, { Component, lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import "./App.css";
import Layout from "./commonComponents/layout/layout.component";
import "./components/loginPanel/login/login.module.css";
import MdpOublie from "./components/mdpOublie/mdpOublie.component";
import { globalI18n } from "./i18n.conf";
import {
    PATCH_INSCRIPTION,
    PATH_ADMIN,
    PATH_BASE,
    PATH_BIBLIO_MENU,
    PATH_FOURNI_MENU,
    PATH_LOGIN,
    PATH_MDPOUBLIE,
    PATH_MODIFICATION_MAIL_CONTACT,
    PATH_NOUS_CONTACTER,
    PATH_PREMIERE_CONNEXION,
    PATH_RESET_MDP,
    ROUTE_DEMANDE_REFERENCEMENT,
    ROUTE_MENU_ATTESTATION_FIN_DECLARATIONS,
    ROUTE_MENU_COMMUNICATION_ACTUS, ROUTE_MENU_COMMUNICATION_DDP,
    ROUTE_MENU_DECLARATION,
    ROUTE_MENU_DECLARATION_EXCEL,
    ROUTE_MENU_DECLARATION_EXCEL_FICHIER,
    ROUTE_MENU_DECLARATION_WEB,
    ROUTE_MENU_ECARTS_DECLARATION,
    ROUTE_MENU_FACTURES,
    ROUTE_MENU_MES_DECLARATIONS,
    ROUTE_MENU_MES_DECLARATIONS_TYPE,
    ROUTE_MENU_MES_STATISTIQUES,
    ROUTE_MENU_PARTENAIRES_REFERENCEMENT_BIB,
    ROUTE_MENU_PARTENAIRES_REFERENCEMENT_FOURNI,
    ROUTE_MENU_PARTENAIRES_REFERENCES_BIB,
    ROUTE_MENU_PARTENAIRES_REFERENCES_FOURNI,
    ROUTE_MENU_PROFIL,
    ROUTE_MENU_PROFIL_TAB,
    ROUTE_MENU_RECAPITULATIF_VENTES,
    ROUTE_PREMIERE_CONNEXION
} from "./routes/paths/paths.util";
import * as actions from "./store/actions/index.action";
import InfoSnackbar from "./commonComponents/snackbar/infoSnackbar";
import MonProfilMotDePasse from "./components/monProfil/formulaires/motDePasse/monProfilMotDePasse.component";
import ModificationMailContact from "./components/monProfil/landingPages/modificationMailContact.component";
import ForcageProfil from "./components/monProfil/forcageProfil.component";
import { getPrefixUrl, isAdminRole, isForFirstConnectionOrForcage } from "./utils/utility";
import GlobalDialog from "./commonComponents/dialog/globalDialog/globalDialog.component";
import LoginPanel from "./components/loginPanel/loginPanel.container";
import NousContacter from "./components/nousContacter/NousContacter.component";
import { selectLoginToken, selectMonProfilActiveStep, selectUtilisateurGencod } from "./utils/selectors";
import { isNotEmpty } from "./utils/stringUtil";
import Inscription from "./components/inscription/inscription.component";
import Spinner from "./commonComponents/spinner/spinner";
import { CommunicationActus, CommunicationDpp } from "./components/communication/communication.component";


const Admin = lazy(() => import("./components/accueil/admin/adminAccueil.component"));
const Accueil = lazy(() => import("./components/accueil/accueil.component"));
const DeclarationMenu = lazy(() => import("./components/declaration/choixTypeDeclaration/choixTypeDeclaration.component"));
const MesDeclarations = lazy(() => import("./components/mesDeclarations/mesDeclarations.component"));
const MonProfilComponent = lazy(() => import("./components/monProfil/monProfil.component"));
const MesPartenairesReferencesComponent = lazy(() => import("./components/mesPartenaires/mesPartenairesReferences.component"));
const MesPartenairesReferencementComponent = lazy(() => import("./components/mesPartenaires/mesPartenairesReferencement.component"));
const MesFactures = lazy(() => import("./components/mesFactures/mesFactures.component"));
const FiltreRecapitulatif = lazy(() => import("./components/mesDocuments/filtres/filtreRecapitulatifAchats"));
const AttestationFinDeclarations = lazy(() => import("./components/mesDocuments/attestationFinDeclarations/attestationFinDeclarations.component"));
const DeclarationWeb = lazy(() => import("./components/declaration/web/DeclarationWeb"));
const ListIntegrationsTableurContainer = lazy(() => import("./components/declaration/tableur/list/listIntegrationsTableur.container"));
const DeclarationTableur = lazy(() => import("./components/declaration/tableur/single/declarationTableur.component"));
const DemandeReferencement = lazy(() => import("./components/demandeReferencement/demandeReferencement.component"));
const MesStatistiquesDeclarations = lazy(() => import("./components/mesStatistiques/mesStatistiquesDeclarations.component"));
const CroisementBibFour = lazy(() => import("./components/croisementBibFour/croisementBibFour.component"));

export const LATEST_PATH_DEFAULT_VALUE = "";

class App extends Component {
    state = {
        latestPath: LATEST_PATH_DEFAULT_VALUE
    };

    componentDidMount() {
        const { onTryAutoLogin, getLibelles, location } = this.props;
        onTryAutoLogin(location.pathname);
        // Ajoute l'ensemble des libelles pour les autocompletes principalement
        getLibelles();
    }

    componentDidUpdate() {
        const { libelles, getLibelles, getNotifications, gencod, history, notificationLoading } = this.props;
        if (libelles && libelles.length === 0) {
            getLibelles();
        }
        // Recharger les notifications à chaque fois qu'on change d'onglet ou écran
        const currentPath = history.location.pathname;
        const latestPath = this.state.latestPath;
        if (gencod && latestPath !== currentPath && currentPath !== PATH_LOGIN && !notificationLoading) {
            // Notification sans écarts, les écarts ne sont récupérés qu'une fois dans loginStoreInitilisation de login.saga lors de la connexion
            getNotifications(gencod, false);
        }
        if (latestPath !== currentPath) {
            this.setState({ latestPath: currentPath });
        }
    }

    render() {
        const {
            location,
            loginRedirectPath,
            suffixRedirectPath,
            isAuthenticated,
            premiereConnexion,
            prefixUrl,
            locale,
            reloadLogin,
            isAdmin,
            usurperRedirectPath,
            gencod,
            currentStepPremiereCo
        } = this.props;
        const isFirstConnectionOrForcage = isForFirstConnectionOrForcage(premiereConnexion, currentStepPremiereCo);
        const isUsurperRedirectPathPresent = usurperRedirectPath !== PATH_BASE;
        const isLocationOnUsurperRedirectPath = location.pathname.startsWith(usurperRedirectPath);
        const isUtilisateurGencodPresent = isNotEmpty(gencod);

        return (
            <MuiThemeProvider>
                <I18nProvider i18n={globalI18n}>
                    <Helmet htmlAttributes={{ lang: locale }}/>
                    <GlobalDialog fullScreen={false}/>
                    <InfoSnackbar/>
                    <Layout>
                        <Suspense fallback={<Spinner/>}>
                            <Switch>
                                <Route path={PATH_NOUS_CONTACTER} exact component={NousContacter}/>
                                {isAuthenticated &&
                                    <Switch>
                                        {isFirstConnectionOrForcage && isUtilisateurGencodPresent &&
                                            <Switch>
                                                <Route path={ROUTE_PREMIERE_CONNEXION} exact component={ForcageProfil}/>
                                                {/* Redirection systématique vers la page de premiere connexion pour les utilisateurs n’ayant pas encore terminé
                                             leur première connexion ou dont des informations sont manquantes*/}
                                                <Redirect to={`${prefixUrl}${PATH_PREMIERE_CONNEXION}`}/>
                                            </Switch>
                                        }
                                        {isAdmin && isUsurperRedirectPathPresent && !isLocationOnUsurperRedirectPath &&
                                            <Switch>
                                                {/* Si c’est l’admin qui fait la première connexion avec un mail connu, on redirige vers l’accueil de l’usurpé*/}
                                                <Redirect from={ROUTE_PREMIERE_CONNEXION} exact to={usurperRedirectPath}/>
                                                {/* Redirection vers l’accueil de l’usurpé après une usurpation depuis la page admin*/}
                                                <Redirect from={PATH_ADMIN} exact to={usurperRedirectPath}/>
                                                {/* Redirection vers l’accueil de l’usurpé après une usurpation en dehors de la page admin*/}
                                                <Redirect to={usurperRedirectPath}/>
                                            </Switch>
                                        }
                                        {/* Redirige vers la page d’accueil si on tente d’accéder à l’URL de premiere connexion hors forçage du profil,
                                             c’est aussi comme ça que l’on quitte la première connexion ou le forçage*/}
                                        <Redirect from={ROUTE_PREMIERE_CONNEXION} exact to={loginRedirectPath}/>
                                        {/* Redirige la page de login vers l’accueil*/}
                                        <Redirect from={PATH_BASE} exact to={loginRedirectPath + suffixRedirectPath}/>
                                        <Redirect from={PATH_LOGIN} exact to={loginRedirectPath + suffixRedirectPath}/>
                                        <Route path={PATH_ADMIN} exact component={Admin}/>
                                        <Route path={PATH_BIBLIO_MENU} exact component={Accueil}/>
                                        <Route path={PATH_FOURNI_MENU} exact component={Accueil}/>
                                        <Route path={ROUTE_MENU_DECLARATION} exact component={DeclarationMenu}/>
                                        <Route path={ROUTE_MENU_MES_DECLARATIONS_TYPE} exact component={MesDeclarations}/>
                                        <Route path={ROUTE_MENU_MES_DECLARATIONS} exact component={MesDeclarations}/>
                                        <Route path={ROUTE_MENU_PROFIL_TAB} exact component={MonProfilComponent}/>
                                        <Route path={ROUTE_MENU_PROFIL} exact component={MonProfilComponent}/>
                                        <Route path={ROUTE_MENU_PARTENAIRES_REFERENCES_BIB} exact component={MesPartenairesReferencesComponent}/>
                                        <Route path={ROUTE_MENU_PARTENAIRES_REFERENCEMENT_BIB} exact component={MesPartenairesReferencementComponent}/>
                                        <Route path={ROUTE_MENU_PARTENAIRES_REFERENCES_FOURNI} exact component={MesPartenairesReferencesComponent}/>
                                        <Route path={ROUTE_MENU_PARTENAIRES_REFERENCEMENT_FOURNI} exact component={MesPartenairesReferencementComponent}/>
                                        <Route path={ROUTE_MENU_FACTURES} exact component={MesFactures}/>
                                        <Route path={ROUTE_MENU_RECAPITULATIF_VENTES} exact component={FiltreRecapitulatif}/>
                                        <Route path={ROUTE_MENU_ATTESTATION_FIN_DECLARATIONS} exact component={AttestationFinDeclarations}/>
                                        <Route path={ROUTE_MENU_COMMUNICATION_ACTUS} exact component={CommunicationActus}/>
                                        <Route path={ROUTE_MENU_COMMUNICATION_DDP} exact component={CommunicationDpp}/>
                                        <Route path={ROUTE_MENU_DECLARATION_WEB} exact component={DeclarationWeb}/>
                                        <Route path={ROUTE_MENU_DECLARATION_EXCEL} exact component={ListIntegrationsTableurContainer}/>
                                        <Route path={ROUTE_MENU_DECLARATION_EXCEL_FICHIER} exact component={DeclarationTableur}/>
                                        <Route path={ROUTE_DEMANDE_REFERENCEMENT} exact component={DemandeReferencement}/>
                                        <Route path={ROUTE_MENU_MES_STATISTIQUES} exact component={MesStatistiquesDeclarations}/>
                                        <Route path={ROUTE_MENU_ECARTS_DECLARATION} exact component={CroisementBibFour}/>
                                        {/* Redirige vers l’accueil si on essaye d’accéder à une page inconnue*/}
                                        {prefixUrl && <Redirect to={prefixUrl}/>}
                                    </Switch>
                                }
                                <Route path={PATH_RESET_MDP} exact component={MonProfilMotDePasse}/>
                                <Route path={PATCH_INSCRIPTION} exact component={Inscription}/>
                                <Route path={PATH_MDPOUBLIE} exact component={MdpOublie}/>
                                <Route path={PATH_MODIFICATION_MAIL_CONTACT} exact component={ModificationMailContact}/>
                                <Route path={PATH_LOGIN} exact component={LoginPanel}/>
                                {/* Si une page inconnue est attaquée, on renvoie sur la page de login*/}
                                {(reloadLogin && !isAuthenticated) && <Redirect to={PATH_LOGIN}/>}
                            </Switch>
                        </Suspense>
                    </Layout>
                </I18nProvider>
            </MuiThemeProvider>
        );
    }
}

const mapStateToProps = state => ({
    isAuthenticated: selectLoginToken(state) !== null,
    loginRedirectPath: state.login.loginRedirectPath,
    prefixUrl: getPrefixUrl(state.utilisateur.roles),
    suffixRedirectPath: state.login.suffixRedirectPath,
    premiereConnexion: state.utilisateur.informations.premiereConnexion,
    libelles: state.libelle.libelles,
    locale: state.utilisateur.informations.locale,
    reloadLogin: state.login.reloadLogin,
    isAdmin: isAdminRole(state.login.userInfos.authorities),
    usurperRedirectPath: state.admin.usurperRedirectPath,
    gencod: selectUtilisateurGencod(state),
    currentStepPremiereCo: selectMonProfilActiveStep(state),
    notificationLoading: state.notifications.loading
});

const mapDispatchToProps = dispatch => ({
    onTryAutoLogin: pathname => dispatch(actions.loginCheckState(pathname)),
    getLibelles: () => dispatch(actions.getLibelles()),
    getNotifications: (gencod, avecEcart) => dispatch(actions.getAllNotifications(gencod, avecEcart))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
export { App };
