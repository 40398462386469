import { Grid, Paper } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import React from "react";

export const PremiereConnexionLoading =
    () => <Grid container spacing={2}>
        <Grid container>
            <Paper>
                <Trans id={"tableLoading"}/>
            </Paper>
        </Grid>
    </Grid>;
