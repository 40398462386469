import React from "react";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { I18n } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { useSelector } from "react-redux";
import { ITEMS_NON_CLIQUABLE } from "../../../utils/libelleConstantes";
import { selectCommon } from "../../../utils/selectors";
import { UrlAideUtilisateur } from "../urlAideUtilisateur/UrlAideUtilisateur.component";

export default function FilAriane() {
    const { filArianeKeyMessage, filArianeValue } = useSelector(selectCommon);
    const pathnames = useLocation().pathname.split("/").filter(x => x);

    return (
        <div style={{ display: "flex" }}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>} aria-label="Breadcrumb" style={{ display: "flex" }}>
                {pathnames.map((value, index) => {
                    const isItemNonCliquable = ITEMS_NON_CLIQUABLE.includes(value);
                    const last = index === pathnames.length - 1;
                    const fromRoot = `/${pathnames.slice(0, index + 1)
                        .join("/")}`;
                    const to = `/${pathnames.slice(index, index + 1)
                        .join("/")}`;
                    const key = to.slice(1, to.length);
                    let trad = <Trans id={key} values={{ value }}/>;
                    // Message special pour les urls qui affichent un id
                    if (Number.isInteger(parseInt(key, 10))) {
                        trad = <Trans id={filArianeKeyMessage} values={{ value: filArianeValue }}/>;
                    }

                    return <I18n key={key}>
                        {last || isItemNonCliquable
                            ? () => <Typography color="textPrimary" key={to}>{trad}</Typography>
                            : () => <Link color="inherit" to={fromRoot} key={to}>{trad}</Link>
                        }
                    </I18n>;
                })}
            </Breadcrumbs>
            <UrlAideUtilisateur />
        </div>
    );
}
