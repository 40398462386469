import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { Button, Step, StepLabel, Stepper } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import { globalI18n } from "../../i18n.conf";
import * as actionCreator from "../../store/actions/index.action";
import { checkIsFournisseurConnected, isEnCoursPremiereCo, isEnForcage } from "../../utils/utility";
import { selectMonProfil, selectUtilisateurGencod, selectUtilisateurPremiereConnexion, selectUtilisateurRoles } from "../../utils/selectors";
import {
    LIBELLE_FONCTION_CONTACT_CODE_DFA,
    LIBELLE_FONCTION_CONTACT_CODE_RDP,
    LIBELLE_FONCTION_CONTACT_CODE_RL,
    LIBELLE_MON_PROFIL_INFORMATIONS_DFA,
    LIBELLE_MON_PROFIL_INFORMATIONS_ENTITE,
    LIBELLE_MON_PROFIL_INFORMATIONS_RDP,
    LIBELLE_MON_PROFIL_INFORMATIONS_RL,
    LIBELLE_MON_PROFIL_OPTIONS_FACTURATION,
    LIBELLE_MON_PROFIL_VALIDATION,
    LIBELLE_MOT_DE_PASSE
} from "../../utils/libelleConstantes";
import MonProfilMotDePasse from "./formulaires/motDePasse/monProfilMotDePasse.component";
import MonProfilFormulaireEntiteComponent from "./formulaires/entite/monProfilFormulaireEntite.component";
import MonProfilFormulaireContactComponent from "./formulaires/contact/monProfilFormulaireContact.component";
import { PremiereConnexionCheckMailStep } from "./landingPages/premiereConnexionCheckMailStep";
import { PremiereConnexionLoading } from "./landingPages/premiereConnexionLoading";
import MonProfilFormulaireOptionsFacturationComponent from "./formulaires/optionFacturation/monProfilFormulaireOptionsFacturation.component";
import {
    MON_PROFIL_STEP_DFA,
    MON_PROFIL_STEP_ENTITE,
    MON_PROFIL_STEP_MAIL_CONFIRMATION,
    MON_PROFIL_STEP_MDP,
    MON_PROFIL_STEP_OPTION_FACTURATION,
    MON_PROFIL_STEP_RDP,
    MON_PROFIL_STEP_RL
} from "./monProfilUtil";

function getStepsLabel(steps) {
    const result = [];
    if (!steps) {
        return result;
    }
    if (steps.includes(MON_PROFIL_STEP_MDP)) {
        result.push(globalI18n._(LIBELLE_MOT_DE_PASSE));
    }
    if (steps.includes(MON_PROFIL_STEP_ENTITE)) {
        result.push(globalI18n._(LIBELLE_MON_PROFIL_INFORMATIONS_ENTITE));
    }
    if (steps.includes(MON_PROFIL_STEP_RDP)) {
        result.push(globalI18n._(LIBELLE_MON_PROFIL_INFORMATIONS_RDP));
    }
    if (steps.includes(MON_PROFIL_STEP_DFA)) {
        result.push(globalI18n._(LIBELLE_MON_PROFIL_INFORMATIONS_DFA));
    }
    if (steps.includes(MON_PROFIL_STEP_RL)) {
        result.push(globalI18n._(LIBELLE_MON_PROFIL_INFORMATIONS_RL));
    }
    if (steps.includes(MON_PROFIL_STEP_OPTION_FACTURATION)) {
        result.push(globalI18n._(LIBELLE_MON_PROFIL_OPTIONS_FACTURATION));
    }
    if (steps.includes(MON_PROFIL_STEP_MAIL_CONFIRMATION)) {
        result.push(globalI18n._(LIBELLE_MON_PROFIL_VALIDATION));
    }
    return result;
}

function MonProfilStepper() {
    const { steps, currentStepPremiereCo: activeStep } = useSelector(selectMonProfil);
    const stepsLabel = getStepsLabel(steps);

    return <Stepper activeStep={steps && steps.indexOf(activeStep)}>
        {stepsLabel.map((label, index) => (<Step key={label} completed={index < steps.indexOf(activeStep) || activeStep === MON_PROFIL_STEP_MAIL_CONFIRMATION}>
            <StepLabel>{label}</StepLabel>
        </Step>))}
    </Stepper>;
}

// eslint-disable-next-line complexity
export function StepContent({
    infoPremiereCo: {
        npai, bibliothequeEtrangere, rdpMailValid, dfaMailValid, rlMailValid
    }, gencod, step, isFournisseur
}) {
    if (step === MON_PROFIL_STEP_MDP) {
        return <MonProfilMotDePasse connected={true} premiereCo/>;
    }
    if (step === MON_PROFIL_STEP_ENTITE) {
        return <MonProfilFormulaireEntiteComponent premiereCo npai={npai} bibEtrangere={bibliothequeEtrangere}/>;
    }
    if (step === MON_PROFIL_STEP_RDP) {
        return <MonProfilFormulaireContactComponent code={LIBELLE_FONCTION_CONTACT_CODE_RDP} withMail={!rdpMailValid} premiereCo
            isFournisseur={isFournisseur}/>;
    }
    if (step === MON_PROFIL_STEP_DFA) {
        return <MonProfilFormulaireContactComponent code={LIBELLE_FONCTION_CONTACT_CODE_DFA} withMail={!dfaMailValid} premiereCo
            isFournisseur={isFournisseur}/>;
    }
    if (step === MON_PROFIL_STEP_RL) {
        return <MonProfilFormulaireContactComponent code={LIBELLE_FONCTION_CONTACT_CODE_RL} withMail={!rlMailValid} premiereCo
            isFournisseur={isFournisseur}/>;
    }
    if (step === MON_PROFIL_STEP_OPTION_FACTURATION) {
        return <MonProfilFormulaireOptionsFacturationComponent premiereCo/>;
    }
    if (step === MON_PROFIL_STEP_MAIL_CONFIRMATION) {
        return <PremiereConnexionCheckMailStep gencod={gencod}/>;
    }
    return <PremiereConnexionLoading/>;
}


export default function ForcageProfil() {
    const dispatch = useDispatch();
    const { currentStepPremiereCo: activeStep } = useSelector(selectMonProfil);
    const premiereConnexionInfo = useSelector(selectUtilisateurPremiereConnexion);
    const gencod = useSelector(selectUtilisateurGencod);
    const roles = useSelector(selectUtilisateurRoles);
    const isFournisseur = checkIsFournisseurConnected(roles);

    const handleBack = () => {
        dispatch(actionCreator.goToPreviousStepPremiereConnexion());
        window.scrollTo(0, 0);
    };

    const showPreviousButton = () => {
        if (isEnCoursPremiereCo(premiereConnexionInfo)) {
            return activeStep !== MON_PROFIL_STEP_MDP && activeStep !== MON_PROFIL_STEP_ENTITE && activeStep !== MON_PROFIL_STEP_MAIL_CONFIRMATION;
        } else if (isEnForcage(premiereConnexionInfo, activeStep)) {
            return false;
        }
    };

    useEffect(() => {
        // À chaque fois, on change le step il faut remonter en haut de l’écran
        window.scrollTo(0, 0);
    }, [activeStep]);

    return (<div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <MonProfilStepper/>
        <div style={{ flex: "0.8", padding: "0 25px" }}>
            {showPreviousButton() && <Button onClick={handleBack}>
                <Trans id={"backPreviousForm"}/>
            </Button>}
            <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                <div style={{ width: "800px" }}>
                    <StepContent infoPremiereCo={premiereConnexionInfo} gencod={gencod} step={activeStep} isFournisseur={isFournisseur}/>
                </div>
            </div>
        </div>
    </div>);
}
