import React from "react";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import CardContainer from "../../../commonComponents/cardContainer/CardContainer.container";
import InteractiveConfirmButton from "../../declaration/tableur/correctionErreur/renderer/button/InteractiveConfirmButton";

import * as actions from "../../../store/actions/index.action";
import FiltreBorderWrapper from "../../../commonComponents/borderWrapper/FiltreBorderWrapper";
import AnneeFilter from "../../../commonComponents/filtres/anneeFiltre/anneeFilter";
import { LIBELLE_EXPORT_MENSUALISATION } from "../../../utils/libelleConstantes";

function ExportMensualisationChiffres({
    gencodDeclarant,
    anneeDebut,
    anneeFin,
    isLoading,
    updateAnneeDebut,
    updateAnneeFin,
    exportMensualisation
}) {
    const changePeriod = (value, oldDebut, oldFin) => {
        if (oldDebut !== value[0] || oldFin !== value[1]) {
            updateAnneeDebut(value[0]);
            updateAnneeFin(value[1]);
        }
    };

    const exportAction = () => {
        exportMensualisation(gencodDeclarant);
    };

    return <CardContainer title={LIBELLE_EXPORT_MENSUALISATION}>
        <div style={{
            display: "flex",
            justifyContent: "space-between"
        }}>
            <FiltreBorderWrapper
                width={{ width: "80%" }}
                topElementKey={"period"}
            >
                <Grid container style={{ paddingLeft: "80px", paddingRight: "80px", paddingTop: "10px" }}>
                    <Grid item xs={12}>
                        <AnneeFilter
                            debut={anneeDebut}
                            fin={anneeFin}
                            updatePeriod={changePeriod}
                        />
                    </Grid>
                </Grid>
            </FiltreBorderWrapper>
            <InteractiveConfirmButton message={"export"} loading={isLoading} onClick={exportAction}/>
        </div>
    </CardContainer>;
}

export const mapStateToProps = state => ({
    isLoading: state.common.isLoading,
    gencodDeclarant: state.utilisateur.informations.gencod,
    anneeDebut: state.statistiquesDeclarations.anneeDebut,
    anneeFin: state.statistiquesDeclarations.anneeFin
});


export const mapDispatchToProps = dispatch => ({
    exportMensualisation: gencodDeclarant => dispatch(actions.exportMensualisation(gencodDeclarant)),
    updateAnneeDebut: anneeDebut => dispatch(actions.updateAnneeDebut(anneeDebut)),
    updateAnneeFin: anneeFin => dispatch(actions.updateAnneeFin(anneeFin))
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportMensualisationChiffres);
