import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { IconButton } from "material-ui";
import { LiveHelpRounded } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
import { globalI18n } from "../../../i18n.conf";
import { LIBELLE_TOOLTIP_AIDE_UTILISATEUR } from "../../../utils/libelleConstantes";

export function UrlAideUtilisateur() {
    const liste = useSelector(state => state.common.aideUtilisateur);
    const { pathname } = useLocation();

    const aide = liste.find(ele => ele.urlSdd === pathname);

    if (aide) {
        return (
            <Tooltip title={globalI18n._(LIBELLE_TOOLTIP_AIDE_UTILISATEUR)}>
                <IconButton onClick={() => window.open(aide.urlLaSofia)} style={{ marginTop: -8 }}>
                    <LiveHelpRounded fontSize={"large"} color={"disabled"}/>
                </IconButton>
            </Tooltip>
        );
    }

    return (<></>);
}
