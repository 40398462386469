import * as actionTypes from "../actionTypes/actionTypes";

export const updateDisableValideButton = flag => ({
    type: actionTypes.UPDATE_DISABLE_BUTTON_VALIDE,
    disabled: flag
});

export const setLoading = isLoading => ({
    type: actionTypes.LOADING,
    isLoading
});

export const setFilArianeKeyMessage = (keyMessage, value) => ({
    type: actionTypes.SET_FIL_ARIANE_KEY_MESSAGE,
    keyMessage,
    value
});

export const checkSiret = siret => ({
    type: actionTypes.CHECK_SIRET,
    siret
});

export const checkSiretSuccess = isValid => ({
    type: actionTypes.CHECK_SIRET_SUCCESS,
    isValid
});

export const updateFlagShowAndMessageSnackbar = (showSnackbar, keyMessageSnackbar = "", paramMessageSnackbar = {}, autoHideDuration) => ({
    type: actionTypes.UPDATE_FLAG_AND_MESSAGE_SNACK_BAR,
    showSnackbar,
    keyMessageSnackbar,
    paramMessageSnackbar,
    autoHideDuration
});

export const updateNumeroToMap = numero => ({
    type: actionTypes.UPDATE_NUMERO_TO_MAP,
    numero
});

export const updateMessageConfirmation = message => ({
    type: actionTypes.UPDATE_MESSAGE_CONFIRMATION,
    message
});

export const updateApplyErrorStyle = flag => ({
    type: actionTypes.UPDATE_ERROR_STYLE,
    flag
});

export const updateUrlAideUtilisateur = flag => ({
    type: actionTypes.UPDATE_URL_AIDE_UTILISATEUR,
    flag
});

export const updateIsCalledFromInscription = flag => ({
    type: actionTypes.UPDATE_IS_CALLED_INSCRIPTION,
    flag
});

export const sendMessageNousContacter = data => ({
    type: actionTypes.SEND_MESSAGE_NOUS_CONTACTER,
    data
});
