import { useSelector } from "react-redux";
import React from "react";
import { selectLibelle } from "../../../utils/selectors";
import { LIBELLE_CIVLITE } from "../../../utils/libelleConstantes";
import { CIVILITE_CODE_MME, CIVILITE_CODE_MONSIEUR } from "../../../utils/constantes";
import { RadioGroupValidator } from "../radio/RadioGroupValidator";
import { isBlank } from "../../../utils/utility";


export const CiviliteRadioGroup = props => {
    const { libelles } = useSelector(selectLibelle);
    const libellesCivilite = libelles.filter(l => l.type === LIBELLE_CIVLITE && (l.code === CIVILITE_CODE_MONSIEUR || l.code === CIVILITE_CODE_MME));

    const { value, ...otherProps } = props;
    // Besoin du upperCase sur value car les valeurs en base sur les contacts pour le code Madame n'ont pas toujours la même casse ('MME' / 'Mme')
    const upperCase = isBlank(value) ? "" : value.toUpperCase();
    return <RadioGroupValidator name="civiliteRadioGroup" value={upperCase} libelles={libellesCivilite} {...otherProps} />;
};

export const TypeEntiteRadioGroup = value => {
    const libellesCivilite = ["Fournisseur", "Bibliotheque"];

    return <RadioGroupValidator name="typeEntiteRadioGroup" value={value} libelles={libellesCivilite} variant="outlined" row/>;
};
