import * as actionTypes from "../../actionTypes/actionTypes";
import { reducerDoute, updateObject } from "../../../utils/utility";
import { ANNEE_PIECE, DOUTEUSE, ENVOYEE, FACTUREE, SAUVEGARDE, SORT_ASC, SORT_DESC, SORT_NULL } from "../../../utils/constantes";
import { getSort } from "../../../utils/bootstrapTableUtil";

const initialState = {
    /* Filtres */
    page: 1,
    sort: [
        getSort("datePiece", SORT_NULL),
        getSort("numeroPiece", SORT_NULL),
        getSort("totalHt", SORT_NULL),
        getSort("totalTtc", SORT_NULL),
        getSort("ddp", SORT_NULL),
        getSort("dateModification", SORT_DESC),
        getSort("dateEnvoiSofia", SORT_DESC),
        getSort("dateDeFacture", SORT_DESC),
        getSort("raisonSociale1", SORT_ASC),
        getSort("ville", SORT_ASC)
    ],
    typeDeclaration: null, // DOUTEUSE EN_ATTENTE_ENVOIE ENVOYEE FACTUREE,
    totalSizeRecap: 0,
    nombreDeclarationParType: {
        douteuse: 0,
        enAttente: 0,
        envoyee: 0,
        facturee: 0
    },
    filtresPartenaires: [],
    filtresSource: [],
    glnDeclarant: null,
    glnPartenaires: [], // La liste des gencodes Partenaire selectionnés par l'usager
    anneeDebut: new Date().getUTCFullYear() - 3,
    anneeFin: new Date().getUTCFullYear(),
    onlyInvalidDeclaration: false,
    typeAnnee: ANNEE_PIECE, // ANNEE_EFFECTIVE
    sourcesDeclaration: [],
    doutes: [],
    numeroFacture: "",
    /* Resultats */
    entetes: [],
    statistiques: {},
    nombreDoutesParType: [],
    nombreTotalDoutes: 0,
    rowIndex: -1,
    showClone: false,
    showOriginale: false,
    openDetailDialog: false,
    loading: false,
    loadingStatistiquesMontants: false,
    showStatistiquesMontants: false,
    dialogDeclarationModifEnCours: false // Si la déclaration est en cours de modification et que l'envoi au back n'a pas encore eu lieu
};

function reduceSortColumns(state, { token }) {
    // Recupération de l'index de l'élément dans le tableau sinon -1
    const index = state.sort.findIndex(sortColumn => sortColumn.column === token.column);
    return {
        ...state,
        // Si -1, ajout à la fin du tableau l'objet, sinon il est remplacé
        sort: [
            ...state.sort.slice(0, index),
            token,
            ...state.sort.slice(index + 1)
        ]
    };
}

const updateDeclarationLigneForEntete = (state, action) => {
    const entete = {
        ...state.entetes[action.rowIndex],
        lignes: action.lignes
    };
    const entetes = [
        ...state.entetes.slice(0, action.rowIndex),
        entete,
        ...state.entetes.slice(action.rowIndex + 1)
    ];
    return updateObject(state, { entetes });
};

// Si le type de déclaration correspond à des déclarations non envoyées, on met le filtre de type année à date de facture
const getTypeAnnee = (typeDeclaration, typeAnnee) => {
    if (typeDeclaration === DOUTEUSE || typeDeclaration === SAUVEGARDE) {
        return ANNEE_PIECE;
    }
    return typeAnnee;
};

const prepareLigne = ligne => {
    // eslint-disable-next-line no-unused-vars
    const { douteuse, canBeMergedByEan, canBeMergedByTitleAndEditor, numeroLigne, ...resteLigne } = ligne;
    const doute = Object.keys(ligne).reduce((prevVal, key) => reducerDoute(prevVal, ligne[key]), false);
    return {
        douteuse: doute,
        numeroLigne,
        ligne: resteLigne
    };
};

const addClone = (state, action) => {
    const rowIndex = state.rowIndex;
    const entetes = state.entetes;
    return updateObject(state, {
        entetes: [
            ...entetes.slice(0, rowIndex),
            { ...entetes[rowIndex], clone: action.clone },
            ...entetes.slice(rowIndex + 1)
        ]
    });
};

const updateDeclarationEnteteEtLignes = (state, action) => {
    const entetes = state.entetes;
    const wrappedEntete = action.isClone ? entetes[action.rowIndex].clone : action.isOriginale ? entetes[action.rowIndex].originale : entetes[action.rowIndex];

    // Parcours des différents champs de l'entête et de chacune des lignes passées dans l'action à la recherche de doute
    const hasEnteteDouteuse = Object.keys(action.entete).reduce((prevVal, key) => reducerDoute(prevVal, action.entete[key]), false);
    const hasLigneDouteuse = action.lignes.reduce((accumulator, ligne) => Object.keys(ligne).reduce((prevVal, key) => reducerDoute(prevVal, ligne[key]), accumulator), false);
    const hasDoute = hasEnteteDouteuse || hasLigneDouteuse;

    const isEnteteEnvoyeeOrFacturee = [FACTUREE, ENVOYEE].includes(wrappedEntete.entete.statut.cellValue);

    const newEntete = {
        ...wrappedEntete,
        entete: {
            ...wrappedEntete.entete,
            glnPartenaire: {
                ...wrappedEntete.entete.glnPartenaire,
                cellValue: action.partenaire.gencod
            },
            raisonSociale: {
                ...wrappedEntete.entete.raisonSociale,
                cellValue: action.partenaire.raisonSociale
            },
            ville: {
                ...wrappedEntete.entete.ville,
                cellValue: action.partenaire.ville
            },
            dateRecap: {
                ...wrappedEntete.entete.dateRecap,
                cellValue: isEnteteEnvoyeeOrFacturee ? wrappedEntete.entete.dateRecap.cellValue : action.entete.dateModification.cellValue
            },
            ...action.entete
        },
        lignes: action.lignes.map(item => prepareLigne(item)),
        douteuse: hasDoute
    };

    if (action.isClone) {
        entetes[action.rowIndex].clone = newEntete;
    } else if (action.isOriginale) {
        entetes[action.rowIndex].originale = newEntete;
    } else {
        entetes[action.rowIndex] = newEntete;
    }
    return updateObject(state, {
        entetes: [...entetes.slice(0, action.rowIndex), entetes[action.rowIndex], ...entetes.slice(action.rowIndex + 1)]
    });
};

// eslint-disable-next-line complexity
const reducer = (state = initialState, action) => {
    switch (action.type) {
    // Ici on applique la mise à jour des colonnes à trier
    case actionTypes.UPDATE_MD_SORT_COLUMN:
        return reduceSortColumns(state, action);
    case actionTypes.UPDATE_MD_ANNEE_DEBUT:
        return updateObject(state, {
            // On vérifie que la date de début n'est pas supérieur à la date de fin
            anneeDebut: (action.token > state.anneeFin ? state.anneeFin : action.token)
        });
    case actionTypes.UPDATE_MD_ANNEE_FIN:
        return updateObject(state, {
            // On vérifie que la date de fin n'est pas inférieur à la date de début
            anneeFin: (action.token < state.anneeDebut ? state.anneeDebut : action.token)
        });
    case actionTypes.UPDATE_MD_PERIOD:
        return updateObject(state, { page: 1, anneeDebut: action.token[0], anneeFin: action.token[1] });
    case actionTypes.UPDATE_MD_DOUTES:
        return updateObject(state, { doutes: action.token });
    case actionTypes.UPDATE_MD_GLN_DECLARANT:
        return updateObject(state, { glnDeclarant: action.token, page: 1 });
    case actionTypes.UPDATE_MD_GLN_DECLARANT_AND_REINIT_PARTENAIRE:
        return updateObject(state, { glnDeclarant: action.token, glnPartenaires: [], page: 1 });
    case actionTypes.UPDATE_MD_PARTENAIRES:
        return updateObject(state, { glnPartenaires: action.token });
    case actionTypes.UPDATE_MD_FILTRE_PARTENAIRES:
        return updateObject(state, { filtresPartenaires: action.filtresPartenaires });
    case actionTypes.UPDATE_MD_PAGE:
        return updateObject(state, { page: action.token });
    case actionTypes.UPDATE_MD_TYPE_ANNEE:
        return updateObject(state, { typeAnnee: action.token });
    case actionTypes.UPDATE_MD_DIALOG_DECLARATION_MODIF_EN_COURS:
        return updateObject(state, { dialogDeclarationModifEnCours: action.token });
    case actionTypes.UPDATE_MD_SHOW_ONLY_INVALIDE_DECLARATION:
        return updateObject(state, { onlyInvalidDeclaration: action.flag });
    case actionTypes.UPDATE_MD_TYPE_DECLARATION:
        return updateObject(state, {
            typeDeclaration: action.token.type,
            totalSizeRecap: action.token.nombre,
            typeAnnee: getTypeAnnee(action.token.type, state.typeAnnee)
        });
    case actionTypes.UPDATE_MD_NOMBRE_DECLARATION_PAR_TYPE:
        return updateObject(state, { nombreDeclarationParType: action.token });
    case actionTypes.UPDATE_MD_RESULTAT:
        return updateObject(state, {
            entetes: [...action.token],
            loading: false
        });
    case actionTypes.GET_MD_LIST_DECLARATIONS_LIGNE_BY_NUMERO_DECLARATION_RESULT:
        return updateDeclarationLigneForEntete(state, action);
    case actionTypes.UPDATE_MD_ALL_INFOS:
        return updateObject(state, {
            ...state,
            ...action.token,
            loading: false
        });
    case actionTypes.CLEAR_MD_STORE:
        return initialState;
    case actionTypes.REFRESH_MD_STORE:
        return updateObject(state, { ...initialState, glnDeclarant: state.glnDeclarant });
    case actionTypes.UPDATE_MD_INDEX_OPENED_DECLARATION:
        return updateObject(state, {
            rowIndex: action.index
        });
    case actionTypes.UPDATE_MD_TOTAL_SIZE_RECAP:
        return updateObject(state, {
            totalSizeRecap: action.totalSize
        });
    case actionTypes.UPDATE_MD_DECLARATION_ENTETE_ET_LIGNES:
        return updateDeclarationEnteteEtLignes(state, action);
    case actionTypes.UPDATE_MD_SOURCE_DECLARATION: {
        return updateObject(state, {
            sourcesDeclaration: action.sources
        });
    }
    case actionTypes.UPDATE_ENTETES_AND_ROWINDEX_RESULT: {
        return updateObject(state, {
            entetes: [...action.entetes],
            rowIndex: action.index,
            loading: false
        });
    }
    case actionTypes.ADD_MD_CLONE:
        return addClone(state, action);
    case actionTypes.UPDATE_MD_SHOW_CLONE:
        return updateObject(state, {
            showClone: action.showClone
        });
    case actionTypes.UPDATE_MD_SHOW_ORIGINALE:
        return updateObject(state, {
            showOriginale: action.showOriginale
        });
    case actionTypes.UPDATE_MD_OPEN_DETAIL_DIALOG:
        return updateObject(state, {
            openDetailDialog: action.open
        });
    case actionTypes.SET_MD_LOADING: {
        return updateObject(state, { loading: action.loading });
    }
    case actionTypes.UPDATE_MD_STATISTIQUES_MONTANTS_RESULT: {
        return updateObject(state, {
            statistiques: action.statistiques,
            loadingStatistiquesMontants: false
        });
    }
    case actionTypes.SET_MD_LOADING_STATISTIQUES_MONTANTS: {
        return updateObject(state, { loadingStatistiquesMontants: action.loading });
    }
    case actionTypes.SET_MD_SHOW_STATISTIQUES_MONTANTS: {
        return updateObject(state, { showStatistiquesMontants: action.show });
    }
    case actionTypes.UPDATE_MD_FILTRE_NUMERO_FACTURE: {
        return updateObject(state, { numeroFacture: action.numeroFacture });
    }
    default:
        return state;
    }
};

export default reducer;
