import * as actionTypes from "../actionTypes/actionTypes";

export const changePwdUnconnected = (gencod, token, password) => ({
    type: actionTypes.CHANGE_PWD_UNCONNECTED,
    gencod,
    token,
    password
});

export const changePwdConnected = (password, premiereCo) => ({
    type: actionTypes.CHANGE_PWD_CONNECTED,
    password,
    premiereCo
});

export const sendMailForPasswordReset = (genCode, mail) => ({
    type: actionTypes.SEND_MAIL_FOR_PWD_RESET,
    gln: genCode,
    adressemail: mail
});
