import React, { useEffect, useRef, useState } from "react";
import { FormControlLabel } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { Trans } from "@lingui/macro";
import * as actions from "../../../../store/actions/index.action";
import { globalI18n } from "../../../../i18n.conf";
import { LIBELLE_MON_PROFIL_SAUT_ETAPE_RDP } from "../../../../utils/libelleConstantes";
import { useStyles } from "../../../monProfil/monProfilUtil";
import FormContactInscription from "../formContactInscription.component";
import { selectInscriptionState, selectStepHiddenInscriptionRdp } from "../../../../utils/selectors";
import { GreenCheckbox } from "../../../../commonComponents/checkBox/GreenCheckBox";
import CopyEntiteDetailsCheckbox from "../copyEntiteDetailsCheckbox";
import { CONTACT_FONCTION, INSCRIPTION_RL } from "../../../../utils/constantes";
import InscriptionSubmitButton from "../inscriptionSubmitButton";
import { enableInstantValidate } from "../../../../utils/utility";


export default function RepresentantLegal({ onClickNext }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [applyEntityDetails, setApplyEntityDetails] = useState(false);
    const initCopyRDP = useSelector(selectStepHiddenInscriptionRdp);
    const [copyRDPDetails, setCopyRDPDetails] = useState(initCopyRDP);
    const { inscriptionEntite, inscriptionRL, profilDialog, formMailValide } = useSelector(selectInscriptionState);
    const [formulaire, setFormulaire] = useState(inscriptionRL);
    const formReference = useRef();
    const formulaireRef = useRef();

    function updateFormMailRLValide(statut) {
        dispatch(actions.updateFormulaireMailValide(CONTACT_FONCTION.REPRESENTANT_LEGAL, statut));
    }

    function getFormMailValide() {
        return !formMailValide.find(f => f.code === CONTACT_FONCTION.REPRESENTANT_LEGAL).valide;
    }

    useEffect(() => {
        formulaireRef.current = formulaire;
    }, [formulaire]);

    /*
     * Unmount hook
     * on met à jour le store seulement si on démonte le composant
     */
    useEffect(() => function unmount() {
        dispatch(actions.updateInscriptionRlFormulaire(formulaireRef.current));
    }, []);

    function onSubmit(e) {
        e.preventDefault();
        // On met à jour le store comme quoi le formulaire est validé par l'utilisateur
        updateFormMailRLValide(true);
        if (copyRDPDetails) {
            dispatch(actions.updateInscriptionRdpFormulaire(formulaire));
            dispatch(actions.rechercheGlnBeforeInscription(profilDialog));
        } else {
            onClickNext();
        }
    }

    const changeSkipStep = event => {
        const { target: { checked } } = event;
        if (checked) {
            dispatch(actions.removeStepInscriptionRdp());
        } else {
            dispatch(actions.addStepInscriptionRdp());
        }
        setCopyRDPDetails(!copyRDPDetails);
    };


    return <>
        <div className={classes.titreStepper}>
            <Trans id={INSCRIPTION_RL}/>
        </div>
        <div className={classes.alignLeft}>
            <div className={classes.affichageColonneCentree}>
                <CopyEntiteDetailsCheckbox
                    formulaire={formulaire}
                    setFormulaire={setFormulaire}
                    applyEntityDetails={applyEntityDetails}
                    setApplyEntityDetails={setApplyEntityDetails}
                    entite={inscriptionEntite}/>
            </div>
            <ValidatorForm ref={formReference} onSubmit={onSubmit} instantValidate={false}>
                <FormContactInscription
                    formulaire={formulaire}
                    setFormulaire={setFormulaire}
                    showConfirmationInput={getFormMailValide()}
                    classes={classes}
                />
                <div className={classes.affichageColonneCentree}>
                    <FormControlLabel control={<GreenCheckbox checked={copyRDPDetails} onChange={changeSkipStep}/>}
                        label={globalI18n._(LIBELLE_MON_PROFIL_SAUT_ETAPE_RDP)}/>
                </div>
                <InscriptionSubmitButton id="saveReprLegalButton" onClick={() => enableInstantValidate(formReference)} lastStep={copyRDPDetails}/>
            </ValidatorForm>
        </div>
    </>;
}
