import MuiAlert from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import React from "react";
import * as actions from "../../store/actions/index.action";

function InfoSnackbar({ showSnackbar, keyMessageSnackbar, paramMessageSnackbar, closeSnackBar, autoHideDuration = 4000 }) {
    function handleCloseSnackBar(reason) {
        if (reason !== "clickaway") {
            closeSnackBar();
        }
    }

    const Alert = props => <MuiAlert elevation={6} variant="filled" {...props} />;

    return <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        autoHideDuration={autoHideDuration}
        onClose={(event, reason) => handleCloseSnackBar(reason)}
        open={showSnackbar}>
        <Alert onClose={handleCloseSnackBar} severity="info">
            {<Trans id={keyMessageSnackbar} values={paramMessageSnackbar}/>}
        </Alert>
    </Snackbar>;
}

InfoSnackbar.propTypes = {
    showSnackbar: PropTypes.bool.isRequired,
    closeSnackBar: PropTypes.func.isRequired,
    keyMessageSnackbar: PropTypes.string.isRequired,
    autoHideDuration: PropTypes.number,
    paramMessageSnackbar: PropTypes.object
};

const mapStateToProps = state => ({
    showSnackbar: state.common.showSnackbar,
    keyMessageSnackbar: state.common.keyMessageSnackbar,
    paramMessageSnackbar: state.common.paramMessageSnackbar,
    autoHideDuration: state.common.autoHideDuration
});

const mapDispatchToProps = dispatch => ({
    closeSnackBar: () => dispatch(actions.updateFlagShowAndMessageSnackbar(false))
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoSnackbar);
