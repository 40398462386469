import React, { useState } from "react";
import { Trans } from "@lingui/macro";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { PATCH_INSCRIPTION } from "../../../routes/paths/paths.util";
import { LIBELLE_ANNUAIRE, LIBELLE_INSCRIPTION } from "../../../utils/libelleConstantes";
import RechercheAnnuaireDialog from "../../../commonComponents/dialog/rechercheAnnuaireDialog";
import classes from "./loginControls.module.css";
import ContactUs from "./contactUs.component";

export default function LoginControls() {
    const [displayRechercheAssujetti, setDisplayRechercheAssujetti] = useState(false);
    const history = useHistory();

    const openRecherchePopup = () => setDisplayRechercheAssujetti(true);
    const closeRecherchePopup = () => setDisplayRechercheAssujetti(false);
    const toInscription = () => history.push(PATCH_INSCRIPTION);

    return (
        <div className={classes.LoginControls}>
            <div className={classes.Control} style={{ flexGrow: "1" }}>
                <Button onClick={openRecherchePopup} color="primary" variant="contained">
                    <Trans id={LIBELLE_ANNUAIRE}/>
                </Button>
            </div>
            <div className={classes.Control} >
                <Button onClick={toInscription} color="primary" variant="contained">
                    <Trans id={LIBELLE_INSCRIPTION}/>
                </Button>
            </div>
            <ContactUs/>
            <RechercheAnnuaireDialog open={displayRechercheAssujetti} close={closeRecherchePopup}/>
        </div>
    );
}
