import { updateObject } from "../../../../utils/utility";
import * as actionTypes from "../../../actionTypes/actionTypes";

const initialState = {
    loading: true,
    sauvegardeLoading: false,
    entetesChoisies: [],
    erreursCalculees: {},
    erreursStats: null,
    openBarPorgressDialog: false,
    loadingPercentage: 0,
    passToCorrectionErreur: true
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.SET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_LOADING_PERCENTAGE:
        return updateObject(state, {
            loadingPercentage: action.percentage
        });
    case actionTypes.SET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_LOADING:
        return updateObject(state, {
            loading: action.loading
        });
    case actionTypes.GET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_LIGNES_BY_ID_INTEGRATION_RESULT:
        return updateObject(state, {
            erreursCalculees: action.erreursCalculees,
            erreursStats: action.erreursStats
        });
    case actionTypes.GET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_ENTETES_CHOISIES_BY_ID_INTEGRATION_RESULT:
        return updateObject(state, {
            entetesChoisies: action.entetesChoisies
        });
    case actionTypes.SAVE_GET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_LIGNES_RESULT:
        return updateObject(state, {
            erreursCalculees: action.erreursCalculees,
            erreursStats: action.erreursStats
        });
    case actionTypes.DELETE_GET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_STATS_RESULT:
        return updateObject(state, {
            erreursStats: action.erreursStats
        });
    case actionTypes.SET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_SAUVEGARDE_LOADING:
        return updateObject(state, {
            sauvegardeLoading: action.sauvegardeLoading
        });
    case actionTypes.OPEN_OR_CLORE_BAR_PROGRESS_DIALOG:
        return updateObject(state, {
            openBarPorgressDialog: action.flag
        });
    case actionTypes.REINIT_DATA_CORRECTION_ERREUR:
        return updateObject(state, {
            erreursStats: null,
            erreursCalculees: [],
            entetesChoisies: []
        });
    case actionTypes.PASS_TO_STEP_CORRECTION_ERREUR:
        return updateObject(state, {
            passToCorrectionErreur: action.flag
        });

    default:
        return state;
    }
};

export default reducer;
