import React, { Component } from "react";
import Autosuggest from "react-autosuggest";
import { TextValidator } from "react-material-ui-form-validator";
import { MenuItem, Paper, withStyles } from "@material-ui/core";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { MAX_LENGTH_VILLE } from "../../../utils/constantes";


const styles = theme => ({
    root: {
        height: 50,
        width: "90%"
    },
    container: {
        position: "relative"
    },
    suggestionsContainerOpen: {
        position: "absolute",
        width: "600px",
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0
    },
    suggestion: {
        display: "block"
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: "none"
    },
    divider: {
        height: theme.spacing(2)
    },
    paper: {
        width: "600px"
    }
});

class VilleAutoComplete extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: "",
            suggestions: [],
            isLoading: false
        };
        this.firstValue = "";
        this.lastRequestId = null;
    }


    renderSuggestion = (suggestion, { query, isHighlighted }) => {
        const matches = match(suggestion, query);
        const parts = parse(suggestion, matches);

        return (
            <MenuItem selected={isHighlighted} component="div">
                <div>
                    {parts.map((part, index) => <span key={index} style={{ fontWeight: part.highlight ? 600 : 600 }}>
                        {part.text}
                    </span>)}
                </div>
            </MenuItem>
        );
    }

    renderInputComponent = inputProps => {
        const {
            inputRef = () => {
            }, ref, ...other
        } = inputProps;
        return (
            <TextValidator
                InputProps={{
                    inputRef: node => {
                        ref(node);
                        inputRef(node);
                    }
                }}
                inputProps={{ maxLength: MAX_LENGTH_VILLE }}
                {...other}
            />
        );
    }

    shouldRenderSuggestions = value => value.trim().length >= 3


    getSuggestionValue = suggestion => {
        this.firstValue = suggestion;
        return suggestion;
    }

    getSuggestionsVilles = value => {
        if (value !== "" && this.firstValue !== value) {
            this.props.getSuggestions(value)
                .then(response => {
                    this.firstValue = value;
                    this.setState({ suggestions: response });
                });
        }
    }

    loadSuggestions = value => {
        // Cancel the previous request
        if (this.lastRequestId !== null) {
            clearTimeout(this.lastRequestId);
        }

        this.setState({
            isLoading: true
        });

        this.lastRequestId = setTimeout(() => {
            this.setState({ isLoading: false });
            this.getSuggestionsVilles(value, this.props);
        }, 1000);
    }

    onChange = (event, { newValue }) => {
        this.props.onChange(newValue);
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.loadSuggestions(value);
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    render() {
        const { suggestions } = this.state;
        const { ville, classes } = this.props;
        const inputProps = {
            value: ville,
            label: this.props.label,
            onChange: this.onChange,
            style: { width: "100%" }
        };
        return (
            <div className={classes.root}>
                <Autosuggest
                    renderInputComponent={this.renderInputComponent}
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    shouldRenderSuggestions={this.shouldRenderSuggestions}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    inputProps={inputProps}
                    theme={{
                        container: classes.container,
                        suggestionsContainerOpen: classes.suggestionsContainerOpen,
                        suggestionsList: classes.suggestionsList,
                        suggestion: classes.suggestion
                    }}
                    renderSuggestionsContainer={options => <Paper className={classes.paper}
                        {...options.containerProps}
                        square
                        style={{
                            width: "350px",
                            zIndex: "90"
                        }}>
                        {options.children}
                    </Paper>}
                />
            </div>
        );
    }
}

export default withStyles(styles)(VilleAutoComplete);
