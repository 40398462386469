import { Trans } from "@lingui/macro";
import React from "react";
import { LabelInputTextWithChildren, TextValidatorSmallOutlinedUpperCase } from "../input/fieldInput";
import {
    LIBELLE_CHAMP_OBLIGATOIRE,
    LIBELLE_CIVILITE_OBLIGATOIRE,
    LIBELLE_CIVLITE,
    LIBELLE_FONCTION,
    LIBELLE_NOM,
    LIBELLE_PRENOM
} from "../../utils/libelleConstantes";
import { CiviliteRadioGroup } from "../input/civilite/CiviliteRadioGroup";
import { getField, inputOnChange } from "../../components/monProfil/monProfilUtil";
import { CIVILITE, MAX_LENGTH_NOM, MAX_LENGTH_PRENOM, MAX_LENGTH_QUALITE, NOM, PRENOM, QUALITE, TELEPHONE } from "../../utils/constantes";
import { TelephoneFormattedTextInput } from "../input/telephone/TelephoneFormattedTextInput";
import { REQUIRED } from "../../utils/validatorUtil";


export default function CommonContactFormulaire({ formulaire, onChange, classes, isFournisseur, errorCivilite }) {
    return <>
        <LabelInputTextWithChildren labelKey={LIBELLE_CIVLITE} required>
            <CiviliteRadioGroup
                value={getField(formulaire, CIVILITE)}
                onChange={inputOnChange(CIVILITE, onChange)}
                row
                validators={[REQUIRED]}
                errorMessages={[<Trans id={LIBELLE_CIVILITE_OBLIGATOIRE}/>]}
                className={classes.inputFields}
                error={errorCivilite}
                variant="outlined"/>
        </LabelInputTextWithChildren>
        <LabelInputTextWithChildren labelKey={LIBELLE_NOM} required>
            <TextValidatorSmallOutlinedUpperCase
                value={getField(formulaire, NOM)}
                onChange={inputOnChange(NOM, onChange)}
                inputProps={{ maxLength: MAX_LENGTH_NOM }}
                validators={[REQUIRED]}
                errorMessages={[<Trans id={LIBELLE_CHAMP_OBLIGATOIRE}/>]}/>
        </LabelInputTextWithChildren>
        <LabelInputTextWithChildren labelKey={LIBELLE_PRENOM} required>
            <TextValidatorSmallOutlinedUpperCase
                value={getField(formulaire, PRENOM)}
                onChange={inputOnChange(PRENOM, onChange)}
                inputProps={{ maxLength: MAX_LENGTH_PRENOM }}
                validators={[REQUIRED]}
                errorMessages={[<Trans id={LIBELLE_CHAMP_OBLIGATOIRE}/>]}/>
        </LabelInputTextWithChildren>
        <TelephoneFormattedTextInput value={getField(formulaire, TELEPHONE)} isRequired={true}
            onChange={v => onChange(TELEPHONE, v)} showForeignTooltip={isFournisseur}/>
        <LabelInputTextWithChildren labelKey={LIBELLE_FONCTION} required>
            <TextValidatorSmallOutlinedUpperCase
                value={getField(formulaire, QUALITE)}
                onChange={inputOnChange(QUALITE, onChange)}
                inputProps={{ maxLength: MAX_LENGTH_QUALITE }}
                validators={[REQUIRED]}
                errorMessages={[<Trans id={LIBELLE_CHAMP_OBLIGATOIRE}/>]}/>
        </LabelInputTextWithChildren>
    </>;
}
