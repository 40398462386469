import { Button, Dialog, DialogActions, makeStyles } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    PROFIL_BIBLIOTHEQUE,
    PROFIL_BIBLIOTHEQUE_COLOR,
    PROFIL_BIBLIOTHEQUE_COLOR_DARKER,
    PROFIL_FOURNISSEUR,
    PROFIL_FOURNISSEUR_COLOR,
    PROFIL_FOURNISSEUR_COLOR_DARKER
} from "../../../utils/constantes";
import * as actions from "../../../store/actions/index.action";
import { PATH_LOGIN } from "../../../routes/paths/paths.util";
import {
    LIBELLE_INSCRIPTION_CHOIX_PROFIL_DECLARANT,
    LIBELLE_INSCRIPTION_PROFIL_BIBLIOTHEQUE,
    LIBELLE_INSCRIPTION_PROFIL_FOURNISSEUR
} from "../../../utils/libelleConstantes";
import { ClosableDialogTitle } from "../ClosableDialogTitle.component";

const useStyles = makeStyles(theme => ({
    fournisseurButton: {
        color: theme.palette.getContrastText(PROFIL_FOURNISSEUR_COLOR),
        background: PROFIL_FOURNISSEUR_COLOR,
        "&:hover": {
            backgroundColor: PROFIL_FOURNISSEUR_COLOR_DARKER
        }
    },
    bibliothequeButton: {
        color: theme.palette.getContrastText(PROFIL_BIBLIOTHEQUE_COLOR),
        background: PROFIL_BIBLIOTHEQUE_COLOR,
        "&:hover": {
            backgroundColor: PROFIL_BIBLIOTHEQUE_COLOR_DARKER
        }
    }
}));

export function ChooseProfilDialog({ open, handleClose }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const handleClick = profil => {
        dispatch(actions.initStepInscription());
        dispatch(actions.onChangeProfilDialog(profil));
        dispatch(actions.updateFlagIsCalledFromDeclaration(false));
        handleClose(false);
    };

    const handleClickFourni = () => {
        handleClick(PROFIL_FOURNISSEUR);
    };

    const handleClickBib = () => {
        handleClick(PROFIL_BIBLIOTHEQUE);
    };
    const redirectToLogin = () => {
        history.push(PATH_LOGIN);
    };
    return (

        <Dialog className="center"
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >

            <ClosableDialogTitle onClose={redirectToLogin} id="alert-dialog-title" style={{ textAlign: "center" }}>
                <Trans id={LIBELLE_INSCRIPTION_CHOIX_PROFIL_DECLARANT}/>
            </ClosableDialogTitle>
            <DialogActions>
                <div className="container center">
                    <div className="row">
                        <div className="col-md-auto">
                            <Button id="confirm_four" variant="contained" onClick={handleClickFourni} className={classes.fournisseurButton}>
                                <Trans id={LIBELLE_INSCRIPTION_PROFIL_FOURNISSEUR}/>
                            </Button>
                        </div>
                        <div className="col-md-auto">
                            <Button id="confirm_bib" variant="contained" onClick={handleClickBib} className={classes.bibliothequeButton}>
                                <Trans id={LIBELLE_INSCRIPTION_PROFIL_BIBLIOTHEQUE}/>
                            </Button>
                        </div>
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    );
}
