import "./wdyr";
import "./polyfills";
import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import thunk from "redux-thunk";
import { createBrowserHistory, createHashHistory } from "history";
import { ConnectedRouter, connectRouter, routerMiddleware } from "connected-react-router";

import { Provider } from "react-redux";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import inscriptionReducer from "./store/reducers/inscription/inscription.reducer";
import loginReducer from "./store/reducers/login/login.reducer";
import stepFormReducer from "./store/reducers/stepReducer/step.reducer";
import rechercheGLNReducer from "./store/reducers/rechercheGLNReducer/rechercheGLN.reducer";
import adminReducer from "./store/reducers/admin/admin.reducer";
import attestationFinReducer from "./store/reducers/attestationFinDeclarations/attestationFinDeclarations.reducer";
import globalDialogReducer from "./store/reducers/globalDialog/globalDialog.reducer";
import declarationReducer from "./store/reducers/declaration/declaration.reducer";
import mesDeclarationsReducer from "./store/reducers/mesDeclarations/mesDeclarations.reducer";
import utilisateurReducer from "./store/reducers/utilisateur/utilisateur.reducer";
import commonReducer from "./store/reducers/common/common.reducer";
import integrationTableur from "./store/reducers/integrationTableur/integrationTableur.reducer";
import integrationTableurRecapitulatifReducer
    from "./store/reducers/integrationTableur/recapitulatif/integrationTableurRecapitulatif.reducer";
import integrationTableurChoixEnteteReducer
    from "./store/reducers/integrationTableur/choixEntete/integrationTableurChoixEntete.reducer";
import integrationTableurDeclarationReducer
    from "./store/reducers/integrationTableur/declaration/integrationTableurDeclaration.reducer";
import integrationTableurCorrectionErreurReducer
    from "./store/reducers/integrationTableur/correctionErreur/integrationTableurCorrectionErreur.reducer";
import statistiquesDeclarationsReducer from "./store/reducers/statistiquesDeclarations/statistiquesDeclaration.reducer";
import croisementBibFourReducer from "./store/reducers/croisementBibFour/croisementBibFour.reducer";
import mesFacturesReducer from "./store/reducers/mesFactures/mesFactures.reducer";
import notificationsReducer from "./store/reducers/notifications/notification.reducer";
import monProfilReducer from "./store/reducers/monProfil/monProfil.reducer";
import libelleReducer from "./store/reducers/libelle/libelle.reducer";


import { supportsHtml5HistoryApi } from "./utils/browserUtils";

import {
    watchAdmin,
    watchAttestationFinDeclarations,
    watchCommon,
    watchCroisementBibFour,
    watchDeclaration,
    watchDemandeReferencement,
    watchDocumentsDeclarations,
    watchGlobalDialog,
    watchInscriptionProfil,
    watchIntegration,
    watchIntegrationCorrectionErreur,
    watchIntegrationEntete,
    watchIntegrationGetListeDeclaration,
    watchIntegrationRecapitulatif,
    watchLibelle,
    watchLogin,
    watchMdpOublie,
    watchMesDeclarations,
    watchMesFactures,
    watchMesPartenaires,
    watchMonProfil,
    watchNotifications,
    watchRechercheGlN,
    watchRedirectToDemandeReferencement,
    watchResetMdp,
    watchStatistiquesDeclarations,
    watchUtilisateur
} from "./store/sagas/index.saga";
import ScrollToTop from "./commonComponents/layout/scrollToTop";
import { LOGOUT } from "./store/actionTypes/actionTypes";
import setupAxiosInterceptors from "./services/interceptor/AxiosInterceptor";
import mesPartenairesReducer from "./store/reducers/mesPartenaires/mesPartenaires.reducer";
import demandeReferencementReducer from "./store/reducers/demandeReferencement/demandeReferencement.reducer";

// eslint-disable-next-line no-process-env,no-undef,no-underscore-dangle
const composeEnhancers = (process.env.NODE_ENV === "development" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const history = supportsHtml5HistoryApi() ? createBrowserHistory() : createHashHistory({ hashType: "slash" });

const appReducer = combineReducers({
    router: connectRouter(history),
    inscription: inscriptionReducer,
    login: loginReducer,
    rechercheGLN: rechercheGLNReducer,
    stepForm: stepFormReducer,
    globalDialog: globalDialogReducer,
    admin: adminReducer,
    attestationFin: attestationFinReducer,
    declaration: declarationReducer,
    utilisateur: utilisateurReducer,
    common: commonReducer,
    integrationTableur,
    mesDeclarations: mesDeclarationsReducer,
    integrationTableurRecapitulatif: integrationTableurRecapitulatifReducer,
    integrationTableurChoixEntete: integrationTableurChoixEnteteReducer,
    integrationTableurDeclaration: integrationTableurDeclarationReducer,
    integrationTableurCorrectionErreur: integrationTableurCorrectionErreurReducer,
    statistiquesDeclarations: statistiquesDeclarationsReducer,
    croisementBibFour: croisementBibFourReducer,
    mesFactures: mesFacturesReducer,
    notifications: notificationsReducer,
    monProfil: monProfilReducer,
    libelle: libelleReducer,
    mesPartenaires: mesPartenairesReducer,
    demandeReferencement: demandeReferencementReducer
});

const rootReducer = (state, action) => {
    if (action.type === LOGOUT) {
        // eslint-disable-next-line no-param-reassign
        state = undefined;
    }

    return appReducer(state, action);
};

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk, sagaMiddleware, routerMiddleware(history)))
);

setupAxiosInterceptors();
sagaMiddleware.run(watchLogin);
sagaMiddleware.run(watchAttestationFinDeclarations);
sagaMiddleware.run(watchRechercheGlN);
sagaMiddleware.run(watchInscriptionProfil);
sagaMiddleware.run(watchAdmin);
sagaMiddleware.run(watchMdpOublie);
sagaMiddleware.run(watchResetMdp);
sagaMiddleware.run(watchDemandeReferencement);
sagaMiddleware.run(watchRedirectToDemandeReferencement);
sagaMiddleware.run(watchUtilisateur);
sagaMiddleware.run(watchDeclaration);
sagaMiddleware.run(watchIntegration);
sagaMiddleware.run(watchIntegrationRecapitulatif);
sagaMiddleware.run(watchIntegrationEntete);
sagaMiddleware.run(watchIntegrationCorrectionErreur);
sagaMiddleware.run(watchIntegrationGetListeDeclaration);
sagaMiddleware.run(watchMesDeclarations);
sagaMiddleware.run(watchStatistiquesDeclarations);
sagaMiddleware.run(watchDocumentsDeclarations);
sagaMiddleware.run(watchCroisementBibFour);
sagaMiddleware.run(watchMesFactures);
sagaMiddleware.run(watchMonProfil);
sagaMiddleware.run(watchLibelle);
sagaMiddleware.run(watchGlobalDialog);
sagaMiddleware.run(watchMesPartenaires);
sagaMiddleware.run(watchNotifications);
sagaMiddleware.run(watchCommon);

ReactDOM.render(<Provider store={store}>
    <ConnectedRouter history={history}>
        {/* CF https://reacttraining.com/react-router/web/guides/scroll-restoration/scroll-to-top */}
        <ScrollToTop/>
        <StrictMode>
            <App/>
        </StrictMode>
    </ConnectedRouter>
</Provider>, document.getElementById("root"));

/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
