import React from "react";
import { Close } from "@material-ui/icons";
import { DialogTitle, IconButton, Typography, withStyles } from "@material-ui/core";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

function StyledClosableDialogTitle({ children, classes, onClose, ...other }) {
    return (
        <DialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <Close/>
            </IconButton>
        </DialogTitle>
    );
}

export const ClosableDialogTitle = withStyles(styles)(StyledClosableDialogTitle);
