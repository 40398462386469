import * as actionTypes from "../actionTypes/actionTypes";

export const getLibelles = () => ({
    type: actionTypes.GET_LIBELLES
});

export const getLibellesSuccess = datas => ({
    type: actionTypes.GET_LIBELLES_SUCCESS,
    datas
});
