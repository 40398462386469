import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Checkbox from "@material-ui/core/Checkbox";
export const GreenCheckbox = withStyles({
    root: {
        "&$checked": {
            color: green[600]
        }
    },
    // Si le checked n'est pas à vide, la couleur verte ne s'applique pas
    checked: {}
})(props => <Checkbox color="default" {...props} />);
