import React, { useEffect, useRef, useState } from "react";
import * as VilleInseeService from "../../../services/ville.service";
import { LIST_CODE_PAYS_FRANCE } from "../../../utils/paysConstantes";
import CodeAndVilleAutoSuggestBase from "../../autoSuggest/CodeAndVilleAutoSuggestBase";


function CodePostalAutoSuggest(props) {
    const [suggestions, setSuggestions] = useState([]);
    const inputRef = useRef(null);
    const { codePays, changeCodePostalAndVille, InputComponent, inputProps: initialInputProps } = { ...props };

    function shouldRenderSuggestions(value) {
        // On affiche les suggesitons si on renseigne les 5 chiffres
        const lengthLimiter = value.trim().length === 5;
        const enFrance = LIST_CODE_PAYS_FRANCE.includes(codePays);
        const showSuggestions = suggestions && enFrance; // On affiche les suggestions de villes pour la france uniquement
        return showSuggestions && lengthLimiter;
    }

    function onSuggestionsFetchRequested({ value }) {
        if (value !== "" && LIST_CODE_PAYS_FRANCE.includes(codePays)) {
            // Si on a un code postal on s'en sert pour la recherche
            if (value) {
                VilleInseeService.getVillesByCodePostal(value).then(response => {
                    response && setSuggestions(response.data);
                });
            }
        }
    }

    function onKeyDown(event) {
        if (event.key === "ArrowDown" || event.key === "ArrowUp") {
            event.stopPropagation();
        }
    }

    useEffect(() => {
        // Pour n'appliquer le customEvent que sur ce composant
        inputRef.current.addEventListener("keydown", event => onKeyDown(event));
        return () => {
            inputRef.current.removeEventListener("keydown", event => onKeyDown(event));
        };
    }, []);


    return (
        <CodeAndVilleAutoSuggestBase
            suggestions={suggestions}
            setSuggestions={setSuggestions}
            inputProps={{ inputRef,
                ...initialInputProps }}
            changeCodePostalAndVille={changeCodePostalAndVille}
            InputComponent={InputComponent}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            shouldRenderSuggestions={shouldRenderSuggestions}
        />
    );
}

export default CodePostalAutoSuggest;
