import React from "react";
import { IconButton, Typography } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import classNames from "classnames";
import HomeIcon from "@material-ui/icons/Home";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AdminPanel from "../../../components/admin/adminPanel/adminPanel.container";
import Controls from "../controls/controls.component";
import Menu from "../menu/menu.component";
import { LIBELLE_LOGIN_BIENVENUE_1, LIBELLE_LOGIN_BIENVENUE_2 } from "../../../utils/libelleConstantes";
import InformationDialog from "../../dialog/informations/InformationDialog";
import { PATH_LOGIN } from "../../../routes/paths/paths.util";
import { selectShowMenu } from "../../../utils/selectors";
import classes from "./header.module.css";
import LogoComponent from "./logo.component";


export default function Header(props) {
    const history = useHistory();
    const showMenu = useSelector(selectShowMenu);

    const { isAdmin, signOut, locale, updateLocale, isLogged } = props;
    const className = isLogged ? classNames(classes.Header, classes.HeaderBorder) : classes.Header;

    return (
        <div className={className}>
            <LogoComponent isLogged={isLogged}/>
            {isAdmin && <AdminPanel {...props} history/>}
            {!isLogged &&
                <div>
                    <Typography variant="body1"><Trans id={LIBELLE_LOGIN_BIENVENUE_1}/></Typography>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}><Trans id={LIBELLE_LOGIN_BIENVENUE_2}/></Typography>
                </div>
            }
            {!isLogged &&
                <div style={{ display: "flex" }}>
                    {history.location.pathname !== PATH_LOGIN &&
                        <IconButton onClick={() => history.push(PATH_LOGIN)}><HomeIcon/></IconButton>
                    }
                    <InformationDialog/>
                </div>

            }
            <Menu isLogged={showMenu} signOut={signOut}/>
            <Controls layout="desktop" locale={locale} updateLocale={updateLocale} signOut={signOut} isLogged={isLogged}/>
        </div>
    );
}
