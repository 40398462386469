import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans } from "@lingui/macro";
import Alert from "@material-ui/lab/Alert";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import CardContainer from "../../../commonComponents/cardContainer/CardContainer.container";
import InteractiveConfirmButton from "../../declaration/tableur/correctionErreur/renderer/button/InteractiveConfirmButton";
import * as actions from "../../../store/actions/index.action";
import PartenaireStatistiqueFiltre from "../filtres/partenaireStatistiqueFiltre";
import AnneeStatistiqueFiltre from "../filtres/anneeStatistiqueFiltre";
import FactureStatistiqueFiltre from "../filtres/factureStatistiqueFiltre";
import { selectCommon, selectMesStatistiques, selectUtilisateurInformations } from "../../../utils/selectors";
import { usePrevious } from "../../../utils/utility";
import { LIBELLE_EXPORT_LIGNES_EAN, TEMPS_MAX_EXPORT_LIGNES_EAN, TEMPS_MIN_EXPORT_LIGNES_EAN } from "../../../utils/libelleConstantes";


export function ExportLignesEan() {
    const { anneeCivile, disabledButton, loadingCountExportEanLigne, countExportEanLigne } = useSelector(selectMesStatistiques);
    const { regroupe } = useSelector(selectUtilisateurInformations);
    const { isLoading } = useSelector(selectCommon);
    const dispatch = useDispatch();

    const prevPropsAnneeCivile = usePrevious(anneeCivile);
    useEffect(() => {
        // Récupération du compte de ligne concernées pour pouvoir afficher un temps approximatif
        if (anneeCivile && (!prevPropsAnneeCivile || prevPropsAnneeCivile !== anneeCivile)) {
            dispatch(actions.getCountExportLigneEan());
        }
    }, [anneeCivile]);

    const duration = moment.utc(moment.duration(countExportEanLigne / 700000, "m").asMilliseconds());
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    const showFastMessage = seconds < 5 && minutes === 0;

    const message = showFastMessage ? <Trans id={TEMPS_MIN_EXPORT_LIGNES_EAN}/>
        : <Trans id={TEMPS_MAX_EXPORT_LIGNES_EAN} values={{ nombreMinutes: minutes, nombreSecondes: seconds }}/>;
    const waitOrMessage = loadingCountExportEanLigne ? <CircularProgress size={24}/>
        : message;

    return <CardContainer title={LIBELLE_EXPORT_LIGNES_EAN}>
        <div style={{ display: "flex", width: "100%" }}>
            <AnneeStatistiqueFiltre/>
            <PartenaireStatistiqueFiltre/>
        </div>
        <div style={{ width: "100%" }}>
            {!regroupe && <FactureStatistiqueFiltre/>}
        </div>
        <div style={{
            display: "flex",
            paddingLeft: "20px",
            paddingRight: "20px",
            justifyContent: "space-between"
        }}>
            <div>
                {(countExportEanLigne > 0 || loadingCountExportEanLigne) &&
                    <Alert severity={"info"}>
                        {waitOrMessage}
                    </Alert>
                }
            </div>
            {/* Le bouton sera inactif si aucune date n'est sélectionnée ou la liste des partenaires / Factures est en cours de chargement*/}
            <InteractiveConfirmButton
                message={"export"}
                loading={isLoading}
                onClick={() => dispatch(actions.exportLignesEan())}
                disabled={disabledButton || !anneeCivile}/>
        </div>
    </CardContainer>;
}

export default ExportLignesEan;
