import React from "react";
import { Redirect, useLocation, useRouteMatch } from "react-router-dom";

export default function Guard({ children, isParamValid, currentRoute }) {
    const { path } = useRouteMatch();
    const { pathname } = useLocation();

    // Si le paramètre reçu n’est pas valide et que l’on est sur la route à protéger, on redirige
    return !isParamValid() && path === currentRoute
        ? <Redirect to={pathname.substring(0, pathname.lastIndexOf("/"))}/>
        : children;
}
