import { Box, Dialog, DialogContent, DialogTitle, IconButton, Link, Typography } from "@material-ui/core";
import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { Trans } from "@lingui/macro";
import { Info } from "@material-ui/icons";


export default function InformationDialog() {
    const [open, setOpen] = useState(false);
    return <div>
        <IconButton onClick={() => setOpen(true)} title="Informations"><Info/></IconButton>
        <Dialog open={open} fullWidth={true} maxWidth={"xl"} style={{ textAlign: "justify" }}>
            <DialogTitle>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                    <Typography variant="h5" gutterBottom><Trans>informationTitle1</Trans></Typography>
                    <IconButton onClick={() => setOpen(false)}><CloseIcon/></IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <Box>
                    <Typography variant="body1" gutterBottom>
                        <Trans>informationBody1</Trans>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <Trans>informationBody1Bis</Trans>
                    </Typography>
                    <Typography variant="h6" gutterBottom style={{ color: "red" }}>
                        <Trans>informationTitle2</Trans>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <Trans>informationBody2</Trans>
                    </Typography>
                    <Typography variant="h6" gutterBottom style={{ color: "red" }}>
                        <Trans>informationTitle3</Trans>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <Trans>informationBody3</Trans>
                    </Typography>
                    <Typography variant="h6" gutterBottom style={{ color: "red" }}>
                        <Trans>informationTitle4</Trans>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <Trans>informationBody4</Trans>
                        <Link target="_blank"
                            href="https://www.la-sofia.org/droits-geres/droit-de-pret/">https://www.la-sofia.org/droits-geres/droit-de-pret/</Link>
                    </Typography>
                </Box>
            </DialogContent>
        </Dialog>
    </div>;
}
