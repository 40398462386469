import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ValidatorForm } from "react-material-ui-form-validator";
import { makeStyles } from "@material-ui/core";
import moment from "moment";
import { Trans } from "@lingui/macro";
import { selectMonProfilCompteUtilisateur, selectUtilisateurRoles } from "../../../../utils/selectors";
import { classeNames } from "../../../../commonComponents/input/fieldInput";
import * as actions from "../../../../store/actions/index.action";
import { checkIsFournisseurConnected, enableInstantValidate, isEqualObjects } from "../../../../utils/utility";
import { entiteFormulaireTemplate, useStyles } from "../../monProfilUtil";
import { MonProfilLibelleInteractiveBoutonSauvegarde } from "../../MonProfilLibelleBoutonSauvegarde";
import { handleNotificationWithoutSave } from "../../monProfil.component";
import { EntiteFormulaire } from "../../../../commonComponents/formulaire/EntiteFormulaire.component";
import { LIBELLE_MON_PROFIL_INFORMATIONS_ENTITE } from "../../../../utils/libelleConstantes";

const styleNpai = makeStyles({
    parent: classeNames.parent,
    content: {
        width: "800px",
        margin: "1em 0",
        fontStyle: "italic"
    }
});

function MonProfilFormulaireEntiteComponent({ premiereCo, npai, bibEtrangere }) {
    const compteUtilisateur = useSelector(selectMonProfilCompteUtilisateur);
    const roles = useSelector(selectUtilisateurRoles);

    const [formulaire, setFormulaire] = useState(entiteFormulaireTemplate);
    const classes = useStyles();
    const classesNpai = styleNpai();
    const dispatch = useDispatch();
    const formRef = useRef();
    const isFournisseur = checkIsFournisseurConnected(roles);


    useEffect(() => {
        // Permet de renseigner les champs du formulaire une fois les infos récupérées du store redux
        setFormulaire(compteUtilisateur);
    }, [compteUtilisateur]);


    useEffect(() => {
        const formulaireDiffToInitialeState = !isEqualObjects(formulaire, entiteFormulaireTemplate);
        handleNotificationWithoutSave(premiereCo, dispatch, formulaireDiffToInitialeState && !isEqualObjects(formulaire, compteUtilisateur));
    }, [formulaire, premiereCo, compteUtilisateur, dispatch]);

    function onSubmit(e) {
        e.preventDefault();
        if (isFournisseur) {
            formulaire.dateCreation = moment(formulaire.dateCreation).format("YYYY-MM-DD");
        }
        dispatch(actions.saveMonProfilCompteUtilisateur(formulaire));
    }

    return <ValidatorForm onSubmit={onSubmit} instantValidate={false} ref={formRef}>
        <div className={classes.titreStepper}>
            <Trans id={LIBELLE_MON_PROFIL_INFORMATIONS_ENTITE}/>
        </div>
        {npai && <div className={classesNpai.parent}><span className={classesNpai.content}><Trans id="monProfilEntiteNPAI"/></span></div>}
        {bibEtrangere && <div className={classesNpai.parent}><span className={classesNpai.content}><Trans id="monProfilEntiteBibEtrangere"/></span></div>}
        <EntiteFormulaire formulaire={formulaire} setFormulaire={setFormulaire} isFournisseur={isFournisseur}/>
        <div className={classes.sauvegarderButton}>
            <MonProfilLibelleInteractiveBoutonSauvegarde premiereCo={premiereCo} handleClick={() => enableInstantValidate(formRef)}/>
        </div>
    </ValidatorForm>;
}

export default memo(MonProfilFormulaireEntiteComponent);

MonProfilFormulaireEntiteComponent.whyDidYouRender = true;
