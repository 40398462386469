import * as actionsType from "../actionTypes/actionTypes";


export const updateMdSortColumn = token => ({
    type: actionsType.UPDATE_MD_SORT_COLUMN,
    token
});

export const updateFiltreTypeAnnee = name => ({
    type: actionsType.UPDATE_MD_FILTRE_TYPE_ANNEE,
    name
});

export const updateMdPage = token => ({
    type: actionsType.UPDATE_MD_PAGE,
    token
});
export const updateMdTypeDeclaration = token => ({
    type: actionsType.UPDATE_MD_TYPE_DECLARATION,
    token
});

export const reinitMdTypeDeclaration = value => ({
    type: actionsType.REINIT_MD_TYPE_DECLARATION,
    value
});

export const updateMdGlnDeclarant = token => ({
    type: actionsType.UPDATE_MD_GLN_DECLARANT,
    token
});

export const updateMdGlnDeclarantAndReinitPartenaire = token => ({
    type: actionsType.UPDATE_MD_GLN_DECLARANT_AND_REINIT_PARTENAIRE,
    token
});
export const updateMdPartenaires = token => ({
    type: actionsType.UPDATE_MD_PARTENAIRES,
    token
});
export const updateMdFiltrePartenaires = filtresPartenaires => ({
    type: actionsType.UPDATE_MD_FILTRE_PARTENAIRES,
    filtresPartenaires
});

export const updateMdFiltreInvalideDeclaration = flag => ({
    type: actionsType.UPDATE_MD_SHOW_ONLY_INVALIDE_DECLARATION,
    flag
});

export const updateMdAnneeDebut = token => ({
    type: actionsType.UPDATE_MD_ANNEE_DEBUT,
    token
});
export const updateMdPeriod = token => ({
    type: actionsType.UPDATE_MD_PERIOD,
    token
});
export const updateMdAnneeFin = token => ({
    type: actionsType.UPDATE_MD_ANNEE_FIN,
    token
});
export const updateMdTypeAnnee = token => ({
    type: actionsType.UPDATE_MD_TYPE_ANNEE,
    token
});
export const updateMdDialogDeclarationModifEnCours = token => ({
    type: actionsType.UPDATE_MD_DIALOG_DECLARATION_MODIF_EN_COURS,
    token
});
export const updateMdDoutes = token => ({
    type: actionsType.UPDATE_MD_DOUTES,
    token
});
export const updateMdNombreDeclarationParType = token => ({
    type: actionsType.UPDATE_MD_NOMBRE_DECLARATION_PAR_TYPE,
    token
});
export const updateMdResultat = token => ({
    type: actionsType.UPDATE_MD_RESULTAT,
    token
});
export const updateMdAllInfos = token => ({
    type: actionsType.UPDATE_MD_ALL_INFOS,
    token
});
export const getInfosNecessairesSaga = () => ({
    type: actionsType.GET_INFOS_NECESSAIRES
});
export const getMesDeclarationsSaga = () => ({
    type: actionsType.UPDATE_MD_RESULTAT_SAGA
});

export const getMDListDeclarationsLigneByNumeroDeclaration = (index, numeroDeclaration) => ({
    type: actionsType.GET_MD_LIST_DECLARATIONS_LIGNE_BY_NUMERO_DECLARATION,
    index,
    numeroDeclaration
});

export const getMDListeDelcarationsLigneByNumeroDeclarationResult = (rowIndex, lignes) => ({
    type: actionsType.GET_MD_LIST_DECLARATIONS_LIGNE_BY_NUMERO_DECLARATION_RESULT,
    rowIndex,
    lignes
});

export const clearMDStore = () => ({
    type: actionsType.CLEAR_MD_STORE
});

export const refreshMDStore = () => ({
    type: actionsType.REFRESH_MD_STORE
});


export const updateIndexOpenedDeclaration = index => ({
    type: actionsType.UPDATE_MD_INDEX_OPENED_DECLARATION,
    index
});

export const sendAllFiltered = () => ({
    type: actionsType.SEND_MD_ALL_FILTERED
});

export const updateMdTotalSizeRecap = totalSize => ({
    type: actionsType.UPDATE_MD_TOTAL_SIZE_RECAP,
    totalSize
});

export const invalidateDeclarationByNumeroDeclaration = (numeroDeclaration, isLastDeclaration = false) => ({
    type: actionsType.INVALIDATE_DECLARATION_BY_NUMERO,
    numeroDeclaration,
    isLastDeclaration
});

export const sendDeclarationByNumeroDeclaration = (numeroDeclaration, isLastDeclaration = false) => ({
    type: actionsType.SEND_DECLARATION_BY_NUMERO,
    numeroDeclaration,
    isLastDeclaration
});

export const rollBackDeclarationByNumeroDeclaration = (numeroDeclaration, isLastDeclaration = false) => ({
    type: actionsType.ROLLBACK_DECLARATION_MODIFICATION_BY_NUMERO,
    numeroDeclaration,
    isLastDeclaration
});


export const updateMDDeclarationEnteteEtLignes = (entete, lignes, partenaire, isClone = false, isOriginale = false, rowIndex) => ({
    type: actionsType.UPDATE_MD_DECLARATION_ENTETE_ET_LIGNES,
    entete,
    lignes,
    partenaire,
    isClone,
    isOriginale,
    rowIndex
});

export const closeMDDeclaration = (closingPopup = true) => ({
    type: actionsType.CLOSE_MD_DECLARATION,
    closingPopup
});

export const updateMdSourceDeclaration = sources => ({
    type: actionsType.UPDATE_MD_SOURCE_DECLARATION,
    sources
});

export const updateEntetesAndRowIndex = index => ({
    type: actionsType.UPDATE_ENTETES_AND_ROWINDEX,
    index
});

export const updateEntetesAndRowIndexResult = (entetes, index) => ({
    type: actionsType.UPDATE_ENTETES_AND_ROWINDEX_RESULT,
    entetes,
    index
});

export const modifyDeclarationFacturee = numeroDeclaration => ({
    type: actionsType.MODIFY_DECLARATION_FACTUREE,
    numeroDeclaration
});

export const checkStatutMDDeclaration = () => ({
    type: actionsType.CHECK_STATUT_MD_DECLARATION_SAGA
});

export const onNextDeclarationFromMesDeclarations = () => ({
    type: actionsType.ON_NEXT_DECLARATION_FROM_MES_DECLARATIONS_SAGA
});

export const onPreviousDeclarationFromMesDeclarations = () => ({
    type: actionsType.ON_PREVIOUS_DECLARATION_FROM_MES_DECLARATIONS_SAGA
});

export const closeDetailDialogFromMesDeclarations = () => ({
    type: actionsType.CLOSE_DETAIL_DIALOG_FROM_MES_DECLARATIONS_SAGA
});

export const addClone = clone => ({
    type: actionsType.ADD_MD_CLONE,
    clone
});

export const updateMDShowClone = showClone => ({
    type: actionsType.UPDATE_MD_SHOW_CLONE,
    showClone
});

export const updateMDShowOriginale = showOriginale => ({
    type: actionsType.UPDATE_MD_SHOW_ORIGINALE,
    showOriginale
});
export const updateMDOpenDetailDialog = open => ({
    type: actionsType.UPDATE_MD_OPEN_DETAIL_DIALOG,
    open
});

export const setMdLoading = loading => ({
    type: actionsType.SET_MD_LOADING,
    loading
});

export const getPartenaireAndUpdateFiltre = () => ({
    type: actionsType.GET_MD_PARTENAIRE_AND_UPDATE_FILTRE
});

export const updateMDStatistiquesMontants = () => ({
    type: actionsType.UPDATE_MD_STATISTIQUES_MONTANTS
});

export const updateMDStatistiquesMontantsResult = statistiques => ({
    type: actionsType.UPDATE_MD_STATISTIQUES_MONTANTS_RESULT,
    statistiques
});

export const setMdLoadingStatistiquesMontants = loading => ({
    type: actionsType.SET_MD_LOADING_STATISTIQUES_MONTANTS,
    loading
});

export const setMdShowStatistiquesMontants = show => ({
    type: actionsType.SET_MD_SHOW_STATISTIQUES_MONTANTS,
    show
});

export const updateMdFiltreNumeroFacture = numeroFacture => ({
    type: actionsType.UPDATE_MD_FILTRE_NUMERO_FACTURE,
    numeroFacture
});
