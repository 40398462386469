import { Dialog, DialogContent, IconButton, Link } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import React, { useState } from "react";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import _ from "lodash";
import { LIBELLE_NOUS_CONTACTER } from "../../utils/libelleConstantes";
import StyledTooltip from "../tooltip/styledTooltip.component";
import NousContacter from "../../components/nousContacter/NousContacter.component";
import { globalI18n } from "../../i18n.conf";
import { ClosableDialogTitle } from "./ClosableDialogTitle.component";

export default function NousContacterDialog({ fromPC = false }) {
    const [open, setOpen] = useState(false);
    const closeDialog = () => setOpen(false);
    const openDialog = () => setOpen(true);
    const link = <Link onClick={openDialog} href={"#"}>
        {_.lowerCase(globalI18n._(LIBELLE_NOUS_CONTACTER))}
    </Link>;
    const icon = <StyledTooltip show={true} keyMessage={LIBELLE_NOUS_CONTACTER}>
        <IconButton onClick={openDialog}>
            <MailOutlineIcon />
        </IconButton>
    </StyledTooltip>;
    const linkOrIcon = fromPC ? link : icon;
    return (
        <>
            {linkOrIcon}
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={"xl"}
                style={{ textAlign: "justify" }}>
                <ClosableDialogTitle onClose={closeDialog}>
                    <Trans id={LIBELLE_NOUS_CONTACTER} />
                </ClosableDialogTitle>
                <DialogContent>
                    <NousContacter
                        closeDialog={closeDialog}
                        calledFromLogin={false}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
}
