import LoadingOverlay from "react-loading-overlay";
import { Trans } from "@lingui/macro";
import React from "react";

export const SpinnerOverlay = props => <LoadingOverlay
    active={props.loading}
    spinner
    styles={{
        overlay: base => ({
            ...base,
            background: "rgba(255, 252, 252, 0.82)",
            color: "#0aa9d4"
        }),
        spinner: base => ({
            ...base,
            "& svg circle": {
                stroke: "#0aa9d4"
            }
        })
    }}
    text={<Trans id="tableLoading"/>}
>{props.children}</LoadingOverlay>;
