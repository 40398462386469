import React, { useEffect, useRef, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { FormControlLabel } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../store/actions/index.action";
import {
    selectMonProfil,
    selectMonProfilCompteUtilisateur,
    selectMonProfilSkipStep,
    selectUtilisateurGencod,
    selectUtilisateurPremiereConnexion
} from "../../../../utils/selectors";
import { globalI18n } from "../../../../i18n.conf";
import { MON_PROFIL_STEP_DFA, MON_PROFIL_STEP_RDP, MON_PROFIL_STEP_RL, useStyles } from "../../monProfilUtil";
import { MonProfilLibelleInteractiveBoutonSauvegarde } from "../../MonProfilLibelleBoutonSauvegarde";
import {
    LIBELLE_FONCTION_CONTACT_CODE_RDP,
    LIBELLE_MON_PROFIL_CONTACT_COORDONNEES_TEMPORAIRES,
    LIBELLE_MON_PROFIL_CONTACT_INFO_DFA,
    LIBELLE_MON_PROFIL_CONTACT_INFO_RDP,
    LIBELLE_MON_PROFIL_CONTACT_MAIL_TEMPORAIRE,
    LIBELLE_MON_PROFIL_DUPLIQUER_INFOS_ENTITE,
    LIBELLE_MON_PROFIL_INFORMATIONS_DFA,
    LIBELLE_MON_PROFIL_INFORMATIONS_RDP,
    LIBELLE_MON_PROFIL_INFORMATIONS_RL,
    LIBELLE_MON_PROFIL_INFOS_DONNEES_TEMPORAIRES,
    LIBELLE_MON_PROFIL_INFOS_DONNEES_TEMPORAIRES_NON_RDP,
    LIBELLE_MON_PROFIL_INFOS_DONNEES_TEMPORAIRES_RDP,
    LIBELLE_MON_PROFIL_SAUT_ETAPE_DFA,
    LIBELLE_MON_PROFIL_SAUT_ETAPE_RL
} from "../../../../utils/libelleConstantes";
import AdresseFieldGroupComponent from "../../../../commonComponents/input/adresse/AdresseFieldGroup.component";
import { enableInstantValidate, isBlank, isEnCoursPremiereCo, isEnForcage } from "../../../../utils/utility";
import { GreenCheckbox } from "../../../../commonComponents/checkBox/GreenCheckBox";
import TextHeader from "../../../../commonComponents/texte/TextHeader.container";
import ContactMailFormulaireComponent from "../../../../commonComponents/formulaire/ContactMailFormulaire.component";
import CommonContactFormulaire from "../../../../commonComponents/formulaire/commonContactFormulaire.component";


function HeaderInfoContact({ code }) {
    const classes = useStyles();
    let keyMessageResponsable = "";
    let keyTitreResponsable = "";
    if (code === MON_PROFIL_STEP_DFA) {
        keyMessageResponsable = LIBELLE_MON_PROFIL_CONTACT_INFO_DFA;
        keyTitreResponsable = LIBELLE_MON_PROFIL_INFORMATIONS_DFA;
    } else if (code === MON_PROFIL_STEP_RDP) {
        keyMessageResponsable = LIBELLE_MON_PROFIL_CONTACT_INFO_RDP;
        keyTitreResponsable = LIBELLE_MON_PROFIL_INFORMATIONS_RDP;
    } else if (code === MON_PROFIL_STEP_RL) {
        keyTitreResponsable = LIBELLE_MON_PROFIL_INFORMATIONS_RL;
    }
    if (isBlank(keyMessageResponsable) && isBlank(keyTitreResponsable)) {
        return (<></>);
    }
    return (<>
            {keyTitreResponsable && <div className={classes.titreStepper}>
                <Trans id={keyTitreResponsable}/>
            </div>}
            {keyMessageResponsable && <TextHeader>
                <Trans id={keyMessageResponsable}/>
            </TextHeader>}
        </>
    );
}

function HeaderWarningTempDatas({ contactFound, formulaire }) {
    const { mailValide, contactValide, fonction } = contactFound;
    const dispatch = useDispatch();
    const gencod = useSelector(selectUtilisateurGencod);
    const [mailModified, setMailModified] = useState(false);

    // Pour éviter de lancer des appels vers le back qui ecrase le mail en cours d'édition
    useEffect(() => {
        if (contactFound && formulaire && formulaire.email !== contactFound.email) {
            setMailModified(true);
        } else {
            setMailModified(false);
        }
    }, [formulaire, contactFound]);

    useEffect(() => {
        const intervalId = setInterval(
            () => {
                if (!mailModified && !contactFound.mailValide) {
                    dispatch(actions.getMonProfilAvecNotification(gencod));
                }
            },
            10000
        );
        return () => {
            clearInterval(intervalId);
        };
    }, [contactFound, mailModified]);

    if (mailValide && contactValide) {
        return (<></>);
    }
    return <TextHeader severity="warning">
        {!mailValide && <div><Trans id={LIBELLE_MON_PROFIL_CONTACT_MAIL_TEMPORAIRE}/></div>}
        {!contactValide && <div><Trans id={LIBELLE_MON_PROFIL_CONTACT_COORDONNEES_TEMPORAIRES}/></div>}
        <div>
            <Trans id={LIBELLE_MON_PROFIL_INFOS_DONNEES_TEMPORAIRES}/>
            <Trans
                id={fonction === LIBELLE_FONCTION_CONTACT_CODE_RDP ? LIBELLE_MON_PROFIL_INFOS_DONNEES_TEMPORAIRES_RDP : LIBELLE_MON_PROFIL_INFOS_DONNEES_TEMPORAIRES_NON_RDP}/>
        </div>
    </TextHeader>;
}

export default function MonProfilFormulaireContactInfosComponent({
    code,
    classes,
    formulaire,
    setFormulaire,
    dispatch,
    contactFound,
    withMail,
    premiereCo,
    isFournisseur
}) {
    const initialSkipStep = useSelector(selectMonProfilSkipStep);
    const premiereConnexion = useSelector(selectUtilisateurPremiereConnexion);
    const { currentStepPremiereCo: activeStep, lastValidatedStepPremiereCo } = useSelector(selectMonProfil);
    const compteUtilisateur = useSelector(selectMonProfilCompteUtilisateur);
    const formReference = useRef();

    const [applyEntityDetails, setApplyEntityDetails] = useState(false);
    const [skipStep, setSkipStep] = useState(initialSkipStep);

    const premiereConnexionEnCours = isEnCoursPremiereCo(premiereConnexion);
    // On affiche la case à cocher pour dupliquer les infos de l'entité seulement si on est sur la première connexion ou une étape de forçage
    const showDuplicateEntity = premiereConnexionEnCours || isEnForcage(premiereConnexion);
    const isRDPStep = code === LIBELLE_FONCTION_CONTACT_CODE_RDP && premiereConnexionEnCours;

    // Met à jour le champ modifié dans notre objet de state formulaire
    function onChange(field, value) {
        setFormulaire({
            ...formulaire,
            [field]: value
        });
    }

    /**
     *  Si on affiche un contact différent (comme lors d'un changement d'onglet), on détecte un changement de la propriété function (=code)
     */
    useEffect(() => {
        formReference.current.resetValidations();
        setApplyEntityDetails(false); // On décoche la case de duplication des coordonnées de l'entité
    }, [formulaire && formulaire.fonction, code]);

    function onSubmit(e) {
        e.preventDefault();
        dispatch(actions.saveContactGlobal(formulaire, code, skipStep));
    }

    function copyEntityDetails(event) {
        setApplyEntityDetails(event.target.checked);
        // Copie des données de l'entité
        if (event.target.checked) {
            setFormulaire({
                ...formulaire,
                numeroVoie: compteUtilisateur.numeroVoie,
                adresse1: compteUtilisateur.adresse1,
                adresse2: compteUtilisateur.adresse2,
                codePostal: compteUtilisateur.codePostal,
                ville: compteUtilisateur.ville,
                codePays: compteUtilisateur.codePays
            });
        } else { // Annule la copie des données de l'entité
            setFormulaire({
                ...formulaire,
                numeroVoie: contactFound.numeroVoie,
                adresse1: contactFound.adresse1,
                adresse2: contactFound.adresse2,
                codePostal: contactFound.codePostal,
                ville: contactFound.ville,
                codePays: contactFound.codePays
            });
        }
    }

    function changeSkipStep(event) {
        const value = event.target.checked;
        dispatch(actions.updateSkipStepPremiereConnexion(value, isFournisseur ? MON_PROFIL_STEP_DFA : MON_PROFIL_STEP_RL));
        setSkipStep(value);
    }

    /*
     * On Vérifie que le step courant de l'utilisateur est supérieur du dernier step validé
     * afin d'afficher le champ confirmation du mail
     */
    function isCurrentStepNotAlreadyDone() {
        if (premiereCo) {
            const steps = premiereConnexion.steps;
            // L'index du dernier step validé par l'utilisateur
            const indexOfLastValidatedStepPremierCo = steps.indexOf(lastValidatedStepPremiereCo);
            // L'index du step courant où l'utilisateur est en train de faire ses modifications
            const indexOfCurrentStepPremierCo = steps.indexOf(activeStep);
            return indexOfCurrentStepPremierCo > indexOfLastValidatedStepPremierCo;
        }
    }

    const libelleDuplication = isFournisseur ? LIBELLE_MON_PROFIL_SAUT_ETAPE_DFA : LIBELLE_MON_PROFIL_SAUT_ETAPE_RL;

    return <div>
        <HeaderInfoContact code={code}/>
        <div className={classes.affichageColonneCentree} style={{ gap: "5px", marginBottom: "15px" }}>
            {!premiereConnexionEnCours && contactFound &&
                <HeaderWarningTempDatas contactFound={contactFound} formulaire={formulaire}/>
            }
            {showDuplicateEntity && <FormControlLabel
                control={<GreenCheckbox checked={applyEntityDetails} value={true} onChange={copyEntityDetails}/>}
                label={<Trans id={LIBELLE_MON_PROFIL_DUPLIQUER_INFOS_ENTITE}/>}
            />}
        </div>
        <ValidatorForm ref={formReference} onSubmit={onSubmit} instantValidate={false}>
            <CommonContactFormulaire formulaire={formulaire} onChange={onChange} isFournisseur={isFournisseur} classes={classes}/>
            {withMail &&
                <ContactMailFormulaireComponent formulaire={formulaire} mailFromStore={contactFound && contactFound.email} premiereCo={premiereConnexionEnCours}
                    showConfirmationInput={isCurrentStepNotAlreadyDone()} onChange={onChange}/>}
            <AdresseFieldGroupComponent formulaire={formulaire} setFormulaire={setFormulaire} classes={classes}
                showOnlyPaysFrance={!isFournisseur}/>
            {isRDPStep &&
                <div className={classes.affichageColonneCentree}>
                    <FormControlLabel control={<GreenCheckbox checked={skipStep} onChange={changeSkipStep}/>} label={globalI18n._(libelleDuplication)}/>
                </div>
            }
            <div className={classes.sauvegarderButton}>
                <MonProfilLibelleInteractiveBoutonSauvegarde premiereCo={premiereCo} handleClick={() => enableInstantValidate(formReference)}/>
            </div>
        </ValidatorForm>
    </div>;
}
