import * as actionsType from "../actionTypes/actionTypes";

export const rechercheGLN = (typeProfil, bodyFilter, flag) => dispatch => {
    dispatch({
        type: actionsType.SEARCH_GLN_ASSUJETTI,
        profil: typeProfil,
        filter: bodyFilter,
        isFromInscription: flag
    });
    dispatch({
        type: actionsType.UPDATE_FLAG_FIRST_SEARCH,
        value: false
    });
};

export const rechercheGLNStart = () => ({
    type: actionsType.SEARCH_GLN_ASSUJETTI_START
});

export const rechercheGLNEnd = emptyDataMessage => ({
    type: actionsType.SEARCH_GLN_ASSUJETTI_END,
    emptyDataMessage
});

export const rechercheGLNFailed = emptyDataMessage => ({
    type: actionsType.SEARCH_GLN_ASSUJETTI_FAILED,
    emptyDataMessage
});

export const updateResultsGLN = values => ({
    type: actionsType.UPDATE_RESULT_GLN,
    results: values
});

export const updateResultsGLNAndActiveButton = values => ({
    type: actionsType.UPDATE_RESULT_GLN_AND_ACTIVE_BUTTON,
    results: values
});

export const updateTypeSearchGLN = falg => ({
    type: actionsType.UPDATE_TYPE_SEARCH_GLN,
    flag: falg
});

export const updateFlagFirstSearch = value => ({
    type: actionsType.UPDATE_FLAG_FIRST_SEARCH,
    value
});

export const updateFlagIsCalledFromDeclaration = value => ({
    type: actionsType.UPDATE_FLAG_IS_CALLED_DECLARATION,
    value
});

export const updateInfoFiltre = value => ({
    type: actionsType.UPDATE_FILTRE_SEARCH_GLN,
    value
});

export const initRechercheGLN = () => ({
    type: actionsType.INIT_RECHERCHE_GLN
});

