import { updateObject } from "../../../../utils/utility";
import * as actionTypes from "../../../actionTypes/actionTypes";

const initialState = {
    integrations: [],
    loading: true
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.GET_INTEGRATIONS_TABLEUR_BY_GLN_DECLARANT_RESULTS:
        return updateObject(state, {
            integrations: action.integrations,
            loading: false
        });
    case actionTypes.SET_INTEGRATION_TABLEUR_RECAPITULATIF_LOADING:
        return updateObject(state, {
            loading: action.loading
        });
    default:
        return state;
    }
};

export default reducer;
