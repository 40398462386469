import * as actionTypes from "../actionTypes/actionTypes";

export const updateInscriptionEntiteFormulaire = formulaire => ({
    type: actionTypes.UPDATE_INSCRIPTION_ENTITE_FORMULAIRE,
    formulaire
});

export const updateInscriptionRlFormulaire = formulaire => ({
    type: actionTypes.UPDATE_INSCRIPTION_RL_FORMULAIRE,
    formulaire
});

export const updateInscriptionRdpFormulaire = formulaire => ({
    type: actionTypes.UPDATE_INSCRIPTION_RDP_FORMULAIRE,
    formulaire
});

export const initStateInscription = () => ({
    type: actionTypes.INIT_STATE_INSCRIPTION
});

export const onChangeHiddenInput = value => ({
    type: actionTypes.CHANGE_HIDDEN_INPUT,
    value
});

export const onChangeProfilDialog = value => ({
    type: actionTypes.SELECT_PROFIL_ON_DIALOGN,
    value
});


export const setActiveStep = value => ({
    type: actionTypes.SET_ACTIVE_STEP,
    value
});

export const rechercheGlnBeforeInscription = profil => ({
    type: actionTypes.RECHERCHE_GLN_INSCRIPTION,
    profil
});

export const saveInscription = () => ({
    type: actionTypes.SAVE_INSCRIPTION
});

export const updateStatutSaveInscription = state => ({
    type: actionTypes.UPDATE_STATUT_SAVE_INSCRIPTION,
    state
});

export const updateFormulaireMailValide = (code, statut) => ({
    type: actionTypes.UPDATE_FORMULAIRE_MAIL_VALIDE,
    code,
    statut
});

export const updateLoadingInscription = loading => ({
    type: actionTypes.UPDATE_LOADING_INSCRIPTION,
    loading
});

export const updateIsFormulaireStarted = isFormulaireStarted => ({
    type: actionTypes.UPDATE_INSCRIPTION_IS_FORMULAIRE_STARTED,
    isFormulaireStarted
});

export const updateIsGencodSelected = isGencodSelected => ({
    type: actionTypes.UPDATE_INSCRIPTION_IS_GENCOD_SELECTED,
    isGencodSelected
});
