import { put, call } from "redux-saga/effects";
import { push } from "connected-react-router";
import * as actions from "../../../actions/index.action";
import { deleteIntegration, getIntegrationsByGlnDeclarant, integrerTableurFile } from "../../../../services/integrationTableur.service";
import { ERREUR_INTEGRATION_TABLEUR } from "../../../../utils/libelleConstantes";


export function *displayErrorMsgSaga (action) {
    const { response, messageCode } = action;
    yield put(actions.updateDisableValideButton(false));
    if (response && response.data && response.data.message) {
        response.data.message.messageKey ? yield put(actions.openDialog(response.data.message.messageKey)) : yield put(actions.openDialogNoRedirect("", response.data.message));
    } else {
        yield put(actions.openDialog(messageCode));
    }
}

export function *integrerTableurSaga (action) {
    yield put(actions.setIntegrationsTableurRecapitulatifLoading(true));
    const formData = new FormData();
    formData.append("glnDeclarant", action.glnDeclarant);
    formData.append("fichier", action.file);
    formData.append("nom", action.fileName);
    let response;
    try {
        response = yield integrerTableurFile(formData);
        if (response.status !== 200) {
            yield put(actions.displayErrorMessage(response, ERREUR_INTEGRATION_TABLEUR));
        } else {
            yield put(push(`${action.pathname}/${response.data.data.idIntegration}`));
        }
    } catch (error) {
        response = error.response;
        if (response && response.data && response.data.data && response.data.data.integrationStatus < 0) {
            yield put(actions.getIntegrationsTableurByGlnDeclarant(action.glnDeclarant));
        }
        yield put(actions.displayErrorMessage(error.response, ERREUR_INTEGRATION_TABLEUR));
    }
    yield put(actions.setIntegrationsTableurRecapitulatifLoading(false));
}

export function *getIntegrationsByGlnDeclarantSaga (action) {
    try {
        const response = yield getIntegrationsByGlnDeclarant(action.glnDeclarant);
        yield put(actions.getIntegrationsTableurByGlnDeclarantResults(response.data));
    } catch (error) {
        yield put(actions.displayErrorMessage(error.response, "integrationTableurGetError"));
    }
}

export function *getIntegrationsTableurByGlnDeclarantSaga (glnDeclarant, pathname, reloadIntegrationsList) {
    yield put(actions.setIntegrationsTableurRecapitulatifLoading(true));
    try {
        if (reloadIntegrationsList) {
            yield put(actions.getIntegrationsTableurByGlnDeclarant(glnDeclarant));
        } else if (pathname) {
            yield put(push(pathname));
        }
    } catch (error) {
        yield put(actions.displayErrorMessage(error.response, "integrationTableurGetError"));
    }
    yield put(actions.setIntegrationsTableurRecapitulatifLoading(false));
}

export function *deleteIntegrationsSaga (action) {
    yield put(actions.setDeleteIntegrationsLoading(true));
    try {
        yield deleteIntegration(action.idIntegration);
        yield put(actions.setDeleteIntegrationsLoading(false));
        yield call(getIntegrationsTableurByGlnDeclarantSaga, action.glnDeclarant, action.pathname, action.reloadIntegrationsList);
    } catch (error) {
        yield put(actions.displayErrorMessage(error.response, "integrationTableurDeleteError"));
        yield put(actions.setDeleteIntegrationsLoading(false));
    }
}


