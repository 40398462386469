import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import { AutoCompleteWithAllSelect } from "../../../commonComponents/autocomplete/Autocomplete";
import * as actions from "../../../store/actions/index.action";
import { isEmptyArray } from "../../../utils/utility";


const useStyle = makeStyles({
    autoComplete: {
        width: "60%",
        paddingLeft: "20px",
        paddingBottom: "20px"
    }
});

function FactureStatistiqueFiltre({
    allFactures,
    loadingFactures,
    loadingPartenairesFactures,
    displayHelperText,
    selectedPartenaires,
    disableFiltreFacture,
    selectedFactures,
    updateSelectedFactures,
    updateDisableFiltreFacture
}) {
    const classes = useStyle();
    const allObject = { title: "mesStatistiquesAllFactures", value: "selectAll" };
    const getLabel = option => `${option.numeroFacture} | ${option.montantTTC} | ${option.dateFacture}`;

    useEffect(() => {
        const disabled = isEmptyArray(selectedFactures) && selectedPartenaires.length > 0;
        updateDisableFiltreFacture(disabled);
    }, [selectedFactures, selectedPartenaires, updateDisableFiltreFacture]);


    const updateFactures = options => updateSelectedFactures(options);

    const getHelperText = () => displayHelperText && <span style={{ color: "#4682B4" }}><Trans id="helperFacture"/></span>;

    return <AutoCompleteWithAllSelect allObject={allObject} all={allFactures} update={updateFactures} selected={selectedFactures} getLabel={getLabel}
        label={"Factures"} disabled={isEmptyArray(allFactures) || disableFiltreFacture}
        loading={loadingFactures || loadingPartenairesFactures} className={classes.autoComplete} helperText={getHelperText()}
        limit/>;
}

const mapStateToProps = state => ({
    allFactures: state.statistiquesDeclarations.filtreFactures,
    selectedFactures: state.statistiquesDeclarations.selectedFactures,
    selectedPartenaires: state.statistiquesDeclarations.selectedPartenaires,
    loadingFactures: state.statistiquesDeclarations.loadingFactures,
    loadingPartenairesFactures: state.statistiquesDeclarations.loadingPartenairesFactures,
    displayHelperText: state.statistiquesDeclarations.displayFactureHelperText,
    disableFiltreFacture: state.statistiquesDeclarations.disableFiltreFacture
});

const mapDispatchToProps = dispatch => ({
    updateSelectedFactures: options => dispatch(actions.updateSelectedFactures(options)),
    updateDisableFiltreFacture: flag => dispatch(actions.updateDisableFiltreFacture(flag))
});

export default connect(mapStateToProps, mapDispatchToProps)(FactureStatistiqueFiltre);
