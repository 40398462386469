import { push } from "connected-react-router";
import { put } from "@redux-saga/core/effects";
import * as actions from "../../actions/index.action";
import { PATH_LOGIN } from "../../../routes/paths/paths.util";

export function *closeDialog({ redirect }) {
    yield put(actions.closeDialogSuccess());
    if (redirect) {
        yield put(push(PATH_LOGIN));
    }
}

