import * as actionTypes from "../../actionTypes/actionTypes";
import { updateObject } from "../../../utils/utility";

const initialState = {
    usurperRedirectPath: "/",
    error: null,
    loading: false,
    disableButton: true,
    usurperUnauthorized: false
};

const usurper = state => updateObject(state, { error: null });

const usurperStart = state => updateObject(state, { loading: true });

const usurperSuccess = (state, action) => updateObject(state, {
    error: null,
    usurperRedirectPath: action.usurperRedirectPath,
    loading: false,
    usurperUnauthorized: false
});

const usuperFail = (state, action) => updateObject(state, {
    error: action.error,
    loading: false,
    usurperUnauthorized: false
});

const updateUsurperUnauthorized = (state, action) => updateObject(state, {
    usurperUnauthorized: action.isUserUnauthorized,
    loading: false
});

const updateDisableButton = (state, action) => updateObject(state, {
    disableButton: action.disableButton
});

export const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.ADMIN_USURPER:
        return usurper(state);
    case actionTypes.ADMIN_USURPER_START:
        return usurperStart(state);
    case actionTypes.ADMIN_USURPER_SUCCESS:
        return usurperSuccess(state, action);
    case actionTypes.ADMIN_USURPER_FAIL:
        return usuperFail(state, action);
    case actionTypes.UPDATE_DISABLE_BUTTON:
        return updateDisableButton(state, action);
    case actionTypes.ADMIN_USURPER_UNAUTHORIZED:
        return updateUsurperUnauthorized(state, action);
    default:
        return state;
    }
};

export default reducer;
