import axios from "axios";
import moment from "moment";
import { downloadFileFromResponse } from "../utils/downloadUtil";

const config = { responseType: "blob" };

export const exportMensualisationFournisseur = data => axios.post(
    "/statistiques/exportMensualisationFournisseur",
    data, config
).then(downloadFileFromResponse(`FichierMensualisation_${data.anneeDebut}_${data.anneeFin}_du_${moment().format("DD-MM-YYYY_HH-ss")}.xls`));

export const exportMensualisationBibliotheque = data => axios.post(
    "/statistiques/exportMensualisationBibliotheque",
    data, config
).then(downloadFileFromResponse(`FichierMensualisation_${data.anneeDebut}_${data.anneeFin}_du_${moment().format("DD-MM-YYYY_HH-ss")}.xls`));

export const exportExcelLignesEANService = data => axios.post(
    "/statistiques/exportExcelLignesEAN",
    data, config
).then(downloadFileFromResponse(`ExportEans_de_${data.annee}_du_${moment().format("DD-MM-YYYY_HH-ss")}.xlsx`));

export const getCountExportEanLignes = data => axios.post("/statistiques/getCountExportEanLignes", data);
