import React from "react";
import { makeStyles, Menu, MenuItem, Paper } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import { Trans } from "@lingui/macro";
import { useSelector } from "react-redux";
import classes from "../menu.module.css";
import { selectRootPath } from "../../../../utils/selectors";
import { LIBELLE_NOUVELLE_DECLARATION } from "../../../../utils/libelleConstantes";


const useStyles = makeStyles(() => ({
    menuSelected: {
        color: "#c90039"
    }
}));

export const MenuItemWithSubMenu = ({ item }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isMenuSelected = useLocation().pathname.includes(item.name);
    const rootPath = useSelector(selectRootPath);

    const classeMenuSelected = useStyles();

    let classNames = "";
    if (isMenuSelected) {
        classNames = classeMenuSelected.menuSelected;
    }
    if (item.name === LIBELLE_NOUVELLE_DECLARATION) {
        classNames = classes.MenuDeclarer;
    }
    const open = Boolean(anchorEl);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onClickSub = () => {
        setAnchorEl(null);
    };

    // Lien vers une page
    if (item.to) {
        return <MenuItem key={item.name} component={Link} to={`${rootPath}${item.to}`} className={classNames}>
            <Trans id={item.name} values={ item.params }/>
        </MenuItem>;
    }
    // Sinon ouverture d'un menu
    return (<>
            <MenuItem onClick={handleClick} className={classNames}>
                <div className={classNames}><Trans id={item.name} values={ item.params }/></div>
            </MenuItem>
            <Menu
                anchorEl={anchorEl}
                open={open}
                id="fade-menu"
                className={classes.SubMenu}
                keepMounted
                onClose={handleClose}>
                <Paper elevation={0} onMouseLeave={handleClose}>
                    {item.menu.map(subItem => (
                        <MenuItem key={subItem.name} component={Link} to={`${rootPath}${subItem.to}`} onClick={onClickSub}>
                            <Trans id={subItem.name} values={ item.params }/>
                        </MenuItem>
                    ))}
                </Paper>
            </Menu>
        </>
    );
};
