import React from "react";
import { ValidatorComponent } from "react-material-ui-form-validator";
import DatePicker from "../datePicker/DatePicker";
import { globalI18n } from "../../i18n.conf";

class DateValidator extends ValidatorComponent {
    render() {
        const {
            // eslint-disable-next-line no-unused-vars
            errorMessages,
            // eslint-disable-next-line no-unused-vars
            validatorListener,
            helperText,
            variant,
            ...rest
        } = this.props;
        const { isValid } = this.state;
        const placeHolder = globalI18n._("placeHolderDateDDMMYYYY");
        return (
            <DatePicker dateProps={{
                inputVariant: variant,
                error: !isValid,
                placeholder: placeHolder,
                helperText: (!isValid && this.getErrorMessage()) || helperText,
                ...rest
            }}/>
        );
    }
}

export default DateValidator;
