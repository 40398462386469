import { call, put, select } from "redux-saga/effects";
import {
    selectNotificationsMail,
    selectUtilisateur,
    selectUtilisateurGencod,
    selectUtilisateurInformations,
    selectUtilisateurPremiereConnexion
} from "../../../utils/selectors";
import * as actions from "../../actions/index.action";
import {
    checkMailToken,
    getInformationsMonProfil,
    getInformationsMonProfilAvecNotification,
    saveCompteUtilisateur,
    saveInfoGlobalContact,
    saveInfosContactAndDuplicateIt,
    updateAccesVueCroisement,
    updateLastStepPremiereConnexionFournisseur
} from "../../../services/monProfil.service";
import {
    ERREUR_MON_PROFIL_ENVOI_CROISEMENT,
    LIBELLE_MON_PROFIL_ERREUR_CHARGEMENT,
    LIBELLE_MON_PROFIL_ERREUR_CHECK_MAIL,
    LIBELLE_MON_PROFIL_ERREUR_MODIFICATION_MAIL,
    LIBELLE_MON_PROFIL_ERREUR_SAUVEGARDE_CONTACT,
    LIBELLE_MON_PROFIL_ERREUR_SAUVEGARDE_ENTITE,
    LIBELLE_MON_PROFIL_ERREUR_SAUVEGARDE_OPTIONS_FACTURATION,
    LIBELLE_MON_PROFIL_MODIFICATION_MAIL_SUCCESS,
    MODIFICATION_MAIL_FAILED,
    MODIFICATION_MAIL_SUCCESS
} from "../../../utils/libelleConstantes";
import { CONTACT_FONCTION, MON_PROFIL_SAUVEGARDE_CONTACT, ROLES } from "../../../utils/constantes";
import { checkIsFournisseurConnected, checkProfilConnected, isEnCoursPremiereCo } from "../../../utils/utility";
import {
    isPaysFrance,
    MON_PROFIL_STEP_DFA,
    MON_PROFIL_STEP_ENTITE,
    MON_PROFIL_STEP_FIN,
    MON_PROFIL_STEP_MDP,
    MON_PROFIL_STEP_RL
} from "../../../components/monProfil/monProfilUtil";

export function *getMonProfilSaga({ gencod }) {
    // Il faut utiliser le gencod de l’action et pas le store utilisateur, pour que l’usurpation par un admin fonctionne.
    try {
        if (gencod !== "") {
            yield put(actions.updateLoadingMonProfil(true));
            const response = yield call(getInformationsMonProfil, gencod);
            yield put(actions.getMonProfilSuccess(response.data));
        }
    } catch (error) {
        yield put(actions.openDialogNoRedirect(LIBELLE_MON_PROFIL_ERREUR_CHARGEMENT, error.message));
    }
    yield put(actions.updateLoadingMonProfil(false));
}

export function *getMonProfilAvecNotificationMailSaga({ gencod }) {
    // Il faut utiliser le gencod de l’action et pas le store utilisateur, pour que l’usurpation par un admin fonctionne.
    try {
        if (gencod !== "") {
            const oldNotificationsMail = yield select(selectNotificationsMail);
            yield put(actions.updateLoadingMonProfil(true));
            const response = yield call(getInformationsMonProfilAvecNotification, gencod);
            const { profilDto, notificationsMailContact } = response.data;
            yield put(actions.getMonProfilSuccess(profilDto));
            if (notificationsMailContact.length > 0) {
                yield put(actions.decrementNbNotifications(oldNotificationsMail.length));
                yield put(actions.incrementNbNotifications(notificationsMailContact.length));
                yield put(actions.updateNotificationsMail(notificationsMailContact));
            } else {
                yield put(actions.decrementNbNotifications(oldNotificationsMail.length));
                yield put(actions.updateNotificationsMail([]));
            }
        }
    } catch (error) {
        yield put(actions.openDialogNoRedirect(LIBELLE_MON_PROFIL_ERREUR_CHARGEMENT, error.message));
    }
    yield put(actions.updateLoadingMonProfil(false));
}

export function *saveCompteUtilisateurSaga({ compteUtilisateur }) {
    const { informations, roles } = yield select(selectUtilisateur);
    const enCoursPremiereCo = isEnCoursPremiereCo(informations.premiereConnexion);
    const profil = checkProfilConnected(roles);
    if (compteUtilisateur.dateCreation === "Invalid date") {
        compteUtilisateur.dateCreation = null;
    }
    try {
        yield put(actions.updateMonProfilDataNotSaved(false));
        yield put(actions.updateLoadingMonProfil(true));
        const response = yield call(saveCompteUtilisateur, compteUtilisateur);
        yield put(actions.saveMonProfilCompteUtilisateurSuccess(response.data));
        /*
         * Dans le cas où c'est une bibliothèque qui est connectée,
         * Et selon le pays selectionné on mets à jour le flag biblithequeEtrangere pour restreindre ou pas l'accès au site à cette bibliothèque
         */
        if (response.data && profil === ROLES.BIBLIOTHEQUE) {
            const isPaysEtranger = !isPaysFrance(compteUtilisateur);
            yield put(actions.updateFlagBibliothequeEtrangere(isPaysEtranger));
            if (isPaysEtranger && informations.premiereConnexion.stepPremiereConnexion === MON_PROFIL_STEP_FIN) {
                const { stepPremiereConnexion, rdpDupliqueAsRf } = informations.premiereConnexion;
                yield put(actions.updateMonProfilPremiereConnexionInfos(stepPremiereConnexion, [MON_PROFIL_STEP_ENTITE], rdpDupliqueAsRf, false));
            }
        }
        // Met à jour les infos de première connexion
        yield put(actions.goToNextStepPremiereConnexion());
        yield put(actions.updateInfoPremiereconnexionSuccess({ entiteValid: true, npai: false }));
        if (!enCoursPremiereCo) {
            yield put(actions.updateFlagShowAndMessageSnackbar(true, "monProfilSauvegardeEntite"));
        }
    } catch (error) {
        yield put(actions.openDialogNoRedirect(LIBELLE_MON_PROFIL_ERREUR_SAUVEGARDE_ENTITE, error.message));
        yield put(actions.updateMonProfilDataNotSaved(true));
    }
    yield put(actions.updateLoadingMonProfil(false));
}

export function *updateContactInfosSaga({ contact }) {
    const { gencod, premiereConnexion } = yield select(selectUtilisateurInformations);
    const enCoursPremiereCo = isEnCoursPremiereCo(premiereConnexion);
    const data = {
        ...contact,
        gencod
    };
    try {
        yield put(actions.updateMonProfilDataNotSaved(false));
        yield put(actions.updateLoadingMonProfil(true));

        const response = yield call(saveInfoGlobalContact, data);
        yield put(actions.saveMonProfilContactSuccess(response.data.contactDto));
        // Met à jour les infos de premières connexion du contact
        const contactValid = {};
        if (contact.fonction === CONTACT_FONCTION.RESPONSABLE_DROIT_DE_PRET) {
            contactValid.rdpValid = true;
        } else if (contact.fonction === CONTACT_FONCTION.RESPONSABLE_SUIVI_FACTURES) {
            contactValid.dfaValid = true;
        } else if (contact.fonction === CONTACT_FONCTION.REPRESENTANT_LEGAL) {
            contactValid.rlValid = true;
        }
        yield put(actions.updateInfoPremiereconnexionSuccess(contactValid));
        yield put(actions.goToNextStepPremiereConnexion());
        if (!enCoursPremiereCo) {
            yield put(actions.updateFlagShowAndMessageSnackbar(true, MON_PROFIL_SAUVEGARDE_CONTACT));
            yield put(actions.updateMonProfilContactMailSucess(contact.email, contact.fonction, false));
            if (response.data.modificationMailEtat === MODIFICATION_MAIL_SUCCESS) {
                yield put(actions.openDialogNoRedirect(LIBELLE_MON_PROFIL_MODIFICATION_MAIL_SUCCESS));
            }
        } else if (response.data.modificationMailEtat === MODIFICATION_MAIL_FAILED) {
            // En cas d'erreur sur la modification du mail
            yield put(actions.openDialogNoRedirect(LIBELLE_MON_PROFIL_ERREUR_MODIFICATION_MAIL));
            yield put(actions.updateMonProfilDataNotSaved(true));
        }
    } catch (error) {
        yield put(actions.openDialogNoRedirect(LIBELLE_MON_PROFIL_ERREUR_SAUVEGARDE_CONTACT, error.message));
        yield put(actions.updateMonProfilDataNotSaved(true));
    }
    yield put(actions.updateLoadingMonProfil(false));
}

export function *updateContactInfosAndDuplicateItSaga({ contact }) {
    const { informations: { gencod }, roles } = yield select(selectUtilisateur);
    const isFournisseur = checkIsFournisseurConnected(roles);
    const data = {
        ...contact,
        gencod
    };
    try {
        yield put(actions.updateMonProfilDataNotSaved(false));
        yield put(actions.updateLoadingMonProfil(true));
        const response = yield call(saveInfosContactAndDuplicateIt, data);
        yield put(actions.saveMonProfilContactsSuccess(response.data));
        // Met à jour les infos de premières connexions pour les deux contacts
        const newPremiereCoInfos = { rdpValid: true };
        if (isFournisseur) {
            newPremiereCoInfos.dfaValid = true;
        } else {
            newPremiereCoInfos.rlValid = true;
        }
        yield put(actions.updateInfoPremiereconnexionSuccess(newPremiereCoInfos));
        yield put(actions.goToNextStepPremiereConnexion());
    } catch (error) {
        yield put(actions.openDialogNoRedirect(LIBELLE_MON_PROFIL_ERREUR_SAUVEGARDE_CONTACT, error.message));
        yield put(actions.updateMonProfilDataNotSaved(true));
    }
    yield put(actions.updateLoadingMonProfil(false));
}

export function *saveContactGlobalSaga({ contact, skipStep }) {
    const premiereConnexion = yield select(selectUtilisateurPremiereConnexion);
    const premiereConnexionEnCours = isEnCoursPremiereCo(premiereConnexion);
    const sagaFunction = (premiereConnexionEnCours && skipStep) ? updateContactInfosAndDuplicateItSaga : updateContactInfosSaga;
    yield call(sagaFunction, { contact });
}


export function *checkMailTokenSaga({ gencod, fonction, token, forcage }) {
    yield put(actions.updateLoadingMonProfil(true));
    try {
        const response = yield call(checkMailToken, gencod, fonction, token, forcage);
        yield put(actions.checkMailTokenSuccess(response.data));
    } catch (error) {
        yield put(actions.openDialogNoRedirect(LIBELLE_MON_PROFIL_ERREUR_CHECK_MAIL, error.message));
    }
    yield put(actions.updateLoadingMonProfil(false));
}

export function *updateVueCroisementSaga({ accesVueCroisement }) {
    const glnUsurpe = yield select(selectUtilisateurGencod);
    try {
        yield call(updateAccesVueCroisement, { glnUsurpe, accesVueCroisement });
        yield put(actions.updateVueCroisementSuccess(accesVueCroisement));
        yield put(actions.updateFlagShowAndMessageSnackbar(true, "monProfilEnvoiCroisementSucces", null, 5000));
    } catch (error) {
        yield put(actions.openDialogNoRedirect(ERREUR_MON_PROFIL_ENVOI_CROISEMENT, error.message));
    }
}


export function *updateMonProfilLastStepPremiereConnexionFournisseurSaga() {
    const { gencod } = yield select(selectUtilisateurInformations);
    yield put(actions.updateLoadingMonProfil(true));
    try {
        yield call(updateLastStepPremiereConnexionFournisseur, gencod);
        yield put(actions.goToNextStepPremiereConnexion());
    } catch (error) {
        yield put(actions.updateMonProfilDataNotSaved(true));
        yield put(actions.openDialogNoRedirect(LIBELLE_MON_PROFIL_ERREUR_SAUVEGARDE_OPTIONS_FACTURATION, error.message));
    }
    yield put(actions.updateLoadingMonProfil(false));
}

export function *updateMonProfilPremiereConnexionInfosSaga({ stepPremiereConnexion, steps, rdpDuplique, isFournisseur }) {
    // Si la première connexion est terminée et qu'un chemin d'étape est présent, alors on est dans un forçage et on affiche la première étape
    const activeStep = stepPremiereConnexion !== MON_PROFIL_STEP_FIN && steps ? stepPremiereConnexion : steps[0];
    // On récupère l'index du step non valide renvoyé par le back
    const indexCurrentNotValidStep = steps.indexOf(stepPremiereConnexion);
    /*
     * Le dernier step valide par l'utilisateur sera le step précédent du step courant renvoyé par le back
     * pour le cas où on est sur le step du MDP alors le dernier step validé est null
     */
    const lastValidatedStep = ![MON_PROFIL_STEP_MDP, MON_PROFIL_STEP_FIN].includes(stepPremiereConnexion) ? steps[indexCurrentNotValidStep - 1] : null;
    yield put(actions.setStepPremiereCo(activeStep));
    yield put(actions.setLastValidatedStepPremiereCo(lastValidatedStep));
    yield put(actions.setStepsPremiereCo(steps));
    // La duplication du RDP ne concerne que la première connexion
    if (stepPremiereConnexion !== MON_PROFIL_STEP_FIN) {
        yield put(actions.updateSkipStepPremiereConnexion(rdpDuplique, isFournisseur ? MON_PROFIL_STEP_DFA : MON_PROFIL_STEP_RL));
    }
}
