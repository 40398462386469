import * as actionTypes from "../../actionTypes/actionTypes";
import { getComparator, stableSort, toNumber, updateObject } from "../../../utils/utility";

const initialState = {
    typeDeclaration: "",
    skipSearchInfoPartenaire: false,
    partenaire: {
        gencod: "",
        raisonSociale: "",
        ville: "",
        codePostal: ""
    },
    declarant: {
        gencod: "",
        raisonSociale: "",
        ville: "",
        codePostal: ""
    },
    declaration: {
        validEntetes: [],
        validLignes: [],
        loading: false,
        disableSendButton: false,
        disableSendButtonLignes: false,
        // Gère le forcage du mode lecture pour une déclaration invalide ou facturée dans la popup de MD
        readOnly: false,
        globale: false
    },
    loading: false,
    loadingSendDeclaration: false,
    enableToUpdateStore: true,
    reinitPartenaire: false,
    order: "desc",
    codePostalFournisseur: null
};

const updateDeclarationState = (state, action) => {
    // Si le store déclaration est vide il n'y a rien à mettre à jour
    if (!state.declaration.validEntetes || state.declaration.validEntetes.length === 0) {
        return state;
    }

    const validEntetes = action.wrapper.entetes.map((entete, index) => {
        const { tva, ddp } = state.declaration.validEntetes[index];
        return {
            ...entete.entete,
            ddp: {
                ...ddp,
                cellValue: entete.entete.ddp.cellValue
            },
            tva
        };
    });

    // L’objet ligne est réécrit à partir de cette méthode
    function transformationLigneDuBack(ligne) {
        return {
            ...ligne.ligne,
            canBeMergedByEan: true,
            canBeMergedByTitleAndEditor: true,
            numeroLigne: ligne.numeroLigne,
            valide: ligne.valide,
            validable: ligne.validable
        };
    }

    // On met à jour à chaque fois le store avec ce que nous renvoie le back, la principale raison étant de suivre la renumérotation des lignes
    let validLignes = [];
    if (action.wrapper.entetes && action.wrapper.entetes[0] && action.wrapper.entetes[0].lignes) {
        validLignes = action.wrapper.entetes[0].lignes.map(ligne => transformationLigneDuBack(ligne));
    }

    // Par défaut on trie sur les numéros de ligne
    validLignes = validLignes.sort((a, b) => a.numeroLigne - b.numeroLigne);

    return updateObject(state, {
        declaration: {
            ...state.declaration,
            time: new Date(),
            validEntetes,
            validLignes: action.applySortByDateModif ? stableSort(validLignes, getComparator(state.order, "dateModification")) : validLignes
        }
    });
};


const updateDeclarationsEntetes = (state, action) => {
    const { declaration: { validEntetes } } = state;
    const rowIndex = validEntetes.findIndex(e => e.numeroDeclaration.cellValue === action.numeroDeclaration);
    if (validEntetes[rowIndex]) {
        const newEntete = {
            ...validEntetes[rowIndex],
            valide: {
                ...validEntetes[rowIndex].valide,
                cellValue: action.toBeAbandon ? "false" : "true"
            }
        };
        const newValidEntetes = [...validEntetes.slice(0, rowIndex), newEntete, ...validEntetes.slice(rowIndex + 1)];
        return updateObject(state, {
            declaration: {
                ...state.declaration,
                validEntetes: newValidEntetes
            }
        });
    }
    return state;
};

const mergeLigneInState = (state, { index, indexDoublon, quantityToAdd }) => {
    const lignes = state.declaration.validLignes;

    const ligneExistanteAvecNouvelleQuantite = {
        ...lignes[index],
        quantite: {
            ...lignes[index].quantite,
            cellValue: (toNumber(lignes[index].quantite.cellValue) + toNumber(quantityToAdd)).toString()
        }
    };

    // Maj de la quantité de la ligne existante
    lignes.splice(index, 1, ligneExistanteAvecNouvelleQuantite);

    // Si la ligne en doublon est connue du back
    if (lignes[indexDoublon]) {
        // Alors on la supprime
        lignes.splice(indexDoublon, 1);
    }

    return updateObject(state, {
        declaration: {
            ...state.declaration,
            validLignes: [...lignes]
        }
    });
};

function setTvaForEnteteResult(state, action) {
    const entetes = state.declaration.validEntetes.slice();
    if (entetes.length > 0) {
        entetes[action.index].tva = action.tva;
    }
    return updateObject(state, {
        declaration: {
            ...state.declaration,
            validEntetes: entetes
        }
    });
}

function deleteLigne(state, action) {
    const lignes = state.declaration.validLignes;
    lignes.splice(action.index, 1);
    return updateObject(state, {
        declaration: {
            ...state.declaration,
            validLignes: [...lignes]
        }
    });
}

// eslint-disable-next-line complexity
const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.UPDATE_FLAG_SEARCH_INFO_PARTENAIRE:
        return updateObject(state, { skipSearchInfoPartenaire: action.flag });
    case actionTypes.UPDATE_INFO_PARTENAIRE:
        return updateObject(state, {
            partenaire: {
                gencod: action.gencodPartenaire,
                raisonSociale: action.raisonSocialePartenaire,
                ville: action.villePartenaire,
                codePostal: action.codePostalPartenaire
            }
        });

    case actionTypes.REINIT_INTEGRATION_RECAP_DECLARATION:
    case actionTypes.REINITIALIZE_DECLARATION_DATA:
        return updateObject(state, {
            declarant: action.reinitDeclarant
                ? {
                    gencod: "",
                    raisonSociale: "",
                    ville: "",
                    codePostal: ""
                } : { ...state.declarant },
            partenaire: {
                gencod: "",
                raisonSociale: "",
                ville: "",
                codePostal: ""
            },
            declaration: {
                validEntetes: [],
                validLignes: [],
                loading: false,
                loadingSendDeclaration: false,
                readOnly: state.declaration.readOnly
            }
        });
    case actionTypes.UPDATE_DECLARATION_ENTETE:
        return updateObject(state, {
            declaration: {
                ...state.declaration,
                validEntetes: [...state.declaration.validEntetes.slice(0, action.index), action.entete, ...state.declaration.validEntetes.slice(action.index + 1)]
            }
        });
    case actionTypes.SENT_DECLARATION:
        return updateObject(state, {
            declaration: {
                ...state.declaration,
                isSent: true
            }
        });
    case actionTypes.UPDATE_DECLARATION_LIGNE:
        state.declaration.validLignes.splice(action.index, 1, {
            ...action.ligne
        });
        return updateObject(state, {
            declaration: {
                ...state.declaration,
                validLignes: state.declaration.validLignes
            }
        });
    case actionTypes.UPDATE_DECLARATION_LIGNES:
        // Par défaut on trie sur les numéros de ligne
        return updateObject(state, {
            declaration: {
                ...state.declaration,
                validLignes: action.lignes.sort((a, b) => a.numeroLigne - b.numeroLigne)
            }
        });
    case actionTypes.UPDATE_DECLARATION_ALL_ENTETES:
        return updateObject(state, {
            declaration: {
                ...state.declaration,
                validEntetes: action.entetes
            }
        });
    case actionTypes.MERGE_LIGNE:
        return mergeLigneInState(state, action);
    case actionTypes.DELETE_DECLARATION_LIGNE:
        return deleteLigne(state, action);
    case actionTypes.CANCEL_DECLARATION_LIGNE:
        // On rend invalide la ligne
        state.declaration.validLignes.splice(action.index, 1, {
            ...action.ligne,
            valide: false
        });
        return updateObject(state, {
            declaration: {
                ...state.declaration,
                validLignes: [...state.declaration.validLignes]
            }
        });
    case actionTypes.REVALID_DECLARATION_LIGNE:
        // On rend valide la ligne
        state.declaration.validLignes.splice(action.index, 1, {
            ...action.ligne,
            valide: true
        });
        return updateObject(state, {
            declaration: {
                ...state.declaration,
                validLignes: [...state.declaration.validLignes]
            }
        });
    case actionTypes.UPDATE_DECLARATION_REQUEST_INDICATOR:
        return updateObject(state, {
            declaration: {
                ...state.declaration,
                loading: action.loading
            }
        });
    case actionTypes.UPDATE_DECLARANT_INFOS:
        return updateObject(state, {
            declarant: {
                gencod: action.gencodDeclarant,
                raisonSociale: action.raisonSocialeDeclarant,
                ville: action.villeDeclarant,
                codePostal: action.codePostalDeclarant
            }
        });
    case actionTypes.SAVE_DECLARATIONS_SUCCESS:
        return updateDeclarationState(state, action);
    case actionTypes.UPDATE_DECLARATION_ENTETE_LIST:
        return updateDeclarationsEntetes(state, action);
    case actionTypes.UPDATE_ENABLE_UPDATE_STORE:
        return updateObject(state, {
            enableToUpdateStore: action.flag
        });
    case actionTypes.REINIT_PARTENAIRE:
        return updateObject(state, {
            reinitPartenaire: action.flag
        });
    case actionTypes.SET_DECLARATION_LOADING:
        return updateObject(state, {
            loading: action.loading
        });
    case actionTypes.UPDATE_DECLARATION_CODE_POSTAL_FOURNISSEUR:
        return updateObject(state, {
            codePostalFournisseur: action.codePostal
        });
    case actionTypes.UPDATE_DECLARATION_ID_WRAPPER_INFO:
        return updateObject(state, {
            declaration: {
                ...state.declaration
            }
        });
    case actionTypes.UPDATE_DECLARATION_READ_ONLY:
        return updateObject(state, {
            declaration: {
                ...state.declaration,
                readOnly: action.readOnly
            }
        });
    case actionTypes.RELOAD_TYPE_DECLARATION:
        return updateObject(state, {
            typeDeclaration: action.typeDeclaration
        });
    case actionTypes.REINIT_DECLARATION_TIME:
        return updateObject(state, {
            declaration: {
                ...state.declaration,
                time: null
            }
        });
    case actionTypes.UPDATE_DECLARATION_LIGNES_AND_ORDER:
        return updateObject(state, {
            declaration: {
                ...state.declaration,
                validLignes: action.lignes
            },
            order: action.order
        });
    case actionTypes.UPDATE_DECLARATION_LIGNES_AND_ENTETES_AND_CODE_POSTAL_FOURNISSEUR:
        return updateObject(state, {
            declaration: {
                ...state.declaration,
                validLignes: action.lignes,
                validEntetes: action.entetes
            },
            codePostalFournisseur: action.codePostalFournisseur
        });
    case actionTypes.UPDATE_DECLARATION_GLOBALE:
        return updateObject(state, {
            declaration: {
                ...state.declaration,
                globale: action.globale
            }
        });
    case actionTypes.SET_TVA_FOR_ENTETE_RESULT:
        return setTvaForEnteteResult(state, action);
    case actionTypes.UPDATE_DISABLE_SEND_BUTTON:
        return updateObject(state, {
            declaration: {
                ...state.declaration,
                disableSendButton: action.flag
            }
        });
    case actionTypes.UPDATE_DISABLE_SEND_BUTTON_LIGNES:
        return updateObject(state, {
            declaration: {
                ...state.declaration,
                disableSendButtonLignes: action.flag
            }
        });
    default:
        return state;
    }
};

export default reducer;
