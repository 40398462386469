import { Trans } from "@lingui/macro";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

class ConfirmDialog extends React.Component {
    state = {
        open: this.props.open,
        closeDialogAlertClick: null,
        title: "",
        showAgreeButton: true
    };

    openDialog = () => {
        this.setState({
            open: true
        });
    };

    setOnAgreeClick = func => {
        this.setState({
            onAgreeClick: func
        });
    };

    setTitle = title => {
        this.setState({
            title
        });
    };

    setShowAgreeButton = flag => {
        this.setState({
            showAgreeButton: flag
        });
    }

    setCloseAlertDialogClick = func => {
        this.setState({
            closeDialogAlertClick: func
        });
    }

    agreeHandler = event => {
        if (this.state.onAgreeClick) { // TODO Refacto pour retirer setOnAgreeClick
            this.state.onAgreeClick(event);
        } else if (this.props.onAgreeClick) {
            this.props.onAgreeClick(event);
        }
        this.setState({ open: false });
    };

    disagreeHandler = event => {
        if (this.props.onDisagreeClick) {
            this.props.onDisagreeClick(event);
        }
        this.handleClose();
    };

    handleClose = () => {
        const { closeDialogAlertClick } = this.state;
        closeDialogAlertClick ? this.closeDialogAlertClick() : this.setState({ open: false });
    };

    closeDialogAlertClick = () => {
        this.state.closeDialogAlertClick();
        this.setState({ open: false });
    }

    getTitle = () => {
        const { title } = this.state;
        return title ? title : this.props.title;
    };

    getStyle = () => {
        let style = { marginTop: "10px", justifyContent: "flex-end" };
        const { showAgreeButton } = this.state;
        if (!showAgreeButton) {
            style = {
                ...style,
                justifyContent: "center"
            };
        }
        return style;
    }

    render() {
        const { agreeCode, disagreeCode, children, disabledAgreeButton } = this.props;
        const { open, showAgreeButton } = this.state;
        return (
            <Dialog
                open={this.props.open || open}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                PaperProps={{ style: { padding: "8px 24px" } }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    <p style={{ textAlign: "center" }}>{<Trans id={this.getTitle()}/>}</p>
                </DialogTitle>
                <DialogContent style={{ whiteSpace: "pre-line" }}>
                    {children}
                </DialogContent>
                <DialogActions style={this.getStyle()}>
                    {showAgreeButton &&
                        <>
                            <Button onClick={this.agreeHandler} color="primary" autoFocus data-testid="agreeButton" disabled={disabledAgreeButton}>
                                <Trans id={agreeCode}/>
                            </Button>
                        <Button onClick={this.disagreeHandler} color="primary">
                            <Trans id={disagreeCode}/>
                        </Button>
                        </>
                    }
                    {!showAgreeButton &&
                        <Button onClick={this.handleClose} color="primary">
                            <Trans id="ok"/>
                        </Button>}
                </DialogActions>
            </Dialog>
        );
    }
}

ConfirmDialog.propTypes = {
    onAgreeClick: PropTypes.func,
    onDisagreeClick: PropTypes.func,
    disagreeCode: PropTypes.string.isRequired,
    agreeCode: PropTypes.string.isRequired
};

ConfirmDialog.defaultProps = {
    open: false,
    title: "confirmTitre",
    disagreeCode: "confirmDialogDisagree",
    agreeCode: "confirmDialogAgree"
};

export default ConfirmDialog;
