import React from "react";
import classes from "./drawerToggle.module.css";

const DrawerToggle = props => (
    <div className={classes.DrawerToggle} onClick={props.onMenuClick}>
        <div></div>
        <div></div>
        <div></div>
    </div>
);

export default DrawerToggle;
