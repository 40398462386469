import { FormControlLabel } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import React from "react";
import { GreenCheckbox } from "../../../commonComponents/checkBox/GreenCheckBox";
import { LIBELLE_MON_PROFIL_DUPLIQUER_INFOS_ENTITE } from "../../../utils/libelleConstantes";

export default function CopyEntiteDetailsCheckbox({ formulaire, setFormulaire, entite, applyEntityDetails, setApplyEntityDetails }) {
    const copyEntityDetails = () => {
        // Si on coche la case, on copie les infos de l'entité
        const newApply = !applyEntityDetails;
        let newFormulaire;
        if (newApply) {
            newFormulaire = {
                ...formulaire,
                numeroVoie: entite.numeroVoie,
                adresse1: entite.adresse1,
                adresse2: entite.adresse2,
                ville: entite.ville,
                codePostal: entite.codePostal,
                codePays: entite.codePays
            };
        } else {
            newFormulaire = {
                ...formulaire,
                numeroVoie: "",
                adresse1: "",
                adresse2: "",
                ville: "",
                codePostal: "",
                codePays: ""
            };
        }
        setFormulaire(newFormulaire);
        setApplyEntityDetails(newApply);
    };

    return <FormControlLabel
        control={<GreenCheckbox id="applyEntity" checked={applyEntityDetails} color="primary" onChange={copyEntityDetails}/>}
        label={<Trans id={LIBELLE_MON_PROFIL_DUPLIQUER_INFOS_ENTITE}/>}
    />;
}
