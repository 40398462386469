import { isEmptyArray, updateObject } from "../../../utils/utility";
import * as actionsType from "../../actionTypes/actionTypes";

const initialState = {
    anneeDebut: 2013,
    anneeFin: new Date().getUTCFullYear(),
    anneeCivile: null,
    filtrePartenaires: [],
    selectedPartenaires: [],
    filtreFactures: [],
    selectedFactures: [],
    loadingPartenairesFactures: false,
    loadingFactures: false,
    displayPartenaireHelperText: false,
    displayFactureHelperText: false,
    disabledButton: true,
    disableFiltrePartenaire: false,
    disableFiltreFacture: false,
    countExportEanLigne: 0,
    loadingCountExportEanLigne: false

};

const updateFacutes = (state, action) => {
    const { factures } = action;
    const { filtreFactures, selectedFactures } = state;

    factures.forEach(newFacture => {
        if (!filtreFactures.some(facture => newFacture.numeroFacture === facture.numeroFacture)) {
            filtreFactures.push(newFacture);
        }
    });

    const newFactures = filtreFactures.filter(facture => factures.find(newFacture => facture.numeroFacture === newFacture.numeroFacture));

    const newSelectedFactures = selectedFactures.filter(facture => newFactures.find(newFacture => facture.numeroFacture === newFacture.numeroFacture));
    // Garder l'ordre de la liste des factures
    newFactures.sort((a, b) => {
        const dateA = new Date(a.dateFacture).getTime();
        const dateB = new Date(b.dateFacture).getTime();
        return dateA < dateB ? 1 : -1;
    });

    return updateObject(state, { filtreFactures: newFactures, selectedFactures: newSelectedFactures, displayFactureHelperText: isEmptyArray(newFactures) });
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionsType.UPDATE_STATISTIQUES_MENSUELLES_ANNEE_DEBUT:
        return updateObject(state, { anneeDebut: action.annee });
    case actionsType.UPDATE_STATISTIQUES_MENSUELLES_ANNEE_FIN:
        return updateObject(state, { anneeFin: action.annee });
    case actionsType.UPDATE_ANNEE_CIVILE:
        return updateObject(state, { anneeCivile: action.anneeCivile, filtresPartenaires: [] });
    case actionsType.UPDATE_FILTRES_PARTENAIRES_FACTURES:
        return updateObject(state, {
            filtrePartenaires: action.data.partenaires,
            displayPartenaireHelperText: isEmptyArray(action.data.partenaires),
            disabledButton: isEmptyArray(action.data.partenaires),
            filtreFactures: action.data.factures,
            displayFactureHelperText: isEmptyArray(action.data.factures),
            selectedPartenaires: [],
            selectedFactures: []
        });
    case actionsType.UPDATE_SELECTED_PARTENAIRES:
        return updateObject(state, { selectedPartenaires: action.selectedPartenaires });
    case actionsType.UPDATE_LOADING_STATITSTIQUES_PARTENAIRES_FACTURES:
        return updateObject(state, { loadingPartenairesFactures: action.loading });
    case actionsType.REINIT_STATISTIQUES_VIEW:
        return updateObject(state, initialState);
    case actionsType.UPDATE_FILTRES_FACTURES:
        return updateFacutes(state, action);
    case actionsType.UPDATE_SELECTED_FACTURES:
        return updateObject(state, { selectedFactures: action.selectedFactures });
    case actionsType.UPDATE_LOADING_STATITSTIQUES_FACTURES:
        return updateObject(state, { loadingFactures: action.loading });
    case actionsType.UPDATE_DISABLE_PARTENAIRE_FILTRE:
        return updateObject(state, { disableFiltrePartenaire: action.disabled });
    case actionsType.UPDATE_DISABLE_FACTURE_FILTRE:
        return updateObject(state, { disableFiltreFacture: action.disabled });
    case actionsType.EXPORT_EAN_UPDATE_COUNT_LIGNE:
        return updateObject(state, { countExportEanLigne: action.count });
    case actionsType.EXPORT_EAN_UPDATE_LOADING_COUNT_LIGNE:
        return updateObject(state, { loadingCountExportEanLigne: action.loading });
    case actionsType.REINIT_ALL_FILTRE:
        return updateObject(state, initialState);
    default:
        return state;
    }
};

export default reducer;
