import { call, put, select } from "redux-saga/effects";
import { saveInscription } from "../../../services/inscription.service";
import * as actions from "../../actions/index.action";
import { selectInscriptionState } from "../../../utils/selectors";
import { searchGlnInscriptionBibliotheque, searchGlnInscriptionFournisseur } from "../../../services/rechercheGLN.service";
import { PROFIL_FOURNISSEUR } from "../../../utils/constantes";
import { ERREUR_INSCRIPTION, INSCRIPTION_INVALIDE, INSCRIPTION_VALIDEE } from "../../../utils/libelleConstantes";

export function *saveInscriptionSaga() {
    yield put(actions.updateStatutSaveInscription(true));
    yield (put(actions.updateIsCalledFromInscription(true)));
    yield (put(actions.updateApplyErrorStyle(false)));
    yield put(actions.updateDisableValideButton(true));
    const { inscriptionEntite, inscriptionRL, inscriptionRdp, profilDialog } = yield select(selectInscriptionState);
    const body = {
        locale: sessionStorage.getItem("locale"),
        inscriptionDto: { profilChoisi: profilDialog, ...inscriptionEntite },
        contactRL: inscriptionRL,
        contactDdp: inscriptionRdp
    };
    yield put(actions.updateLoadingInscription(true));
    try {
        const response = yield call(saveInscription, body);
        if (response.data) {
            const numeroInscription = response.data;
            yield put(actions.updateDisableValideButton(false));
            yield put(actions.updateNumeroToMap(numeroInscription));
            yield put(actions.updateMessageConfirmation(INSCRIPTION_VALIDEE));
            yield put(actions.updateIsFormulaireStarted(false));
        }
    } catch (error) {
        yield put(actions.updateStatutSaveInscription(false));
        yield put(actions.updateDisableValideButton(false));
        yield (put(actions.updateApplyErrorStyle(true)));
        yield put(actions.updateMessageConfirmation(INSCRIPTION_INVALIDE));
    }
    yield put(actions.updateLoadingInscription(false));
}


export function *rechechereGlnBeforeSaveSaga({ profil }) {
    const { inscriptionEntite, inscriptionRL, inscriptionRdp } = yield select(selectInscriptionState);
    const body = {
        gencod: "",
        raisonSociale: `${inscriptionEntite.raisonSociale1} ${inscriptionEntite.raisonSociale2}`,
        adresse: `${inscriptionEntite.adresse1} ${inscriptionEntite.adresse2}`,
        codePostal: inscriptionEntite.codePostal,
        ville: inscriptionEntite.ville,
        telephone: inscriptionEntite.telephone,
        emailEntite: inscriptionEntite.courriel,
        emailRDP: inscriptionRdp.email,
        emailRL: inscriptionRL.email,
        siret: inscriptionEntite.siret
    };
    yield put(actions.updateLoadingInscription(true));
    try {
        const service = profil === PROFIL_FOURNISSEUR ? searchGlnInscriptionFournisseur : searchGlnInscriptionBibliotheque;
        const response = yield call(service, body);
        if (response.data.length > 0) {
            yield put(actions.updateResultsGLN(response.data));
            yield put(actions.handleRechercheGLN(response.data));
        } else {
            // Dans le cas où on ne trouve pas de résultat on sauvegarde l'inscription
            yield call(saveInscriptionSaga);
            yield put(actions.nextStep());
        }
    } catch (error) {
        yield put(actions.openDialogNoRedirect(ERREUR_INSCRIPTION, error.message));
    }
    yield put(actions.updateLoadingInscription(false));
}
