import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Trans } from "@lingui/macro";
import React, { useEffect, useRef, useState } from "react";
import { isValidSirenOrSiret } from "../../services/utilisateur.service";
import { usePrevious } from "../../utils/utility";
import { REQUIRED } from "../../utils/validatorUtil";
import {
    LIBELLE_CHAMP_OBLIGATOIRE,
    LIBELLE_INVALID_NUMERO_SIREN,
    LIBELLE_INVALID_NUMERO_SIRET,
    LIBELLE_MAX_MIN_NUMERO_SIREN_SIRET
} from "../../utils/libelleConstantes";
import { LIST_CODE_PAYS_FRANCE } from "../../utils/paysConstantes";

export function NumeroSirenOrSiret(props) {
    const { value, codePays, isRequired, ...restProps } = props;
    const [isErrorSirenOrSiret, setIsErrorSirenOrSiret] = useState(true);
    const [invalidNumeroKey, setInvalidNumeroKey] = useState("");


    function validationRuleSirenOrSiret() {
        return isErrorSirenOrSiret;
    }
    async function checkSirenOrSiret(v) {
        const isSiren = v.length === 9;
        await isValidSirenOrSiret(v).then(response => {
            setInvalidNumeroKey(isSiren ? LIBELLE_INVALID_NUMERO_SIREN : LIBELLE_INVALID_NUMERO_SIRET);
            setIsErrorSirenOrSiret(response.data);
        });
    }

    function validLengthOfSirenOrSiret(v) {
        const regexSiren = /^[\d]{9}$/;
        const regexSiret = /^[\d]{14}$/;
        return (v && (v.match(regexSiren) || v.match(regexSiret)));
    }

    function validationLengthSirenOrSiret(v) {
        return !(v && (v.length < 9 || (v.length > 9 && v.length < 14)));
    }

    useEffect(() => {
        // eslint-disable-next-line no-unused-vars
        if (ValidatorForm.hasValidationRule && ValidatorForm.hasValidationRule("isErrorSirenOrSiret")) {
            ValidatorForm.removeValidationRule("isErrorSirenOrSiret");
        }
        if (ValidatorForm.hasValidationRule && ValidatorForm.hasValidationRule("isErrorLength")) {
            ValidatorForm.removeValidationRule("isErrorLength");
        }
        ValidatorForm.addValidationRule("isErrorSirenOrSiret", validationRuleSirenOrSiret);
        ValidatorForm.addValidationRule("isErrorLength", v => validationLengthSirenOrSiret(v));
    });


    const ref = useRef();
    const prevProps = usePrevious(isErrorSirenOrSiret);
    useEffect(() => {
        if (prevProps !== undefined && prevProps !== isErrorSirenOrSiret) {
            ref.current.validate();
        }
    }, [isErrorSirenOrSiret]);

    function handleInput(event) {
        setIsErrorSirenOrSiret(true);
        const newValue = event.target.value.trim();
        event.target.value = newValue.replace(/[^0-9\\]+/g, "");
        if (validLengthOfSirenOrSiret(newValue) && LIST_CODE_PAYS_FRANCE.includes(codePays)) {
            checkSirenOrSiret(newValue);
        }
    }

    function getValidators() {
        const validators = ["isErrorSirenOrSiret", "isErrorLength"];
        if (isRequired) {
            validators.unshift(REQUIRED);
        }
        return validators;
    }
    function getErrorMessages() {
        const errorMessages = [<Trans id={invalidNumeroKey}/>, <Trans id={LIBELLE_MAX_MIN_NUMERO_SIREN_SIRET}/>];
        if (isRequired) {
            errorMessages.unshift(<Trans id={LIBELLE_CHAMP_OBLIGATOIRE}/>);
        }
        return errorMessages;
    }
    return (
        <TextValidator
            id="numeroSirenOrSiret"
            ref={ref}
            onInput={handleInput}
            validators={getValidators()}
            errorMessages={getErrorMessages()}
            value={value}
            {...restProps}
        />
    );
}
