import React from "react";
import { Button, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/index.action";
import { selectGlobalDialog } from "../../../utils/selectors";

export default function GlobalDialog({ fullScreen }) {
    const { openDialog, message, cause, message2, redirect } = useSelector(selectGlobalDialog);
    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(actions.closeDialog(redirect));
    };

    return (
        <Dialog
            fullScreen={fullScreen}
            open={openDialog}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent
                style={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: "20px", textAlign: "center", whiteSpace: "pre-wrap" }}>
                <div id="globalDialogFirstMessage">
                    <Trans id={message}/>
                </div>
                {
                    cause ? <div id="globalDialogCauseMessage">
                        {cause}
                    </div> : null
                }
                <div id="globalDialogSecondMessage">
                    {message2}
                </div>
            </DialogContent>
            <DialogActions>
                <Button align="center" onClick={onClose} color="secondary" autoFocus>
                    <Trans>close</Trans>
                </Button>
            </DialogActions>
        </Dialog>
    );
}
GlobalDialog.whyDidYouRender = true;
