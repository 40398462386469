import React from "react";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles(() => ({
    root: {
        display: "flex",
        alignItems: "center",
        whiteSpace: "pre-wrap",
        width: "100%"
    }
}));

export default function TextHeader({ severity = "info", children, ...props }) {
    const classes = useStyle();
    return (
        <Alert severity={severity} className={classes.root} {...props}>
            {children}
        </Alert>
    );
}
