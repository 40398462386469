import { call, put, select } from "redux-saga/effects";
import * as actions from "../../actions/index.action";
import {
    downloadModele,
    getFichierRecapitulatifByIdIntegration,
    getIntegration,
    getIntegrationIdFromNumeroDeclarationBibliotheque,
    getIntegrationIdFromNumeroDeclarationFournisseur
} from "../../../services/integrationTableur.service";
import { getIntegrationResultFailed, getIntegrationResultSuccess } from "../../actions/integrationTableur.action";
import { selectState } from "../../../utils/selectors";
import { checkProfilConnected } from "../../../utils/utility";
import { ROLES, TYPE_EXCEL } from "../../../utils/constantes";
import {
    ERREUR_ID_INTEGRATION_FROM_NUMERODECLARATION,
    LIBELLE_INTEGRATION_BLOC_FORMAT_ERREUR_DOWNLOAD_MODELE,
    LIBELLE_INTEGRATION_RECAPITULATIF_DOWNLOAD_ERROR
} from "../../../utils/libelleConstantes";

export function *getIntegrationSaga(action) {
    try {
        yield put(actions.setLoading(true));
        const response = yield call(getIntegration, action.idIntegration);
        yield put(getIntegrationResultSuccess(response.data));
    } catch (error) {
        yield put(getIntegrationResultFailed());
    }
    yield put(actions.setLoading(false));
}

export function *redirectToIntegrationFromNumeroDeclarationSaga({ numeroDeclaration }) {
    const state = yield select(selectState);
    const profil = checkProfilConnected(state.utilisateur.roles);
    try {
        const service = (profil === ROLES.BIBLIOTHEQUE)
            ? getIntegrationIdFromNumeroDeclarationBibliotheque
            : getIntegrationIdFromNumeroDeclarationFournisseur;
        const response = yield call(
            service,
            numeroDeclaration
        );
        yield put(actions.redirectToDeclaration(TYPE_EXCEL, response.data.idIntegration));
    } catch (error) {
        yield put(actions.openDialogNoRedirect(ERREUR_ID_INTEGRATION_FROM_NUMERODECLARATION, error.message));
    }
}

export function *downloadIntegrationRecapitulatifSaga({ idIntegration }) {
    yield put(actions.setLoading(true));
    try {
        yield call(getFichierRecapitulatifByIdIntegration, idIntegration);
    } catch (error) {
        yield put(actions.openDialogNoRedirect(LIBELLE_INTEGRATION_RECAPITULATIF_DOWNLOAD_ERROR, error.message));
    }
    yield put(actions.setLoading(false));
}

export function *downloadModeleExcelSaga({ gencodDeclarant }) {
    yield put(actions.setLoading(true));
    try {
        yield call(downloadModele, gencodDeclarant);
    } catch (error) {
        yield put(actions.openDialogNoRedirect(LIBELLE_INTEGRATION_BLOC_FORMAT_ERREUR_DOWNLOAD_MODELE, error.message));
    }
    yield put(actions.setLoading(false));
}
