import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, withStyles } from "@material-ui/core";
import ZoomIn from "@material-ui/icons/ZoomIn";
import { Trans } from "@lingui/macro";
import { useSelector } from "react-redux";
import { TextFieldDisabled } from "../../input/fieldInput";
import { LIBELLE_TYPE, TYPE_BIBLIOTHEQUE, TYPE_FOURNISSEUR } from "../../../utils/libelleConstantes";
import { selectLibelle } from "../../../utils/selectors";
import { getLibelle } from "../../../utils/libelleUtil";

const styles = () => ({
    dialogAction: {
        width: "100%",
        display: "inherit",
        alignItems: "inherit",
        justifyContent: "center"
    }

});

function DetailPartenaire({ isFournisseur, classes, currentObject }) {
    const typeLibelle = isFournisseur ? TYPE_FOURNISSEUR : TYPE_BIBLIOTHEQUE;
    const { libelles } = useSelector(selectLibelle);
    const [open, setOpen] = useState(false);


    const typeDeclarant = libelles.find(l => l.type === typeLibelle && l.code === currentObject.type);

    function handleOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }
    return (
        <div>
            <Button id="open_dialog" variant="text" color="primary" onClick={handleOpen}>
                <ZoomIn/>
            </Button>
            <Dialog
                fullScreen={false}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogContent>
                    <TextFieldDisabled
                        labelKey={"courriel"}
                        value={currentObject.emailEntite}/>
                    <TextFieldDisabled
                        labelKey={"tel"}
                        value={currentObject.telephone}/>
                    {isFournisseur && <TextFieldDisabled
                        labelKey={"numSiret"}
                        value={currentObject.siret}/>}
                    <TextFieldDisabled
                        labelKey={LIBELLE_TYPE}
                        value={typeDeclarant ? getLibelle(typeDeclarant) : ""}/>
                </DialogContent>
                <DialogActions className={classes.dialogAction}>
                    <Button id="bouton_cancel" onClick={handleClose} color="primary" autoFocus>
                        <Trans>close</Trans>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default withStyles(styles)(DetailPartenaire);
