import { call, put } from "redux-saga/effects";
import * as actions from "../../actions/index.action";
import { LIBELLE_ERREUR_RECUPERATION } from "../../../utils/libelleConstantes";
import { getLibelles } from "../../../services/libelle.service";

export function *getLibellesSaga () {
    try {
        const response = yield call(getLibelles);
        yield put(actions.getLibellesSuccess(response.data));
    } catch (error) {
        yield put(actions.openDialogNoRedirect(LIBELLE_ERREUR_RECUPERATION, error.message));
    }
}
