import * as actionTypes from "../../actionTypes/actionTypes";
import { updateObject } from "../../../utils/utility";
import { SORT_DESC } from "../../../utils/constantes";

const initialState = {
    openDialog: false,
    annee: "",
    ecartsGlobal: [],
    ecartsDetaille: [],
    totalEcartsDetaille: 0,
    loading: false,
    sortColumn: {
        column: "id.annee",
        direction: SORT_DESC
    },
    page: 1
};

const updateCroisementsDetaille = (state, action) => updateObject(state, { ecartsDetaille: action.ecarts, loading: false });

const updateCroisementsGlobal = (state, action) => updateObject(state, { ecartsGlobal: action.ecarts, loading: false });

const updateTotalEcartsDetaille = (state, action) => updateObject(state, { totalEcartsDetaille: action.total });

const updatePage = (state, action) => updateObject(state, { page: action.page });

const updateSortColumn = (state, action) => updateObject(state, { sortColumn: action.sortColumn });

const openCroisementDialog = (state, action) => updateObject(state, { openDialog: true, annee: action.annee });

const updateLoading = (state, action) => updateObject(state, { loading: action.loading });

const closeCroisementDialog = state => updateObject(state, {
    openDialog: false,
    ecartsDetaille: [],
    annee: "",
    page: 1
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.OPEN_CROISEMENT_DIALOG:
        return openCroisementDialog(state, action);
    case actionTypes.UPDATE_LISTE_ECARTS_DETAILLE:
        return updateCroisementsDetaille(state, action);
    case actionTypes.UPDATE_LISTE_ECARTS_GLOBAL:
        return updateCroisementsGlobal(state, action);
    case actionTypes.UPDATE_LOADING:
        return updateLoading(state, action);
    case actionTypes.UPDATE_PAGE:
        return updatePage(state, action);
    case actionTypes.UPDATE_SORT_COLUMN:
        return updateSortColumn(state, action);
    case actionTypes.UPDATE_TOTAL_ECARTS_DETAILLE:
        return updateTotalEcartsDetaille(state, action);
    case actionTypes.CLOSE_CROISEMENT_DIALOG:
        return closeCroisementDialog(state);
    default:
        return state;
    }
};

export default reducer;
