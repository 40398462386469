import React from "react";
import { SelectValidator } from "react-material-ui-form-validator";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";

const formLabelsTheme = createMuiTheme({
    overrides: {
        MuiFormLabel: {
            asterisk: {
                color: "red"
            }
        }
    }
});

export const CustomSelectValidator = props => <MuiThemeProvider theme={formLabelsTheme}>
    <SelectValidator {...props}/>
</MuiThemeProvider>;
