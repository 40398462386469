import * as actionTypes from "../../actionTypes/actionTypes";
import { updateObject } from "../../../utils/utility";
import { DEFAULT_MESSAGE_CONFIRMATION_INSCRIPTION } from "../../../utils/constantes";

const initialState = {
    isLoading: false,
    disabledButton: false,
    filArianeKeyMessage: "",
    filArianeValue: "",
    showSnackbar: false,
    keyMessageSnackbar: "",
    paramMessageSnackbar: {},
    isErrorSiret: false,
    messageConfirmation: DEFAULT_MESSAGE_CONFIRMATION_INSCRIPTION,
    numeroToMap: "",
    applyErrorStyle: false,
    isCalledFromInscription: false,
    filepond: {
        openFileNameDialog: false,
        fileName: "",
        fileNameDialogErrorMessage: ""
    },
    aideUtilisateur: []
};


export const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.LOADING:
        return updateObject(
            state,
            { isLoading: action.isLoading }
        );
    case actionTypes.UPDATE_DISABLE_BUTTON_VALIDE:
        return updateObject(
            state,
            { disabledButton: action.disabled }
        );
    case actionTypes.SET_FIL_ARIANE_KEY_MESSAGE: {
        return updateObject(
            state,
            {
                filArianeKeyMessage: action.keyMessage,
                filArianeValue: action.value
            }
        );
    }
    case actionTypes.CHECK_SIRET_SUCCESS: {
        return updateObject(
            state,
            {
                isErrorSiret: !action.isValid
            }
        );
    }
    case actionTypes.UPDATE_FLAG_AND_MESSAGE_SNACK_BAR:
        return updateObject(
            state,
            {
                showSnackbar: action.showSnackbar,
                keyMessageSnackbar: action.keyMessageSnackbar,
                paramMessageSnackbar: action.paramMessageSnackbar,
                autoHideDuration: action.autoHideDuration
            }
        );
    case actionTypes.UPDATE_MESSAGE_CONFIRMATION: {
        return updateObject(
            state,
            {
                messageConfirmation: action.message
            }
        );
    }
    case actionTypes.UPDATE_NUMERO_TO_MAP: {
        return updateObject(
            state,
            {
                numeroToMap: action.numero
            }
        );
    }
    case actionTypes.UPDATE_ERROR_STYLE: {
        return updateObject(
            state,
            {
                applyErrorStyle: action.flag
            }
        );
    }
    case actionTypes.UPDATE_URL_AIDE_UTILISATEUR: {
        return updateObject(
            state,
            {
                aideUtilisateur: action.flag
            }
        );
    }
    case actionTypes.UPDATE_IS_CALLED_INSCRIPTION: {
        return updateObject(
            state,
            {
                isCalledFromInscription: action.flag
            }
        );
    }
    case actionTypes.UPDATE_FILEPOND: {
        return updateObject(
            state,
            {
                filepond: {
                    ...state.filepond,
                    ...action.filepond
                }
            }
        );
    }
    default:
        return state;
    }
};

export default reducer;
