import React from "react";

export default function Iframe(props) {
    return (
        <iframe
            width="100%"
            height="700"
            {...props}>
        </iframe>
    );
}
