import React from "react";
import { Trans } from "@lingui/macro";
import { InputAdornment } from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { TextValidator } from "react-material-ui-form-validator";
import { LIBELLE_ERREUR_GLN_LENGTH, LIBELLE_GLN_OBLIGATOIRE } from "../../../utils/libelleConstantes";
import { GLN_REGEX, REQUIRED } from "../../../utils/validatorUtil";
import { formatInputGencod } from "../../../utils/utility";


const GencodTextInput = props => (
    <TextValidator
        {...props}
        onInput={formatInputGencod}
        InputProps={{
            endAdornment:
                <InputAdornment position="end">
                    <AccountCircle style={{ marginRight: "12px" }}/>
                </InputAdornment>

        }}
        validators={[
            REQUIRED,
            GLN_REGEX
        ]}
        errorMessages={[
            <Trans id={LIBELLE_GLN_OBLIGATOIRE} />,
            <Trans id={LIBELLE_ERREUR_GLN_LENGTH} />
        ]}
        required
    />
);

export default GencodTextInput;
