import ThemeProvider from "@material-ui/styles/ThemeProvider";
import MaterialTable from "material-table";
import { Trans } from "@lingui/macro";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import _ from "lodash";
import DataTableCellTooltip from "../../commonComponents/tooltip/dataTableCellTooltip";
import { createDataTableTheme, tableIcons } from "../../utils/tableHelper";
import PaginationFooter from "../../commonComponents/pagination/pagination.component";
import { selectLibelle, selectRechercheGlnState, selectUtilisateurPreferenceSize } from "../../utils/selectors";
import {
    LIBELLE_ADRESSE_1,
    LIBELLE_CODE_POSTAL,
    LIBELLE_COMPTE_CLOS,
    LIBELLE_DETAILS,
    LIBELLE_GERE_PAR,
    LIBELLE_GLN,
    LIBELLE_NON_ASSUJETTI,
    LIBELLE_PAYS,
    LIBELLE_RAISON_SOCIALE,
    LIBELLE_REGROUPE,
    LIBELLE_REMPLACE,
    LIBELLE_TYPE_PAYS,
    LIBELLE_VILLE
} from "../../utils/libelleConstantes";
import DetailPartenaire from "../../commonComponents/dialog/detailsGlnDialog/detailsGlnDialog.component";
import { getLibelleFromCodeAndType } from "../../utils/libelleUtil";
import * as actionCreator from "../../store/actions/index.action";


function RechercheResultLibelle({ libelleKey, color, withCheckbox, checkboxIcon }) {
    return <div
        style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignContent: "space-around",
            alignItems: "center",
            width: "100px",
            textAlign: "center"
        }}>
        <span
            style={{
                fontSize: "12px",
                fontWeight: "bold",
                ...(color && { color })
            }}>
            {<Trans id={libelleKey}/>}
        </span>
        {withCheckbox && checkboxIcon}
    </div>;
}

export function RechercheGlnResult({
    onSelect,
    loading,
    emptyDataMessage,
    showForFournisseur,
    displayPagination = true,
    fromReferencement = false,
    disabledChoice = false
}) {
    const { results } = useSelector(selectRechercheGlnState);
    const { libelles } = useSelector(selectLibelle);
    const dispatch = useDispatch();

    const pageSizeUser = useSelector(selectUtilisateurPreferenceSize);
    const tableTheme = createDataTableTheme();
    const emptyDataSourceMessage = (
        <span style={{ fontWeight: "bold", fontSize: "medium" }}>
            <Trans id={emptyDataMessage}/>
        </span>
    );

    useEffect(() => () => {
        // On réinitialise les résultats lorsque le composant se démonte
        !fromReferencement && dispatch(actionCreator.initRechercheGLN());
    }, []);

    function onSelectItem(declarants, row) {
        /**
         * Corrige un soucis de React Table :
         * lors du clique sur une case, les autres lignes liées à cette case sont automatiquement cochées
         * on filtre donc ces lignes et on décoche manuellement en mettant à false la props checked
         */
        declarants.filter(m => m.gencod !== row.gencod).map(d => d.tableData.checked = false);
        onSelect([_.find(declarants, m => m.gencod === row.gencod)]);
    }

    const getIconForRow = (rowData, checked = false) => {
        const checkboxIcon = checked ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/>;
        if (rowData.enfant) {
            return <RechercheResultLibelle libelleKey={LIBELLE_GERE_PAR} color={"red"} withCheckbox={!disabledChoice} checkboxIcon={checkboxIcon}/>;
        } else if (rowData.remplace) {
            return <RechercheResultLibelle libelleKey={LIBELLE_REMPLACE} withCheckbox={!disabledChoice} checkboxIcon={checkboxIcon}/>;
        } else if (rowData.superDeclarantGerant) {
            return <RechercheResultLibelle libelleKey={LIBELLE_REGROUPE} withCheckbox={!disabledChoice} checkboxIcon={checkboxIcon}/>;
        } else if (rowData.compteClos) {
            return <RechercheResultLibelle libelleKey={LIBELLE_COMPTE_CLOS}/>;
        } else if (rowData.odpNonAssujettiDdp) {
            return <RechercheResultLibelle libelleKey={LIBELLE_NON_ASSUJETTI} color={"red"} withCheckbox={false}/>;
        }
        return !disabledChoice ? checkboxIcon : <></>;
    };

    return (
        <div style={{ maxWidth: "100%" }}>
            <ThemeProvider theme={tableTheme}>
                <MaterialTable
                    columns={[
                        {
                            title: <Trans id={LIBELLE_GLN}/>,
                            field: "gencod",
                            filtering: true,
                            sorting: true
                        },
                        {
                            title: <Trans id={LIBELLE_RAISON_SOCIALE}/>,
                            render: assujetti => (
                                <DataTableCellTooltip
                                    contentText={`${assujetti.raisonSociale1} ${assujetti.raisonSociale2}`}
                                />
                            ),
                            filtering: true,
                            sorting: true,
                            customSort: (a1, a2) => `${a1.raisonSociale1} ${a1.raisonSociale2}`.localeCompare(`${a2.raisonSociale1} ${a2.raisonSociale2}`),
                            customFilterAndSearch: (term, assujetti) => `${assujetti.raisonSociale1} ${assujetti.raisonSociale2}`.includes(term)
                        },
                        {
                            title: <Trans id={LIBELLE_ADRESSE_1}/>,
                            render: assujetti => (
                                <DataTableCellTooltip
                                    contentText={`${assujetti.adresse1} ${assujetti.adresse2}`}
                                />
                            ),
                            filtering: true,
                            sorting: true,
                            customSort: (a1, a2) => `${a1.adresse1} ${a1.adresse2}`.localeCompare(`${a2.adresse1} ${a2.adresse2}`),
                            customFilterAndSearch: (term, assujetti) => `${assujetti.adresse1} ${assujetti.adresse2}`.includes(term)
                        },
                        {
                            title: <Trans id={LIBELLE_CODE_POSTAL}/>,
                            field: "codePostal",
                            filtering: true,
                            cellStyle: { width: "30px" }
                        },
                        {
                            title: <Trans id={LIBELLE_VILLE}/>,
                            field: "ville",
                            filtering: true,
                            sorting: true
                        },
                        {
                            title: <Trans id={LIBELLE_PAYS}/>,
                            field: "codePays",
                            render: assujetti => (
                                <span>
                                    {getLibelleFromCodeAndType(
                                        assujetti.codePays,
                                        LIBELLE_TYPE_PAYS,
                                        libelles
                                    )}
                                </span>
                            ),
                            filtering: true,
                            sorting: true
                        },
                        {
                            title: <Trans id={LIBELLE_DETAILS}/>,
                            render: assujetti => (
                                <DetailPartenaire
                                    currentObject={assujetti}
                                    isFournisseur={showForFournisseur}
                                />
                            ),
                            filtering: false,
                            sorting: false,
                            cellStyle: { width: "70px" }
                        }
                    ]}
                    data={results}
                    isLoading={loading}
                    title=""
                    options={{
                        search: false,
                        filtering: false,
                        sorting: true,
                        selection: true,
                        defaultExpanded: true,
                        selectionProps: rowData => ({
                            disabled: rowData.compteClos || rowData.odpNonAssujettiDdp || (rowData.superDeclarantGerant && rowData.remplace && rowData.superDeclarantGerant.compteClos),
                            icon: getIconForRow(rowData, false),
                            checkedIcon: getIconForRow(rowData, true)
                        }),
                        pageSize: pageSizeUser,
                        showSelectAllCheckbox: false,
                        showTextRowsSelected: false,
                        toolbar: false,
                        loadingType: "overlay",
                        headerStyle: {
                            fontWeight: "bold",
                            fontSize: "16px",
                            textAlign: "center"
                        },
                        rowStyle: (rowData, index) => ({
                            backgroundColor: index % 2 ? "#f7f7f7" : "white"
                        }),
                        emptyRowsWhenPaging: false
                    }}
                    onSelectionChange={onSelectItem}
                    icons={tableIcons}
                    localization={{
                        pagination: {
                            firstAriaLabel: <Trans>tablePaginationFirst</Trans>,
                            firstTooltip: (
                                <Trans>tablePaginationFirstTooltip</Trans>
                            ),
                            lastTooltip: (
                                <Trans>tablePaginationLastTooltip</Trans>
                            ),
                            nextTooltip: (
                                <Trans>tablePaginationNextTooltip</Trans>
                            ),
                            previousTooltip: (
                                <Trans>tablePaginationPreviousTooltip</Trans>
                            ),
                            labelRowsSelect: "",
                            labelRowsPerPage: (
                                <Trans>tablePaginationLabelRowsSelect</Trans>
                            ),
                            labelDisplayedRows:
                                !results || !results.length
                                    ? ""
                                    : "{from}-{to} / {count}"
                        },
                        body: {
                            emptyDataSourceMessage,
                            filterRow: {
                                filterTooltip: <Trans>tableFilter</Trans>
                            }
                        }
                    }}
                    components={{
                        Pagination: props => displayPagination ? (
                            <PaginationFooter
                                currentPage={props.page + 1}
                                handleChangePage={(event, page) => props.onChangePage(event, page - 1)
                                }
                                preferenceSizePagination={props.rowsPerPage}
                                handleChangeSizePerPage={size => props.onChangeRowsPerPage(
                                    { target: { value: size } },
                                    size
                                )
                                }
                                totalSize={props.count}
                                table={true}
                                max50={true}
                            />
                        ) : (
                            <></>
                        )
                    }}
                    parentChildData={(row, rows) => rows.find(a => a.superDeclarantGerant &&
                        a.superDeclarantGerant.gencod === row.gencod &&
                        row.parent &&
                        row.enfant === a.gencod)
                    }
                />
            </ThemeProvider>
        </div>
    );
}
