function downloadFile(blob, filename, openOnly) {
    // IE
    if (typeof window.navigator.msSaveBlob !== "undefined") {
        window.navigator.msSaveBlob(blob, filename);
    } else {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;

        if (openOnly) {
            link.setAttribute("target", "_blank");
        } else {
            link.setAttribute("download", filename);
            // Si le browser ne gère pas l'attribut 'download'
            if (typeof link.download === "undefined") {
                link.setAttribute("target", "_blank");
            }
        }

        document.body.appendChild(link);
        link.click();

        setTimeout(() => {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }, 200);
    }
}

export const download = (blob, filename) => downloadFile(blob, filename, false);

export const downloadFileFromResponse = filename => response => {
    const defaultFilename = response.headers["content-disposition"] && response.headers["content-disposition"].split(/filename="?/i)[1].split("\";")[0];
    download(response.data, filename ? filename : defaultFilename);
};

export const open = (blob, filename) => downloadFile(blob, filename, true);

export const openFileFromResponse = filename => response => {
    open(response.data, filename);
};
