import { setupI18n } from "@lingui/react";
import frIcon from "./assets/icons/fr.svg";
import enIcon from "./assets/icons/en.svg";

export const locales = {
    en: {
        code: "en",
        image: enIcon,
        libelle: "English"
    },
    fr: {
        code: "fr",
        image: frIcon,
        libelle: "Français"
    }
};

export const defaultLocale = locales.fr.code;

function loadCatalog (locale) {
    // eslint-disable-next-line function-paren-newline
    return import(/* WebpackMode: "lazy", webpackChunkName: "i18n-[index]" */
        `./locales/${locale}/messages.js`);
}

export const globalI18n = setupI18n();
globalI18n.willActivate(loadCatalog);

if (!sessionStorage.getItem("locale")) {
    sessionStorage.setItem("locale", "fr");
}
globalI18n.activate(sessionStorage.getItem("locale"));
