import React from "react";
import { Button } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import { useHistory } from "react-router-dom";
import { LIBELLE_NOUS_CONTACTER } from "../../../utils/libelleConstantes";
import { PATH_NOUS_CONTACTER } from "../../../routes/paths/paths.util";
import classes from "../loginPanel.module.css";
import { IMG_CONTACTEZ_NOUS } from "../../../utils/imageConstantes";

export default function ContactUs() {
    const history = useHistory();

    const redirectNousContacter = () => history.push(PATH_NOUS_CONTACTER);

    return <div style={{ flex: "1" }}>
        <div>
            <img src={IMG_CONTACTEZ_NOUS} alt="" className={classes.LoginImage}/>
        </div>
        <div>
            <Button onClick={redirectNousContacter} color="primary" variant="contained">
                <Trans id={LIBELLE_NOUS_CONTACTER}/>
            </Button>
        </div>
    </div>;
}
