import { Save } from "@material-ui/icons";
import { Trans } from "@lingui/macro";
import React from "react";
import { useSelector } from "react-redux";
import { LIBELLE_ENVOYER, LIBELLE_VALIDER } from "../../../utils/libelleConstantes";
import { useStyles } from "../../monProfil/monProfilUtil";
import InteractiveConfirmButton
    from "../../declaration/tableur/correctionErreur/renderer/button/InteractiveConfirmButton";
import { selectInscriptionLoading } from "../../../utils/selectors";

const style = {};

export default function InscriptionSubmitButton({ id, onClick, lastStep }) {
    const loading = useSelector(selectInscriptionLoading);
    const classes = useStyles();
    return <div className={classes.sauvegarderButton}>
        <InteractiveConfirmButton
            id={id}
            loading={loading}
            type="submit"
            variant="contained"
            rootStyle={style}
            onClick={onClick}
            endIcon={<Save/>}>
            <Trans id={lastStep ? LIBELLE_ENVOYER : LIBELLE_VALIDER}/>
        </InteractiveConfirmButton>
    </div>;
}
