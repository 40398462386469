import React, { useState } from "react";
import moment from "moment";
import { Trans } from "@lingui/macro";
import { Breadcrumbs } from "@material-ui/core";
import {
    LIBELLE_FOOTER_CONDITIONS_UTILISATION,
    LIBELLE_FOOTER_COOKIE,
    LIBELLE_FOOTER_MENTIONS_LEGALES,
    LIBELLE_FOOTER_POLITIQUE_CONFIDENTIALITE,
    LIBELLE_FOOTER_RGPD
} from "../../../utils/libelleConstantes";
import LinkToLaSofia from "../../link/LinkToLaSofia";

function ToLaSofia({ to, libelle }) {
    const [isDialogOpen, setDialogOpen] = useState(false);

    return (<LinkToLaSofia to={to} isDialogOpen={isDialogOpen} setDialogOpen={setDialogOpen}>
        <span onClick={() => setDialogOpen(true)} style={{
            color: "rgb(77, 80, 83)",
            cursor: "pointer"
        }}>
            <Trans id={libelle}/>
        </span>
    </LinkToLaSofia>);
}

export default function Footer() {
    const anneeCourante = moment().year();

    return (
        <div style={{
            width: "inherit",
            padding: "10px 50px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#f0f0f2"
        }}>
            <Breadcrumbs separator="|" aria-label="footer-breadcrumb">
                <ToLaSofia to={"politique-de-confidentialite/"} libelle={LIBELLE_FOOTER_RGPD}/>
                <ToLaSofia to={"conditions-dutilisation/"} libelle={LIBELLE_FOOTER_CONDITIONS_UTILISATION}/>
                <ToLaSofia to={"mentions-legales/"} libelle={LIBELLE_FOOTER_MENTIONS_LEGALES}/>
                <ToLaSofia to={"charte-dutilisation-des-cookies-sur-notre-site/"} libelle={LIBELLE_FOOTER_COOKIE}/>
                <ToLaSofia to={"politique-de-confidentialite-2/"} libelle={LIBELLE_FOOTER_POLITIQUE_CONFIDENTIALITE}/>
            </Breadcrumbs>
            <div>© Sofia {anneeCourante}</div>
        </div>
    );
}
