import React, { useRef } from "react";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import HtmlTooltip from "./htmlTooltip";


const styles = {
    tooltipTitle: {
        overflowWrap: "normal",
        textAlign: "justify",
        textJustify: "inter-word"
    }
};


function getClassForSpan(validOpenedDeclaration) {
    let style;
    if (!validOpenedDeclaration) {
        style = { color: "#ababbd", fontWeight: "bold", fontStyle: "oblique" };
    }
    return style;
}

function DataTableCellTooltip(props) {
    const ref = useRef();
    const { classes, validOpenedDeclaration } = props;
    const strFinalToDisplay = props.contentText ? props.contentText : props.contentCell.cellValue;
    return <HtmlTooltip
        ref={ref}
        title={
            <div className={classes.tooltipTitle}>
                {strFinalToDisplay}
            </div>
        }
        placement="bottom"
        enterTouchDelay={700}>
        <span style={getClassForSpan(validOpenedDeclaration)}>{strFinalToDisplay}</span>
    </HtmlTooltip>;
}

export default withStyles(styles)(DataTableCellTooltip);

DataTableCellTooltip.propTypes = {
    contentText: PropTypes.string,
    valideRow: PropTypes.string,
    contentCell: PropTypes.object
};
