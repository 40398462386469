import { Trans } from "@lingui/macro";
import { Save } from "@material-ui/icons";
import React from "react";
import { useSelector } from "react-redux";
import { LIBELLE_NEXT, LIBELLE_SAUVEGARDER } from "../../utils/libelleConstantes";
import InteractiveConfirmButton from "../declaration/tableur/correctionErreur/renderer/button/InteractiveConfirmButton";
import { selectMonProfilLoading } from "../../utils/selectors";


/**
 * Gère l'affichage du bouton de sauvegarde des formulaires de mon profil
 * @param premiereCo
 * @returns {JSX.Element}
 * @constructor
 */
export function MonProfilLibelleInteractiveBoutonSauvegarde({ premiereCo, disabled, handleClick }) {
    const loading = useSelector(selectMonProfilLoading);
    const id = premiereCo ? "next" : LIBELLE_SAUVEGARDER;
    return <InteractiveConfirmButton
        type="submit" message={id}
        loading={loading}
        disabled={disabled}
        onClick={handleClick}
        rootStyle={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}
        endIcon={<Save/>}/>;
}

export function MonProfilLibelleBoutonNext() {
    return <span><Trans id={LIBELLE_NEXT}/><Save/></span>;
}
