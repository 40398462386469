import React from "react";
import { useDispatch, useSelector } from "react-redux";
import AdresseFieldGroupComponent from "../../../commonComponents/input/adresse/AdresseFieldGroup.component";
import ContactMailFormulaireComponent from "../../../commonComponents/formulaire/ContactMailFormulaire.component";
import { selectInscriptionState } from "../../../utils/selectors";
import { CONTACT_FONCTION, PROFIL_FOURNISSEUR } from "../../../utils/constantes";
import CommonContactFormulaire from "../../../commonComponents/formulaire/commonContactFormulaire.component";
import * as actions from "../../../store/actions/index.action";

export default function FormContactInscription({ formulaire, setFormulaire, showConfirmationInput, classes }) {
    const { profilDialog, inscriptionRL, inscriptionRdp } = useSelector(selectInscriptionState);
    const dispatch = useDispatch();
    const isFournisseur = profilDialog === PROFIL_FOURNISSEUR;

    // Met à jour le champ modifié dans notre objet de state formulaire
    function onChange(field, value) {
        setFormulaire({
            ...formulaire,
            [field]: value
        });
    }

    function onChangeMail(filed, value, mailChanged) {
        onChange(filed, value);
        dispatch(actions.updateFormulaireMailValide(formulaire.fonction, !mailChanged));
    }

    function getOldMailFormStore() {
        let oldMailFromStore;
        if (!formulaire) {
            return;
        }
        if (formulaire.fonction === CONTACT_FONCTION.REPRESENTANT_LEGAL) {
            oldMailFromStore = inscriptionRL.email;
        } else {
            oldMailFromStore = inscriptionRdp.email;
        }
        return oldMailFromStore;
    }

    return (
        <>
            <CommonContactFormulaire formulaire={formulaire} onChange={onChange} isFournisseur={isFournisseur} classes={classes}/>
            <ContactMailFormulaireComponent formulaire={formulaire} onChange={onChangeMail} showConfirmationInput={showConfirmationInput} mailFromStore={getOldMailFormStore()}/>
            <AdresseFieldGroupComponent formulaire={formulaire} setFormulaire={setFormulaire} classes={classes} showOnlyPaysFrance={!isFournisseur}/>
        </>
    );
}
