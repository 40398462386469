import React, { useEffect } from "react";
import { IconButton } from "@material-ui/core";
import { withI18n } from "@lingui/react";
import HomeIcon from "@material-ui/icons/Home";
import { useHistory } from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useSelector } from "react-redux";
import { locales } from "../../../i18n.conf";
import { SessionVariablesEnum } from "../../../utils/constantes";
import { getPathFromProfil, isForFirstConnectionOrForcage, usePrevious } from "../../../utils/utility";
import { selectMonProfilActiveStep, selectUtilisateurPremiereConnexion } from "../../../utils/selectors";
import NousContacterDialog from "../../dialog/nousContacterDialog";
import { LIBELLE_ACCUEIL, LIBELLE_SE_DECONNECTER } from "../../../utils/libelleConstantes";
import StyledTooltip from "../../tooltip/styledTooltip.component";
import RechercheAnnuaire from "./rechercheAnnuaire";
import classes from "./controls.module.css";


function Controles({ locale, i18n, updateLocale, layout, signOut, isLogged }) {
    const premiereConnexionInfos = useSelector(selectUtilisateurPremiereConnexion);
    const activeStep = useSelector(selectMonProfilActiveStep);
    const history = useHistory();

    const isFirstConnectionOrForcage = isForFirstConnectionOrForcage(premiereConnexionInfos, activeStep);

    useEffect(() => {
        if (locale) {
            sessionStorage.setItem("locale", locale);
            i18n.activate(locale);
        }
    }, [locale]);

    const prevProps = usePrevious(locale);
    useEffect(() => {
        if (locale && prevProps && prevProps.locale !== locale) {
            sessionStorage.setItem("locale", locale);
            i18n.activate(locale);
        }
    }, [locale, i18n]);

    function changeLocale(lang) {
        i18n.activate(lang);
        sessionStorage.setItem("locale", lang);
        updateLocale(lang);
    }

    let css = classes.FlagButton;
    let cssControls = classes.ControlsContainerDisplayNone;
    let signOutContainer = null;
    let rechercheAnnuaire = null;
    let nousContacter = null;

    if (isLogged) {
        css = layout === "desktop" ? classes.FlagButtonDesktop : classes.FlagButtonMobile;
        cssControls = classes.ControlsContainer;
        signOutContainer = <div className={css}>
            {!isFirstConnectionOrForcage && <StyledTooltip show={true} keyMessage={LIBELLE_ACCUEIL}><IconButton
                onClick={() => history.push(getPathFromProfil(JSON.parse(sessionStorage.getItem(SessionVariablesEnum.USER_INFOS))))}><HomeIcon/></IconButton></StyledTooltip>}
            <StyledTooltip show={true} keyMessage={LIBELLE_SE_DECONNECTER}>
                <IconButton onClick={signOut}>
                    <ExitToAppIcon/>
                </IconButton>
            </StyledTooltip>
        </div>;
        rechercheAnnuaire = <div className={css}>
            <RechercheAnnuaire/>
        </div>;
        nousContacter = <div className={css}>
            <NousContacterDialog/>
        </div>;
    }

    return (
        <div className={cssControls}>
            {/* On cache les boutons de modification de la langue qu'on doit reprendre dans la v2 */}
            <div className={css}>
                <IconButton hidden={true} id="changeLocaleFrButton" onClick={() => changeLocale(locales.fr.code)}>
                    <img src={locales.fr.image} width="30" height="50" alt={locales.fr.libelle}/>
                </IconButton>
            </div>
            <div className={css}>
                <IconButton hidden={true} id="changeLocaleEnButton" onClick={() => changeLocale(locales.en.code)}>
                    <img src={locales.en.image} width="30" height="50" alt={locales.en.libelle}/>
                </IconButton>
            </div>
            {rechercheAnnuaire}
            {nousContacter}
            {signOutContainer}
        </div>
    );
}

export default withI18n(Controles);
export { Controles };
