import axios from "axios";

export const checkEcartDeclarationService = gencodDeclarant => axios.get("/notifications/checkIfEcartIsPresent", { params: { gencodDeclarant } });

export const getAllNotificationsService = (gencodDeclarant, avecEcart) => axios.get("/notifications/getAllNotifications", {
    params: {
        gencodDeclarant,
        avecEcart
    }
});

export const updateVueService = gencodDeclarant => axios.post("/notifications/updateVue", gencodDeclarant, { headers: { "Content-Type": "text/plain" } });
