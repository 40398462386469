import React, { Component } from "react";
import { Trans } from "@lingui/macro";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { ValidatorForm } from "react-material-ui-form-validator";
import GencodTextInput from "../../../commonComponents/input/gencodTextInput/gencodTextInput";
import * as actions from "../../../store/actions/index.action";
import StyledTooltip from "../../../commonComponents/tooltip/styledTooltip.component";
import { SpinnerOverlay } from "../../../commonComponents/spinner/spinnerOverlay.component";
import { LIBELLE_GLN } from "../../../utils/libelleConstantes";
import classes from "./adminPanel.module.css";

class AdminPanel extends Component {
    state = {
        gencod: "",
        disableSubmitButton: true,
        latestPath: this.props.history.location
    };

    componentDidMount() {
        const utilisateurInformations = this.props.utilisateur.informations;
        if (utilisateurInformations.gencod !== "") {
            this.setState({
                gencod: utilisateurInformations.gencod,
                disableSubmitButton: true
            });
            this.props.usurperUnauthorized(false);
        }
    }

    componentDidUpdate(prevProps) {
        const { admin, utilisateur, history } = this.props;
        const utilisateurInformations = utilisateur.informations;
        if (history.location !== this.state.latestPath) {
            this.setState({
                latestPath: history.location
            });
        }
        if (prevProps.utilisateur.informations.gencod !== utilisateurInformations.gencod ||
            (admin.usurperUnauthorized && history.location !== this.state.latestPath)) {
            this.setState({
                gencod: utilisateurInformations.gencod,
                disableSubmitButton: admin.disableButton
            });
            this.props.usurperUnauthorized(false);
        }
    }

    onChangeInputField = event => {
        const value = event.target.value;
        this.setState({
            gencod: value,
            disableSubmitButton: !this.validateGencod(value)
        });
    };

    validateGencod = gencod => {
        const utilisateurInformations = this.props.utilisateur.informations;
        return gencod !== utilisateurInformations.gencod && gencod.length === 13;
    };

    onSubmit = event => {
        event.preventDefault();
        const {
            updateResultsSearchGln, updatePartenaireInfo,
            clearIntegrationRecapitualtif, onUsurperHandler, updateDeclarantInfo
        } = this.props;
        updateResultsSearchGln();
        updatePartenaireInfo();
        clearIntegrationRecapitualtif();
        onUsurperHandler(this.state.gencod);
        updateDeclarantInfo();
        this.setState({ disableSubmitButton: true });
    };

    render() {
        const { utilisateur, admin } = { ...this.props };
        const { gencod, disableSubmitButton } = this.state;

        const utilisateurInformations = utilisateur.informations;

        return <SpinnerOverlay loading={admin.loading}>
            <div className={classes.title}>
                <Trans>administration</Trans>
            </div>
            {/* eslint-disable-next-line react/no-string-refs */}
            <ValidatorForm ref="form" onSubmit={this.onSubmit}>
                <GencodTextInput
                    variant="standard"
                    label={<Trans id={LIBELLE_GLN}/>}
                    name="gencod"
                    value={this.state.gencod}
                    onChange={this.onChangeInputField}/>
                <StyledTooltip
                    keyMessage="adminUserUspDisabled"
                    show={utilisateurInformations.gencod === gencod}>
                    <div>
                        <Button
                            id="submitButton"
                            variant="contained"
                            className={classes.SubmitButton}
                            color="primary"
                            type="submit"
                            disabled={disableSubmitButton}>
                            <Trans>usurper</Trans>
                        </Button>
                    </div>
                </StyledTooltip>
            </ValidatorForm>
        </SpinnerOverlay>;
    }
}

const mapStateToProps = state => ({
    utilisateur: state.utilisateur,
    admin: state.admin
});

const mapDispatchToProps = dispatch => ({
    onUsurperHandler: gencod => {
        dispatch(actions.usurper(gencod));
    },
    updateResultsSearchGln: () => {
        dispatch(actions.updateResultsGLN([]));
    },
    updatePartenaireInfo: () => dispatch(actions.updatePartenaireInfo("", "", "", "")),
    updateDeclarantInfo: (gencod, ville, raisonSociale, codePostal) => dispatch(actions.updateDeclarantInfo(gencod, raisonSociale, ville, codePostal)),
    clearIntegrationRecapitualtif: () => dispatch(actions.getIntegrationsTableurByGlnDeclarantResults([])),
    usurperUnauthorized: isUserUnauthorized => dispatch(actions.usurperUnauthorized(isUserUnauthorized))

});

export default connect(mapStateToProps, mapDispatchToProps)(AdminPanel);
export { AdminPanel };
