import { call, cancel, fork, put, take, takeEvery } from "redux-saga/effects";
import { push } from "react-router-redux";
import * as actiontypes from "../actionTypes/actionTypes";
import { SessionVariablesEnum } from "../../utils/constantes";
import * as actions from "../actions/index.action";
import { usurperSaga } from "./admin/admin.saga";
import {
    cancelEntetesAndSendDeclaration,
    cancelEntetesWebAndSendDeclaration,
    cancelLigneAndSendDeclaration,
    deleteLigneAndSendDeclaration,
    mergeLigneAndSendDeclaration,
    modifierStatutDeclarationEnteteSaga,
    redirectDeclaration,
    revalidEntetesAndSendDeclaration,
    revalidLigneAndSendDeclaration,
    saveDeclarationSaga,
    sendDeclarationSaga,
    setTvaForEnteteSaga,
    updateAllEnteteAndSaveDeclarationSaga,
    updateDeclarantSaga,
    updateDeclarationSaga,
    updateEnteteAndSaveDeclarationSaga,
    updateLigneAndSaveDeclaration,
    updatePartenaireSaga,
    validateOneDeclarationEnEtatSagaIntegrationExcel,
    validateOneDeclarationEnEtatSagaMesDeclarations
} from "./declaration/declaration.saga";
import {
    addToFavorisAndRedirectToMesPartenairesSaga,
    addToFavorisAndRedirectToSaisieWebSaga,
    rechechereGlnBeforeSaveReferencementSaga,
    redirectDemandeReferencementSaga,
    saveDemandeReferencementSaga,
    searchByGlnSaga
} from "./demandeReferencement/demandeReferencement.saga";
import { rechechereGlnBeforeSaveSaga, saveInscriptionSaga } from "./inscriptionProfil/inscriptionProfil.saga";
import {
    createDeclarationsForIdIntegrationSaga,
    deleteAndGetStatisticsForIdIntegrationSaga,
    getCorrectionErreurEntetesChoisiesByIdIntegrationSaga,
    getCorrectionErreurLignesByIdIntegrationSaga,
    getPercentageSaga,
    saveAndGetLignesForIdIntegrationSaga
} from "./integrationTableur/correctionErreur/integrationTableurCorrectionErreur.saga";
import {
    closeDeclarationSaga,
    confirmEntetesChoisiesSaga,
    getEchantillonEtEntetesIdentifieesEtAttenduesSaga
} from "./integrationTableur/choixEntete/integrationTableurChoixEntete.saga";
import {
    deleteIntegrationsSaga,
    displayErrorMsgSaga,
    getIntegrationsByGlnDeclarantSaga,
    integrerTableurSaga
} from "./integrationTableur/recapitulatif/integrationTableurRecapitulatif.saga";
import { loginCheckStateSaga, loginSaga, logoutSaga } from "./login/login.saga";
import { mdpOublieSaga } from "./mdpOublie/mdpOublie.saga";
import { rechercheGLNSaga, rechercheInfoPartenaire } from "./rechercheGLN/rechercheGLN.saga";
import { changePwdConnectedSaga, changePwdUnconnectedSaga } from "./resetMdp/resetMdp.saga";
import { checkSiretSaga, getInformationsDeclarantSaga, updateLocaleSaga, updatePreferenceSizePaginationSaga } from "./utilisateur/utilisateur.saga";
import {
    getListDeclarationsEnteteByIdIntegrationSaga,
    getListDeclarationsLigneByNumeroDeclarationSaga,
    validateDeclarationsEnEtatSaga
} from "./integrationTableur/recapitulatifDeclarations/integrationTableurRecapitulatifDeclaration.saga";
import {
    downloadIntegrationRecapitulatifSaga,
    downloadModeleExcelSaga,
    getIntegrationSaga,
    redirectToIntegrationFromNumeroDeclarationSaga
} from "./integrationTableur/integrationTableur.saga";
import {
    checkStatutMDDeclarationSaga,
    closeDetailDialogMesDeclarationsSaga,
    closeMDDeclarationSaga,
    getInfosNecessaires,
    getInfosNecessairesAndPartenaireAndUpdateFiltre,
    getMDListDeclarationsLigneByNumeroDeclarationSaga,
    getPartenairesAndUpdateFiltreAndSelection,
    invalidateDeclarationByNumeroDeclarationSaga,
    modifyDeclarationFactureeSaga,
    onNextDeclarationFromMesDeclarationsSaga,
    onPreviousDeclarationFromMesDeclarationsSaga,
    reinitMdTypeDeclarationSaga,
    rollBackDeclarationModificationByNumeroDeclarationSaga,
    sendDeclarationByNumeroDeclarationSaga,
    sendMdAllDeclarationsFiltrees,
    updateEntetesAndRowIndexSaga,
    updateEntetesSaga,
    updateMdFiltreAnneeSaga,
    updateStatistiquesMontantsMesDeclarationsSaga
} from "./mesDeclarations/mesDeclarations.saga";
import {
    exportLignesEanSaga,
    exportMensualisationSaga,
    getCountExportEanLignesSaga,
    loadFacturesSaga,
    loadPartenairesAndFacturesSaga
} from "./statistiquesDeclarations/statistiquesDeclarations.saga";
import { exportRecapitulatifVenteSaga } from "./documentsDeclarations/documentDeclarations.saga";
import { downloadAttestationSaga, getAttestationsFinSaga, sendAttestationFinSaga } from "./attestationFinDeclarations/attestationFinDeclarations.saga";
import { getCountDeclarantListEcartFourBib, getListeEcartsFourBib, openCroisementDialogSaga } from "./croisementBibFour/croisementBibFour.saga";
import { getFactureDocumentFromDeclarationSaga, getFactureDocumentSaga, getMesFacturesSaga } from "./mesFactures/mesFactures.saga";
import { checkIfEcartIsDetectedSaga, getAllNotificationsSaga, sendMessageNousContacterSaga, updateVueSaga } from "./notifications/notifications.saga";
import {
    checkMailTokenSaga,
    getMonProfilAvecNotificationMailSaga,
    getMonProfilSaga,
    saveCompteUtilisateurSaga,
    saveContactGlobalSaga,
    updateContactInfosAndDuplicateItSaga,
    updateContactInfosSaga,
    updateMonProfilLastStepPremiereConnexionFournisseurSaga,
    updateMonProfilPremiereConnexionInfosSaga,
    updateVueCroisementSaga
} from "./monProfil/monProfil.saga";
import { getLibellesSaga } from "./libelle/libelle.saga";
import { closeDialog } from "./globalDialog/globalDialog.saga";
import {
    addPartenaireToFavorisSaga,
    checkIfPartenaireRecentSaga,
    exporterPartenairesSaga,
    getMesListesPartenairesSaga,
    getMesPartenairesFavorisSaga,
    getMesPartenairesRecentsSaga,
    removePartenaireFromFavorisSaga
} from "./mesPartenaires/mesPartenaires.saga";
import { integrerFichierExcelFromFilepondSaga } from "./filePond/filePond.saga";


function *authentificationCheckSaga(saga, payload) {
    const accessToken = sessionStorage.getItem(SessionVariablesEnum.TOKEN_NAME);
    const userInfos = JSON.parse(sessionStorage.getItem(SessionVariablesEnum.USER_INFOS));

    // eslint-disable-next-line no-eq-null,eqeqeq
    if (accessToken != null && userInfos.exp < new Date().getTime() / 1000) {
        // Vide le store et appelle la saga de deconnexion
        yield put(actions.logout());
        yield put(push("/login"));
        yield put(actions.updateFlagShowAndMessageSnackbar(true, "errorsTokenExpired", {}, 10000));
    } else {
        yield call(saga, payload);
    }
}

// eslint-disable-next-line func-names
const takeEveryAuthentificationCheck = (patternOrChannel, saga) => fork(function *() {
    while (true) {
        const action = yield take(patternOrChannel);
        yield fork(authentificationCheckSaga, saga, action);
    }
});

// eslint-disable-next-line func-names
const takeLatestAuthentificationCheck = (patternOrChannel, saga) => fork(function *() {
    let lastTask;
    while (true) {
        const action = yield take(patternOrChannel);
        if (lastTask) {
            yield cancel(lastTask);
        }
        lastTask = yield fork(authentificationCheckSaga, saga, action);
    }
});

export function *watchLogin() {
    yield takeEvery(actiontypes.LOGIN, loginSaga);
    yield takeEvery(actiontypes.LOGOUT, logoutSaga);
    yield takeEvery(actiontypes.LOGIN_CHECK_STATE, loginCheckStateSaga);
}

export function *watchRechercheGlN() {
    yield takeEveryAuthentificationCheck(actiontypes.SEARCH_GLN_ASSUJETTI, rechercheGLNSaga);
    yield takeEveryAuthentificationCheck(actiontypes.SEARCH_INFO_PARTENAIRE, rechercheInfoPartenaire);
}

export function *watchInscriptionProfil() {
    yield takeEveryAuthentificationCheck(actiontypes.SAVE_INSCRIPTION, saveInscriptionSaga);
    yield takeEveryAuthentificationCheck(actiontypes.RECHERCHE_GLN_INSCRIPTION, rechechereGlnBeforeSaveSaga);
}

export function *watchAdmin() {
    yield takeEveryAuthentificationCheck(actiontypes.ADMIN_USURPER, usurperSaga);
}

export function *watchAttestationFinDeclarations() {
    yield takeEveryAuthentificationCheck(actiontypes.ATTESTATION_FIN_DOWNLOAD_ATTESTATION, downloadAttestationSaga);
    yield takeEveryAuthentificationCheck(actiontypes.ATTESTATION_FIN_GET_ATTESTATIONS, getAttestationsFinSaga);
    yield takeEveryAuthentificationCheck(actiontypes.ATTESTATION_FIN_SEND_ATTESTATION, sendAttestationFinSaga);
}

export function *watchMdpOublie() {
    yield takeEveryAuthentificationCheck(actiontypes.SEND_MAIL_FOR_PWD_RESET, mdpOublieSaga);
}

export function *watchResetMdp() {
    yield takeEveryAuthentificationCheck(actiontypes.CHANGE_PWD_UNCONNECTED, changePwdUnconnectedSaga);
    yield takeEveryAuthentificationCheck(actiontypes.CHANGE_PWD_CONNECTED, changePwdConnectedSaga);
}

export function *watchDemandeReferencement() {
    yield takeEveryAuthentificationCheck(actiontypes.SAVE_DEMANDE_REFERENCEMENT, saveDemandeReferencementSaga);
    yield takeEveryAuthentificationCheck(actiontypes.RECHERCHE_GLN_REFERENCEMENT, rechechereGlnBeforeSaveReferencementSaga);
    yield takeEveryAuthentificationCheck(actiontypes.RECHERCHE_REFERENCEMENT_BY_GLN, searchByGlnSaga);
}

export function *watchRedirectToDemandeReferencement() {
    yield takeEveryAuthentificationCheck(actiontypes.REDIRIGE_REFERENCEMENT, redirectDemandeReferencementSaga);
    yield takeEveryAuthentificationCheck(actiontypes.ADD_TO_FAVORIS_AND_REDIRECT_SAISIE_WEB, addToFavorisAndRedirectToSaisieWebSaga);
    yield takeEveryAuthentificationCheck(actiontypes.ADD_TO_FAVORIS_AND_REDIRECT_MES_PARTENAIRES, addToFavorisAndRedirectToMesPartenairesSaga);
}

export function *watchUtilisateur() {
    yield takeEveryAuthentificationCheck(actiontypes.UTILISATEUR_GET_INFORMATIONS_DECLARANT, getInformationsDeclarantSaga);
    yield takeEveryAuthentificationCheck(actiontypes.UTILISATEUR_UPDATE_PREFERENCE_SIZE_PAGINATION, updatePreferenceSizePaginationSaga);
    yield takeLatestAuthentificationCheck(actiontypes.UPDATE_LOCALE_FOR_USER, updateLocaleSaga);
    yield takeLatestAuthentificationCheck(actiontypes.CHECK_SIRET, checkSiretSaga);
}

export function *watchDeclaration() {
    yield takeLatestAuthentificationCheck(actiontypes.SAVE_DECLARATION, saveDeclarationSaga);
    yield takeEveryAuthentificationCheck(actiontypes.UPDATE_DECLARATION_ENTETE_AND_SAVE_SAGA, updateEnteteAndSaveDeclarationSaga);
    yield takeEveryAuthentificationCheck(actiontypes.UPDATE_DECLARATION_AND_SAVE_ALL_ENTETES, updateAllEnteteAndSaveDeclarationSaga);
    yield takeEveryAuthentificationCheck(actiontypes.UPDATE_DECLARATION_STORE, updateDeclarationSaga);
    yield takeEveryAuthentificationCheck(actiontypes.REVALID_DECLARATION_ENTETES_SAGA, revalidEntetesAndSendDeclaration);
    yield takeEveryAuthentificationCheck(actiontypes.CANCEL_DECLARATION_ENTETES_SAGA, cancelEntetesAndSendDeclaration);
    yield takeEveryAuthentificationCheck(actiontypes.CANCEL_DECLARATION_WEB_ENTETES_SAGA, cancelEntetesWebAndSendDeclaration);
    yield takeEveryAuthentificationCheck(actiontypes.SEND_DECLARATION_SAGA, sendDeclarationSaga);
    yield takeEveryAuthentificationCheck(actiontypes.MERGE_LIGNE_SAGA, mergeLigneAndSendDeclaration);
    yield takeEveryAuthentificationCheck(actiontypes.UPDATE_DECLARATION_LIGNES_SAGA, updateLigneAndSaveDeclaration);
    yield takeEveryAuthentificationCheck(actiontypes.DELETE_DECLARATION_LIGNE_SAGA, deleteLigneAndSendDeclaration);
    yield takeEveryAuthentificationCheck(actiontypes.REVALID_DECLARATION_LIGNE_SAGA, revalidLigneAndSendDeclaration);
    yield takeEveryAuthentificationCheck(actiontypes.CANCEL_DECLARATION_LIGNE_SAGA, cancelLigneAndSendDeclaration);
    yield takeEveryAuthentificationCheck(actiontypes.VALIDATE_ONE_DECLARATION_EN_ETAT_INTEGRATION_EXCEL, validateOneDeclarationEnEtatSagaIntegrationExcel);
    yield takeEveryAuthentificationCheck(actiontypes.VALIDATE_ONE_DECLARATION_EN_ETAT_MES_DECLARATIONS, validateOneDeclarationEnEtatSagaMesDeclarations);
    yield takeEveryAuthentificationCheck(actiontypes.REDIRECT_TO_DECLARATION, redirectDeclaration);
    yield takeEveryAuthentificationCheck(actiontypes.UPDATE_INFO_PARTENAIRE, updatePartenaireSaga);
    yield takeEveryAuthentificationCheck(actiontypes.UPDATE_DECLARANT_INFOS, updateDeclarantSaga);
    yield takeLatestAuthentificationCheck(actiontypes.SET_TVA_FOR_ENTETE, setTvaForEnteteSaga);
}

export function *watchIntegration() {
    yield takeLatestAuthentificationCheck(actiontypes.GET_INTEGRATION, getIntegrationSaga);
    yield takeLatestAuthentificationCheck(actiontypes.REDIRECT_TO_INTEGRATION_FROM_DECLARATION, redirectToIntegrationFromNumeroDeclarationSaga);
    yield takeEveryAuthentificationCheck(actiontypes.DOWNLOAD_MODELE_EXCEL, downloadModeleExcelSaga);
    yield takeEveryAuthentificationCheck(actiontypes.DOWNLOAD_RECAPITULATIF_INTEGRATION, downloadIntegrationRecapitulatifSaga);
}

export function *watchIntegrationRecapitulatif() {
    yield takeEveryAuthentificationCheck(actiontypes.INTEGRATION_DECLARATION_TABLEUR, integrerTableurSaga);
    yield takeEveryAuthentificationCheck(actiontypes.GET_INTEGRATIONS_TABLEUR_BY_GLN_DECLARANT, getIntegrationsByGlnDeclarantSaga);
    yield takeEveryAuthentificationCheck(actiontypes.DELETE_INTEGRATION_TABLEUR, deleteIntegrationsSaga);
    yield takeEveryAuthentificationCheck(actiontypes.DISPLAY_ERROR_MESSAGE, displayErrorMsgSaga);
}

export function *watchIntegrationEntete() {
    yield takeEveryAuthentificationCheck(actiontypes.GET_ECHANTILLON_ENTETE_IDENTIFIEES_ET_ATTENDUES_BY_ID_INTEGRATION, getEchantillonEtEntetesIdentifieesEtAttenduesSaga);
    yield takeEveryAuthentificationCheck(actiontypes.CONFIRM_CHOIX_ENTETE, confirmEntetesChoisiesSaga);
    yield takeEveryAuthentificationCheck(actiontypes.CLOSE_DECLARATION, closeDeclarationSaga);
}

export function *watchIntegrationCorrectionErreur() {
    yield takeEveryAuthentificationCheck(actiontypes.GET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_LIGNES_BY_ID_INTEGRATION, getCorrectionErreurLignesByIdIntegrationSaga);
    yield takeEveryAuthentificationCheck(actiontypes.GET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_ENTETES_CHOISIES_BY_ID_INTEGRATION, getCorrectionErreurEntetesChoisiesByIdIntegrationSaga);
    yield takeEveryAuthentificationCheck(actiontypes.SAVE_GET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_LIGNES, saveAndGetLignesForIdIntegrationSaga);
    yield takeEveryAuthentificationCheck(actiontypes.DELETE_GET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_STATS, deleteAndGetStatisticsForIdIntegrationSaga);
    yield takeEveryAuthentificationCheck(actiontypes.CREATE_DECLARATIONS_FROM_FICHIER, createDeclarationsForIdIntegrationSaga);
    yield takeEveryAuthentificationCheck(actiontypes.GET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_LOADING_PERCENTAGE_SAGA, getPercentageSaga);
}

export function *watchIntegrationGetListeDeclaration() {
    yield takeEveryAuthentificationCheck(actiontypes.GET_LIST_DECLARATIONS_ENTETE_BY_INTEGRATION, getListDeclarationsEnteteByIdIntegrationSaga);
    yield takeEveryAuthentificationCheck(actiontypes.GET_LIST_DECLARATIONS_LIGNE_BY_NUMERO_DECLARATION, getListDeclarationsLigneByNumeroDeclarationSaga);
    yield takeEveryAuthentificationCheck(actiontypes.VALIDATE_INTEGRATION_EN_ETAT, validateDeclarationsEnEtatSaga);
    yield takeEveryAuthentificationCheck(actiontypes.UPDATE_DECLARATION_ENTETE_STATUS, modifierStatutDeclarationEnteteSaga);
}

export function *watchMesDeclarations() {
    yield takeEveryAuthentificationCheck(actiontypes.REINIT_MD_TYPE_DECLARATION, reinitMdTypeDeclarationSaga);
    yield takeEveryAuthentificationCheck(actiontypes.GET_INFOS_NECESSAIRES, getInfosNecessaires);
    yield takeEveryAuthentificationCheck(actiontypes.UPDATE_MD_PERIOD, getInfosNecessairesAndPartenaireAndUpdateFiltre);
    yield takeLatestAuthentificationCheck(actiontypes.UPDATE_MD_RESULTAT_SAGA, updateEntetesSaga);
    yield takeLatestAuthentificationCheck(actiontypes.GET_MD_LIST_DECLARATIONS_LIGNE_BY_NUMERO_DECLARATION, getMDListDeclarationsLigneByNumeroDeclarationSaga);
    yield takeLatestAuthentificationCheck(actiontypes.SEND_MD_ALL_FILTERED, sendMdAllDeclarationsFiltrees);
    yield takeLatestAuthentificationCheck(actiontypes.INVALIDATE_DECLARATION_BY_NUMERO, invalidateDeclarationByNumeroDeclarationSaga);
    yield takeLatestAuthentificationCheck(actiontypes.ROLLBACK_DECLARATION_MODIFICATION_BY_NUMERO, rollBackDeclarationModificationByNumeroDeclarationSaga);
    yield takeLatestAuthentificationCheck(actiontypes.SEND_DECLARATION_BY_NUMERO, sendDeclarationByNumeroDeclarationSaga);
    yield takeLatestAuthentificationCheck(actiontypes.CLOSE_MD_DECLARATION, closeMDDeclarationSaga);
    yield takeLatestAuthentificationCheck(actiontypes.UPDATE_ENTETES_AND_ROWINDEX, updateEntetesAndRowIndexSaga);
    yield takeLatestAuthentificationCheck(actiontypes.MODIFY_DECLARATION_FACTUREE, modifyDeclarationFactureeSaga);
    yield takeLatestAuthentificationCheck(actiontypes.CHECK_STATUT_MD_DECLARATION_SAGA, checkStatutMDDeclarationSaga);
    yield takeLatestAuthentificationCheck(actiontypes.ON_PREVIOUS_DECLARATION_FROM_MES_DECLARATIONS_SAGA, onPreviousDeclarationFromMesDeclarationsSaga);
    yield takeLatestAuthentificationCheck(actiontypes.ON_NEXT_DECLARATION_FROM_MES_DECLARATIONS_SAGA, onNextDeclarationFromMesDeclarationsSaga);
    yield takeLatestAuthentificationCheck(actiontypes.CLOSE_DETAIL_DIALOG_FROM_MES_DECLARATIONS_SAGA, closeDetailDialogMesDeclarationsSaga);
    yield takeLatestAuthentificationCheck(actiontypes.GET_MD_PARTENAIRE_AND_UPDATE_FILTRE, getPartenairesAndUpdateFiltreAndSelection);
    yield takeLatestAuthentificationCheck(actiontypes.UPDATE_MD_STATISTIQUES_MONTANTS, updateStatistiquesMontantsMesDeclarationsSaga);
    yield takeLatestAuthentificationCheck(actiontypes.UPDATE_MD_FILTRE_TYPE_ANNEE, updateMdFiltreAnneeSaga);
}

export function *watchStatistiquesDeclarations() {
    yield takeLatestAuthentificationCheck(actiontypes.EXPORT_MENSUALISATION, exportMensualisationSaga);
    yield takeLatestAuthentificationCheck(actiontypes.EXPORT_LIGNES_EAN, exportLignesEanSaga);
    yield takeLatestAuthentificationCheck(actiontypes.UPDATE_STORE_AND_GET_PARTENAIRES_FACTURES, loadPartenairesAndFacturesSaga);
    yield takeLatestAuthentificationCheck(actiontypes.LOAD_FACTURES, loadFacturesSaga);
    yield takeLatestAuthentificationCheck(actiontypes.EXPORT_EAN_GET_COUNT_LIGNE, getCountExportEanLignesSaga);
}

export function *watchDocumentsDeclarations() {
    yield takeLatestAuthentificationCheck(actiontypes.EXPORT_RECAPITULATIF_VENTE, exportRecapitulatifVenteSaga);
}

export function *watchCroisementBibFour() {
    yield takeLatestAuthentificationCheck(actiontypes.GET_LISTE_ECARTS_FOUR_BIB, getListeEcartsFourBib);
    yield takeLatestAuthentificationCheck(actiontypes.GET_COUNT_ECART_FOUR_BIB, getCountDeclarantListEcartFourBib);
    yield takeLatestAuthentificationCheck(actiontypes.OPEN_CROISEMENT_DIALOG_SAGA, openCroisementDialogSaga);
}

export function *watchMesFactures() {
    yield takeLatestAuthentificationCheck(actiontypes.GET_MES_FACTURES, getMesFacturesSaga);
    yield takeLatestAuthentificationCheck(actiontypes.DOWNLOAD_FACTURE, getFactureDocumentSaga);
    yield takeLatestAuthentificationCheck(actiontypes.DOWNLOAD_FACTURE_FROM_DECLARATION, getFactureDocumentFromDeclarationSaga);
}

export function *watchMonProfil() {
    yield takeLatestAuthentificationCheck(actiontypes.GET_MON_PROFIL, getMonProfilSaga);
    yield takeLatestAuthentificationCheck(actiontypes.GET_MON_PROFIL_AVEC_NOTIFICATION, getMonProfilAvecNotificationMailSaga);
    yield takeLatestAuthentificationCheck(actiontypes.SAVE_MON_PROFIL_COMPTE_UTILISATEUR, saveCompteUtilisateurSaga);
    yield takeLatestAuthentificationCheck(actiontypes.SAVE_MON_PROFIL_CONTACT, updateContactInfosSaga);
    yield takeLatestAuthentificationCheck(actiontypes.SAVE_MON_PROFIL_CONTACT_AND_DUPLICATE_IT, updateContactInfosAndDuplicateItSaga);
    yield takeLatestAuthentificationCheck(actiontypes.CHECK_MAIL_TOKEN, checkMailTokenSaga);
    yield takeLatestAuthentificationCheck(actiontypes.UPDATE_MON_PROFIL_LAST_STEP_FIRST_CO_FOURNI, updateMonProfilLastStepPremiereConnexionFournisseurSaga);
    yield takeLatestAuthentificationCheck(actiontypes.UPDATE_VUE_CROISEMENT, updateVueCroisementSaga);
    yield takeLatestAuthentificationCheck(actiontypes.UPDATE_MON_PROFIL_PREMIERE_CONNEXION_INFOS, updateMonProfilPremiereConnexionInfosSaga);
    yield takeLatestAuthentificationCheck(actiontypes.SAVE_MON_PROFIL_CONTACT_GLOBAL, saveContactGlobalSaga);
}

export function *watchLibelle() {
    yield takeLatestAuthentificationCheck(actiontypes.GET_LIBELLES, getLibellesSaga);
}

export function *watchGlobalDialog() {
    yield takeEveryAuthentificationCheck(actiontypes.GLOBAL_DIALOG_CLOSE, closeDialog);
}

export function *watchMesPartenaires() {
    yield takeLatestAuthentificationCheck(actiontypes.GET_MES_PARTENAIRES, getMesListesPartenairesSaga);
    yield takeLatestAuthentificationCheck(actiontypes.GET_MES_PARTENAIRES_FAVORIS, getMesPartenairesFavorisSaga);
    yield takeLatestAuthentificationCheck(actiontypes.GET_MES_PARTENAIRES_RECENTS, getMesPartenairesRecentsSaga);
    yield takeLatestAuthentificationCheck(actiontypes.ADD_PARTENAIRE_TO_FAVORIS, addPartenaireToFavorisSaga);
    yield takeLatestAuthentificationCheck(actiontypes.REMOVE_PARTENAIRE_FROM_FAVORIS, removePartenaireFromFavorisSaga);
    yield takeLatestAuthentificationCheck(actiontypes.EXPOTER_PARTENAIRES, exporterPartenairesSaga);
    yield takeLatestAuthentificationCheck(actiontypes.CHECK_IF_PARTENAIRE_RECENT, checkIfPartenaireRecentSaga);
}

export function *watchNotifications() {
    yield takeLatestAuthentificationCheck(actiontypes.GET_ALL_NOTIFICATIONS, getAllNotificationsSaga);
    yield takeLatestAuthentificationCheck(actiontypes.CHECK_IF_ECART_IS_PRESENT, checkIfEcartIsDetectedSaga);
    yield takeLatestAuthentificationCheck(actiontypes.UPDATE_VUE_NOTIFICATIONS, updateVueSaga);
    yield takeEvery(actiontypes.SEND_MESSAGE_NOUS_CONTACTER, sendMessageNousContacterSaga);
}

export function *watchCommon() {
    yield takeLatestAuthentificationCheck(actiontypes.INTEGRER_EXCEL_FROM_FILEPOND, integrerFichierExcelFromFilepondSaga);
}
