import React from "react";
import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import { green } from "@material-ui/core/colors";
import { isNotBlank } from "../../../../../../utils/utility";

export const INTERACTIVE_MESSAGE_MARGIN_RIGHT = 10;

const useStyles = makeStyles(() => ({
    wrapper: {
        position: "relative"
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    },
    message: {
        marginRight: INTERACTIVE_MESSAGE_MARGIN_RIGHT
    }
}));

const InteractiveConfirmButton = props => {
    const classes = useStyles();
    const rootDefautStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
    };
    const { loading, disabled, message, messageValues, children, rootStyle, ...otherProps } = { ...props };

    return (
        <div style={rootStyle ? rootStyle : rootDefautStyle}>
            <div className={classes.wrapper}>
                <Button
                    variant="contained"
                    disabled={disabled ? disabled : loading}
                    {...otherProps}>
                    {isNotBlank(message) && <span className={classes.message}> {<Trans id={message} values={messageValues}/>} </span> }
                    {children}
                </Button>
                {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}
            </div>
        </div>
    );
};

export default InteractiveConfirmButton;
