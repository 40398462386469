import { call, put, select } from "redux-saga/effects";
import { push } from "connected-react-router";
import { saveDemandeReferencement } from "../../../services/demandeReferencement.service";
import * as actions from "../../actions/index.action";
import { getMailForUtilisateur } from "../../../services/utilisateur.service";
import { PATH_DEMANDE_REFERENCEMENT } from "../../../routes/paths/paths.util";
import { selectUtilisateur, selectUtilisateurGencod, selectUtilisateurRoles } from "../../../utils/selectors";
import { searchGlnInscriptionBibliotheque, searchGlnInscriptionFournisseur, searchGlnReferencementDeclarant } from "../../../services/rechercheGLN.service";
import { checkProfilConnected, getPartenaireLibelle, isFournisseurRole } from "../../../utils/utility";
import { contactStrings } from "../../../utils/stringUtil";
import { addPartenaireToFavorisSaga } from "../mesPartenaires/mesPartenaires.saga";
import {
    DEMANDE_REFERENCEMENT_DIALOG_FIN,
    DEMANDE_REFERENCEMENT_ERROR,
    ERROR_BAD_REQUEST,
    ERROR_INSCRIPTION,
    LIBELLE_ERROR_SYSTEME,
    LIBELLE_PARTENAIRE_NOT_FOUND
} from "../../../utils/libelleConstantes";
import { globalI18n } from "../../../i18n.conf";


export function *saveDemandeReferencementSaga({ body }) {
    yield put(actions.updateLoadingInscription(true));
    try {
        yield put(actions.updateStatutSaveDemande(true));
        yield put(actions.updateIsCalledFromInscription(false));
        yield put(actions.updateApplyErrorStyle(false));
        yield put(actions.updateDisableValideButton(true));
        const response = yield call(saveDemandeReferencement, body);
        if (response.data) {
            yield put(actions.updateDisableValideButton(false));
            yield put(actions.updateNumeroToMap(response.data));
            yield put(actions.updateMessageConfirmation(DEMANDE_REFERENCEMENT_DIALOG_FIN));
            yield put(actions.nextStep());
        }
    } catch (error) {
        yield put(actions.updateStatutSaveDemande(false));
        yield put(actions.updateDisableValideButton(false));
        yield put(actions.updateApplyErrorStyle(true));
        yield put(actions.updateMessageConfirmation(DEMANDE_REFERENCEMENT_ERROR));
        yield put(actions.nextStep());
    }
    yield put(actions.updateLoadingInscription(false));
}

export function *redirectDemandeReferencementSaga(action) {
    let response;
    try {
        response = yield getMailForUtilisateur(action.gencod);
        yield put(push({
            pathname: `${action.prefixPath}${PATH_DEMANDE_REFERENCEMENT}`,
            state: {
                email: response.data.data,
                isCalledFromDeclaration: true,
                data: action.data
            }
        }));
    } catch (error) {
        response = error.response;
        const message = response.data.message.messageKey ? response.data.message.messageKey : ERROR_BAD_REQUEST;
        yield put(actions.openDialogNoRedirect(message));
    }
}

export function *rechechereGlnBeforeSaveReferencementSaga({ formulaire }) {
    const { roles } = yield select(selectUtilisateur);
    const profil = checkProfilConnected(roles);
    const body = {
        gencod: formulaire.gencod,
        raisonSociale: `${formulaire.raisonSociale1} ${formulaire.raisonSociale2}`,
        adresse: `${formulaire.adresse1} ${formulaire.adresse2}`,
        codePostal: formulaire.codePostal,
        ville: formulaire.ville,
        telephone: formulaire.telephone,
        emailEntite: formulaire.email
    };
    yield put(actions.updateLoadingInscription(true));
    try {
        const service = isFournisseurRole(profil) ? searchGlnInscriptionBibliotheque : searchGlnInscriptionFournisseur;
        const response = yield call(service, body);
        if (response.data.length > 0) {
            yield put(actions.updateResultsGLN(response.data));
            yield put(actions.nextStep());
        }
    } catch (error) {
        yield put(actions.updateDisableValideButton(false));
        yield put(actions.openDialogNoRedirect(ERROR_INSCRIPTION, error.message));
    }
    yield put(actions.updateLoadingInscription(false));
}

export function *searchByGlnSaga({ gencodPartenaire }) {
    yield put(actions.updateLoadingInscription(true));
    yield put(actions.updateShowSaveDemande(false));
    try {
        const gencodDeclarant = yield select(selectUtilisateurGencod);
        const roles = yield select(selectUtilisateurRoles);
        const data = { gencodDeclarant, gencodPartenaire };
        const response = yield call(searchGlnReferencementDeclarant, data);
        if (response.data.length > 0) {
            yield put(actions.updateResultsGLN(response.data));
            yield put(actions.nextStep());
        } else {
            yield put(actions.openDialog(globalI18n._({ id: LIBELLE_PARTENAIRE_NOT_FOUND, values: { partenaire: getPartenaireLibelle(roles) } })));
        }
    } catch (error) {
        yield put(actions.updateDisableValideButton(false));
        yield put(actions.openDialogNoRedirect(LIBELLE_ERROR_SYSTEME, error.response.data.message));
    }
    yield put(actions.updateLoadingInscription(false));
}

export function *addToFavorisAndRedirectToMesPartenairesSaga({
    partenaire,
    path,
    isFournisseur
}) {
    yield put(actions.loadingSelectedFavoris(true));
    yield call(addPartenaireToFavorisSaga, { gencodFavoris: partenaire.map(x => x.gencod), isFournisseur });
    yield put(push(path));
    yield put(actions.loadingSelectedFavoris(false));
}

export function *addToFavorisAndRedirectToSaisieWebSaga({ partenaire, path }) {
    const raisonSociale = contactStrings(
        partenaire.raisonSociale1,
        partenaire.raisonSociale2
    );
    yield put(actions.updatePartenaireInfo(
        partenaire.gencod,
        raisonSociale,
        partenaire.ville,
        partenaire.codePostal
    ));
    yield put(push(path));
}

