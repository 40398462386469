import axios from "axios";
import { downloadFileFromResponse } from "../utils/downloadUtil";

export const getAttestations = gencod => axios.get("/statistiques/attestationFinDeclaration/getAttestationFinDeclarationDtoByGencod", { params: { gencodDeclarant: gencod } });

export const sendAttestation = (gencod, annee, functionContact) => {
    const formData = new FormData();
    formData.append("gencodDeclarant", gencod);
    formData.append("annee", annee);
    formData.append("fonctionContact", functionContact);
    return axios.post("/statistiques/attestationFinDeclaration/createAttestationFinDeclarationByGencodAndAnnee", formData, { headers: { "Content-Type": "text/plain" } });
};

export const downloadAttestation = (gencod, annee) => axios.get("/statistiques/attestationFinDeclaration/getAttestationFinDeclarationDocumentByGencodAndAnnee", {
    params: {
        gencodDeclarant: gencod, annee
    },
    responseType: "blob"
}).then(downloadFileFromResponse(`Attestation_fin_declarations_${annee}.pdf`));
