import React from "react";
import NotificationImportant from "@material-ui/icons/NotificationImportant";
import { Badge, ClickAwayListener, Grow, IconButton, MenuList, Paper, Popper, withStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Trans } from "@lingui/macro";
import { MenuItem } from "material-ui";
import DetectionEcartNotification from "../../../components/notifications/croisementBibFour/detectionEcartNotification";
import { DetectionNewFactureNotification } from "../../../components/notifications/nouvelleFacture/detectionNewFactureNotification";
import RelanceFactureNotification from "../../../components/notifications/relanceFacture/relanceFactureNotification";
import MiseEnDemeureNotification from "../../../components/notifications/miseEnDemeure/miseEnDemeureNotification";
import DossierEnContentieuxNotification from "../../../components/notifications/dossierContentieux/dossierEnContentieuxNotification";
import UpdateContactMailNotification from "../../../components/notifications/updateContactMailEnCours/updateContactMailNotification";
import { selectNotifications } from "../../../utils/selectors";
import { updateNotificationsVue } from "../../../store/actions/notification.action";
import NotificationDeclaration2006 from "../../../components/notifications/declaration2006/NotificationDeclaration2006";
import { EMPTY_NOTIFICATIONS, LIBELLE_NOTIFICATIONS } from "../../../utils/libelleConstantes";
import StyledTooltip from "../../tooltip/styledTooltip.component";
import NotificationDeclarationEnAttente from "../../../components/notifications/declarationEnAttente/NotificationDeclarationEnAttente";
import BaseNotification from "./base/baseNotification";

const StyledBadge = withStyles(theme => ({
    badge: {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: "0 4px"
    }
}))(Badge);

export function Notifications() {
    const notifications = useSelector(selectNotifications);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [showTooltip, setShowTooltip] = React.useState(true);

    const dispatch = useDispatch();

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
        setShowTooltip(false);
        if (notifications.nombreNotifications > 0) {
            dispatch(updateNotificationsVue());
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
        setShowTooltip(true);
    };

    return (<div>
        <StyledTooltip show={showTooltip} keyMessage={LIBELLE_NOTIFICATIONS}>
            <IconButton aria-label="cart" onClick={handleClick}>
                <StyledBadge badgeContent={notifications.nombreNotifications} color="secondary">
                    <NotificationImportant/>
                </StyledBadge>
            </IconButton>
        </StyledTooltip>
        <Popper open={open} anchorEl={anchorEl} transition disablePortal placement={"bottom-end"}>
            {({ TransitionProps }) => (
                <Grow {...TransitionProps}>
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList autoFocusItem={open} id="menu-list-grow">
                                {notifications.nombreNotifications === 0 && <MenuItem disabled={true}><BaseNotification
                                    label={<Trans id={EMPTY_NOTIFICATIONS}/>}/></MenuItem>}
                                <NotificationDeclaration2006 notification={notifications.declaration2006} callBack={handleClose}/>
                                <DetectionEcartNotification notification={notifications.ecartDeclaration} callBack={handleClose}/>
                                <NotificationDeclarationEnAttente notification={notifications.declarationEnAttente} callBack={handleClose}/>
                                <DetectionNewFactureNotification notifications={notifications.nouvellesFactures} callBack={handleClose}/>
                                <RelanceFactureNotification notifications={notifications.facturesRelancees} callBack={handleClose}/>
                                <MiseEnDemeureNotification notifications={notifications.facturesMisesEnDemeure} callBack={handleClose}/>
                                <DossierEnContentieuxNotification notifications={notifications.fournisseursEnContentieux} callBack={handleClose}/>
                                <UpdateContactMailNotification notifications={notifications.modificationsMailContact}/>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    </div>);
}

export default Notifications;
