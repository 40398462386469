import { FormControl, FormControlLabel, FormHelperText, RadioGroup } from "@material-ui/core";
import React from "react";
import { ValidatorComponent } from "react-material-ui-form-validator";
import { getLibelle } from "../../../utils/libelleUtil";
import RadioSmallDefault from "../../radio/RadioSmallDefault.component";

export class RadioGroupValidator extends ValidatorComponent {
    render() {
        // eslint-disable-next-line no-unused-vars
        const { errorMessages, validators, validatorListener, name, value, onChange, row, libelles, ...rest } = this.props;
        const { isValid } = this.state;

        const style = isValid ? {} : { color: "#f44436" };
        return (
            <FormControl component="fieldset" error={!isValid} {...rest}>
                <RadioGroup name={name} value={value} onChange={onChange} row={row}>

                    {libelles.map(libelle => <FormControlLabel
                        key={libelle.code}
                        label={getLibelle(libelle)}
                        control={<RadioSmallDefault name="civiliteRadio" value={libelle.code} style={style} />}
                        labelPlacement="start"
                    />)}
                </RadioGroup>
                {!isValid && <FormHelperText style={style}>{this.getErrorMessage()}</FormHelperText>}
            </FormControl>
        );
    }
}
