import React, { Component } from "react";
import { MenuList } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Controls from "../controls/controls.component";
import Notifications from "../notification/notification.component";
import { isForFirstConnectionOrForcage } from "../../../utils/utility";
import classes from "./menu.module.css";
import { MenuItemWithSubMenu } from "./menuItem/menuItem";


class Menu extends Component {
    render() {
        const { isLogged, showInDrawer, signOut, menuItems } = this.props;
        if (isLogged) {
            let attachedClasses = [classes.Menu];
            if (showInDrawer) {
                attachedClasses = [classes.MenuMobileOpen];
            }
            return (
                <div className={attachedClasses.join(" ")}>
                    <MenuList className={classes.MenuList}>
                        {menuItems.map(item => (
                            <MenuItemWithSubMenu
                                key={item.name}
                                item={item}
                            />
                        ))}
                    </MenuList>
                    <Notifications/>
                    <Controls isLogged={isLogged} layout="mobile" signOut={signOut}/>
                </div>
            );
        }
        return null;
    }
}

const mapStateToProps = state => ({
    menuItems: state.login.menuItems,
    firstConnexion: isForFirstConnectionOrForcage(state.utilisateur.informations.premiereConnexion)
});
export default connect(mapStateToProps, null)(withRouter(Menu));
