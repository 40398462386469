import {
    getMenuPartenairePath,
    PATH_DECLARATION_EXCEL,
    PATH_DECLARATION_WEB,
    PATH_MENU_ATTESTATION_FIN_DECLARATIONS,
    PATH_MENU_COMMUNICATION_ACTUS,
    PATH_MENU_COMMUNICATION_DDP,
    PATH_MENU_FACTURES,
    PATH_MENU_MES_DECLARATIONS,
    PATH_MENU_MES_STATISTIQUES,
    PATH_MENU_RECAPITULATIF_VENTES,
    PATH_MON_PROFIL
} from "../routes/paths/paths.util";
import {
    LIBELLE_ADMINISTRATIF,
    LIBELLE_ATTESTATION_FIN_DECALARATION,
    LIBELLE_COMMUNICATION,
    LIBELLE_COMMUNICATION_ACTUS,
    LIBELLE_COMMUNICATION_DDP,
    LIBELLE_DECLARATION_INTEGRATION_EXCEL,
    LIBELLE_DECLARATION_SAISIE_EN_LIGNE,
    LIBELLE_MES_DECLARATIONS,
    LIBELLE_MES_FACTURES,
    LIBELLE_MES_PARTENAIRES_BIB,
    LIBELLE_MES_PARTENAIRES_FOURNI,
    LIBELLE_MES_STATISTIQUES,
    LIBELLE_MON_PROFIL,
    LIBELLE_NOUVELLE_DECLARATION,
    LIBELLE_RECAPITULATIF_VENTES
} from "./libelleConstantes";

export const menuDeclaration = {
    menu: [
        {
            name: LIBELLE_DECLARATION_SAISIE_EN_LIGNE,
            to: PATH_DECLARATION_WEB
        },
        {
            name: LIBELLE_DECLARATION_INTEGRATION_EXCEL,
            to: PATH_DECLARATION_EXCEL
        }
    ],
    name: LIBELLE_NOUVELLE_DECLARATION
};

const menuAdministratif = isGerant => ({
    menu: [
        {
            name: LIBELLE_MES_STATISTIQUES,
            to: PATH_MENU_MES_STATISTIQUES
        },
        ...isGerant ? [
            {
                name: LIBELLE_ATTESTATION_FIN_DECALARATION,
                to: PATH_MENU_ATTESTATION_FIN_DECLARATIONS
            }
        ] : []
    ],
    name: LIBELLE_ADMINISTRATIF
});

export const menuMonProfil = {
    to: PATH_MON_PROFIL,
    name: LIBELLE_MON_PROFIL
};

export const menuMesPartenaires = isFourni => ({
    name: isFourni ? LIBELLE_MES_PARTENAIRES_BIB : LIBELLE_MES_PARTENAIRES_FOURNI,
    to: getMenuPartenairePath(isFourni)
});

export const menuMesDeclarations = {
    to: PATH_MENU_MES_DECLARATIONS,
    name: LIBELLE_MES_DECLARATIONS
};

export const commonMenu = isFourni => [
    menuMonProfil,
    menuMesPartenaires(isFourni),
    menuDeclaration,
    menuMesDeclarations
];
export const menuCommunication = {
    menu: [
        {
            name: LIBELLE_COMMUNICATION_DDP,
            to: PATH_MENU_COMMUNICATION_DDP
        },
        {
            name: LIBELLE_COMMUNICATION_ACTUS,
            to: PATH_MENU_COMMUNICATION_ACTUS
        }
    ],
    name: LIBELLE_COMMUNICATION
};

/**
 * Crée le menu fournisseur et ajoute les boutons factures et attestations pour les gerants
 * @param isGerant
 */
export function getMenuFourni(isGerant) {
    const menu = [...commonMenu(true)];
    const menuAdmini = menuAdministratif(isGerant);
    if (isGerant) {
        menu.push({
            to: PATH_MENU_FACTURES,
            name: LIBELLE_MES_FACTURES
        });
    }
    menuAdmini.menu.push({
        to: PATH_MENU_RECAPITULATIF_VENTES,
        name: LIBELLE_RECAPITULATIF_VENTES
    });
    menu.push(
        menuAdmini,
        menuCommunication
    );
    return menu;
}

export const getMenuBibliotheque = isGerant => [
    ...commonMenu(false),
    menuAdministratif(isGerant),
    menuCommunication
];
export const MENU_ADMIN = [];
