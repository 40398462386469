import {
    DEMANDE_REFERENCEMENT, DEMANDE_REFERENCEMENT_LABEL, INSCRIPTION_CONFIRMATION,
    INSCRIPTION_RDP,
    INSCRIPTION_RECHERCHE_GLN,
    RECHERCHE_GLN_AVANCEE,
    RECHERCHEGLN_CONTENT, REFERENCEMENT_CONFIRMATION,
    REPRESENTANT_LEGAL_CONTENT,
    RESPONSABLE_DROIT_PRET_CONTENT
} from "../../../utils/constantes";
import * as actionsType from "../../actionTypes/actionTypes";
import { steps_demande_referencement, steps_inscription } from "../../../utils/steps";
import { updateObject } from "../../../utils/utility";

const initialState = {
    activeStep: 0,
    steps: steps_inscription,
    hiddenInscriptionRdp: false,
    stepsReferencement: steps_demande_referencement
};

const addStepInscriptionRdp = state => {
    const updateState = [...state.steps];
    const newStep = {
        label: INSCRIPTION_RDP,
        component: RESPONSABLE_DROIT_PRET_CONTENT
    };
    updateState.splice(2, 0, newStep);
    return updateObject(state, {
        steps: updateState,
        hiddenInscriptionRdp: false
    });
};

const removeInscriptionRdp = state => {
    const updateStateSteps = [...state.steps];
    updateStateSteps.splice(2, 1);
    return updateObject(state, {
        steps: updateStateSteps,
        hiddenInscriptionRdp: true
    });
};

const removeStepRechercheGln = (state, index) => {
    const updatedRemovedSteps = [...state.steps];
    updatedRemovedSteps.splice(index, 1);
    return updateObject(state, { steps: updatedRemovedSteps });
};

const addStepRechercheGln = state => {
    const newStep = {
        label: INSCRIPTION_RECHERCHE_GLN,
        component: RECHERCHEGLN_CONTENT
    };
    const updatedAddedSteps = [...state.steps];
    const indexStepResponsableRdp = updatedAddedSteps.findIndex(step => step.component === RESPONSABLE_DROIT_PRET_CONTENT);
    const indexStepRepresentantLegal = updatedAddedSteps.findIndex(step => step.component === REPRESENTANT_LEGAL_CONTENT);
    const indexRechercheGLN = indexStepResponsableRdp !== -1 ? indexStepResponsableRdp + 1 : indexStepRepresentantLegal + 1;
    updatedAddedSteps.splice(indexRechercheGLN, 0, newStep);
    return updateObject(state, { steps: updatedAddedSteps });
};

const addStepRechercheGlnReferencement = state => {
    const newStep = {
        label: INSCRIPTION_RECHERCHE_GLN,
        component: RECHERCHE_GLN_AVANCEE
    };
    const stepsReferencementCopy = [...state.stepsReferencement];
    const indexOfElement = stepsReferencementCopy.findIndex(e => e.component === RECHERCHE_GLN_AVANCEE);
    if (indexOfElement < 0) {
        stepsReferencementCopy.splice(1, 0, newStep);
        return updateObject(state, { stepsReferencement: stepsReferencementCopy });
    }
    return state;
};

const addStepDemandeReferencement = state => {
    const newStep = [
        {
            label: DEMANDE_REFERENCEMENT_LABEL,
            component: DEMANDE_REFERENCEMENT
        }, {
            label: INSCRIPTION_CONFIRMATION,
            component: REFERENCEMENT_CONFIRMATION
        }
    ];
    const stepsReferencementCopy = [...state.stepsReferencement];
    const indexOfElement = stepsReferencementCopy.findIndex(e => e.component === DEMANDE_REFERENCEMENT);
    if (indexOfElement < 0) {
        const newSteps = [...state.stepsReferencement, ...newStep];
        return updateObject(state, { stepsReferencement: newSteps, activeStep: state.activeStep + 1 });
    }
    return updateObject(state, { activeStep: state.activeStep + 1 });
};

const removeStepRechercheGlnReferencement = state => {
    const stepsReferencementCopy = [...state.stepsReferencement];
    const indexOfElement = stepsReferencementCopy.findIndex(e => e.component === RECHERCHE_GLN_AVANCEE);
    if (indexOfElement > 0) {
        stepsReferencementCopy.splice(indexOfElement, 1);
        return updateObject(state, { stepsReferencement: stepsReferencementCopy });
    }
    return state;
};

const nexStep = state => updateObject(state, { activeStep: state.activeStep + 1 });

const backStep = state => updateObject(state, { activeStep: state.activeStep - 1 });

const initActiveStep = state => updateObject(state, {
    steps: steps_inscription,
    stepsReferencement: steps_demande_referencement,
    activeStep: 0,
    hiddenInscriptionRdp: false
});

const setActiveStep = (state, action) => updateObject(state, { activeStep: action.value });

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionsType.ADD_STEP_INSCRIPTION_RDP:
        return addStepInscriptionRdp(state);

    case actionsType.REMOVE_STEP_INSCRIPTION_RDP:
        return removeInscriptionRdp(state);

    case actionsType.NEXT_STEP:
        return nexStep(state);

    case actionsType.BACK_STEP:
        return backStep(state);

    case actionsType.DISPLAY_OR_NOT_STEP_RECHERCHE_GLN:

        // eslint-disable-next-line no-case-declarations
        const results = action.resutlsArray;
        // eslint-disable-next-line no-case-declarations
        const index = state.steps.findIndex(step => step.component === RECHERCHEGLN_CONTENT);
        if (results.length === 0 && index !== -1) {
            return removeStepRechercheGln(state, index);
        }
        if (results.length !== 0 && index === -1) {
            return addStepRechercheGln(state);
        }
        return state;
    case actionsType.INIT_ACTIVE_STEP:
        return initActiveStep(state);
    case actionsType.SET_ACTIVE_STEP:
        return setActiveStep(state, action);
    case actionsType.ADD_STEP_RECHERCHE_GLN_REFERENCEMENT:
        return addStepRechercheGlnReferencement(state);
    case actionsType.ADD_STEP_DEMANDE_REFERENCEMENT:
        return addStepDemandeReferencement(state);
    case actionsType.REMOVE_STEP_RECHERCHE_GLN_REFERENCEMENT:
        return removeStepRechercheGlnReferencement(state);
    default:
        return state;
    }
};

export default reducer;
