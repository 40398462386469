import { SORT_NULL } from "../../../utils/constantes";

export const MES_PARTENAIRES_DTO_GENCOD_PARTENAIRE = "gencodPartenaire";
export const MES_PARTENAIRES_DTO_RAISON_SOCIALE = "raisonSociale";
export const MES_PARTENAIRES_DTO_ADRESSE1 = "adresse1";
export const MES_PARTENAIRES_DTO_CODE_POSTAL = "codePostal";
export const MES_PARTENAIRES_DTO_VILLE = "ville";
export const MES_PARTENAIRES_DTO_SOMME_TTC = "sommeTtc";
export const MES_PARTENAIRES_DTO_TYPE = "type";
export const MES_PARTENAIRES_DTO_FERME = "ferme";

export function getFiltre(sorts, pageNumber, pageSize) {
    return {
        sortColumns: sorts.filter(column => column.direction !== SORT_NULL),
        page: pageNumber - 1, // -1 car la pagination coté back est 0 based mais pas le PaginationFooter
        size: pageSize
    };
}
