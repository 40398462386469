import React, { useRef, useState } from "react";
import { Button, FormControlLabel, makeStyles } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { Trans } from "@lingui/macro";
import {
    classeNames,
    LabelInputTextWithChildren,
    LibelleAutoCompleteSmallOutlined,
    TextareaAutosizeSimple,
    TextValidatorSmallOutlined,
    TextValidatorSmallOutlinedUpperCase
} from "../../commonComponents/input/fieldInput";
import { globalI18n } from "../../i18n.conf";
import * as actions from "../../store/actions/common.action";
import {
    LIBELLE_CHAMP_OBLIGATOIRE,
    LIBELLE_CIVILITE_OBLIGATOIRE,
    LIBELLE_CIVLITE,
    LIBELLE_EMAIL_STRUCTURE,
    LIBELLE_ERREUR_GLN_LENGTH,
    LIBELLE_FORMAT_INCORRECT,
    LIBELLE_GLN,
    LIBELLE_MESSAGE,
    LIBELLE_NOM,
    LIBELLE_NUMERO_SIREN_OR_SIRET,
    LIBELLE_PRENOM,
    LIBELLE_RAISON_SOCIALE_1,
    LIBELLE_TELEPHONE_STRUCTURE,
    LIBELLE_TYPE_ENTITE
} from "../../utils/libelleConstantes";
import { GreenCheckbox } from "../../commonComponents/checkBox/GreenCheckBox";
import { GLN_REGEX, REQUIRED } from "../../utils/validatorUtil";
import { TelephoneFormattedTextInput } from "../../commonComponents/input/telephone/TelephoneFormattedTextInput";
import {
    checkIsFournisseurConnected,
    enableInstantValidate,
    getNumericValueForInput,
    isNotEmptyString,
    setSelectionRangeAndUpperCase
} from "../../utils/utility";
import { CiviliteRadioGroup } from "../../commonComponents/input/civilite/CiviliteRadioGroup";
import {
    autoCompleteOnChange,
    inputOnChange,
    isPaysFrance
} from "../monProfil/monProfilUtil";
import {
    CIVILITE,
    GLN,
    MAIL_CONTACT,
    MAX_LENGTH_RAISON_SOCIALE1,
    MAX_LENGTH_SIRET,
    MESSAGE,
    NOM,
    PRENOM,
    RAISON_SOCIALE,
    TELEPHONE,
    TYPE_ENTITE
} from "../../utils/constantes";
import AdresseFieldGroupComponent from "../../commonComponents/input/adresse/AdresseFieldGroup.component";
import { CODE_PAYS_FRANCE } from "../../utils/paysConstantes";
import { NumeroSirenOrSiret } from "../../commonComponents/input/numeroSirenOrSiret";
import {
    selectMonProfilCompteUtilisateur,
    selectUtilisateurRoles
} from "../../utils/selectors";
import TextHeader from "../../commonComponents/texte/TextHeader.container";

const useStyles = makeStyles(classeNames);

// eslint-disable-next-line complexity
export default function NousContacter({ closeDialog, calledFromLogin = true }) {
    // Libellés en bdd utilisés uniquement pour ce formulaire
    const TYPE_ENTITE_FOURNISSEUR = "FOUR";
    const TYPE_ENTITE_BIBLIOTHEQUE = "BIB";

    const roles = useSelector(selectUtilisateurRoles);
    const entite = useSelector(selectMonProfilCompteUtilisateur);
    const isFournisseur = checkIsFournisseurConnected(roles);
    const rolesPresent = roles.length > 0;

    // Template par défaut
    let template = {
        gln: "",
        civilite: "M",
        typeEntite: "",
        prenom: "",
        nom: "",
        raisonSociale: "",
        sirenOrSiret: "",
        numeroVoie: "",
        adresse1: "",
        adresse2: "",
        codePostal: "",
        codePays: CODE_PAYS_FRANCE,
        ville: "",
        telephone: "",
        email: "",
        message: "",
        sendCopy: false
    };

    // Initialisation des données connues
    if (rolesPresent) {
        template.typeEntite = isFournisseur ? TYPE_ENTITE_FOURNISSEUR : TYPE_ENTITE_BIBLIOTHEQUE;
    }
    template = {
        ...template,
        ...entite.gencod && isNotEmptyString(entite.gencod) && { gln: entite.gencod },
        ...entite.raisonSociale1 && isNotEmptyString(entite.raisonSociale1) && { raisonSociale: entite.raisonSociale1 },
        ...entite.siret && isNotEmptyString(entite.siret) && { sirenOrSiret: entite.siret },
        ...entite.numeroVoie && isNotEmptyString(entite.numeroVoie) && { numeroVoie: entite.numeroVoie },
        ...entite.adresse1 && isNotEmptyString(entite.adresse1) && { adresse1: entite.adresse1 },
        ...entite.adresse2 && isNotEmptyString(entite.adresse2) && { adresse2: entite.adresse2 },
        ...entite.codePostal && isNotEmptyString(entite.codePostal) && { codePostal: entite.codePostal },
        ...entite.codePays && isNotEmptyString(entite.codePays) && { codePays: entite.codePays },
        ...entite.ville && isNotEmptyString(entite.ville) && { ville: entite.ville },
        ...entite.telephone && isNotEmptyString(entite.telephone) && { telephone: entite.telephone },
        ...entite.courriel && isNotEmptyString(entite.courriel) && { email: entite.courriel }
    };

    const [formulaire, setFormulaire] = useState(template);

    function onChange(field, value) {
        setFormulaire({
            ...formulaire,
            [field]: value
        });
    }

    const formRef = useRef();
    const dispatch = useDispatch();
    const isFr = isPaysFrance(formulaire);

    const classes = useStyles();
    const handleSubmit = () => {
        dispatch(actions.sendMessageNousContacter(formulaire));
        closeDialog();
    };

    let mainStyle = {
        marginBottom: "50px"
    };
    // Pour ne pas avoir l'espace en haut du formulaire dans la dialog mais uniquement sur la page dédiée
    if (calledFromLogin) {
        mainStyle = {
            ...mainStyle,
            marginTop: "50px"
        };
    }

    return (
        <div style={mainStyle}>
            <ValidatorForm onSubmit={handleSubmit} instantValidate={false} ref={formRef}>
                <TextHeader style={{ width: "50%", justifyContent: "center", margin: "auto", marginBottom: "20px" }}>
                    <Trans id={"nousContacterTitre"}/>
                </TextHeader>
                <LabelInputTextWithChildren labelKey={LIBELLE_GLN} >
                    <TextValidatorSmallOutlined
                        id="nousContacterGLN"
                        onInput={e => getNumericValueForInput(e, 13)}
                        onChange={e => onChange(GLN, e.target.value)}
                        value={formulaire.gln}
                        validators={[GLN_REGEX]}
                        errorMessages={[<Trans id={LIBELLE_ERREUR_GLN_LENGTH}/>]}
                    />
                </LabelInputTextWithChildren>
                <LabelInputTextWithChildren labelKey={LIBELLE_CIVLITE} required>
                    <CiviliteRadioGroup
                        value={formulaire.civilite}
                        onChange={e => onChange(CIVILITE, e.target.value)}
                        row
                        validators={[REQUIRED]}
                        errorMessages={[<Trans id={LIBELLE_CIVILITE_OBLIGATOIRE}/>]}
                        className={classes.inputFields}
                        variant="outlined"/>
                </LabelInputTextWithChildren>
                <LabelInputTextWithChildren labelKey={LIBELLE_NOM} required>
                    <TextValidatorSmallOutlinedUpperCase
                        id="nousContacterNom"
                        onChange={e => onChange(NOM, e.target.value)}
                        value={formulaire.nom}
                        validators={[REQUIRED]}
                        errorMessages={[<Trans id={LIBELLE_CHAMP_OBLIGATOIRE}/>]}
                    />
                </LabelInputTextWithChildren>
                <LabelInputTextWithChildren labelKey={LIBELLE_PRENOM} required>
                    <TextValidatorSmallOutlinedUpperCase
                        id="nousContacterPrenom"
                        onChange={e => onChange(PRENOM, e.target.value)}
                        value={formulaire.prenom}
                        validators={[REQUIRED]}
                        errorMessages={[<Trans id={LIBELLE_CHAMP_OBLIGATOIRE}/>]}
                    />
                </LabelInputTextWithChildren>
                {!rolesPresent &&
                    <LabelInputTextWithChildren labelKey={LIBELLE_TYPE_ENTITE} required>
                        <LibelleAutoCompleteSmallOutlined id="nousContacterTypeEntite"
                            value={formulaire.typeEntite}
                            onChange={autoCompleteOnChange(TYPE_ENTITE, onChange)}
                            typeLibelle={"type_entite"}
                            name="typeEntite"
                            className={classes.inputFields}
                            validators={[REQUIRED]}
                            errorMessages={[<Trans id={LIBELLE_CHAMP_OBLIGATOIRE}/>]}/>
                    </LabelInputTextWithChildren>
                }
                <LabelInputTextWithChildren labelKey={LIBELLE_RAISON_SOCIALE_1} required>
                    <TextValidatorSmallOutlinedUpperCase
                        value={formulaire.raisonSociale}
                        onChange={e => onChange(RAISON_SOCIALE, e.target.value)}
                        inputProps={{ maxLength: MAX_LENGTH_RAISON_SOCIALE1 }}
                        validators={[REQUIRED]}
                        errorMessages={[<Trans id={LIBELLE_CHAMP_OBLIGATOIRE}/>]}/>
                </LabelInputTextWithChildren>
                {isFr && formulaire.typeEntite === TYPE_ENTITE_FOURNISSEUR &&
                    <LabelInputTextWithChildren labelKey={LIBELLE_NUMERO_SIREN_OR_SIRET}>
                        <NumeroSirenOrSiret
                            value={formulaire.sirenOrSiret}
                            onChange={inputOnChange("sirenOrSiret", onChange)}
                            inputProps={{ maxLength: MAX_LENGTH_SIRET }}
                            codePays={formulaire.codePays}
                            className={classes.inputFields}
                            variant="outlined"
                            size="small"
                            type="text"/>
                    </LabelInputTextWithChildren>
                }
                <AdresseFieldGroupComponent formulaire={formulaire} classes={classes} setFormulaire={setFormulaire}/>
                <LabelInputTextWithChildren labelKey={LIBELLE_EMAIL_STRUCTURE} required>
                    <TextValidatorSmallOutlined
                        id="nousContacterEmail"
                        onChange={e => {
                            setSelectionRangeAndUpperCase(e);
                            onChange(MAIL_CONTACT, e.target.value);
                        }}
                        value={formulaire.email}
                        length={80}
                        validators={[REQUIRED, "isEmail"]}
                        errorMessages={[
                            <Trans id={LIBELLE_CHAMP_OBLIGATOIRE}/>,
                            <Trans id={LIBELLE_FORMAT_INCORRECT}/>
                        ]}
                    />
                </LabelInputTextWithChildren>
                <TelephoneFormattedTextInput
                    labelKey={LIBELLE_TELEPHONE_STRUCTURE}
                    onChange={v => onChange(TELEPHONE, v)}
                    value={formulaire.telephone}
                />
                <LabelInputTextWithChildren labelKey={LIBELLE_MESSAGE} required>
                    <TextareaAutosizeSimple
                        id="nousContacterMessage"
                        onChange={e => onChange(MESSAGE, e.target.value)}
                        value={formulaire.message}
                        validators={[REQUIRED]}
                        errorMessages={[<Trans id={LIBELLE_CHAMP_OBLIGATOIRE}/>]}
                    />
                </LabelInputTextWithChildren>
                <div className={classes.parent}>
                    <FormControlLabel
                        control={<GreenCheckbox checked={formulaire.sendCopy} onChange={e => onChange("sendCopy", e.target.checked)}/>}
                        label={globalI18n._("nousContacterCopieMail")}/></div>
                <div className={classes.parent}>
                    <div className={classes.sauvegarderButton}>
                        <Button
                            id="saveContactInfoButton"
                            type="submit"
                            onClick={() => enableInstantValidate(formRef)}
                            variant="contained">
                            <Trans>send</Trans>
                        </Button>
                    </div>
                </div>
            </ValidatorForm>
        </div>
    );
}
