import { put } from "redux-saga/effects";
import { call } from "@redux-saga/core/effects";
import * as actions from "../../actions/index.action";
import { downloadAttestation, getAttestations, sendAttestation } from "../../../services/attestationsFinDeclarations.service";
import { setAttestationAnneeLoading, setAttestationsLoading } from "../../actions/attestationFinDeclarations.action";
import { ERREUR_DOWNLOAD_ATTESTATION, ERREUR_GET_ATTESTATIONS, ERREUR_SEND_ATTESTATION } from "../../../utils/libelleConstantes";


export function *downloadAttestationSaga(action) {
    yield put(setAttestationAnneeLoading(action.annee, true));
    try {
        yield call(downloadAttestation, action.gencod, action.annee);
    } catch (error) {
        yield put(actions.openDialogNoRedirect(ERREUR_DOWNLOAD_ATTESTATION, error.message));
    }
    yield put(setAttestationAnneeLoading(action.annee, false));
}

export function *getAttestationsFinSaga(action) {
    yield put(setAttestationsLoading(true));
    try {
        const response = yield call(getAttestations, action.gencod);
        yield put(actions.getAttestationsSuccess(response.data));
    } catch (error) {
        yield put(actions.openDialogNoRedirect(ERREUR_GET_ATTESTATIONS, error.message));
    }
    yield put(setAttestationsLoading(false));
}

export function *sendAttestationFinSaga(action) {
    let response;
    yield put(setAttestationAnneeLoading(action.annee, true));
    try {
        response = yield call(sendAttestation, action.gencod, action.annee, action.functionContact);
        yield put(actions.getAttestationsSuccess(response.data.data.result));
    } catch (error) {
        response = error.response;
        const { messageKey } = response.data.message;
        const message = messageKey ? messageKey : ERREUR_SEND_ATTESTATION;
        yield put(actions.openDialogNoRedirect(message));
    }
    yield put(setAttestationAnneeLoading(action.annee, false));
    yield put(actions.getAllNotifications(action.gencod, true));
}
