import * as actionTypes from "../actionTypes/actionTypes";

export const login = (username, password, baseUrl) => ({
    type: actionTypes.LOGIN,
    username,
    password,
    baseUrl
});

export const loginSuccess = (userInfos, token, loginRedirectPath, suffixRedirectPath) => ({
    type: actionTypes.LOGIN_SUCCESS,
    userInfos,
    token,
    loginRedirectPath,
    suffixRedirectPath: suffixRedirectPath ? suffixRedirectPath : ""
});

export const loginFail = () => ({
    type: actionTypes.LOGIN_FAIL
});
export const loginReplace = glnRemplacant => ({
    type: actionTypes.LOGIN_REPLACE,
    glnRemplacant
});

export const loginStart = () => ({
    type: actionTypes.LOGIN_START
});

export const logout = () => ({
    type: actionTypes.LOGOUT
});

export const loginCheckState = pathname => ({
    type: actionTypes.LOGIN_CHECK_STATE,
    pathname
});

export const onChangeLogin = value => ({
    type: actionTypes.LOGIN_INPUT_CHANGE,
    value
});

export const majMenuItems = menuItems => ({
    type: actionTypes.MAJ_MENU_ITEMS,
    menuItems
});

export const reinitLoginRedirectPath = () => ({
    type: actionTypes.REINIT_LOGIN_REDIRECT_PATH
});

export const updateReloading = flag => ({
    type: actionTypes.LOGIN_UPDATE_RELOADING,
    flag
});


export const updateReloadingSite = flag => ({
    type: actionTypes.RELOAD_SITE,
    flag
});

