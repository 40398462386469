import * as actionTypes from "../../actionTypes/actionTypes";
import { updateObject } from "../../../utils/utility";
import { getSort } from "../../../utils/bootstrapTableUtil";
import { MES_PARTENAIRES_DTO_SOMME_TTC } from "../../../components/mesPartenaires/util/mesPartenairesUtil";
import { SORT_DESC } from "../../../utils/constantes";

const initialState = {
    loadingExport: false,
    loadingScreen: false,
    loadingFavoris: false,
    favoris: [],
    totalFavoris: 0,
    sortsFavoris: [getSort(MES_PARTENAIRES_DTO_SOMME_TTC, SORT_DESC)],
    pageFavoris: 1,
    sizePageFavoris: 20,
    loadingRecents: false,
    recents: [],
    totalRecents: 0,
    sortsRecents: [getSort(MES_PARTENAIRES_DTO_SOMME_TTC, SORT_DESC)],
    pageRecents: 1,
    sizePageRecents: 20,
    showDeleteFavoriPopup: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.UPDATE_LOADING_MES_PARTENAIRES:
        return updateObject(state, { loadingScreen: action.loading });
    case actionTypes.UPDATE_LOADING_MES_PARTENAIRES_FAVORIS:
        return updateObject(state, { loadingFavoris: action.loading });
    case actionTypes.UPDATE_LOADING_MES_PARTENAIRES_RECENTS:
        return updateObject(state, { loadingRecents: action.loading });
    case actionTypes.UPDATE_LOADING_MES_PARTENAIRES_EXPORT:
        return updateObject(state, { loadingExport: action.loading });
    case actionTypes.GET_MES_PARTENAIRES_FAVORIS_SUCCESS:
        return updateObject(state, { favoris: action.partenaires, totalFavoris: action.total, loadingFavoris: false });
    case actionTypes.GET_MES_PARTENAIRES_RECENTS_SUCCESS:
        return updateObject(state, { recents: action.partenaires, totalRecents: action.total, loadingRecents: false });
    case actionTypes.UPDATE_SORT_MES_PARTENAIRES_FAVORIS:
        return updateObject(state, { sortsFavoris: action.sort });
    case actionTypes.UPDATE_SORT_MES_PARTENAIRES_RECENTS:
        return updateObject(state, { sortsRecents: action.sort });
    case actionTypes.UPDATE_PAGE_MES_PARTENAIRES_FAVORIS:
        return updateObject(state, { pageFavoris: action.page });
    case actionTypes.UPDATE_PAGE_MES_PARTENAIRES_RECENTS:
        return updateObject(state, { pageRecents: action.page });
    case actionTypes.UPDATE_PAGE_SIZE_MES_PARTENAIRES_FAVORIS:
        return updateObject(state, { sizePageFavoris: action.pageSize });
    case actionTypes.UPDATE_PAGE_SIZE_MES_PARTENAIRES_RECENTS:
        return updateObject(state, { sizePageRecents: action.pageSize });
    case actionTypes.UPDATE_SHOW_DELETE_FAVORI_POPUP:
        return updateObject(state, { showDeleteFavoriPopup: action.showDeleteFavoriPopup });
    default:
        return state;
    }
};

export default reducer;
