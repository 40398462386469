import * as actionsType from "../actionTypes/actionTypes";

export const saveDemandeReferencement = body => ({
    type: actionsType.SAVE_DEMANDE_REFERENCEMENT,
    body
});

export const redirigeToDemandeReferencement = (gencod, prefixPath, data) => ({
    type: actionsType.REDIRIGE_REFERENCEMENT,
    gencod,
    prefixPath,
    data
});


export const rechercheGLnBeforeSave = (formulaire, gencodDeclarant) => ({
    type: actionsType.RECHERCHE_GLN_REFERENCEMENT,
    formulaire,
    gencodDeclarant
});

export const rechercheByGln = gencodPartenaire => ({
    type: actionsType.RECHERCHE_REFERENCEMENT_BY_GLN,
    gencodPartenaire
});

export const loadingSelectedFavoris = loading => ({
    type: actionsType.UPDATE_LOADING_FAVORIS_SELECTED,
    loading
});

export const addToFavorisAndRedirectToMesPartenaires = (partenaire, path, isFournisseur) => ({
    type: actionsType.ADD_TO_FAVORIS_AND_REDIRECT_MES_PARTENAIRES,
    partenaire,
    path,
    isFournisseur
});

export const addToFavorisAndRedirectToSaisieWeb = (partenaire, path) => ({
    type: actionsType.ADD_TO_FAVORIS_AND_REDIRECT_SAISIE_WEB,
    partenaire,
    path
});


export const updateFormulaireReferencement = formulaire => ({
    type: actionsType.UPDATE_FORMULAIRE_REFERENCEMENT,
    formulaire
});

export const updateFormulaireRecherche = formulaire => ({
    type: actionsType.UPDATE_FORMULAIRE_RECHERCHE,
    formulaire
});

export const initFormulaireReferencement = () => ({
    type: actionsType.INIT_FORMULAIRE_REFERENCEMENT
});
export const updateStatutSaveDemande = state => ({
    type: actionsType.UPDATE_STATE_SAVE_DEMANDE_REFERENCEMENT,
    state
});

export const updateShowSaveDemande = state => ({
    type: actionsType.UPDATE_SHOW_SAVE_DEMANDE,
    state
});

export const updateDataNotSaved = state => ({
    type: actionsType.UPDATE_DEMANDE_REFERENCEMENT_NOT_SAVED,
    state
});

export const updateGencodPartenaire = gencod => ({
    type: actionsType.UPDATE_GENCOD_PARTENAIRE,
    gencod
});
