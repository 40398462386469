import React, { Component } from "react";
import { connect } from "react-redux";
import { Trans } from "@lingui/macro";
import { Link, withRouter } from "react-router-dom";
import { Button, IconButton, InputAdornment } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import * as actions from "../../../store/actions/index.action";
import Spinner from "../../../commonComponents/spinner/spinner";
import GencodTextInput from "../../../commonComponents/input/gencodTextInput/gencodTextInput";
import { PATH_MDPOUBLIE } from "../../../routes/paths/paths.util";
import { LIBELLE_GLN, LIBELLE_LOGIN_CONNEXION } from "../../../utils/libelleConstantes";
import { REQUIRED } from "../../../utils/validatorUtil";
import { enableInstantValidate } from "../../../utils/utility";
import classes from "./login.module.css";

class Login extends Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
    }

    state = {
        password: "",
        showPassword: false,
        submitted: false
    };

    componentDidUpdate(prevProps) {
        if (prevProps.username !== this.props.username) {
            this.setState({ password: "" });
        }
    }

    onChangeInputField = input => event => {
        const value = event.target.value.replace(/ /g, "");
        this.setState({
            [input]: value
        });
    };

    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };

    signUp = e => {
        e.preventDefault();
        this.setState({ submitted: true });
        const baseUrl = window.location.href;
        this.props.onSignUp(this.props.username, this.state.password, baseUrl);
    };

    render() {
        const { loading, username, onChangeGencod } = this.props;
        const { password } = this.state;
        const enableButtonSign = username.length > 0 && password.length > 0;

        let form = <Spinner/>;

        if (!loading) {
            form = <ValidatorForm onSubmit={this.signUp} className={classes.Form} instantValidate={false} ref={this.formRef}>
                <div className={classes.FormElement} style={{ fontSize: "x-large" }}>
                    <Trans id={LIBELLE_LOGIN_CONNEXION}/>
                </div>
                <div className={classes.FormElement}>
                    <GencodTextInput
                        id="adornment-login"
                        variant="standard"
                        className={classes.TextValidator}
                        label={<Trans id={LIBELLE_GLN}/>}
                        name="login"
                        value={username}
                        onChange={onChangeGencod}
                    />
                </div>
                <div className={classes.FormElement}>
                    <TextValidator
                        id="adornment-password"
                        className={classes.TextValidator}
                        label={<Trans>pwd</Trans>}
                        type={this.state.showPassword ? "text" : "password"}
                        name="password"
                        value={password}
                        onChange={this.onChangeInputField("password")}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowPassword}>
                                        {this.state.showPassword ? <VisibilityOff/> : <Visibility/>}
                                    </IconButton>
                                </InputAdornment>
                        }}
                        validators={[REQUIRED]}
                        errorMessages={[<Trans>errorPassword</Trans>]}
                        required/>
                </div>
                <div className={classes.FormElement}>
                    <Button
                        variant="contained"
                        className={classes.SubmitButton}
                        color="primary"
                        type="submit"
                        onClick={() => enableInstantValidate(this.formRef)}
                        disabled={!enableButtonSign}>
                        <Trans>signIn</Trans>
                    </Button>
                </div>
                <div className={classes.FormElement}>
                    <Link to={PATH_MDPOUBLIE}>
                        <Trans>mdpOublie</Trans>
                    </Link>
                </div>
            </ValidatorForm>;
        }

        return (
            <div className={classes.Login}>
                {form}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    username: state.login.username,
    error: state.login.error,
    loading: state.login.loading,
    loginRedirectPath: state.login.loginRedirectPath
});

const mapDispatchToProps = dispatch => ({
    onSignUp: (username, password, baseUrl) => dispatch(actions.login(username, password, baseUrl)),
    onChangeGencod: event => dispatch(actions.onChangeLogin(event.target.value))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
export { Login };
