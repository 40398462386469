import { CODE_PAYS_FRANCE } from "./paysConstantes";

export const inscriptionEntite = {
    raisonSociale1: "",
    raisonSociale2: "",
    numeroVoie: "",
    preAdresse: "",
    adresse1: "",
    adresse2: "",
    codePostal: "",
    ville: "",
    codePays: CODE_PAYS_FRANCE,
    siret: "",
    ape: "",
    formeJuridique: "",
    formeJuridiqueAutre: "",
    dateCreation: null,
    telephone: "",
    courriel: "",
    site: "",
    ediSsii: "",
    siegeSocial: true,
    typeOrganisme: "",
    typeAutre: ""
};

export const inscriptionContact = {
    civilite: "",
    nom: "",
    prenom: "",
    telephone: "",
    profession: "",
    email: "",
    numeroVoie: "",
    adresse1: "",
    adresse2: "",
    ville: "",
    codePostal: "",
    codePays: CODE_PAYS_FRANCE,
    fonction: ""
};


export const demandeReferencement = {
    raisonSociale1: "",
    raisonSociale2: "",
    adresse1: "",
    adresse2: "",
    codePostal: "",
    ville: "",
    numeroVoie: "",
    codePays: CODE_PAYS_FRANCE,
    siret: "",
    ape: "",
    telephone: "",
    courriel: "",
    siteWeb: ""
};

export const recherchePartenaire = {
    raisonSociale1: "",
    raisonSociale2: "",
    adresse1: "",
    adresse2: "",
    codePostal: "",
    ville: "",
    numeroVoie: "",
    codePays: CODE_PAYS_FRANCE
};
