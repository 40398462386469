import * as actionTypes from "../actionTypes/actionTypes";

export const redirectToDeclaration = (typeDeclaration, id) => ({
    type: actionTypes.REDIRECT_TO_DECLARATION,
    typeDeclaration,
    id
});

export const updatePartenaireInfo = (gencod, raisonSociale, ville, codePostal, save = true) => ({
    type: actionTypes.UPDATE_INFO_PARTENAIRE,
    gencodPartenaire: gencod,
    raisonSocialePartenaire: raisonSociale,
    villePartenaire: ville,
    codePostalPartenaire: codePostal,
    save
});

export const searchPartenaireInfo = (profil, filtre, onlyGencod = false, save = true) => ({
    type: actionTypes.SEARCH_INFO_PARTENAIRE,
    profil,
    filtre,
    onlyGencod,
    save
});

export const updateDeclarationStore = (entete, typeDeclaration, globale = false) => ({
    type: actionTypes.UPDATE_DECLARATION_STORE,
    entete,
    typeDeclaration,
    globale
});

export const updateDeclarantInfo = (gencodDeclarant, raisonSocialeDeclarant, villeDeclarant, codePostalDeclarant, save = true) => ({
    type: actionTypes.UPDATE_DECLARANT_INFOS,
    gencodDeclarant,
    raisonSocialeDeclarant,
    villeDeclarant,
    codePostalDeclarant,
    save
});

export const updateDeclarationEntete = (index, entete) => ({
    type: actionTypes.UPDATE_DECLARATION_ENTETE,
    entete,
    index
});

export const updateDeclarationEnteteSaga = (index, entete, toSaveDeclaration = true) => ({
    type: actionTypes.UPDATE_DECLARATION_ENTETE_AND_SAVE_SAGA,
    entete,
    index,
    toSaveDeclaration
});

export const sentDeclaration = () => ({
    type: actionTypes.SENT_DECLARATION
});

export const saveDeclaration = () => ({
    type: actionTypes.SAVE_DECLARATION
});

export const sendDeclarationSaga = () => ({
    type: actionTypes.SEND_DECLARATION_SAGA
});

export const revalidDeclarationEnteteSaga = (entetes, index) => ({
    type: actionTypes.REVALID_DECLARATION_ENTETES_SAGA,
    entetes,
    index
});

export const cancelDeclarationEnteteSaga = (entetes, index) => ({
    type: actionTypes.CANCEL_DECLARATION_ENTETES_SAGA,
    entetes,
    index
});

export const cancelDeclarationWebEnteteSaga = (entetes, reinitDeclarant) => ({
    type: actionTypes.CANCEL_DECLARATION_WEB_ENTETES_SAGA,
    entetes,
    reinitDeclarant
});

export const updateDeclarationLigneSaga = (index, ligne, applySortByDateModif = false) => ({
    type: actionTypes.UPDATE_DECLARATION_LIGNES_SAGA,
    ligne,
    index,
    applySortByDateModif
});

export const updateDeclarationLignes = lignes => ({
    type: actionTypes.UPDATE_DECLARATION_LIGNES,
    lignes
});

export const updateDeclarationLignesAndEntetesAndCodePostalFournisseur = (lignes, entetes, codePostalFournisseur) => ({
    type: actionTypes.UPDATE_DECLARATION_LIGNES_AND_ENTETES_AND_CODE_POSTAL_FOURNISSEUR,
    lignes,
    entetes,
    codePostalFournisseur
});

export const updateDeclarationLignesAndOrder = (lignes, order) => ({
    type: actionTypes.UPDATE_DECLARATION_LIGNES_AND_ORDER,
    lignes,
    order
});

export const updateDeclarationEntetes = entetes => ({
    type: actionTypes.UPDATE_DECLARATION_ALL_ENTETES,
    entetes
});

export const updateCodePostalFournisseur = codePostal => ({
    type: actionTypes.UPDATE_DECLARATION_CODE_POSTAL_FOURNISSEUR,
    codePostal
});

export const updateDeclarationEntetesAndSaveDeclaration = entetes => ({
    type: actionTypes.UPDATE_DECLARATION_AND_SAVE_ALL_ENTETES,
    entetes
});

export const updateDeclarationLigne = (index, ligne) => ({
    type: actionTypes.UPDATE_DECLARATION_LIGNE,
    ligne,
    index
});

export const mergeLigne = (index, indexDoublon, quantityToAdd) => ({
    type: actionTypes.MERGE_LIGNE,
    index,
    indexDoublon,
    quantityToAdd
});

export const mergeLigneSaga = (index, indexDoublon, quantityToAdd) => ({
    type: actionTypes.MERGE_LIGNE_SAGA,
    index,
    indexDoublon,
    quantityToAdd
});

export const cancelLigne = (index, ligne) => ({
    type: actionTypes.CANCEL_DECLARATION_LIGNE,
    index,
    ligne
});

export const cancelLigneSaga = (index, ligne) => ({
    type: actionTypes.CANCEL_DECLARATION_LIGNE_SAGA,
    index,
    ligne
});

export const deleteLigne = (index, ligne) => ({
    type: actionTypes.DELETE_DECLARATION_LIGNE,
    index,
    ligne
});

export const deleteLigneSaga = (index, ligne) => ({
    type: actionTypes.DELETE_DECLARATION_LIGNE_SAGA,
    index,
    ligne
});

export const revalidLigne = (index, ligne) => ({
    type: actionTypes.REVALID_DECLARATION_LIGNE,
    index,
    ligne
});

export const revalidLigneSaga = (index, ligne) => ({
    type: actionTypes.REVALID_DECLARATION_LIGNE_SAGA,
    index,
    ligne
});

export const reinitializeDeclarationData = (reinitDeclarant = true) => ({
    type: actionTypes.REINITIALIZE_DECLARATION_DATA,
    reinitDeclarant
});

export const saveDeclarationsSuccess = (wrapper, index, applySortByDateModif) => ({
    type: actionTypes.SAVE_DECLARATIONS_SUCCESS,
    wrapper,
    index,
    applySortByDateModif
});

export const updateDeclarationsEntetesByNumeroDeclaration = (numeroDeclaration, toBeAbandon) => ({
    type: actionTypes.UPDATE_DECLARATION_ENTETE_LIST,
    numeroDeclaration,
    toBeAbandon
});

export const updateEnableUpdateStore = flag => ({
    type: actionTypes.UPDATE_ENABLE_UPDATE_STORE,
    flag
});

export const reinitPartenaire = flag => ({
    type: actionTypes.REINIT_PARTENAIRE,
    flag
});

export const validateOneDeclarationEnEtatIntegrationExcel = (numeroDeclaration, profil) => ({
    type: actionTypes.VALIDATE_ONE_DECLARATION_EN_ETAT_INTEGRATION_EXCEL,
    numeroDeclaration,
    profil
});

export const validateOneDeclarationEnEtatMesDeclarations = (numeroDeclaration, profil) => ({
    type: actionTypes.VALIDATE_ONE_DECLARATION_EN_ETAT_MES_DECLARATIONS,
    numeroDeclaration,
    profil
});

export const setDeclarationLoading = loading => ({
    type: actionTypes.SET_DECLARATION_LOADING,
    loading
});

export const updateDeclarationIdWrapperInfo = idWrapper => ({
    type: actionTypes.UPDATE_DECLARATION_ID_WRAPPER_INFO,
    idWrapper
});

export const updateDeclarationReadOnly = readOnly => ({
    type: actionTypes.UPDATE_DECLARATION_READ_ONLY,
    readOnly
});

export const reinitDeclarationTime = () => ({
    type: actionTypes.REINIT_DECLARATION_TIME
});

export const updateTypeDeclaration = typeDeclaration => ({
    type: actionTypes.RELOAD_TYPE_DECLARATION,
    typeDeclaration
});

export const updateDeclarationGlobale = globale => ({
    type: actionTypes.UPDATE_DECLARATION_GLOBALE,
    globale
});

export const setTvaForEntete = index => ({
    type: actionTypes.SET_TVA_FOR_ENTETE,
    index
});

export const setTvaForEnteteResult = (index, tva) => ({
    type: actionTypes.SET_TVA_FOR_ENTETE_RESULT,
    index,
    tva
});


export const updateDisableSendButton = flag => ({
    type: actionTypes.UPDATE_DISABLE_SEND_BUTTON,
    flag
});

export const updateDisableSendButtonLignes = flag => ({
    type: actionTypes.UPDATE_DISABLE_SEND_BUTTON_LIGNES,
    flag
});
