export const CODE_PAYS_FRANCE = "10000";
export const CODE_PAYS_GUADELOUPE = "10001";
export const CODE_PAYS_GUYANE = "10002";
export const CODE_PAYS_MARTINIQUE = "10003";
export const CODE_PAYS_MAYOTTE = "10004";
export const CODE_PAYS_REUNION = "10007";
export const CODE_PAYS_ST_BARTHELEMY = "10011";
export const CODE_PAYS_ST_MARTIN = "10012";
export const CODE_PAYS_ILE_DE_CLIPPERTON = "10013";
export const CODE_PAYS_NLLE_CALEDONIE = "10005";
export const CODE_PAYS_POLYNESIE = "10006";
export const CODE_PAYS_TERRES_AUSTR = "10009";
export const CODE_PAYS_WALLIS_ET_FUTUNA = "10010";
export const LIST_CODE_PAYS_FRANCE = [
    CODE_PAYS_FRANCE,
    CODE_PAYS_GUADELOUPE,
    CODE_PAYS_GUYANE,
    CODE_PAYS_MARTINIQUE,
    CODE_PAYS_MAYOTTE,
    CODE_PAYS_REUNION,
    CODE_PAYS_ST_BARTHELEMY,
    CODE_PAYS_ST_MARTIN,
    CODE_PAYS_ILE_DE_CLIPPERTON,
    CODE_PAYS_NLLE_CALEDONIE,
    CODE_PAYS_POLYNESIE,
    CODE_PAYS_TERRES_AUSTR,
    CODE_PAYS_WALLIS_ET_FUTUNA
];

export const ISO_FRANCE = "FR";
