import * as actionTypes from "../actionTypes/actionTypes";

export const integrerTableur = (glnDeclarant, file, fileName, pathname) => ({
    type: actionTypes.INTEGRATION_DECLARATION_TABLEUR,
    glnDeclarant,
    file,
    fileName,
    pathname
});

export const getIntegrationsTableurByGlnDeclarant = glnDeclarant => ({
    type: actionTypes.GET_INTEGRATIONS_TABLEUR_BY_GLN_DECLARANT,
    glnDeclarant
});

export const getIntegrationsTableurByGlnDeclarantResults = integrations => ({
    type: actionTypes.GET_INTEGRATIONS_TABLEUR_BY_GLN_DECLARANT_RESULTS,
    integrations
});

export const deleteIntegrationTableur = (idIntegration, glnDeclarant, reloadIntegrationsList = true, pathname) => ({
    type: actionTypes.DELETE_INTEGRATION_TABLEUR,
    idIntegration,
    glnDeclarant,
    reloadIntegrationsList,
    pathname
});

export const setIntegrationsTableurRecapitulatifLoading = loading => ({
    type: actionTypes.SET_INTEGRATION_TABLEUR_RECAPITULATIF_LOADING,
    loading
});

export const setDeleteIntegrationsLoading = loading => ({
    type: actionTypes.UPDATE_DELETE_INTEGRATION_LOADING,
    loading
});

export const setIntegrationStatut = statut => ({
    type: actionTypes.SET_INTEGRATION_STATUT,
    statut
});

export const getIntegration = idIntegration => ({
    type: actionTypes.GET_INTEGRATION,
    idIntegration
});

export const getIntegrationResultSuccess = integration => ({
    type: actionTypes.GET_INTEGRATION_RESULT_SUCCESS,
    integration
});

export const getIntegrationResultFailed = () => ({
    type: actionTypes.GET_INTEGRATION_RESULT_FAILED
});

export const redirectToIntegrationFromNumeroDeclaration = numeroDeclaration => ({
    type: actionTypes.REDIRECT_TO_INTEGRATION_FROM_DECLARATION,
    numeroDeclaration
});

export const reinitIntegrationInfo = () => ({
    type: actionTypes.REINIT_INTEGRATION_INFO
});

export const downloadModeleExcel = gencodDeclarant => ({
    type: actionTypes.DOWNLOAD_MODELE_EXCEL,
    gencodDeclarant
});

export const downloadIntegrationFichierRecapitulatif = idIntegration => ({
    type: actionTypes.DOWNLOAD_RECAPITULATIF_INTEGRATION,
    idIntegration
});


