import { call, put, select } from "redux-saga/effects";
import moment from "moment";
import {
    selectMesPartenairesPageFavoris,
    selectMesPartenairesPageRecents,
    selectMesPartenairesPageSizeFavoris,
    selectMesPartenairesPageSizeRecents,
    selectMesPartenairesSortsFavoris,
    selectMesPartenairesSortsRecents,
    selectUtilisateurGencod,
    selectUtilisateurRoles
} from "../../../utils/selectors";
import {
    addPartenaireFavorisIfNotExistsService,
    checkIfPartenaireRecentService,
    exporterPartenairesService,
    getMesListesPartenairesService,
    getPartenairesFavorisService,
    getPartenairesRecentsService,
    removePartenaireFavorisService
} from "../../../services/mesPartenaires.service";
import * as actions from "../../actions/index.action";
import {
    LIBELLE_EXPORT_FOURNISSEUR_DE_LIVRE,
    LIBELLE_EXPORT_ORGANISME_DE_PRET,
    LIBELLE_MES_PARTENAIRES_AJOUT_BIB_NO_DUPLICATE,
    LIBELLE_MES_PARTENAIRES_AJOUT_BIB_WITH_DUPLICATE,
    LIBELLE_MES_PARTENAIRES_AJOUT_FOUR_NO_DUPLICATE,
    LIBELLE_MES_PARTENAIRES_AJOUT_FOUR_WITH_DUPLICATE,
    LIBELLE_MES_PARTENAIRES_ERREUR_CHARGEMENT_BIBS,
    LIBELLE_MES_PARTENAIRES_ERREUR_CHARGEMENT_FOURNIS,
    LIBELLE_MES_PARTENAIRES_ERREUR_EXPORT_BIBS,
    LIBELLE_MES_PARTENAIRES_ERREUR_EXPORT_FOURNIS
} from "../../../utils/libelleConstantes";
import { download } from "../../../utils/downloadUtil";
import { getFiltre } from "../../../components/mesPartenaires/util/mesPartenairesUtil";
import { checkIsFournisseurConnected } from "../../../utils/utility";
import { globalI18n } from "../../../i18n.conf";

export function *getFiltreFavoris() {
    const sortsFavoris = yield select(selectMesPartenairesSortsFavoris);
    const pageFavoris = yield select(selectMesPartenairesPageFavoris);
    const sizePageFavoris = yield select(selectMesPartenairesPageSizeFavoris);
    return getFiltre(sortsFavoris, pageFavoris, sizePageFavoris);
}

export function *getFiltreRecents() {
    const sortsRecents = yield select(selectMesPartenairesSortsRecents);
    const pageRecents = yield select(selectMesPartenairesPageRecents);
    const sizePageRecents = yield select(selectMesPartenairesPageSizeRecents);
    return getFiltre(sortsRecents, pageRecents, sizePageRecents);
}

export function *getMesPartenairesFavorisSaga() {
    yield put(actions.updateLoadingMesPartenairesFavoris(true));
    try {
        const gencodDeclarant = yield select(selectUtilisateurGencod);
        const filtre = yield call(getFiltreFavoris);
        const response = yield call(getPartenairesFavorisService, gencodDeclarant, filtre);
        const { mesPartenairesFavoris: partenaires, totalFavoris: total } = response.data;
        yield put(actions.getMesPartenairesFavorisSuccess(partenaires, total));
    } catch (error) {
        const roles = yield select(selectUtilisateurRoles);
        const isFournisseur = checkIsFournisseurConnected(roles);
        yield put(actions.openDialogNoRedirect(isFournisseur ? LIBELLE_MES_PARTENAIRES_ERREUR_CHARGEMENT_BIBS : LIBELLE_MES_PARTENAIRES_ERREUR_CHARGEMENT_FOURNIS, error.message));
        yield put(actions.updateLoadingMesPartenairesFavoris(false));
    }
}

export function *getMesPartenairesRecentsSaga() {
    yield put(actions.updateLoadingMesPartenairesRecents(true));
    try {
        const gencodDeclarant = yield select(selectUtilisateurGencod);
        const filtre = yield call(getFiltreRecents);
        const response = yield call(getPartenairesRecentsService, gencodDeclarant, filtre);
        const { mesPartenairesRecents: partenaires, totalRecents: total } = response.data;
        yield put(actions.getMesPartenairesRecentSuccess(partenaires, total));
    } catch (error) {
        const roles = yield select(selectUtilisateurRoles);
        const isFournisseur = checkIsFournisseurConnected(roles);
        yield put(actions.openDialogNoRedirect(isFournisseur ? LIBELLE_MES_PARTENAIRES_ERREUR_CHARGEMENT_BIBS : LIBELLE_MES_PARTENAIRES_ERREUR_CHARGEMENT_FOURNIS, error.message));
        yield put(actions.updateLoadingMesPartenairesRecents(false));
    }
}

export function *getMesListesPartenairesSaga() {
    yield put(actions.updateLoadingMesPartenaires(true));
    try {
        const gencodDeclarant = yield select(selectUtilisateurGencod);
        const filtreFavoris = yield call(getFiltreFavoris);
        const filtreRecents = yield call(getFiltreRecents);
        const response = yield call(getMesListesPartenairesService, gencodDeclarant, filtreFavoris, filtreRecents);
        const { mesPartenairesFavoris, totalFavoris, mesPartenairesRecents, totalRecents } = response.data;
        yield put(actions.getMesPartenairesFavorisSuccess(mesPartenairesFavoris, totalFavoris));
        yield put(actions.getMesPartenairesRecentSuccess(mesPartenairesRecents, totalRecents));
    } catch (error) {
        const roles = yield select(selectUtilisateurRoles);
        const isFournisseur = checkIsFournisseurConnected(roles);
        yield put(actions.openDialogNoRedirect(isFournisseur ? LIBELLE_MES_PARTENAIRES_ERREUR_CHARGEMENT_BIBS : LIBELLE_MES_PARTENAIRES_ERREUR_CHARGEMENT_FOURNIS, error.message));
    }
    yield put(actions.updateLoadingMesPartenaires(false));
}


export function *addPartenaireToFavorisSaga(action) {
    try {
        const gencodDeclarant = yield select(selectUtilisateurGencod);
        let autoHideDuration;
        const response = yield call(addPartenaireFavorisIfNotExistsService, { gencodDeclarant, gencodFavoris: action.gencodFavoris });
        yield call(getMesListesPartenairesSaga);
        let messageSnackbar;
        if (response.data) {
            messageSnackbar = action.isFournisseur ? LIBELLE_MES_PARTENAIRES_AJOUT_BIB_NO_DUPLICATE : LIBELLE_MES_PARTENAIRES_AJOUT_FOUR_NO_DUPLICATE;
            autoHideDuration = 4000;
        } else {
            messageSnackbar = action.isFournisseur ? LIBELLE_MES_PARTENAIRES_AJOUT_BIB_WITH_DUPLICATE : LIBELLE_MES_PARTENAIRES_AJOUT_FOUR_WITH_DUPLICATE;
            autoHideDuration = 10000;
        }
        yield put(actions.updateFlagShowAndMessageSnackbar(true, messageSnackbar, {}, autoHideDuration));
    } catch (error) {
        yield put(actions.openDialogNoRedirect(action.isFournisseur ? LIBELLE_MES_PARTENAIRES_ERREUR_CHARGEMENT_BIBS : LIBELLE_MES_PARTENAIRES_ERREUR_CHARGEMENT_FOURNIS, error.message));
    }
}

export function *removePartenaireFromFavorisSaga({ gencodFavori }) {
    try {
        const gencodDeclarant = yield select(selectUtilisateurGencod);
        yield call(removePartenaireFavorisService, gencodDeclarant, gencodFavori);
        yield call(getMesListesPartenairesSaga);
    } catch (error) {
        const roles = yield select(selectUtilisateurRoles);
        const isFournisseur = checkIsFournisseurConnected(roles);
        yield put(actions.openDialogNoRedirect(isFournisseur ? LIBELLE_MES_PARTENAIRES_ERREUR_CHARGEMENT_BIBS : LIBELLE_MES_PARTENAIRES_ERREUR_CHARGEMENT_FOURNIS, error.message));
    }
}

export function getExportPartenairesFileName(isFournisseur) {
    const currentDate = moment().format(moment.DATETIME_LOCAL);
    const libelle = isFournisseur ? LIBELLE_EXPORT_ORGANISME_DE_PRET : LIBELLE_EXPORT_FOURNISSEUR_DE_LIVRE;
    const filePrefix = globalI18n._(libelle);
    return `Export${filePrefix}${currentDate} .xls`;
}

export function *exporterPartenairesSaga() {
    yield put(actions.updateLoadingExporterPartenaires(true));
    const roles = yield select(selectUtilisateurRoles);
    const isFournisseur = checkIsFournisseurConnected(roles);
    try {
        const gencodDeclarant = yield select(selectUtilisateurGencod);
        const response = yield call(exporterPartenairesService, gencodDeclarant);
        yield call(download, response.data, getExportPartenairesFileName(isFournisseur));
    } catch (error) {
        yield put(actions.openDialogNoRedirect(isFournisseur ? LIBELLE_MES_PARTENAIRES_ERREUR_EXPORT_BIBS : LIBELLE_MES_PARTENAIRES_ERREUR_EXPORT_FOURNIS, error.message));
    }
    yield put(actions.updateLoadingExporterPartenaires(false));
}
export function *checkIfPartenaireRecentSaga({ gencodFavori }) {
    try {
        const gencodDeclarant = yield select(selectUtilisateurGencod);
        const response = yield call(checkIfPartenaireRecentService, gencodDeclarant, gencodFavori);
        if (!response.data) {
            yield put(actions.updateShowDeleteFavoriPopup(true));
        } else {
            yield put(actions.removePartenaireFromFavoris(gencodFavori));
        }
    } catch (error) {
        const roles = yield select(selectUtilisateurRoles);
        const isFournisseur = checkIsFournisseurConnected(roles);
        yield put(actions.openDialogNoRedirect(isFournisseur ? LIBELLE_MES_PARTENAIRES_ERREUR_EXPORT_BIBS : LIBELLE_MES_PARTENAIRES_ERREUR_EXPORT_FOURNIS, error.message));
    }
}
