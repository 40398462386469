import { isAdminRole, isBlank, isForFirstConnectionOrForcage } from "./utility";

export const selectState = stateRedux => stateRedux;
export const selectDeclarationLoading = state => state.declaration.loading;
export const selectDeclarationDeclaration = state => state.declaration.declaration;
export const selectDeclarationDeclarationEntetes = state => state.declaration.declaration.validEntetes;
export const selectDeclarationDeclarationEntetesLength = state => state.declaration.declaration.validEntetes.length;
export const selectDeclarationDeclarationFirstEntete = state => state.declaration.declaration.validEntetes[0];
export const selectDeclarationDeclarationLignes = state => state.declaration.declaration.validLignes;
export const selectDeclarationDeclarationLignesLength = state => state.declaration.declaration.validLignes.length;
// Gère le forcage du mode lecture pour une déclaration invalide ou facturée dans la popup de MD
export const selectDeclarationDeclarationReadOnly = state => state.declaration.declaration.readOnly;
export const selectDeclarationOrder = state => state.declaration.order;
export const selectDeclarationPartenaire = state => state.declaration.partenaire;
export const selectDeclarationPartenaireGencod = state => state.declaration.partenaire.gencod;
export const selectDeclarationDeclarant = state => state.declaration.declarant;
export const selectDeclarationDeclarantGencod = state => state.declaration.declarant.gencod;
export const selectValidEntetes = state => state.declaration.declaration.validEntetes;
export const selectValidLignes = state => state.declaration.declaration.validLignes;
export const selectCodePostalFournisseur = state => state.declaration.codePostalFournisseur;
export const selectUtilisateur = state => state.utilisateur;
export const selectUtilisateurInformations = state => state.utilisateur.informations;
export const selectUtilisateurGencod = state => state.utilisateur.informations.gencod;
export const selectUtilisateurPremiereConnexion = state => state.utilisateur.informations.premiereConnexion;
export const selectUtilisateurPreferenceSize = state => state.utilisateur.informations.preferenceSizePagination;
export const selectUtilisateurCanMakeGlobaleDeclaration = state => state.utilisateur.informations.canMakeDeclarationGlobale;
export const selectUtilisateurPremierConnexionStepPremiereConnexion = state => state.utilisateur.informations.premiereConnexion.stepPremiereConnexion;
export const selectUtilisateurRoles = state => state.utilisateur.roles;
export const selectUtilisateurRegroupements = state => state.utilisateur.regroupements;
export const selectLogin = state => state.login;
export const selectLoginUserInfosAuthorities = state => state.login.userInfos.authorities;
export const selectLoginToken = state => state.login.token;
export const selectRootPath = state => state.admin.usurperRedirectPath !== "/" ? state.admin.usurperRedirectPath : state.login.loginRedirectPath;
export const selectPreferenceSize = state => state.utilisateur.informations.preferenceSizePagination;
export const selectIntegrationEntetes = state => state.integrationTableurDeclaration.declarations.entetes;
export const selectIntegrationDeclarations = state => state.integrationTableurDeclaration.declarations;
export const selectIntegrationChoixEntetes = state => state.integrationTableurChoixEntete;
export const selectMesDeclarationsEntetes = state => state.mesDeclarations.entetes;

export const selectAnneeDebut = state => state.mesDeclarations.anneeDebut;
export const selectAnneeFin = state => state.mesDeclarations.anneeFin;
export const selectMesDeclarationsStatistiques = state => state.mesDeclarations.statistiques;
export const selectMesDeclarationsNombreDeclarationParTYpe = state => state.mesDeclarations.nombreDeclarationParType;
export const selectMesDeclarationsTypeDeclaration = state => state.mesDeclarations.typeDeclaration;

export const selectDeclarationsTypeDeclaration = state => state.declaration.typeDeclaration;
export const selectMesDeclarationsLoading = state => state.mesDeclarations.loading;
export const selectMesDeclarationsGlnDeclarant = state => state.mesDeclarations.glnDeclarant;
export const selectMesDeclarationsSources = state => state.mesDeclarations.filtresSource;
export const selectMesDeclarationsNumeroFacture = state => state.mesDeclarations.numeroFacture;
export const selectMesDeclarationsSourcesSelectionnees = state => state.mesDeclarations.sourcesDeclaration;
export const selectMesDeclarationsLoadingStatistiquesMontants = state => state.mesDeclarations.loadingStatistiquesMontants;
export const selectMesDeclarationsShowStatistiquesMontants = state => state.mesDeclarations.showStatistiquesMontants;
export const selectMesDeclarationsShowOnlyInvalideDeclaration = state => state.mesDeclarations.onlyInvalidDeclaration;
export const selectMesDeclarationsFiltreTypeAnne = state => state.mesDeclarations.typeAnnee;
export const selectMesFactures = state => state.mesFactures;
export const selectMesFacturesFiltres = state => state.mesFactures.filtre;
export const selectNotifications = state => state.notifications;

export const selectNotificationsMail = state => state.notifications.modificationsMailContact;
export const selectMonProfil = state => state.monProfil;
export const selectMonProfilCompteUtilisateur = state => state.monProfil.compteUtilisateur;
export const selectMonProfilContacts = state => state.monProfil.contacts;
export const selectMonProfilLoading = state => state.monProfil.loading;
export const selectMonProfilDataNotSaved = state => state.monProfil.dataNotSaved;
export const selectMonProfilSkipStep = state => state.monProfil.skipStep;
export const selectMonProfilActiveStep = state => state.monProfil.currentStepPremiereCo;
export const selectMonProfilSteps = state => state.monProfil.steps;
export const selectLibelle = state => state.libelle;
export const selectCommon = state => state.common;
export const selectCommonFilepond = state => state.common.filepond;
export const selectGlobalDialog = state => state.globalDialog;
export const selectMesPartenairesLoadingExport = state => state.mesPartenaires.loadingExport;
export const selectMesPartenairesLoadingScreen = state => state.mesPartenaires.loadingScreen;
export const selectMesPartenairesFavoris = state => state.mesPartenaires.favoris;
export const selectMesPartenairesLoadingFavoris = state => state.mesPartenaires.loadingFavoris;
export const selectMesPartenairesTotalFavoris = state => state.mesPartenaires.totalFavoris;
export const selectMesPartenairesSortsFavoris = state => state.mesPartenaires.sortsFavoris;
export const selectMesPartenairesPageFavoris = state => state.mesPartenaires.pageFavoris;
export const selectMesPartenairesPageSizeFavoris = state => state.mesPartenaires.sizePageFavoris;
export const selectMesPartenairesRecents = state => state.mesPartenaires.recents;
export const selectMesPartenairesLoadingRecents = state => state.mesPartenaires.loadingRecents;
export const selectMesPartenairesTotalRecents = state => state.mesPartenaires.totalRecents;
export const selectMesPartenairesSortsRecents = state => state.mesPartenaires.sortsRecents;
export const selectMesPartenairesPageRecents = state => state.mesPartenaires.pageRecents;
export const selectMesPartenairesPageSizeRecents = state => state.mesPartenaires.sizePageRecents;
export const selectShowDeleteFavoriPopup = state => state.mesPartenaires.showDeleteFavoriPopup;
export const selectInscriptionState = state => state.inscription;
export const selectInscriptionIsFormulaireStartedState = state => state.inscription.isFormulaireStarted;
export const selectInscriptionLoading = state => state.inscription.loading;
export const selectStepHiddenInscriptionRdp = state => state.stepForm.hiddenInscriptionRdp;
export const selectStepState = state => state.stepForm;
export const selectRechercheGlnState = state => state.rechercheGLN;
export const selectReferencementState = state => state.demandeReferencement;
export const selectReferencementSelectedLoading = state => state.demandeReferencement.loadingSelected;

export const selectDemandeReferencementDataNotSaved = state => state.demandeReferencement.dataNotSaved;
export const selectAttestationFinState = state => state.attestationFin;
export const selectAdminIsGlnUsurpe = state => !isBlank(state.utilisateur.informations.gencod);
export const selectIsAuthenticated = state => selectLoginToken(state) !== null;
export const selectShowMenu = state => {
    const isAuthenticatedOrUsurpe = isAdminRole(selectLoginUserInfosAuthorities(state)) ? selectAdminIsGlnUsurpe(state) : selectIsAuthenticated(state);
    return isAuthenticatedOrUsurpe && !isForFirstConnectionOrForcage(selectUtilisateurPremiereConnexion(state), selectMonProfilActiveStep(state));
};
export const selectMesStatistiques = state => state.statistiquesDeclarations;
export const selectLoadingIntegrationRecapitulatif = state => state.integrationTableurRecapitulatif.loading;
export const selectLoadingDeleteIntegration = state => state.integrationTableur.deleteLoading;
