import * as actionTypes from "../../actionTypes/actionTypes";
import { updateObject } from "../../../utils/utility";

const initialState = {
    libelles: []
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.GET_LIBELLES_SUCCESS:
        return updateObject(state, { libelles: [...state.libelles, ...action.datas] });
    default:
        return state;
    }
};

export default reducer;
