import { call, put } from "redux-saga/effects";
import { createJWTHelper } from "jwthelper";
import * as actions from "../../actions/index.action";
import {
    BAD_CREDENTIALS,
    CLIENT_AUTH_GRANT_TYPE,
    CLIENT_AUTH_PASSWORD,
    DISABLED_USER,
    ERREUR_DISABLED_USER_LIBELLE,
    ERREUR_SIGN_INTERN,
    ERREUR_SIGN_UP,
    ERREUR_TEMPORARILY_DISABLED_USER,
    MDP_NULL_MESSAGE,
    ODP_NON_ASSUJETTI,
    ROLES,
    SessionVariablesEnum,
    USER_COMPTE_CLOS, USER_NOT_FOUND,
    USER_PNB,
    USER_PNB_WITH_PARENT
} from "../../../utils/constantes";
import { getPathFromProfil, getRedirectURl } from "../../../utils/utility";
import { login } from "../../../services/login.service";
import { globalI18n } from "../../../i18n.conf";
import { getInformationsDeclarantSaga } from "../utilisateur/utilisateur.saga";
import { getListeUrlAide } from "../../../services/util.service";
import { ERREUR_GET_LISTE_URL_AIDE } from "../../../utils/libelleConstantes";


export function *logoutSaga() {
    yield sessionStorage.removeItem(SessionVariablesEnum.TOKEN_NAME);
    yield sessionStorage.removeItem(SessionVariablesEnum.USER_INFOS);
    yield sessionStorage.removeItem(SessionVariablesEnum.DECLARANT_USURPE);
}


export function *loginStoreInitialisation(userInfos, token, baseUrl) {
    const roles = [...userInfos.authorities];
    const isRoleAdmin = roles.find(r => r === ROLES.ADMIN);
    /*
     * Si l'utilisateur n'est pas un admin alors on va récupérer ses informations de déclarant
     * Dans le cas contraire, cette récupération se fera lors de l'usurpation
     */
    if (!isRoleAdmin) {
        yield call(getInformationsDeclarantSaga, { gencod: userInfos.sub });
        yield put(actions.getAllNotifications(userInfos.sub, true));
    }
    /*
     * Ajout de la liste des urls pour les aides utilisateur
     *
     */
    try {
        const response = yield call(getListeUrlAide);
        yield put(actions.updateUrlAideUtilisateur(response.data));

        const loginRedirectPath = getPathFromProfil(userInfos);
        let suffixRedirectPath;
        if (baseUrl) {
            suffixRedirectPath = getRedirectURl(baseUrl, loginRedirectPath);
        }
        yield put(actions.loginSuccess(userInfos, token, loginRedirectPath, suffixRedirectPath));
        yield put(actions.updateReloading(true));
    } catch (error) {
        yield put(actions.openDialog(ERREUR_GET_LISTE_URL_AIDE, true, error.message));
    }
}


export function *loginSaga(action) {
    yield put(actions.loginStart());
    /* Initialiser le store redux au cas où l'utilisateur a changé du profil en gardant le même onglet ouvert */
    yield put(actions.updatePartenaireInfo("", "", ""));
    yield put(actions.updateResultsGLN([]));

    const jwtHelper = createJWTHelper({
        secret: CLIENT_AUTH_PASSWORD
    });

    const body = {
        username: action.username,
        password: action.password,
        grant_type: CLIENT_AUTH_GRANT_TYPE
    };


    function *checkBibliothequePNB(errorMessage) {
        const gencodParent = errorMessage.split(" ")[1];
        const message = gencodParent ? globalI18n._({
            id: USER_PNB_WITH_PARENT,
            values: { gencodParent }
        }) : globalI18n._(USER_PNB);
        const actionRedux = gencodParent ? actions.loginReplace(gencodParent) : actions.loginFail();
        yield put(actionRedux);
        yield put(actions.openDialogNoRedirect(ERREUR_SIGN_UP, message));
    }

    try {
        const response = yield call(login, body);
        yield sessionStorage.setItem(SessionVariablesEnum.TOKEN_NAME, response.data.access_token);
        const userInfos = jwtHelper.decode(response.data.access_token);
        sessionStorage.setItem(SessionVariablesEnum.USER_INFOS, JSON.stringify(userInfos));
        yield loginStoreInitialisation(userInfos, response.data.access_token, action.baseUrl);
    } catch (error) {
        if (error.response) {
            if (error.response.status >= 500) {
                yield put(actions.loginFail());
                yield put(actions.openDialogNoRedirect(ERREUR_SIGN_INTERN));
            } else {
                const errorMessage = error.response.data.error_description;
                if ((errorMessage.includes(USER_COMPTE_CLOS))) {
                    yield put(actions.openDialogNoRedirect(ERREUR_SIGN_UP, globalI18n._(USER_COMPTE_CLOS)));
                    yield put(actions.loginFail());
                } else if (errorMessage.includes(ODP_NON_ASSUJETTI)) {
                    yield put(actions.openDialogNoRedirect(ERREUR_SIGN_UP, globalI18n._(ODP_NON_ASSUJETTI)));
                    yield put(actions.loginFail());
                } else if (errorMessage.includes(DISABLED_USER)) {
                    const gencod = errorMessage.split(" ")[1];
                    yield put(actions.loginReplace(gencod));
                    yield put(actions.openDialogNoRedirect(ERREUR_SIGN_UP, globalI18n._({
                        id: DISABLED_USER,
                        values: { gencod }
                    })));
                    yield put(actions.reinitLoginRedirectPath());
                } else if (errorMessage.includes(BAD_CREDENTIALS)) {
                    yield put(actions.loginFail());
                    yield put(actions.openDialogNoRedirect(ERREUR_SIGN_UP, globalI18n._(BAD_CREDENTIALS)));
                } else if (errorMessage.includes(ERREUR_DISABLED_USER_LIBELLE)) {
                    yield put(actions.loginFail());
                    yield put(actions.openDialogNoRedirect(ERREUR_SIGN_UP, globalI18n._(ERREUR_TEMPORARILY_DISABLED_USER)));
                } else if (errorMessage.includes(USER_PNB)) {
                    yield call(checkBibliothequePNB, errorMessage);
                } else if (errorMessage.includes(USER_NOT_FOUND)) {
                    const gencod = errorMessage.split(" ")[1];
                    yield put(actions.openDialogNoRedirect("attentionTitre", globalI18n._({ id: USER_NOT_FOUND, values: { gencod } })));
                    yield put(actions.usurperUnauthorized(true));
                } else if (errorMessage.includes(MDP_NULL_MESSAGE)) {
                    yield put(actions.loginFail());
                    yield put(actions.openDialogNoRedirect(ERREUR_SIGN_UP, globalI18n._(MDP_NULL_MESSAGE)));
                } else {
                    yield put(actions.loginFail());
                    yield put(actions.openDialogNoRedirect(ERREUR_SIGN_UP, errorMessage));
                }
            }
        } else {
            yield put(actions.loginFail());
            yield put(actions.openDialogNoRedirect(ERREUR_SIGN_UP));
        }
    }
}

export function *loginCheckStateSaga(action) {
    yield put(actions.updateReloading(false));
    const userInfosString = yield sessionStorage.getItem(SessionVariablesEnum.USER_INFOS);
    let userInfos;
    if (userInfosString) {
        userInfos = JSON.parse(userInfosString);
    }

    // On déconnecte l’utilisateur s’il n’y a plus ses informations dans la session ou si le token a expiré
    let isTokenExpired = false;
    if (userInfos) {
        const expirationDate = new Date();
        expirationDate.setTime(userInfos.exp * 1000);
        isTokenExpired = expirationDate < new Date();
    }

    if (!userInfos || isTokenExpired) {
        yield put(actions.updateReloadingSite(true));
        yield put(actions.logout());
    } else {
        // Reconnexion
        yield put(actions.updateReloadingSite(false));
        const token = yield sessionStorage.getItem(SessionVariablesEnum.TOKEN_NAME);
        yield loginStoreInitialisation(userInfos, token, action.pathname);

        // Pour l’admin, on essaye en plus de récupérer le dernier déclarant usurpé
        const declarantUsurpeString = sessionStorage.getItem(SessionVariablesEnum.DECLARANT_USURPE);
        if (declarantUsurpeString) {
            const declarantUsurpe = JSON.parse(declarantUsurpeString);
            if (declarantUsurpe) {
                yield put(actions.usurper(declarantUsurpe.gencod));
            }
        }
    }
}
