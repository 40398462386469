import { parseInt } from "lodash/string";
import { locales } from "../i18n.conf";
import { getLocale } from "./localeUtil";

export function getLibelle({ libelleEn = "", libelleFr = "" }) {
    const locale = getLocale();
    if (locale === locales.en.code) {
        return libelleEn;
    }
    return libelleFr;
}

export function compareLibelles(l1, l2) {
    return getLibelle(l1).localeCompare(getLibelle(l2));
}

export function compareLibellesByCode(l1, l2) {
    return parseInt(l1.code) > parseInt(l2.code);
}

export function getLibelleFromCodeAndType(code, type, libelles) {
    const libellesTypes = libelles.filter(l => l.type === type);
    const libelle = libellesTypes.find(l => l.code === code.toUpperCase());
    return libelle ? getLibelle(libelle) : "";
}
