import axios from "axios";
import { getQueryString } from "../utils/objectUtil";
import { downloadFileFromResponse } from "../utils/downloadUtil";

export const integrerTableurFile = formData => axios.post("/integrationFichier/uploadIntegrationFile", formData);

export const getIntegrationsByGlnDeclarant = glnDeclarant => axios.get("/integrationFichier/getIntegrationsByGlnDeclarant", { params: { glnDeclarant } });

export const deleteIntegration = idIntegration => {
    const data = {
        idIntegration
    };
    return axios.post("/integrationFichier/deleteIntegration", getQueryString(data), { headers: { "Content-Type": "application/x-www-form-urlencoded" } });
};

export const getEchantillonEntetesIdentifieesEtAttenduesByIdIntegration = idIntegration => axios.get("/integrationFichier/getEntetesIdentifieesAttenduesAndEchantillon", {
    params: {
        idIntegration
    }
});

export const isNomFichierAvailable = (glnDeclarant, nomFichier) => axios.get("/integrationFichier/isNomFichierAvailable", {
    params: {
        glnDeclarant,
        nomFichier
    }
});

export const confirmeEntetesChoisies = (entetesSelectionnees, idIntegration) => {
    const formData = new FormData();
    formData.set("entetesChoisies", entetesSelectionnees);
    formData.set("idIntegration", idIntegration);
    return axios.post("/integrationFichier/confirmEntetes", formData);
};

export const getLignesByIdIntegration = idIntegration => axios.get("/integrationFichier/getLignesByIdIntegration", {
    params: {
        idIntegration
    }
});

export const getEntetesChoisiesByIdIntegration = idIntegration => axios.get("/integrationFichier/getEntetesChoisiesByIdIntegration", {
    params: {
        idIntegration
    }
});

export const getIntegration = idIntegration => axios.get("/integrationFichier/getIntegration", {
    params: {
        idIntegration
    }
});

export const saveAndGetLignesForIdIntegration = (lignes, idIntegration) => {
    const formData = new FormData();
    formData.append("lignes", JSON.stringify(lignes));
    formData.append("idIntegration", idIntegration);
    return axios.post("/integrationFichier/saveAndGetLignesForIdIntegration", formData);
};

export const deleteAndGetStatisticsForIdIntegration = (numerosLignesASupprimer, idIntegration) => {
    const formData = new FormData();
    formData.append("listNumeroLigneASupprimer", JSON.stringify(numerosLignesASupprimer));
    formData.append("idIntegration", idIntegration);
    return axios.post("/integrationFichier/deleteAndGetStatisticsForIdIntegration", formData);
};

export const createDeclarationsForIdIntegration = idIntegration => {
    const formData = new FormData();
    formData.append("idIntegration", idIntegration);
    return axios.post("/integrationFichier/createDeclarationsForIdIntegration", formData);
};


export const getListDeclarationsEnteteByIdIntegration = idIntegration => axios.get("/integrationFichier/getDeclarationEntetesConvertiesList", { params: { idIntegration } });

export const getListDeclarationsLigneByNumeroDeclaration = numeroDeclaration => axios.get("/integrationFichier/getDeclarationLignesConvertiesList", { params: { numeroDeclaration } });

export const sendIntegrationEnEtat = idIntegration => {
    const formData = new FormData();
    formData.append("idIntegration", idIntegration);
    return axios.post("/integrationFichier/envoiIntegrationEnEtat", formData);
};

export const getFichierRecapitulatifByIdIntegration = idIntegration => {
    const requestConfig = {
        params: { idIntegration },
        responseType: "blob"
    };
    // eslint-disable-next-line
    return axios.get("/integrationFichier/getFichierRecapitulatif", requestConfig).then(downloadFileFromResponse(`FichierRecapitulatif${idIntegration}.xls`));
};

export const getStatistiquesRecapitulatifByIdIntegration = idIntegration => axios.get("/integrationFichier/getStatistiquesRecapitulatif", { params: { idIntegration } });

export const getIntegrationIdFromNumeroDeclarationFournisseur = numeroDeclaration => axios.get("/integrationFichier/getIntegrationIdFromNumeroDeclarationFournisseur", { params: { numeroDeclaration } });
export const getIntegrationIdFromNumeroDeclarationBibliotheque = numeroDeclaration => axios.get("/integrationFichier/getIntegrationIdFromNumeroDeclarationBibliotheque", { params: { numeroDeclaration } });

export const downloadModele = gencod => axios.get("/integrationFichier/getModeleExcel", {
    params: {
        gencodDeclarant: gencod
    },
    responseType: "blob"
}).then(downloadFileFromResponse());
