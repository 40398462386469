import { call, put } from "redux-saga/effects";
import { searchGLN } from "../../../services/rechercheGLN.service";

import * as actions from "../../actions/index.action";
import { contactStrings } from "../../../utils/stringUtil";
import { PROFIL_BIBLIOTHEQUE } from "../../../utils/constantes";
import {
    LIBELLE_CHAINE_VIDE,
    LIBELLE_ERREUR_FOURNISSEUR_COMPTE_CLOS,
    LIBELLE_ERREUR_GLN,
    LIBELLE_TABLE_NO_RESULT,
    LIBELLE_UNKNOWN_LIBRARY,
    LIBELLE_UNKNOWN_PROVIDER
} from "../../../utils/libelleConstantes";

export function *rechercheGLNSaga(action) {
    yield put(actions.rechercheGLNStart());
    const profil = action.profil;
    const filter = action.filter;
    try {
        const response = yield call(searchGLN, profil, filter);

        const { data } = response;

        const tableNoResultMessage = data.length > 0 ? LIBELLE_CHAINE_VIDE : LIBELLE_TABLE_NO_RESULT;
        yield put(actions.updateResultsGLNAndActiveButton(data));
        yield put(actions.rechercheGLNEnd(tableNoResultMessage));
    } catch (error) {
        yield put(actions.rechercheGLNFailed(LIBELLE_ERREUR_GLN, error.message));
    }
}

export function *rechercheInfoPartenaire(action) {
    const profil = action.profil;
    const filter = action.filtre;
    try {
        const response = yield call(searchGLN, profil, filter);
        const { data } = response;
        if (data.length > 0) {
            // Dans le cas où le partenaire est un compte fermé on affiche une popup pour prévenir l'usager
            if (action.onlyGencod && data[0].compteClos) {
                yield put(actions.openDialog(LIBELLE_ERREUR_FOURNISSEUR_COMPTE_CLOS));
            } else {
                const raisonSociale = contactStrings(data[0].raisonSociale1, data[0].raisonSociale2);
                yield put(actions.updatePartenaireInfo(data[0].gencod, raisonSociale, data[0].ville, data[0].codePostal, action.save));
            }
        } else {
            const keyMessage = (profil === PROFIL_BIBLIOTHEQUE) ? LIBELLE_UNKNOWN_LIBRARY : LIBELLE_UNKNOWN_PROVIDER;
            yield put(actions.openDialogNoRedirect(keyMessage));
        }
    } catch (error) {
        yield put(actions.openDialogNoRedirect(LIBELLE_ERREUR_GLN, error.message));
    }
}

