import React, { useEffect, useRef } from "react";
import { format } from "date-fns";
import { isEqual, isNil } from "lodash";
import { Trans } from "@lingui/macro";
import moment from "moment";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { KeyboardBackspace } from "@material-ui/icons";
import { PATH_ADMIN, PATH_BIBLIO_MENU, PATH_FOURNI_MENU } from "../routes/paths/paths.util";
import { MON_PROFIL_STEP_FIN, MON_PROFIL_STEP_FORCAGE_FIN } from "../components/monProfil/monProfilUtil";
import * as actions from "../store/actions/index.action";
import { globalI18n } from "../i18n.conf";
import {
    DATE_DELIMITER,
    DATE_FORMAT,
    ENVOYEE,
    FACTUREE,
    MASK_CHAR,
    MAX_HT,
    MAX_TTC,
    PROFIL_BIBLIOTHEQUE,
    PROFIL_FOURNISSEUR,
    ROLES,
    SAUVEGARDE,
    STATUS_BAD_REQUEST,
    STATUS_FORBIDDEN
} from "./constantes";
import { isValidDeclarationDate } from "./objectUtil";
import { removeZeroFirstChar } from "./stringUtil";
import { inscriptionContact } from "./model";
import {
    LIBELLE_ARTICLE_DE_L,
    LIBELLE_ARTICLE_DES,
    LIBELLE_ARTICLE_DU,
    LIBELLE_INSCRIPTION_PROFIL_BIBLIOTHEQUE,
    LIBELLE_INSCRIPTION_PROFIL_BIBLIOTHEQUES,
    LIBELLE_INSCRIPTION_PROFIL_FOURNISSEUR,
    LIBELLE_INSCRIPTION_PROFIL_FOURNISSEURS,
    LIBELLE_MES_PARTENAIRES_BIB,
    LIBELLE_MES_PARTENAIRES_FOURNI
} from "./libelleConstantes";


export const isString = value => typeof value === "string" || value instanceof String;

export const updateObject = (oldObject, updatedProperties) => ({
    ...oldObject,
    ...updatedProperties
});

export const isAdminRole = roles => (roles && roles.includes(ROLES.ADMIN)) || false;

export const isFournisseurRole = role => role === ROLES.FOURNISSEUR;

export const isBibliothequeRole = role => role === ROLES.BIBLIOTHEQUE;

export const isNotBlank = str => Boolean(str && str.toString().trim().length > 0);

export const isBlank = str => Boolean(!str || str.toString().trim().length === 0);

export const checkProfilConnected = utilisateurRoles => {
    const roleBibliotheque = utilisateurRoles.find(r => r.type === ROLES.BIBLIOTHEQUE);
    const roleFournisseur = utilisateurRoles.find(r => r.type === ROLES.FOURNISSEUR);

    if (roleBibliotheque) {
        return roleBibliotheque.type;
    } else if (roleFournisseur) {
        return roleFournisseur.type;
    }
    return null;
};

export const checkIsBibliothequeConnected = utilisateurRoles => checkProfilConnected(utilisateurRoles) === ROLES.BIBLIOTHEQUE;

export const checkIsFournisseurConnected = utilisateurRoles => checkProfilConnected(utilisateurRoles) === ROLES.FOURNISSEUR;

export const getPartenaireIsFourniLibelle = (isFourni, pluriel = false) => {
    const libelle = isFourni
        ? pluriel ? LIBELLE_INSCRIPTION_PROFIL_BIBLIOTHEQUES : LIBELLE_INSCRIPTION_PROFIL_BIBLIOTHEQUE
        : pluriel ? LIBELLE_INSCRIPTION_PROFIL_FOURNISSEURS : LIBELLE_INSCRIPTION_PROFIL_FOURNISSEUR;
    return globalI18n._(libelle);
};

export const getMenuPartenaireLibelleId = isFourni => isFourni ? LIBELLE_MES_PARTENAIRES_BIB : LIBELLE_MES_PARTENAIRES_FOURNI;

export const getArticlePartitifForTypeEntite = (isFourni, pluriel = false) => {
    if (pluriel) {
        return LIBELLE_ARTICLE_DES;
    }
    const article = isFourni
        ? LIBELLE_ARTICLE_DU : LIBELLE_ARTICLE_DE_L;
    return globalI18n._(article);
};

export const getPartenaireLibelle = (utilisateurRoles, pluriel = false) => {
    const isFourni = checkIsFournisseurConnected(utilisateurRoles);
    return getPartenaireIsFourniLibelle(isFourni, pluriel);
};

export const getPrefixUrl = utilisateurRoles => {
    const role = checkProfilConnected(utilisateurRoles);
    switch (role) {
    case ROLES.BIBLIOTHEQUE:
        return PATH_BIBLIO_MENU;
    case ROLES.FOURNISSEUR:
        return PATH_FOURNI_MENU;
    default:
        return null;
    }
};

export const getPathFromDeclarantRoles = roles => {
    const isRoleBibliotheque = roles.find(r => r.type === ROLES.BIBLIOTHEQUE);
    return isRoleBibliotheque ? PATH_BIBLIO_MENU : PATH_FOURNI_MENU;
};

export const toNumber = valueAsStr => {
    if (!valueAsStr) {
        return valueAsStr;
    }
    return parseFloat(valueAsStr
        .toString()
        .replace(/[^\d.-]/g, "")
        .trim());
};

/**
 * Truncates n decimal digit of a number without rounding it.
 */
export const truncateNumber = (val, decimals) => {
    if (!val) {
        return val;
    }
    const truncateRegex = new RegExp(`^-?\\d+(?:.\\d{0,${decimals}})?`);
    return val.toString().match(truncateRegex)[0];
};

export const removeMinusFromValue = value => {
    if (value) {
        return value.replace(/-/g, "").trim();
    }
    return "";
};

export const removeSpacesFromValue = value => {
    if (value) {
        return value.replace(/ /g, "").trim();
    }
    return "";
};

export const formatNumberValue = value => {
    if (value === "-") {
        return value;
    }
    const nb = toNumber(value);
    if (!isNil(nb) && isFinite(nb)) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "\u00a0");
    }
    return "";
};

export const formatNumberValueForExcel = (value, defaultValue) => {
    if (value === null && defaultValue !== undefined) {
        return defaultValue;
    }
    return formatNumberValue(value);
};

export const unformatNumberValue = value => {
    const nb = toNumber(value);
    if (!isNil(nb) && isFinite(nb)) {
        return toNumber(nb.toString().replace(/ /g, "")
            .trim());
    }
    return "";
};

export const formatNumberWithScale = (number, scale) => Number(number).toFixed(scale);

export const getFormatterPrixEuros = value => `${formatNumberValue(formatNumberWithScale(value, 2))} €`;

export const calculTtc = (montantHtString, tva) => {
    let montantTtc;
    const montantHtNumber = toNumber(unformatNumberValue(montantHtString));
    if (montantHtString && isFinite(montantHtNumber) && isFinite(tva)) {
        const montantTva = montantHtNumber * tva / 100;
        montantTtc = formatNumberWithScale(montantHtNumber + montantTva, 2);
    } else {
        montantTtc = montantHtNumber;
    }
    return removeZeroFirstChar(montantTtc || "");
};

export const calculHt = (montantTtcString, tva) => {
    let montantHt;
    const montantTtcNumber = toNumber(unformatNumberValue(montantTtcString));
    if (montantTtcString && isFinite(montantTtcNumber) && isFinite(tva)) {
        montantHt = formatNumberWithScale(((100 * montantTtcNumber) / (100 + tva)), 2);
    } else {
        montantHt = montantTtcNumber;
    }
    return removeZeroFirstChar(montantHt || "");
};

export const calculLivreHt = montantHtString => {
    let montantLivreHt = 0;
    const montantHtNumber = toNumber(unformatNumberValue(montantHtString));
    if (montantHtNumber && isFinite(montantHtNumber)) {
        const montantLivreHT = montantHtNumber / 0.91;
        montantLivreHt = formatNumberWithScale(montantLivreHT, 2);
    }
    return removeZeroFirstChar(montantLivreHt);
};

export const calculateDdp = montantHtString => {
    let ddp = 0;
    const montantHtNumber = toNumber(unformatNumberValue(montantHtString));
    if (isFinite(montantHtNumber)) {
        ddp = formatNumberWithScale(6 * montantHtNumber / 100, 2);
    }
    return ddp;
};

export const getEnteteTva = entete => ((toNumber(entete.totalTtc.cellValue) - toNumber(entete.totalHt.cellValue)) * 100) / toNumber(entete.totalHt.cellValue);

/**
 * Format a date as string according to the specified format.
 *
 * @param {*} date date object
 * @param {*} dateFormat date format
 *
 * @return string that contains the formatted date
 */
export const formatDate = (date, dateFormat) => {
    if (!date || date.toString() === "Invalid Date" || !(date instanceof Date)) {
        return "";
    }
    let formattedDate = "";
    if (isNotBlank(date)) {
        try {
            formattedDate = format(date, dateFormat);
        } catch (e) {
            formattedDate = "";
        }
    }
    return formattedDate;
};

export const formatDate_DDMMYYYHHMMSS = date => date ? moment(date, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YY - H:mm:ss") : "";

export const formatDate_DDMMYYYY = date => date ? moment(date, "YYYY-MM-DD").format("DD/MM/YY") : "";
export const formatDate_HMMSS = date => date ? moment(date, "YYYY-MM-DDTHH:mm:ss").format("H:mm:ss") : "";

/**
 * Converts a string to date
 *
 * @param {*} stringDate string that should contain a date
 * @param {*} dateFormat date format
 * @param {*} delimiter  the date format delimiter
 *
 * @param maskChar
 * @return a date object
 */
export const stringToDate = (stringDate, dateFormat, delimiter, maskChar) => {
    if (!stringDate || !isString(stringDate) || stringDate.includes(maskChar) || !dateFormat || stringDate.length !== dateFormat.length) {
        return null;
    }
    try {
        const formatLowerCase = dateFormat.toLowerCase();
        const formatItems = formatLowerCase.split(delimiter);
        const dateItems = stringDate.split(delimiter);
        const monthIndex = formatItems.indexOf("mm");
        const dayIndex = formatItems.indexOf("dd");
        const yearIndex = formatItems.indexOf("yy");
        const day = parseInt(dateItems[dayIndex], 10);
        const month = parseInt(dateItems[monthIndex], 10) - 1; // Month JS value is between 0 (january) and 11 (december)
        if (month < 0 || month > 11 || day < 1 || day > 31) {
            return null;
        }
        const presumedDate = new Date(dateItems[yearIndex], month, day);
        if (presumedDate.getMonth() !== month || presumedDate.getDate() !== day) {
            return null;
        }
        return presumedDate;
    } catch (e) {
        return null;
    }
};

/**
 * Check if a string contains date according to the specified format.
 *
 * @param {*} stringDate string that should contain a date
 * @param {*} dateFormat date format
 * @param {*} delimiter  the date format delimiter
 * @param {*} maskChar   mask char
 *
 * @return true if the string contains a date, false otherwise
 */
export const isDateAsString = (stringDate, dateFormat, delimiter, maskChar) => Boolean(stringToDate(stringDate, dateFormat, delimiter, maskChar));

/**
 * Detect IE
 * returns version of IE or false, if browser is not Internet Explorer
 */
export const isIE = () => {
    const ua = window.navigator.userAgent;

    const msie = ua.indexOf("MSIE ");
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
    }

    const trident = ua.indexOf("Trident/");
    if (trident > 0) {
        // IE 11 => return version number
        const rv = ua.indexOf("rv:");
        return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
    }

    const edge = ua.indexOf("Edge/");
    if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
    }

    // Other browser
    return false;
};

export const prepareReduxAction = (typeAction, props, values) => {
    let returnResult = {};
    let counter = 0;
    returnResult = {
        type: typeAction
    };
    if (props && props.length > 0) {
        props.forEach(prop => {
            returnResult[[prop]] = values[counter];
            ++counter;
        });
    }
    return returnResult;
};

export const hasSubArray = (array, subArray) => {
    let allFound = true;
    for (const element of subArray) {
        if (!Object.values(array).includes(element)) {
            allFound = false;
            break;
        }
    }
    return allFound;
};

export const truncateFiled = value => value.substring(0, value.indexOf("."));

export const prepareLigne = ligne => ({
    ...ligne.ligne,
    numeroLigne: ligne.numeroLigne,
    canBeMergedByEan: ligne.valide === true,
    canBeMergedByTitleAndEditor: ligne.valide === true,
    valide: ligne.valide,
    validable: ligne.validable
});

export const updateMessageHttp = cause => {
    switch (cause) {
    case
        STATUS_FORBIDDEN:
        return <Trans id="errorForbidden"/>;
    case
        STATUS_BAD_REQUEST:
        return <Trans id="errorBadRequest"/>;
    default:
        return cause;
    }
};

export const withEnfants = regroupements => Boolean(regroupements && regroupements.filter(dec => dec.remplace === false).length > 0);

export const shouldDisplayDeclarantPanel = state => withEnfants(state.utilisateur.regroupements);

export const reducerDoute = (hasDoute, item) => hasDoute || Boolean(item && item.doutes && item.doutes.length > 0);


/**
 * Une fonction pour récuperer l'ancienne valeur d'une prop
 * @param value
 */
export function usePrevious(value) {
    /*
     * The ref object is a generic container whose current property is mutable ...
     * ... and can hold any value, similar to an instance property on a class
     */
    const ref = useRef();

    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes

    // Return previous value (happens before update in useEffect above)
    return ref.current;
}


export const isMaxValueExceeded = (value, maxValue) => maxValue && value > maxValue;
export const isMinValueExceeded = (value, minValue) => minValue && value < minValue;
export const validateMontant = valeur => valeur !== "" && valeur !== "-" && valeur.toString().match(new RegExp("^(-)?\\d{0,8}([\\.][\\d]{0,2})?$")) !== null;

export const getStatutEntete = entete => entete && entete.statut && entete.statut.cellValue;

export const isValidEntete = entete => {
    const { datePiece, numeroPiece, totalHt, totalTtc } = entete;

    if (!datePiece || !datePiece.cellValue || !numeroPiece || !numeroPiece.cellValue || !totalHt || !totalHt.cellValue || !totalTtc || !totalTtc.cellValue) {
        return false;
    }
    if (!validateMontant(totalHt.cellValue) || !validateMontant(totalTtc.cellValue)) {
        return false;
    }
    // Les données des cellValue sont de type string par défaut, on doit donc les caster pour faire les vérifications sur les montants
    const totalHtNumber = parseFloat(totalHt.cellValue);
    const totalTtcNumber = parseFloat(totalTtc.cellValue);

    const isDateFormatValid = isDateAsString(datePiece.cellValue, DATE_FORMAT, DATE_DELIMITER, MASK_CHAR);
    const isDateAllowed = isValidDeclarationDate(datePiece.cellValue);
    const isTotalHtValid = !isMaxValueExceeded(totalHtNumber, MAX_HT) && !isMinValueExceeded(totalHtNumber, -MAX_HT);
    const isTotalTtcValid = !isMaxValueExceeded(totalTtcNumber, MAX_TTC) && !isMinValueExceeded(totalTtcNumber, -MAX_TTC);
    return numeroPiece.cellValue && isDateFormatValid && isDateAllowed && isTotalHtValid && isTotalTtcValid;
};

export const isEnteteAbandonnee = entete => {
    if (entete) {
        const statutSauvegarde = entete.statut && entete.statut.cellValue === SAUVEGARDE;
        const invalide = entete.valide && entete.valide.cellValue === "false";
        return statutSauvegarde && invalide;
    }
    return false;
};

export const isClonePresent = wrappedEntete => {
    const existClone = wrappedEntete && Boolean(wrappedEntete.clone);
    const nonAbandonne = existClone && !isEnteteAbandonnee(wrappedEntete.clone.entete);
    return existClone && nonAbandonne;
};

export const isEnteteEnvoyeeOuFacturee = entete => entete && entete.statut && [ENVOYEE, FACTUREE].includes(entete.statut.cellValue);

export const isEnteteEnvoyeeWithDateEnvoiNull = entete => entete && entete.statut && ENVOYEE === entete.statut.cellValue && entete.dateRecap.cellValue === "";

export const isNotValidEntete = entete => entete && entete.statut && [SAUVEGARDE, ENVOYEE, FACTUREE].includes(entete.statut.cellValue) && entete.valide && entete.valide.cellValue === "false";

export const isEqualObjects = (object1, object2) => isEqual(object1, object2);

export const hasDoute = declaration => {
    if (declaration.validEntetes === undefined) {
        return true;
    }
    const entete = declaration.validEntetes[0];
    const douteEntete = entete !== undefined && Boolean(Object.values(entete).reduce(reducerDoute, false));
    const douteLignes = declaration.validLignes !== undefined &&
        declaration.validLignes.map(item => Object.values(item).reduce(reducerDoute, false))
            // eslint-disable-next-line no-shadow
            .reduce((hasDoute, val) => hasDoute || val, false);
    return douteEntete || douteLignes;
};

export const isLastDeclaration = (rowIndex, page, pageSize, totalSize) => {
    const lastPage = Math.ceil(totalSize / pageSize);
    return page === lastPage && rowIndex === ((totalSize - 1) % pageSize); // RowIndex est 0 based
};


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] && a[orderBy] && new Date(b[orderBy].cellValue) < new Date(a[orderBy].cellValue)) {
        return -1;
    }
    if (b[orderBy] && a[orderBy] && new Date(b[orderBy].cellValue) > new Date(a[orderBy].cellValue)) {
        return 1;
    }
    return 0;
}

export function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}


export function generateArrayOfYears() {
    const max = new Date().getFullYear();
    const min = 2013;
    return Array.from(new Array(max - min + 1), (v, k) => (max - k).toString());
}

export const isEmptyArray = array => array && array.length === 0;

export const isNotEmptyArray = array => array && array.length > 0;

export function susbstr20(strFinalToDisplay) {
    let strToDisplay;
    if (strFinalToDisplay && strFinalToDisplay.length > 20) {
        strToDisplay = `${strFinalToDisplay.trim().substring(0, 20)}...`;
    } else {
        strToDisplay = strFinalToDisplay;
    }
    return strToDisplay;
}

export function updateEnteteWithTvaAndTtc(entete, tva, ttc) {
    return {
        ...entete,
        totalTtc: {
            ...entete.totalTtc,
            cellValue: ttc,
            doutes: []
        },
        tva
    };
}

/**
 * Met à jour la tva pour les lignes de détail de la facture
 * Si le prix provient des tables FEL, on garde le prix renseigné car il provient de source officielle
 * @param lignes
 * @param tva
 * @returns {*}
 */
export function updateLignesWithTva(lignes, tva) {
    return lignes.map(ligne => ({
        ...ligne,
        prixPublicTtc: {
            ...ligne.prixPublicTtc,
            cellValue: ligne.prixUnitaireFel.cellValue ? ligne.prixPublicTtc.cellValue : calculTtc(ligne.prixPublicHt.cellValue, tva),
            doutes: []
        }
    }));
}

export function getProfilPartenaire(rolesDeclarant) {
    let typProfilPartenaire;
    const role = checkProfilConnected(rolesDeclarant);
    if (isFournisseurRole(role)) {
        typProfilPartenaire = PROFIL_BIBLIOTHEQUE;
    }
    if (isBibliothequeRole(role)) {
        typProfilPartenaire = PROFIL_FOURNISSEUR;
    }
    return typProfilPartenaire;
}

export const isEmptyString = string => string === "";

export const isNotEmptyString = string => string && !isEmptyString(string);

export const partenaireIsPresent = state => isNotBlank(state.declaration.partenaire.gencod);

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const getRedirectURl = (baseUrl, keyWord) => {
    const indexOfKeyWord = baseUrl.indexOf(keyWord);
    return indexOfKeyWord > -1 ? baseUrl.substring(indexOfKeyWord + keyWord.length, baseUrl.length) : null;
};

export const isDeclarationDoffice = declaration => declaration.doffice && declaration.doffice.cellValue === "true";

export function isNullOrUndefined(value) {
    // eslint-disable-next-line no-eq-null,eqeqeq
    return value == null;
}

export function isNullOrEmptyString(value) {
    // eslint-disable-next-line no-eq-null,eqeqeq
    return isNullOrUndefined(value) || isEmptyString(value);
}

/**
 * L'utilisateur est toujours redirégé vers l'écran première connexion si ne la termine pas
 */
export function isEnCoursPremiereCo(premiereConnexionInfo) {
    return (premiereConnexionInfo.stepPremiereConnexion !== null && premiereConnexionInfo.stepPremiereConnexion !== MON_PROFIL_STEP_FIN);
}

export function isEnForcage(premiereConnexionInfo, currentStepPremiereCo) {
    const { steps } = { ...premiereConnexionInfo };
    const presenceStepForcage = steps && steps.length > 0;
    return !isEnCoursPremiereCo(premiereConnexionInfo) && presenceStepForcage && currentStepPremiereCo !== MON_PROFIL_STEP_FORCAGE_FIN;
}

/**
 * Une méthode pour vérifier si on est dans un mode première connexion ou forçage
 * @param premiereConnexionInfo
 * @returns {*|boolean|boolean|null}
 */
export function isForFirstConnectionOrForcage(premiereConnexionInfo, currentStepPremiereCo) {
    return isEnCoursPremiereCo(premiereConnexionInfo) || isEnForcage(premiereConnexionInfo, currentStepPremiereCo);
}

/**
 *  Fonction pour bloquer le copier/coller
 * @param e
 * @returns {boolean}
 */
export function disableAction(e) {
    e.preventDefault();
    return false;
}

export function nth_regex_occurrence(string, regex, nth) {
    const first_index = string.search(regex);
    const length_up_to_first_index = first_index + 1;

    if (nth === 1) {
        return first_index;
    }
    const string_after_first_occurrence = string.slice(length_up_to_first_index);
    const next_occurrence = nth_regex_occurrence(string_after_first_occurrence, regex, nth - 1);

    if (next_occurrence === -1) {
        return -1;
    }
    return length_up_to_first_index + next_occurrence;
}


export function getInscriptionContactModel(value) {
    return {
        ...inscriptionContact,
        fonction: value
    };
}

export function numericOnlyOnKeyPress(event) {
    if (!(/[0-9]/).test(event.key)) {
        event.preventDefault();
    }
}

export function maxLengthCheck(e) {
    if (e.target.value.length > e.target.maxLength) {
        e.target.value = e.target.value.slice(0, e.target.maxLength);
    }
}

export const formatInputGencod = e => {
    const value = e.target.value.trim();
    const newValue = value.replace(/[^0-9\\]+/g, "");
    e.target.value = newValue.length > 0 ? Math.max(0, parseInt(value))
        .toString()
        .slice(0, 13) : "";
};


export function getNumericValueForInput(e, size) {
    const value = e.target.value.trim();
    if (!value || isNaN(value)) {
        const length = value.length - 1;
        const slice = value.slice(0, length);
        e.target.value = isNaN(slice)
            ? ""
            : slice;
    } else {
        e.target.value = value.toString()
            .slice(0, size);
    }
}

export function formatInputTelephone(e) {
    const value = e.target.value.trim();
    e.target.value = value.replace(/[^0-9\\+]+/g, "");
}


export function ButtonBackStep({ hiddenButton }) {
    const dispatch = useDispatch();
    return <Button
        variant="contained"
        onClick={() => dispatch(actions.backStep())}
        hidden={hiddenButton}
        size={"small"}
        style={{ alignSelf: "flex-start" }}
        startIcon={<KeyboardBackspace/>}>
        <Trans id={"backPreviousForm"}/>
    </Button>;
}

export function getContactByCode(contacts, code) {
    return code && contacts.find(c => c.fonction === code);
}

export function setSelectionRangeAndUpperCase(event) {
    // Récupérer la position du curseur sur le champ en question
    const { selectionStart, selectionEnd, value } = event.target;

    if (!selectionStart || !selectionEnd) {
        return;
    }
    event.target.value = value.toUpperCase();
    event.target.setSelectionRange(selectionStart, selectionEnd);
}

export const enableInstantValidate = ref => {
    ref.current.instantValidate = true;
    ref.current.childs.forEach(element => {
        element.instantValidate = true;
    });
};

export const getPathFromProfil = userInfos => {
    const roles = [...userInfos.authorities];

    if (isAdminRole(roles)) {
        return PATH_ADMIN;
    }
    const isRoleBibliotheque = roles.find(r => r === ROLES.BIBLIOTHEQUE);
    return isRoleBibliotheque ? PATH_BIBLIO_MENU : PATH_FOURNI_MENU;
};

export const initBeforeUnLoad = enableDisplayPrompt => {
    window.onbeforeunload = event => {
        // Show prompt based on state
        if (enableDisplayPrompt) {
            event.preventDefault();
            if (event) {
                event.returnValue = "";
            }
            return "";
        }
    };
};

/**
 * On ne fait pas attention à la casse, protocole et www. optionnels, possibilité d'avoir plusieurs sous domaines et les - sont authorisés,
 * avec ça on accepte quasiment tout ce qu'il y a de correct en base provenant de DDP.
 * Dans les formulaires, on ne peut pas utiliser la règle matchRegexp des TextValidator car elle ne supporte pas les flags comme insensible à la casse :
 * https://github.com/NewOldMax/react-form-validator-core/issues/73
 */
export const isUrl = url => (/^((https?|ftp|smtp):\/\/)?(www.)?(?!www)[\w-]+(\.(-?\w)+)+(\/([\w#]+)?)*$/i).test(url);


