import * as actionTypes from "../../actionTypes/actionTypes";


const initialState = {
    fail: false,
    integration: {
        statutCourant: -1
    },
    deleteLoading: false
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.GET_INTEGRATION_RESULT_SUCCESS:
        return {
            integration: action.integration,
            fail: false
        };
    case actionTypes.GET_INTEGRATION_RESULT_FAILED:
        return {
            integration: {
                statutCourant: -1,
                nomFichier: ""
            },
            fail: true
        };
    case actionTypes.SET_INTEGRATION_STATUT:
        if (state.integration !== null) {
            return {
                ...state,
                integration: {
                    ...state.integration,
                    statutCourant: action.statut
                }
            };
        }
        break;
    case actionTypes.REINIT_INTEGRATION_INFO:
        return {
            ...state,
            integration: {
                statutCourant: -1
            }
        };
    case actionTypes.UPDATE_DELETE_INTEGRATION_LOADING:
        return {
            ...state,
            deleteLoading: action.loading
        };
    // Si l'integration n'existe pas, on ne fait rien => go to default
    // eslint-disable-next-line no-fallthrough
    default:
        return state;
    }
};

export default reducer;
