import * as actionsType from "../actionTypes/actionTypes";


export const updateListeEcartsGlobal = ecarts => ({
    type: actionsType.UPDATE_LISTE_ECARTS_GLOBAL,
    ecarts
});
export const updateListeEcartsDetaille = ecarts => ({
    type: actionsType.UPDATE_LISTE_ECARTS_DETAILLE,
    ecarts
});
export const updateEcartDetecte = isEcartDetecte => ({
    type: actionsType.UPDATE_ECARTS_DETECTE,
    isEcartDetecte
});

export const updateTotalEcartsDetaille = total => ({
    type: actionsType.UPDATE_TOTAL_ECARTS_DETAILLE,
    total
});
export const checkIfEcartIsPresent = gencod => ({
    type: actionsType.CHECK_IF_ECART_IS_PRESENT,
    gencod
});

export const updateLoading = loading => ({
    type: actionsType.UPDATE_LOADING,
    loading
});

export const openCroisementDialogSaga = annee => ({
    type: actionsType.OPEN_CROISEMENT_DIALOG_SAGA,
    annee
});

export const openCroisementDialog = annee => ({
    type: actionsType.OPEN_CROISEMENT_DIALOG,
    annee
});

export const getListeEcartsForDeclarant = isDetailedView => ({
    type: actionsType.GET_LISTE_ECARTS_FOUR_BIB,
    isDetailedView
});

export const getCountEcartForDeclarant = (gencodDeclarant, annee) => ({
    type: actionsType.GET_COUNT_ECART_FOUR_BIB,
    gencodDeclarant,
    annee
});

export const updatePage = page => ({
    type: actionsType.UPDATE_PAGE,
    page
});

export const updateSortColumn = sortColumn => ({
    type: actionsType.UPDATE_SORT_COLUMN,
    sortColumn
});

export const closeCroisementDialog = () => ({
    type: actionsType.CLOSE_CROISEMENT_DIALOG
});
