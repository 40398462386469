import * as actionTypes from "../actionTypes/actionTypes";
import * as actions from "./index.action";

export const addStepInscriptionRdp = () => ({
    type: actionTypes.ADD_STEP_INSCRIPTION_RDP
});

export const removeStepInscriptionRdp = () => ({
    type: actionTypes.REMOVE_STEP_INSCRIPTION_RDP
});

export const nextStep = () => ({
    type: actionTypes.NEXT_STEP
});

export const backStep = () => ({
    type: actionTypes.BACK_STEP
});

export const handleRechercheGLN = results => dispatch => {
    dispatch({
        type: actionTypes.DISPLAY_OR_NOT_STEP_RECHERCHE_GLN,
        resutlsArray: results
    });
    dispatch(nextStep());
};

export const initActiveStep = () => ({
    type: actionTypes.INIT_ACTIVE_STEP
});

export const initStepInscription = () => dispatch => {
    dispatch(actions.initActiveStep());
    dispatch(actions.initStateInscription());
};

export const initStepReferencement = () => dispatch => {
    dispatch(actions.initActiveStep());
    dispatch(actions.initFormulaireReferencement());
};

export const addStepResultGlnRereferencement = () => ({
    type: actionTypes.ADD_STEP_RECHERCHE_GLN_REFERENCEMENT
});

export const addStepDemandeRereferencement = () => ({
    type: actionTypes.ADD_STEP_DEMANDE_REFERENCEMENT
});

export const removeStepResultGlnRereferencement = () => ({
    type: actionTypes.REMOVE_STEP_RECHERCHE_GLN_REFERENCEMENT
});

