import React, { Component } from "react";
import classNames from "classnames";
import { Button, FormControlLabel } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { MenuItem } from "material-ui";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import VilleAutoComplete from "../../commonComponents/layout/autoCompletion/villeAutoCompletion";
import * as actionCreator from "../../store/actions/index.action";
import { searchListVilles } from "../../services/rechercheGLN.service";
import {
    CODE_PAYS,
    MAX_LENGTH_ADRESSE_1,
    MAX_LENGTH_RAISON_SOCIALE1,
    PROFIL_BIBLIOTHEQUE,
    PROFIL_FOURNISSEUR
} from "../../utils/constantes";
import {
    getNumericValueForInput,
    getPrefixUrl,
    isNotEmptyArray,
    isNotEmptyString
} from "../../utils/utility";
import { GreenCheckbox } from "../../commonComponents/checkBox/GreenCheckBox";
import {
    LIBELLE_ADRESSE,
    LIBELLE_CODE_POSTAL,
    LIBELLE_EFFACER,
    LIBELLE_ERREUR_GLN_LENGTH,
    LIBELLE_FERMER,
    LIBELLE_GLN,
    LIBELLE_INSCRIPTION_PROFIL_BIBLIOTHEQUE,
    LIBELLE_INSCRIPTION_PROFIL_FOURNISSEUR,
    LIBELLE_PAYS,
    LIBELLE_RAISON_SOCIALE,
    LIBELLE_RECHERCHE_ASSUJ_DEMANDE_REFERENCMENT,
    LIBELLE_RECHERCHE_ASSUJ_ENTITE,
    LIBELLE_RECHERCHE_ASSUJ_ERREUR_CODE_POSTAL_LENGTH,
    LIBELLE_RECHERCHE_ASSUJ_RECHERCHE_DETAILLEE,
    LIBELLE_RECHERCHE_ASSUJ_SEARCH,
    LIBELLE_VILLE
} from "../../utils/libelleConstantes";
import { REQUIRED } from "../../utils/validatorUtil";
import { CustomSelectValidator } from "../../commonComponents/validator/CustomSelectValidator";
import { PaysLibelleAutoComplete } from "../../commonComponents/input/fieldInput";
import {
    autoCompleteOnChange,
    isCodePaysFrance
} from "../monProfil/monProfilUtil";
import { CODE_PAYS_FRANCE } from "../../utils/paysConstantes";
import { selectLoginToken } from "../../utils/selectors";
import { globalI18n } from "../../i18n.conf";
import classes from "./rechercheAssujetti.module.css";
import { RechercheGlnResult } from "./rechercheGlnResult";

class RechercherAssujettiCore extends Component {
    state = {
        checked: false,
        typeEntite: this.props.typeProfilToSearch || "",
        gencod: "",
        raisonSociale: "",
        adresse: "",
        codePostal: "",
        ville: "",
        codePays: CODE_PAYS_FRANCE,
        assujettis: [],
        searchWithDetails: !this.props.isCallFromDeclaration
    };

    componentDidUpdate() {
        const typeEntite = this.state.typeEntite;
        const { typeProfilToSearch } = this.props;
        if (isNotEmptyString(typeProfilToSearch) && typeEntite !== typeProfilToSearch) {
            this.setState({ typeEntite: typeProfilToSearch });
        }
    }

    loadUsers = () => {
        const { gencod, raisonSociale, adresse, codePostal, ville, typeEntite, codePays } = this.state;
        const filter = {
            gencod,
            raisonSociale,
            adresse,
            codePostal,
            ville,
            codePays
        };
        this.props.rechercheAssujetti(typeEntite, filter);
    };

    handleChange = input => e => {
        this.setState({
            [input]: e.target.value
        });
    };

    onChangePays = (field, value) => {
        this.setState({
            [field]: value,
            ville: "",
            codePostal: ""
        });
    };

    handleChangeEntite = e => {
        this.setState({
            typeEntite: e.target.value
        });
    }

    handleChangeVille = value => {
        this.setState({ ville: value });
    };

    clearFields = () => {
        const { firstCallSearchServicePassed, assujettis, updateFlagFirstSearch, clearResultAsssujettis } = this.props;
        this.setState({
            typeEntite: "",
            gencod: "",
            raisonSociale: "",
            adresse: "",
            codePostal: "",
            ville: "",
            codePays: CODE_PAYS_FRANCE
        });

        if (firstCallSearchServicePassed && assujettis.length !== 0) {
            updateFlagFirstSearch();
        }
        clearResultAsssujettis();
    };

    getSuggestions = async value => {
        let suggestionsVilles = [];
        const { gencod, raisonSociale, adresse, codePostal, typeEntite, codePays } = this.state;
        const ville = value;
        const filtre = {
            gencod,
            raisonSociale,
            adresse,
            codePostal,
            ville,
            codePays
        };
        await searchListVilles(typeEntite, filtre)
            .then(response => {
                suggestionsVilles = response.data;
            });
        return suggestionsVilles;
    };

    redirectToReferencement = () => {
        const { raisonSociale, adresse, codePostal, ville, codePays } = this.state;
        const { utilisateur, updateInfoFiltre, redirectToDemandeReferencement } = this.props;
        const filtre = {
            raisonSociale,
            adresse,
            codePostal,
            ville,
            codePays
        };
        const gencod = utilisateur.informations.gencod;
        const prefixPath = getPrefixUrl(utilisateur.roles);
        updateInfoFiltre(filtre);
        redirectToDemandeReferencement(gencod, prefixPath, filtre);
    }

    onChangeSearchWithDetails = () => {
        this.setState({ searchWithDetails: !this.state.searchWithDetails });
    };

    submit = () => {
        this.rechercherAssujettiForm.submit();
    }

    onSelectGln = assujettis => {
        const { clearResultAsssujettis, updateAssujetti } = this.props;
        if (isNotEmptyArray(assujettis)) {
            clearResultAsssujettis();
            updateAssujetti(assujettis[0]);
            this.setState({
                gencod: "",
                raisonSociale: "",
                adresse: "",
                codePostal: "",
                ville: "",
                codePays: ""
            });
        }
    }

    onCloseRecherche = () => {
        const { clearResultAsssujettis, onClose } = this.props;
        clearResultAsssujettis();
        onClose();
        this.setState({
            gencod: "",
            raisonSociale: "",
            adresse: "",
            codePostal: "",
            ville: "",
            codePays: CODE_PAYS_FRANCE,
            searchWithDetails: false
        });
    }

    render() {
        const { typeEntite, gencod, raisonSociale, adresse, codePostal, ville, codePays, searchWithDetails } = this.state;
        const {
            loading,
            isCallFromDeclaration,
            typeProfilToSearch,
            profilDialog,
            showButtonAfterSearch,
            isAuthenticated,
            emptyDataMessage,
            disabledChoice
        } = this.props;
        /*
         * On affiche le bouton de référencement si on a effectué, dans le cadre d’une déclaration, au moins une première recherche du partenaire
         */
        const showReferencerButton = isCallFromDeclaration && showButtonAfterSearch;

        const isFournisseur = isCallFromDeclaration
            ? typeProfilToSearch === PROFIL_FOURNISSEUR : _.isEmpty(profilDialog)
                ? typeEntite === PROFIL_FOURNISSEUR
                : profilDialog === PROFIL_FOURNISSEUR;

        function getCodePostalValidator() {
            let validators = [];
            if (isCodePaysFrance(codePays)) {
                validators = ["matchRegexp:^\\d{3,5}$"];
            }
            return validators;
        }

        return (
            <div>
                <div className="container-fluid">
                    <div className={classNames(classes.RechercheAssujettiSubmitButtonContainer, "col")}>
                        {isCallFromDeclaration &&
                            <FormControlLabel
                                control={
                                    <GreenCheckbox
                                        checked={searchWithDetails}
                                        onChange={this.onChangeSearchWithDetails}
                                        value="checkedB"
                                        color="primary"
                                    />
                                }
                                label={<Trans id={LIBELLE_RECHERCHE_ASSUJ_RECHERCHE_DETAILLEE}/>}
                            />}
                        <Button
                            variant="contained"
                            id="button_search"
                            color="primary"
                            onClick={this.submit}
                            className={classes.RechercheAssujettiSubmitButton}>
                            <Trans id={LIBELLE_RECHERCHE_ASSUJ_SEARCH}/>
                        </Button>
                        <Button
                            variant="contained"
                            id="button_clear"
                            onClick={this.clearFields}
                            className={classes.RechercheAssujettiSubmitButton}>
                            <Trans id={LIBELLE_EFFACER}/>
                        </Button>
                        {isAuthenticated && isCallFromDeclaration && <Button
                            variant="contained"
                            id="button_close"
                            onClick={this.onCloseRecherche}
                            className={classes.RechercheAssujettiSubmitButton}>
                            <Trans id={LIBELLE_FERMER}/>
                        </Button>}
                        {showReferencerButton && <Button
                            variant="contained"
                            id="button_referencer"
                            color="secondary"
                            onClick={this.redirectToReferencement}
                            className={classes.RechercheAssujettiSubmitButton}>
                            <Trans id={LIBELLE_RECHERCHE_ASSUJ_DEMANDE_REFERENCMENT}/>
                        </Button>}
                    </div>
                    <ValidatorForm
                        ref={r => this.rechercherAssujettiForm = r}
                        onSubmit={this.loadUsers}>
                        <div className={classNames("row", classes.RechercheAssujettiContainer)}>
                            {!isCallFromDeclaration && <div className="col">
                                <CustomSelectValidator
                                    label={<Trans id={LIBELLE_RECHERCHE_ASSUJ_ENTITE}/>}
                                    value={typeEntite}
                                    onChange={this.handleChangeEntite}
                                    name="typeEntite"
                                    required={true}
                                    validators={[REQUIRED]}
                                    errorMessages={[<Trans>mandatoryField</Trans>]}>
                                    <MenuItem value={PROFIL_FOURNISSEUR}>{_.capitalize(globalI18n._(LIBELLE_INSCRIPTION_PROFIL_FOURNISSEUR))}</MenuItem>
                                    <MenuItem value={PROFIL_BIBLIOTHEQUE}>{_.capitalize(globalI18n._(LIBELLE_INSCRIPTION_PROFIL_BIBLIOTHEQUE))}</MenuItem>
                                </CustomSelectValidator>
                            </div>}
                            <div className="col">
                                <TextValidator
                                    onInput={e => getNumericValueForInput(e, 13)}
                                    name="gln"
                                    label={<Trans id={LIBELLE_GLN}/>}
                                    onChange={this.handleChange("gencod")}
                                    value={gencod}
                                    validators={["matchRegexp:^\\d{13}$"]}
                                    errorMessages={[<Trans id={LIBELLE_ERREUR_GLN_LENGTH}/>]}
                                />
                            </div>
                            <div className="col-2">
                                <TextValidator
                                    label={<Trans id={LIBELLE_RAISON_SOCIALE}/>}
                                    inputProps={{ maxLength: MAX_LENGTH_RAISON_SOCIALE1 }}
                                    onChange={this.handleChange("raisonSociale")}
                                    value={raisonSociale}
                                    name="raisonSociale"/>
                            </div>
                            {searchWithDetails && <div className="col-2">
                                <TextValidator
                                    label={<Trans id={LIBELLE_ADRESSE}/>}
                                    inputProps={{ maxLength: MAX_LENGTH_ADRESSE_1 }}
                                    onChange={this.handleChange("adresse")}
                                    value={adresse}
                                    name="adresse"/>
                            </div>}
                            {searchWithDetails && <div className="col">
                                <TextValidator
                                    onInput={e => isCodePaysFrance(codePays) && getNumericValueForInput(e, 5)}
                                    label={<Trans id={LIBELLE_CODE_POSTAL}/>}
                                    onChange={this.handleChange("codePostal")}
                                    value={codePostal}
                                    validators={[...getCodePostalValidator()]}
                                    name="codePostal"
                                    errorMessages={[
                                        <Trans
                                            id={LIBELLE_RECHERCHE_ASSUJ_ERREUR_CODE_POSTAL_LENGTH}/>
                                    ]}/>
                            </div>}
                            <div className="col-2">
                                <VilleAutoComplete label={<Trans id={LIBELLE_VILLE}/>} ville={ville}
                                    onChange={this.handleChangeVille}
                                    getSuggestions={this.getSuggestions}/>
                            </div>
                            {searchWithDetails && <div className="col-2">
                                <PaysLibelleAutoComplete id="paysAutoComplete"
                                    value={codePays}
                                    showOnlyPaysFrance={typeEntite === PROFIL_BIBLIOTHEQUE}
                                    onChange={autoCompleteOnChange(CODE_PAYS, this.onChangePays)}
                                    label={<Trans id={LIBELLE_PAYS}/>}
                                    variant="standard"
                                    className=""
                                    style={{ padding: "3px 0" }}
                                />
                            </div>}
                            {/* Ajout d’un espace dans l’écran de saisie en ligne dans le cas d’une recherche sans détails */}
                            {isAuthenticated && !searchWithDetails && <div className="col-6"></div>}
                        </div>
                    </ValidatorForm>
                </div>
                <RechercheGlnResult loading={loading} onSelect={this.onSelectGln} showForFournisseur={isFournisseur}
                    emptyDataMessage={emptyDataMessage} disabledChoice={disabledChoice}/>
            </div>
        );
    }
}

RechercherAssujettiCore.propTypes = {
    clearResultAsssujettis: PropTypes.func,
    updateFlagFirstSearch: PropTypes.func,
    redirectToDemandeReferencement: PropTypes.func,
    updateInfoFiltre: PropTypes.func,
    rechercheAssujetti: PropTypes.func,
    updateAssujetti: PropTypes.func.isRequired,
    typeProfilConnected: PropTypes.string,
    isCallFromDeclaration: PropTypes.bool
};

const mapStateToProps = state => ({
    firstCallSearchServicePassed: state.rechercheGLN.firstCallSearchService,
    assujettis: state.rechercheGLN.resultsSearchGLN,
    showButtonAfterSearch: state.rechercheGLN.showButtonAfterSearch,
    loading: state.rechercheGLN.loading,
    numeroDeclaration: state.integrationTableurDeclaration.numeroDeclaration,
    preferenceSizePagination: state.utilisateur.informations.preferenceSizePagination,
    utilisateur: state.utilisateur,
    profilDialog: state.inscription.profilDialog,
    isAuthenticated: selectLoginToken(state) !== null,
    emptyDataMessage: state.rechercheGLN.emptyDataMessage
});

const mapDispatchToProps = dispatch => ({
    rechercheAssujetti: (profil, body) => dispatch(actionCreator.rechercheGLN(profil, body, false)),
    clearResultAsssujettis: () => dispatch(actionCreator.initRechercheGLN()),
    redirectToDemandeReferencement: (gencod, prefixPath, data) => dispatch(actionCreator.redirigeToDemandeReferencement(gencod, prefixPath, data)),
    updateFlagFirstSearch: () => dispatch(actionCreator.updateFlagFirstSearch(false)),
    updateInfoFiltre: value => dispatch(actionCreator.updateInfoFiltre(value)),
    initInscription: () => {
        dispatch(actionCreator.initStepInscription());
        dispatch(actionCreator.updateFlagIsCalledFromDeclaration(false));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RechercherAssujettiCore));
