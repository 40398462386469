import * as actionsTypes from "../../actionTypes/actionTypes";
import { updateMessageHttp, updateObject } from "../../../utils/utility";

const intialeState = {
    openDialog: false,
    message: "",
    message2: "",
    redirect: false,
    cause: ""
};

function globalDialogOpen (state, action) {
    action.cause = updateMessageHttp(action.cause);
    return updateObject(state, {
        openDialog: true,
        message: action.message,
        message2: action.message2,
        redirect: action.redirect,
        cause: action.cause
    });
}

function globalDialogOpenNoRedirect (state, action) {
    action.cause = updateMessageHttp(action.cause);
    return updateObject(state, {
        openDialog: true,
        message: action.message,
        message2: action.message2,
        cause: action.cause
    });
}


function globalDialogClose (state) {
    return updateObject(state, {
        openDialog: false,
        message: "",
        message2: "",
        redirect: false,
        cause: ""
    });
}

const reducer = (state = intialeState, action) => {
    switch (action.type) {
    case actionsTypes.GLOBAL_DIALOG_OPEN:
        return globalDialogOpen(state, action);
    case actionsTypes.GLOBAL_DIALOG_OPEN_NOT_REDIRECT:
        return globalDialogOpenNoRedirect(state, action);
    case actionsTypes.GLOBAL_DIALOG_CLOSE_SUCCESS:
        return globalDialogClose(state, action);
    default:
        return state;
    }
};

export default reducer;
