import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { useStyles } from "../../monProfilUtil";
import { selectMonProfilContacts } from "../../../../utils/selectors";
import { handleNotificationWithoutSave } from "../../monProfil.component";
import { getContactByCode } from "../../../../utils/utility";
import MonProfilFormulaireContactInfosComponent from "./monProfilFormulaireContactInfos.component";

function MonProfilFormulaireContactComponent({ code, withMail = true, premiereCo, isFournisseur = true }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const contacts = useSelector(selectMonProfilContacts);
    const [formulaire, setFormulaire] = useState(null);
    const [initialContact, setInitialContact] = useState(getContactByCode(contacts, code));

    useEffect(() => {
        const c = getContactByCode(contacts, code);
        if (c) {
            setInitialContact(c);
            setFormulaire(c);
        }
    }, [contacts, code, setInitialContact, getContactByCode, setFormulaire, dispatch]);

    // Vérifier si un champ du formulaire contactInfo seulement a été modifié
    useEffect(() => {
        handleNotificationWithoutSave(premiereCo, dispatch, formulaire && !isEqual(formulaire, initialContact));
    }, [formulaire, premiereCo, initialContact, dispatch, code]);

    return <div>
        <MonProfilFormulaireContactInfosComponent
            code={code}
            classes={classes}
            formulaire={formulaire}
            setFormulaire={setFormulaire}
            dispatch={dispatch}
            contactFound={initialContact}
            withMail={withMail}
            premiereCo={premiereCo}
            isFournisseur={isFournisseur}/>
    </div>;
}

export default memo(MonProfilFormulaireContactComponent);
MonProfilFormulaireContactComponent.whyDidYouRender = true;
