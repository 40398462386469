import axios from "axios/index";
import { CLIENT_AUTH_PASSWORD, CLIENT_AUTH_USERNAME } from "../utils/constantes";
import { getQueryString } from "../utils/objectUtil";

/*
 * OAuth2 ne peut pas lire le JSON du coup soit le body doit être une chaine de caractère soit on utilise
 * une fonction qui transforme l'objet en chaine de caractère
 */

export const login = body => {
    const axiosPostLoginConfig = {
        url: "/oauth2/token",
        baseURL: "",
        method: "post",
        data: body,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Basic ${btoa(`${CLIENT_AUTH_USERNAME}:${CLIENT_AUTH_PASSWORD}`)}`
        },
        withCredentials: true,
        transformRequest: getQueryString
    };

    return axios(axiosPostLoginConfig);
};
