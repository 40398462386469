import { IconButton } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import logoSofia from "../../../assets/icons/logosofia.jpg";
import { PATH_LOGIN } from "../../../routes/paths/paths.util";
import { selectRootPath } from "../../../utils/selectors";

export default function LogoComponent(props) {
    const styles = {
        responsiveImage: {
            height: 54
        }
    };
    const history = useHistory();
    const rootPath = useSelector(selectRootPath);
    const pathToGo = props.isLogged ? rootPath : PATH_LOGIN;
    const onClickHandler = () => history.push(pathToGo);

    return (
        <IconButton
            id="logoLink"
            style={{ background: "none" }}
            onClick={onClickHandler}>
            <img src={logoSofia} alt="" style={styles.responsiveImage} />
        </IconButton>
    );
}
