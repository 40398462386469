import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Trans } from "@lingui/macro";
import GenericPartenaireFilter from "../../../commonComponents/filtres/genericPartenaireFilter";
import * as actions from "../../../store/actions/index.action";
import { isEmptyArray } from "../../../utils/utility";


function PartenaireStatistiqueFiltre({
    allPartenaires,
    selectedPartenaires,
    loadingPartenairesFactures,
    displayHelperText,
    selectedFactures,
    disableFiltrePartenaire,
    updateSelectedPartenaires,
    updateDisableFiltrePartenaire
}) {
    const getOptionSelected = (option, value) => option.gencod === value.gencod;

    const getHelperText = () => displayHelperText && <span style={{ color: "#4682B4" }}><Trans>helperPartenaire</Trans></span>;

    const updatePartenaires = options => {
        updateSelectedPartenaires(options);
        const disabled = isEmptyArray(options) && selectedFactures.length > 0;
        updateDisableFiltrePartenaire(disabled);
    };


    /*
     * Vérifier si on réactive le filtre partenaire une fois que l'on détecte un changement sur le filtre facture
     */
    useEffect(() => {
        const disabled = isEmptyArray(selectedPartenaires) && selectedFactures.length > 0;
        updateDisableFiltrePartenaire(disabled);
    }, [selectedFactures, selectedPartenaires, updateDisableFiltrePartenaire]);


    /*
     * Gérer si on désactive le filtre à la fermeture du composant
     */
    const onClose = () => {
        const disabled = isEmptyArray(selectedPartenaires) && selectedFactures.length > 0;
        updateDisableFiltrePartenaire(disabled);
    };

    return <GenericPartenaireFilter allPartenaires={allPartenaires} selectedPartenaires={selectedPartenaires}
        update={updatePartenaires} onClose={onClose} getOptionSelected={getOptionSelected}
        disabled={isEmptyArray(allPartenaires) || disableFiltrePartenaire} loading={loadingPartenairesFactures}
        helperText={getHelperText()}/>;
}

const mapStateToProps = state => ({
    allPartenaires: state.statistiquesDeclarations.filtrePartenaires,
    selectedPartenaires: state.statistiquesDeclarations.selectedPartenaires,
    selectedFactures: state.statistiquesDeclarations.selectedFactures,
    loadingPartenairesFactures: state.statistiquesDeclarations.loadingPartenairesFactures,
    displayHelperText: state.statistiquesDeclarations.displayPartenaireHelperText,
    disableFiltrePartenaire: state.statistiquesDeclarations.disableFiltrePartenaire
});

const mapDispatchToProps = dispatch => ({
    updateSelectedPartenaires: options => {
        dispatch(actions.updateSelectedPartenaires(options));
        options.length === 0 && dispatch(actions.loadFactures());
    },
    updateDisableFiltrePartenaire: disabled => dispatch(actions.updateDisableFiltrePartenaire(disabled))
});

export default connect(mapStateToProps, mapDispatchToProps)(PartenaireStatistiqueFiltre);
