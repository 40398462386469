import { push } from "connected-react-router";

export {
    login,
    loginSuccess,
    loginFail,
    logout,
    loginCheckState,
    loginStart,
    onChangeLogin,
    majMenuItems,
    loginReplace,
    reinitLoginRedirectPath,
    updateReloading,
    updateReloadingSite
} from "./login.action";

export {
    getAttestationsSuccess,
    getAttestations,
    sendAttestation,
    downloadAttestation
} from "./attestationFinDeclarations.action";

export {
    updateInscriptionEntiteFormulaire,
    updateInscriptionRlFormulaire,
    updateInscriptionRdpFormulaire,
    initStateInscription,
    onChangeHiddenInput,
    onChangeProfilDialog,
    setActiveStep,
    saveInscription,
    rechercheGlnBeforeInscription,
    updateStatutSaveInscription,
    updateFormulaireMailValide,
    updateLoadingInscription,
    updateIsFormulaireStarted,
    updateIsGencodSelected
} from "./inscription.action";

export {
    updateResultsGLN,
    updateResultsGLNAndActiveButton,
    rechercheGLN,
    rechercheGLNStart,
    rechercheGLNEnd,
    rechercheGLNFailed,
    updateTypeSearchGLN,
    updateFlagFirstSearch,
    updateFlagIsCalledFromDeclaration,
    updateInfoFiltre,
    initRechercheGLN
} from "./rechercheGLN.action";

export {
    addStepInscriptionRdp,
    removeStepInscriptionRdp,
    nextStep,
    backStep,
    handleRechercheGLN,
    initStepInscription,
    initActiveStep,
    addStepResultGlnRereferencement,
    addStepDemandeRereferencement,
    removeStepResultGlnRereferencement,
    initStepReferencement
} from "./step.action";

export {
    openDialog,
    openDialogNoRedirect,
    closeDialog,
    closeDialogSuccess
} from "./globalDialog.action";

export {
    usurper,
    usurperStart,
    usurperSuccess,
    usurperFail,
    usurperUnauthorized,
    updateDisableButton
} from "./admin.action";

export {
    changePwdUnconnected,
    changePwdConnected,
    sendMailForPasswordReset
} from "./resetMdp.action";

export {
    saveDemandeReferencement,
    redirigeToDemandeReferencement,
    rechercheGLnBeforeSave,
    addToFavorisAndRedirectToSaisieWeb,
    addToFavorisAndRedirectToMesPartenaires,
    updateFormulaireReferencement,
    updateFormulaireRecherche,
    initFormulaireReferencement,
    updateStatutSaveDemande,
    loadingSelectedFavoris,
    rechercheByGln,
    updateShowSaveDemande,
    updateDataNotSaved,
    updateGencodPartenaire
} from "./demandeReferencement.action";

export {
    redirectToDeclaration,
    updatePartenaireInfo,
    searchPartenaireInfo,
    updateDeclarationEntete,
    updateDeclarationEntetes,
    updateDeclarationEnteteSaga,
    cancelDeclarationEnteteSaga,
    cancelDeclarationWebEnteteSaga,
    revalidDeclarationEnteteSaga,
    sendDeclarationSaga,
    mergeLigne,
    mergeLigneSaga,
    updateDeclarationLigne,
    updateDeclarationLignes,
    updateDeclarationLigneSaga,
    revalidLigne,
    revalidLigneSaga,
    cancelLigne,
    cancelLigneSaga,
    deleteLigne,
    deleteLigneSaga,
    updateDeclarantInfo,
    updateDeclarationStore,
    reinitializeDeclarationData,
    saveDeclarationsSuccess,
    updateDeclarationsEntetesByNumeroDeclaration,
    sentDeclaration,
    updateDeclarationEntetesAndSaveDeclaration,
    saveDeclaration,
    updateEnableUpdateStore,
    reinitPartenaire,
    validateOneDeclarationEnEtatIntegrationExcel,
    validateOneDeclarationEnEtatMesDeclarations,
    setDeclarationLoading,
    updateDeclarationIdWrapperInfo,
    updateTypeDeclaration,
    updateDeclarationReadOnly,
    reinitDeclarationTime,
    updateDeclarationLignesAndOrder,
    updateDeclarationGlobale,
    setTvaForEntete,
    setTvaForEnteteResult,
    updateDisableSendButton,
    updateDisableSendButtonLignes
} from "./declaration.action";

export {
    getInformationsDeclarant,
    getInformationsDeclarantSuccess,
    updatePreferenceSizePaginationSuccess,
    updateInfoPremiereconnexionSuccess,
    updatePreferenceSizePagination,
    updateLocaleForUser,
    updateGencod,
    updateFlagBibliothequeEtrangere,
    updateUtilisateurLoading
} from "./utilisateur.action";

export {
    updateDisableValideButton,
    setLoading,
    setFilArianeKeyMessage,
    updateFlagShowAndMessageSnackbar,
    checkSiretSuccess,
    checkSiret,
    updateMessageConfirmation,
    updateNumeroToMap,
    updateApplyErrorStyle,
    updateUrlAideUtilisateur,
    updateIsCalledFromInscription,
    sendMessageNousContacter
} from "./common.action";

export {
    integrerTableur,
    getIntegrationsTableurByGlnDeclarant,
    getIntegrationsTableurByGlnDeclarantResults,
    deleteIntegrationTableur,
    setIntegrationsTableurRecapitulatifLoading,
    setDeleteIntegrationsLoading,
    getIntegrationResultSuccess,
    getIntegrationResultFailed,
    getIntegration,
    setIntegrationStatut,
    redirectToIntegrationFromNumeroDeclaration,
    reinitIntegrationInfo,
    downloadModeleExcel,
    downloadIntegrationFichierRecapitulatif
} from "./integrationTableur.action";

export {
    getListeDeclarationsEnteteByIdIntegrationResult,
    getListeDelcarationsLigneByNumeroDeclaration,
    getListeDelcarationsLigneByNumeroDeclarationResult,
    getLoadingPercentageByIdIntegration,
    validateIntegrationEnEtat,
    closeDeclaration,
    reinitDeclarationsData,
    updateDeclarationEnteteStatus,
    updateDeclarationEnteteStatusSuccess,
    updateDeclarationEnteteEtLignes,
    setIntegrationsTableurDeclarationLoading,
    setSentIntegrationLoading,
    updateRowIndex,
    updateIEOpenDetailDialog
} from "./integrationTableurDeclaration.action";

export {
    setIntegrationsTableurCorrectionErreurLoading,
    getCorrectionErreurLignesByIdIntegration,
    getCorrectionErreurLignesByIdIntegrationResults,
    getCorrectionErreurEntetesChoisiesByIdIntegration,
    getCorrectionErreurEntetesChoisiesByIdIntegrationResults,
    saveAndGetLignesForIdIntegration,
    saveAndGetLignesForIdIntegrationResult,
    deleteAndGetStatisticsForIdIntegration,
    deleteAndGetStatisticsForIdIntegrationResult,
    setIntegrationsTableurCorrectionErreurSauvegardeLoading,
    createDeclarationsForIdIntegration,
    getListeDeclarationsEnteteByIdIntegration,
    displayErrorMessage,
    setIntegrationsTableurCorrectionErreurLoadingPercentage,
    openOrCloseBarProgressDialog,
    reinitDataCorrectionErreurs,
    updatePassToStepCorrectionErreur
} from "./integrationTableurCorrectionErreur.action";

export {
    setIntegrationsTableurChoixEntetesLoading,

    getEchantillonEntetesIdentifieesEtAttenduesByIdIntegration,
    getEchantillonEntetesIdentifieesEtAttenduesByIdIntegrationResults,
    confirmEntetes,
    updateConfimerEnteteLoading
} from "./integrationTableurChoixEntete.action";

export {
    updateMdSortColumn,
    updateMdAnneeDebut,
    updateMdAnneeFin,
    updateMdPeriod,
    updateMdTypeAnnee,
    updateMdDialogDeclarationModifEnCours,
    updateMdDoutes,
    updateMdGlnDeclarant,
    updateMdPartenaires,
    updateMdFiltrePartenaires,
    updateMdFiltreInvalideDeclaration,
    updateMdPage,
    updateMdNombreDeclarationParType,
    updateMdResultat,
    updateMdAllInfos,
    reinitMdTypeDeclaration,
    updateMdTypeDeclaration,
    getMDListDeclarationsLigneByNumeroDeclaration,
    getMDListeDelcarationsLigneByNumeroDeclarationResult,
    clearMDStore,
    updateIndexOpenedDeclaration,
    updateMdTotalSizeRecap,
    getMesDeclarationsSaga,
    getInfosNecessairesSaga,
    sendAllFiltered,
    invalidateDeclarationByNumeroDeclaration,
    sendDeclarationByNumeroDeclaration,
    updateMDDeclarationEnteteEtLignes,
    closeMDDeclaration,
    updateMdSourceDeclaration,
    updateEntetesAndRowIndex,
    updateEntetesAndRowIndexResult,
    modifyDeclarationFacturee,
    checkStatutMDDeclaration,
    onNextDeclarationFromMesDeclarations,
    onPreviousDeclarationFromMesDeclarations,
    closeDetailDialogFromMesDeclarations,
    addClone,
    updateMDShowClone,
    updateMDShowOriginale,
    updateMDOpenDetailDialog,
    setMdLoading,
    getPartenaireAndUpdateFiltre,
    updateMDStatistiquesMontants,
    updateMDStatistiquesMontantsResult,
    setMdLoadingStatistiquesMontants,
    setMdShowStatistiquesMontants,
    updateFiltreTypeAnnee,
    rollBackDeclarationByNumeroDeclaration,
    refreshMDStore,
    updateMdGlnDeclarantAndReinitPartenaire,
    updateMdFiltreNumeroFacture
} from "./mesDeclarations.action";

export {
    exportMensualisation,
    exportLignesEan,
    updateAnneeCivile,
    updateSelectedPartenaires,
    loadPartenairesAndFactures,
    updateFiltresPartenairesAndFactures,
    updateLoadingPartenairesFactures,
    updateAnneeDebut,
    updateAnneeFin,
    reinitStatistiquesView,
    updateFiltresFactures,
    updateSelectedFactures,
    loadFactures,
    updateLoadingFactures,
    updateDisableFiltrePartenaire,
    updateStoreAndLoadPartenairesAndFactures,
    reinitAllFiltre,
    updateDisableFiltreFacture,
    getCountExportLigneEan,
    updateCountExportLigneEan,
    updateLoadingCountExportLigneEan
} from "./statistiquesDeclarations.action";

export {
    exportRecapitulatifVente
} from "./documentDeclaration.action";

export {
    updateListeEcartsGlobal,
    updateListeEcartsDetaille,
    updateTotalEcartsDetaille,
    getCountEcartForDeclarant,
    openCroisementDialog,
    openCroisementDialogSaga,
    getListeEcartsForDeclarant,
    updateLoading,
    checkIfEcartIsPresent,
    updatePage,
    updateSortColumn,
    closeCroisementDialog
} from "./croisementBibFour.action";

export {
    updateMesFacturesAnnee,
    updateMesFacturesEtatReglement,
    getFactures,
    updateLoadingMesFactures,
    updateSortColumnMesFactures,
    getFacturesSuccess,
    updatePageMesFactures,
    getFactureDocument,
    getCountNouvellesFactures,
    getAllFacturesNotifications,
    reinitFacturesFiltre,
    updateFactureLoading,
    getFactureDocumentFromDeclaration
} from "./mesFactures.action";

export {
    updateNotificationsSuccess,
    updateEcartDetecte,
    incrementNbNotifications,
    decrementNbNotifications,
    getAllNotifications,
    updateNotificationsVue,
    updateNotificationsMail,
    updateNotificationsLoading
} from "./notification.action";

export {
    getMonProfil,
    getMonProfilSuccess,
    updateLoadingMonProfil,
    saveMonProfilCompteUtilisateur,
    saveMonProfilCompteUtilisateurSuccess,
    saveMonProfilContactSuccess,
    saveMonProfilContactsSuccess,
    checkMailToken,
    checkMailTokenSuccess,
    updateVueCroisement,
    updateVueCroisementSuccess,
    updateMonProfilDataNotSaved,
    goToNextStepPremiereConnexion,
    goToPreviousStepPremiereConnexion,
    updateSkipStepPremiereConnexion,
    updateMonProfilLastStepPremiereConnexionFournisseur,
    updateMonProfilContactMailSucess,
    setStepPremiereCo,
    setStepsPremiereCo,
    updateMonProfilPremiereConnexionInfos,
    saveContactGlobal,
    setLastValidatedStepPremiereCo,
    getMonProfilAvecNotification
} from "./monProfil.action";

export {
    getLibelles,
    getLibellesSuccess
} from "./libelle.action";

export {
    updateLoadingMesPartenairesFavoris,
    updateLoadingMesPartenairesRecents,
    getMesPartenairesFavoris,
    getMesPartenairesFavorisSuccess,
    getMesPartenairesRecents,
    getMesPartenairesRecentSuccess,
    addPartenaireToFavoris,
    removePartenaireFromFavoris,
    exporterPartenaires,
    updateLoadingExporterPartenaires,
    updateSortMesPartenairesFavoris,
    updateSortMesPartenairesRecents,
    updatePageMesPartenairesFavoris,
    updatePageMesPartenairesRecents,
    updatePageSizeMesPartenairesFavoris,
    updatePageSizeMesPartenairesRecents,
    updateLoadingMesPartenaires,
    updateShowDeleteFavoriPopup,
    checkIfPartenaireRecent
} from "./mesPartenaires.action";

export const redirectTo = pathname => push(pathname);

export {
    integrerFichierExcelFromFilepond,
    updateFilepondStore
} from "./filePond.action";
