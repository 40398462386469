import * as actionsType from "../actionTypes/actionTypes";

export const getMonProfil = gencod => ({
    type: actionsType.GET_MON_PROFIL,
    gencod
});

export const getMonProfilAvecNotification = gencod => ({
    type: actionsType.GET_MON_PROFIL_AVEC_NOTIFICATION,
    gencod
});


export const getMonProfilSuccess = datas => ({
    type: actionsType.GET_MON_PROFIL_SUCCESS,
    datas
});

export const updateLoadingMonProfil = loading => ({
    type: actionsType.UPDATE_LOADING_MON_PROFIL,
    loading
});

export const saveMonProfilCompteUtilisateur = compteUtilisateur => ({
    type: actionsType.SAVE_MON_PROFIL_COMPTE_UTILISATEUR,
    compteUtilisateur
});

export const saveMonProfilCompteUtilisateurSuccess = datas => ({
    type: actionsType.SAVE_MON_PROFIL_COMPTE_UTILISATEUR_SUCCESS,
    datas
});


export const saveContactGlobal = (contact, code, skipStep, mailModified) => ({
    type: actionsType.SAVE_MON_PROFIL_CONTACT_GLOBAL,
    contact,
    code,
    skipStep,
    mailModified
});


export const saveMonProfilContactSuccess = contact => ({
    type: actionsType.SAVE_MON_PROFIL_CONTACT_SUCCESS,
    contact
});


export const saveMonProfilContactsSuccess = contacts => ({
    type: actionsType.SAVE_MON_PROFIL_CONTACTS_SUCCESS,
    contacts
});

export const updateMonProfilContactMailSucess = (email, code, mailValide) => ({
    type: actionsType.UPDATE_MON_PROFIL_CONTACT_MAIL_SUCESS,
    email,
    code,
    mailValide
});


export const checkMailToken = (gencod, fonction, token, forcage) => ({
    type: actionsType.CHECK_MAIL_TOKEN,
    gencod,
    fonction,
    token,
    forcage
});

export const checkMailTokenSuccess = isMailChecked => ({
    type: actionsType.CHECK_MAIL_TOKEN_SUCCESS,
    isMailChecked
});


export const updateMonProfilDataNotSaved = dataNotSaved => ({
    type: actionsType.UPDATE_MON_PROFIL_DATA_NOT_SAVED,
    dataNotSaved
});

export const updateMonProfilLastStepPremiereConnexionFournisseur = () => ({
    type: actionsType.UPDATE_MON_PROFIL_LAST_STEP_FIRST_CO_FOURNI
});

export const updateVueCroisement = accesVueCroisement => ({
    type: actionsType.UPDATE_VUE_CROISEMENT,
    accesVueCroisement
});

export const updateVueCroisementSuccess = canAccess => ({
    type: actionsType.UPDATE_VUE_CROISEMENT_SUCCESS,
    canAccess
});

export const goToNextStepPremiereConnexion = () => ({
    type: actionsType.GO_TO_NEXT_STEP_PREMIERE_CONNEXION
});

export const goToPreviousStepPremiereConnexion = () => ({
    type: actionsType.GO_TO_PREVIOUS_STEP_PREMIERE_CONNEXION
});

export const updateSkipStepPremiereConnexion = (stepSkipped, stepToSkip) => ({
    type: actionsType.UPDATE_SKIP_STEP_PREMIERE_CONNEXION,
    stepSkipped,
    stepToSkip
});

export const setStepPremiereCo = step => ({
    type: actionsType.SET_STEP_FORCAGE_PREMIERE_CO,
    step
});

export const setLastValidatedStepPremiereCo = step => ({
    type: actionsType.SET_LAST_VALIDATED_STEP_PREMIERE_CO,
    step
});

export const setStepsPremiereCo = steps => ({
    type: actionsType.SET_STEPS_FORCAGE_PREMIERE_CO,
    steps
});

export const updateMonProfilPremiereConnexionInfos = (stepPremiereConnexion, steps, rdpDuplique, isFournisseur) => ({
    type: actionsType.UPDATE_MON_PROFIL_PREMIERE_CONNEXION_INFOS,
    stepPremiereConnexion,
    steps,
    rdpDuplique,
    isFournisseur
});

