import { call, put } from "@redux-saga/core/effects";
import * as actions from "../../actions/index.action";
import { isNomFichierAvailable } from "../../../services/integrationTableur.service";
import { ERROR_FILE_NAME_DIALOG_NOT_AVAILABLE, FILE_NAME_EXIST_VERIFICATION_FAILED } from "../../../utils/libelleConstantes";


export function *integrerFichierExcelFromFilepondSaga({ glnDeclarant, file, fileName, pathname }) {
    if (fileName) {
        try {
            const response = yield call(isNomFichierAvailable, glnDeclarant, fileName);
            if (response.data) {
                yield put(actions.integrerTableur(glnDeclarant, file, fileName, pathname));

                const initialFilepondState = {
                    openFileNameDialog: false,
                    fileName: "",
                    fileNameDialogErrorMessage: ""
                };
                yield put(actions.updateFilepondStore(initialFilepondState));
            } else {
                yield put(actions.updateFilepondStore({
                    fileNameDialogErrorMessage: ERROR_FILE_NAME_DIALOG_NOT_AVAILABLE
                }));
            }
        } catch (error) {
            yield put(actions.updateFilepondStore({
                fileNameDialogErrorMessage: FILE_NAME_EXIST_VERIFICATION_FAILED
            }));
        }
    }
}


