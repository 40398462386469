import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans } from "@lingui/macro";
import { ValidatorForm } from "react-material-ui-form-validator";
import FormContactInscription from "../formContactInscription.component";
import * as actions from "../../../../store/actions/index.action";
import { useStyles } from "../../../monProfil/monProfilUtil";
import { selectInscriptionState } from "../../../../utils/selectors";
import TextHeader from "../../../../commonComponents/texte/TextHeader.container";
import { LIBELLE_MON_PROFIL_CONTACT_INFO_RDP } from "../../../../utils/libelleConstantes";
import CopyEntiteDetailsCheckbox from "../copyEntiteDetailsCheckbox";
import { CONTACT_FONCTION, INSCRIPTION_RDP } from "../../../../utils/constantes";
import InscriptionSubmitButton from "../inscriptionSubmitButton";
import { enableInstantValidate } from "../../../../utils/utility";

export default function ResponsableDroitPret() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [applyEntityDetails, setApplyEntityDetails] = useState(false);
    const { inscriptionEntite, inscriptionRdp, profilDialog, formMailValide } = useSelector(selectInscriptionState);
    const [formulaire, setFormulaire] = useState(inscriptionRdp);
    const formReference = useRef();
    const formulaireRef = useRef();

    function updateFormMailRDPValide(statut) {
        dispatch(actions.updateFormulaireMailValide(CONTACT_FONCTION.RESPONSABLE_DROIT_DE_PRET, statut));
    }

    function getFormValide() {
        return !formMailValide.find(f => f.code === CONTACT_FONCTION.RESPONSABLE_DROIT_DE_PRET).valide;
    }

    useEffect(() => {
        formulaireRef.current = formulaire;
    }, [formulaire]);

    /*
     * Unmount hook
     * on met à jour le store seulement si on démonte le composant
     */
    useEffect(() => function unmount() {
        dispatch(actions.updateInscriptionRdpFormulaire(formulaireRef.current));
    }, []);

    function onSubmit(e) {
        e.preventDefault();
        // On met à jour le store comme quoi le formulaire est validé par l'utilisateur
        updateFormMailRDPValide(true);
        dispatch(actions.rechercheGlnBeforeInscription(profilDialog));
    }


    return <>
        <div className={classes.titreStepper}>
            <Trans id={INSCRIPTION_RDP} />
        </div>
        <div className={classes.alignLeft}>
            <div className={classes.affichageColonneCentree}>
                <TextHeader>
                    <Trans id={LIBELLE_MON_PROFIL_CONTACT_INFO_RDP}/>
                </TextHeader>
                <CopyEntiteDetailsCheckbox
                    formulaire={formulaire}
                    setFormulaire={setFormulaire}
                    applyEntityDetails={applyEntityDetails}
                    setApplyEntityDetails={setApplyEntityDetails}
                    entite={inscriptionEntite}/>
            </div>
            <ValidatorForm ref={formReference} onSubmit={onSubmit} instantValidate={false}>
                <FormContactInscription
                    formulaire={formulaire}
                    setFormulaire={setFormulaire}
                    showConfirmationInput={getFormValide()}
                    classes={classes}
                />
                <InscriptionSubmitButton id="saveRespDdpButton" onClick={() => enableInstantValidate(formReference)} lastStep/>
            </ValidatorForm>
        </div>
    </>;
}
