import { updateObject } from "../../../utils/utility";
import * as actionsType from "../../actionTypes/actionTypes";

const initialState = {
    ville: "",
    codePostal: "",
    firstCallSearchService: false,
    isDetailSearch: false,
    isCalledFromDeclaration: false,
    results: [],
    loading: false,
    filtre: {},
    showButtonAfterSearch: false,
    emptyDataMessage: ""
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionsType.SEARCH_GLN_ASSUJETTI:
        return updateObject(state, { results: [] });
    case actionsType.SEARCH_GLN_ASSUJETTI_START:
        return updateObject(state, { loading: true, emptyDataMessage: "tableLoading" });
    case actionsType.SEARCH_GLN_ASSUJETTI_END:
        return updateObject(state, { loading: false, emptyDataMessage: action.emptyDataMessage });
    case actionsType.SEARCH_GLN_ASSUJETTI_FAILED:
        return updateObject(state, { loading: false, emptyDataMessage: action.emptyDataMessage });
    case actionsType.UPDATE_RESULT_GLN:
        return updateObject(state, { results: action.results });
    case actionsType.UPDATE_RESULT_GLN_AND_ACTIVE_BUTTON:
        return updateObject(state, { results: action.results, showButtonAfterSearch: true });
    case actionsType.UPDATE_TYPE_SEARCH_GLN:
        return updateObject(state, { isDetailSearch: action.flag });
    case actionsType.UPDATE_FLAG_FIRST_SEARCH:
        return updateObject(state, { firstCallSearchService: action.value });
    case actionsType.UPDATE_FLAG_IS_CALLED_DECLARATION:
        return updateObject(state, { isCalledFromDeclaration: action.value });
    case actionsType.UPDATE_FILTRE_SEARCH_GLN:
        return updateObject(state, {
            filtre: action.value

        });
    case actionsType.INIT_RECHERCHE_GLN:
        return initialState;
    default:
        return state;
    }
};

export default reducer;
