import { updateObject } from "../../../../utils/utility";
import * as actionTypes from "../../../actionTypes/actionTypes";

const initialState = {
    loading: true,
    entetesAttendues: [],
    entetesIdentifiees: [],
    echantillon: [],
    confirmEnteteLoading: false
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.SET_INTEGRATION_TABLEUR_ENTETE_LOADING:
        return updateObject(state, {
            loading: action.loading
        });
    case actionTypes.GET_ECHANTILLON_ENTETE_IDENTIFIEES_ET_ATTENDUES_BY_ID_INTEGRATION_RESULT:
        return updateObject(state, {
            echantillon: action.echantillon,
            entetesIdentifiees: action.entetesIdentifiees,
            entetesAttendues: action.entetesAttendues
        });
    case actionTypes.UPDATE_CONFIRM_ENTETE_LOADING:
        return updateObject(state, {
            confirmEnteteLoading: action.flag
        });
    default:
        return state;
    }
};

export default reducer;
