import { call, put } from "@redux-saga/core/effects";
import { select } from "redux-saga/effects";
import {
    exportExcelLignesEANService,
    exportMensualisationBibliotheque,
    exportMensualisationFournisseur,
    getCountExportEanLignes
} from "../../../services/statistiquesDeclarations.service";
import * as actions from "../../actions/index.action";
import { selectState } from "../../../utils/selectors";
import { checkProfilConnected } from "../../../utils/utility";
import { ROLES } from "../../../utils/constantes";
import { getPartenairesAndFacturesFromDeclaration } from "../../../services/bibliotheque.service";
import { getFacturesEntetes } from "../../../services/factureEntete.service";
import {
    ERREUR_COUNT_EXPORT_LIGNES_EAN,
    ERREUR_EXPORT_LIGNES_EAN,
    ERREUR_EXPORT_MENUSUALISATION,
    ERREUR_LOAD_FACTURES,
    ERREUR_LOAD_PARTENAIRES
} from "../../../utils/libelleConstantes";


export function *exportMensualisationSaga(action) {
    const state = yield select(selectState);
    const { anneeDebut, anneeFin } = state.statistiquesDeclarations;
    const profil = checkProfilConnected(state.utilisateur.roles);
    const service = profil === ROLES.FOURNISSEUR ? exportMensualisationFournisseur : exportMensualisationBibliotheque;
    yield put(actions.setLoading(true));
    const data = {
        gencodDeclarant: action.gencodDeclarant,
        anneeDebut,
        anneeFin
    };
    try {
        yield call(service, data);
        yield put(actions.setLoading(false));
    } catch (error) {
        yield put(actions.setLoading(false));
        yield put(actions.openDialogNoRedirect(ERREUR_EXPORT_MENUSUALISATION, error.message));
    }
}


export function *exportLignesEanSaga() {
    yield put(actions.setLoading(true));
    const state = yield select(selectState);
    const { anneeCivile, selectedPartenaires, selectedFactures } = state.statistiquesDeclarations;
    const data = {
        gencodDeclarant: state.utilisateur.informations.gencod,
        annee: anneeCivile,
        gencodPartenairesList: selectedPartenaires.map(p => p.gencod),
        numeroFacturesList: selectedFactures.map(f => f.numeroFacture)
    };
    try {
        yield call(exportExcelLignesEANService, data);
        yield put(actions.setLoading(false));
    } catch (error) {
        yield put(actions.setLoading(false));
        yield put(actions.openDialogNoRedirect(ERREUR_EXPORT_LIGNES_EAN, error.message));
    }
}

/* TODO a mettre en commun à tous les services */
export function *loadFiltreData(service, loadingAction, filtreAction, filtreBody, errorKeyMessage) {
    yield put(loadingAction(true));
    try {
        const response = yield call(service, filtreBody);
        yield put(filtreAction(response.data));
        yield put(loadingAction(false));
    } catch (error) {
        yield put(loadingAction(false));
        yield put(actions.openDialog(errorKeyMessage));
    }
}

export function *loadPartenairesAndFacturesSaga(action) {
    yield put(actions.updateAnneeCivile(action.annee));
    if (!action.annee) {
        yield put(actions.reinitAllFiltre());
    } else {
        const state = yield select(selectState);
        const { updateLoadingPartenairesFactures, updateFiltresPartenairesAndFactures } = actions;
        const filtres = {
            gencodDeclarant: state.utilisateur.informations.gencod,
            annee: action.annee
        };
        yield call(
            loadFiltreData, getPartenairesAndFacturesFromDeclaration, updateLoadingPartenairesFactures, updateFiltresPartenairesAndFactures
            , filtres, ERREUR_LOAD_PARTENAIRES
        );
    }
}

export function *loadFacturesSaga() {
    const state = yield select(selectState);
    const { updateLoadingFactures, updateFiltresFactures } = actions;
    const { anneeCivile, selectedPartenaires } = state.statistiquesDeclarations;
    const filtre = {
        gencodDeclarant: state.utilisateur.informations.gencod,
        annee: anneeCivile,
        gencodPartenaires: selectedPartenaires.map(p => p.gencod)
    };
    yield call(loadFiltreData, getFacturesEntetes, updateLoadingFactures, updateFiltresFactures, filtre, ERREUR_LOAD_FACTURES);
}

export function *getCountExportEanLignesSaga() {
    const state = yield select(selectState);
    const { updateCountExportLigneEan, updateLoadingCountExportLigneEan } = actions;
    const { anneeCivile, selectedPartenaires, selectedFactures } = state.statistiquesDeclarations;
    const filtre = {
        gencodDeclarant: state.utilisateur.informations.gencod,
        annee: anneeCivile,
        gencodPartenairesList: selectedPartenaires.map(p => p.gencod),
        numeroFacturesList: selectedFactures.map(f => f.numeroFacture)
    };
    yield call(loadFiltreData, getCountExportEanLignes, updateLoadingCountExportLigneEan, updateCountExportLigneEan, filtre, ERREUR_COUNT_EXPORT_LIGNES_EAN);
}
