import { reducerDoute, updateObject } from "../../../../utils/utility";
import * as actionTypes from "../../../actionTypes/actionTypes";

const initialState = {
    loading: true,
    declarations: [],
    rowIndex: -1,
    loadingSentIntegration: false,
    openDetailDialog: false
};

const updateDeclarationLigneForEntete = (state, action) => {
    const entetes = [...state.declarations.entetes];
    const entete = {
        ...entetes[action.rowIndex],
        lignes: action.lignes
    };
    entetes.splice(action.rowIndex, 1, entete);
    return updateObject(state, {
        declarations: {
            ...state.declarations,
            entetes
        }
    });
};

const updateDeclarationEnteteStatus = (state, action) => {
    if (state.declarations.entetes) {
        const entetes = [...state.declarations.entetes];
        const numeroDeclaration = Array.isArray(action.numeroDeclaration) ? action.numeroDeclaration[0] : action.numeroDeclaration;
        const rowIndex = entetes.findIndex(e => e.entete.numeroDeclaration.cellValue === numeroDeclaration);
        const entete = {
            ...entetes[rowIndex],
            entete: {
                ...entetes[rowIndex].entete,
                valide: {
                    ...entetes[rowIndex].entete.valide,
                    cellValue: action.toBeAbandon ? "false" : "true"
                }
            }
        };
        entetes.splice(rowIndex, 1, entete);
        return updateObject(state, {
            declarations: {
                ...state.declarations,
                entetes
            }
        });
    }
    return state;
};

const prepareLigneIntegration = ligne => {
    // eslint-disable-next-line no-unused-vars
    const { douteuse, canBeMergedByEan, canBeMergedByTitleAndEditor, numeroLigne, valide, ...resteLigne } = ligne;
    const doute = Object.keys(ligne).reduce((prevVal, key) => reducerDoute(prevVal, ligne[key]), false);
    return {
        douteuse: doute,
        numeroLigne,
        valide,
        ligne: resteLigne
    };
};


const updateDeclarationEnteteEtLignes = (state, action) => {
    const entetes = state.declarations.entetes;
    const hasEnteteDouteuse = Object.keys(action.entete).reduce((prevVal, key) => reducerDoute(prevVal, action.entete[key]), false);
    const hasLigneDouteuse = action.lignes.reduce((accumulator, ligne) => Object.keys(ligne).reduce((prevVal, key) => reducerDoute(prevVal, ligne[key]), accumulator), false);
    const hasDoute = hasEnteteDouteuse || hasLigneDouteuse;
    const rowIndex = entetes.findIndex(e => e.entete.numeroDeclaration.cellValue === action.entete.numeroDeclaration.cellValue);
    const newEntete = {
        ...entetes[rowIndex],
        entete: {
            ...entetes[rowIndex].entete,
            glnPartenaire: {
                ...entetes[rowIndex].entete.glnPartenaire,
                cellValue: action.partenaire.gencod
            },
            raisonSociale: {
                ...entetes[rowIndex].entete.raisonSociale,
                cellValue: action.partenaire.raisonSociale
            },
            ville: {
                ...entetes[rowIndex].entete.ville,
                cellValue: action.partenaire.ville
            },
            ...action.entete
        },
        lignes: action.lignes.map(item => prepareLigneIntegration(item)),
        douteuse: hasDoute
    };
    return updateObject(state, {
        declarations: {
            ...state.declarations,
            entetes: [...entetes.slice(0, rowIndex), newEntete, ...entetes.slice(rowIndex + 1)]
        }
    });
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.SET_INTEGRATION_TABLEUR_ENTETE_LOADING:
        return updateObject(state, {
            loading: action.loading
        });
    case actionTypes.GET_LIST_DECLARATIONS_ENTETE_BY_INTEGRATION_RESULT:
        return updateObject(state, {
            declarations: action.declarations
        });
    case actionTypes.GET_LIST_DECLARATIONS_LIGNE_BY_NUMERO_DECLARATION_RESULT:
        return updateDeclarationLigneForEntete(state, action);
    case actionTypes.REINIT_INTEGRATION_RECAP_DECLARATION:
        return updateObject(state, {
            declarations: [],
            rowIndex: -1
        });
    case actionTypes.UPDATE_DECLARATION_ENTETE_STATUS_SUCCESS:
        return updateDeclarationEnteteStatus(state, action);
    case actionTypes.UPDATE_DECLARATION_ENTETE_ET_LIGNES:
        return updateDeclarationEnteteEtLignes(state, action);
    case actionTypes.SET_SENT_INTEGRATION_LOADING:
        return updateObject(state, {
            loadingSentIntegration: action.loading
        });
    case actionTypes.UPDATE_ROW_INDEX_INTEGRATION:
        return updateObject(state, {
            rowIndex: action.index
        });
    case actionTypes.UPDATE_IE_OPEN_DETAIL_DIALOG:
        return updateObject(state, {
            openDetailDialog: action.open
        });
    default:
        return state;
    }
};

export default reducer;
