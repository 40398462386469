import { FormControlLabel, RadioGroup } from "@material-ui/core";
import React from "react";
import { Trans } from "@lingui/macro";
import RadioSmallDefault from "../radio/RadioSmallDefault.component";
import { LIBELLE_NON, LIBELLE_OUI } from "../../utils/libelleConstantes";

export default function YesNoRadioButton({ yes, handleChange, className, disabled }) {
    return (
        <RadioGroup aria-label="yes-no" name="yes-no" value={yes} onChange={handleChange} className={className}>
            <FormControlLabel value={true} control={<RadioSmallDefault/>} label={<Trans id={LIBELLE_OUI}/>} labelPlacement="start" disabled={disabled}/>
            <FormControlLabel value={false} control={<RadioSmallDefault/>} label={<Trans id={LIBELLE_NON}/>} labelPlacement="start" disabled={disabled}/>
        </RadioGroup>
    );
}
