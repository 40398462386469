import React from "react";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import StyledTooltip from "../../../../../commonComponents/tooltip/styledTooltip.component";


const useStyles = makeStyles(theme => ({
    tooltip: {
        backgroundColor: "#F3913A",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 500,
        fontSize: theme.typography.pxToRem(15)
    }
}));

function CellFormaterTooltip({ keyMessageArray, cell, valid = true }) {
    const classes = useStyles();
    return <div>
        <StyledTooltip classes={classes} isUniqueKeyMessage={false} keyMessageArray={keyMessageArray}>
            <span>
                <strong style={ { fontWeight: "bold", fontStyle: "italic", color: "#F3913A", textDecoration: valid ? "" : "line-through" } }>{cell}</strong>
            </span>
        </StyledTooltip>
    </div>;
}

CellFormaterTooltip.propTypes = {
    cell: PropTypes.string,
    keyMessageArray: PropTypes.array
};

export default CellFormaterTooltip;
