import * as actionsTypes from "../../actionTypes/actionTypes";
import { getInscriptionContactModel, updateObject } from "../../../utils/utility";
import * as model from "../../../utils/model";
import { CONTACT_FONCTION } from "../../../utils/constantes";


export const initialiseState = {
    profilDialog: "",
    hiddenInput: true,
    // La recherche n'a pas encore eu lieu si null
    resultsGLN: null,
    inscriptionEntite: model.inscriptionEntite,
    inscriptionRL: getInscriptionContactModel(CONTACT_FONCTION.REPRESENTANT_LEGAL),
    inscriptionRdp: getInscriptionContactModel(CONTACT_FONCTION.RESPONSABLE_DROIT_DE_PRET),
    numeroInscription: "",
    messageConfirmation: "inscriptionVeuillezPatienter",
    formMailValide: [{ code: CONTACT_FONCTION.REPRESENTANT_LEGAL, valide: false }, { code: CONTACT_FONCTION.RESPONSABLE_DROIT_DE_PRET, valide: false }],
    saveInscriptionOk: true,
    loading: false,
    isFormulaireStarted: false,
    isGencodSelected: false
};

const initStateInscription = state => ({
    ...state,
    inscriptionEntite: { ...model.inscriptionEntite },
    inscriptionRL: { ...getInscriptionContactModel(CONTACT_FONCTION.REPRESENTANT_LEGAL) },
    inscriptionRdp: { ...getInscriptionContactModel(CONTACT_FONCTION.RESPONSABLE_DROIT_DE_PRET) },
    numeroInscription: "",
    messageConfirmation: "inscriptionVeuillezPatienter"
});

const onChangeHiddenInput = (state, action) => updateObject(state, {
    hiddenInput: action.value,
    inscriptionEntite: {
        ...state.inscriptionEntite
    }
});

const updateNumeroInscription = (state, action) => updateObject(state, {
    numeroInscription: action.numeroInscription
});

const updateMessageConfirmation = (state, action) => updateObject(state, {
    messageConfirmation: action.messageConfirmation
});


const onChangePorfilDialog = (state, action) => updateObject(state, { profilDialog: action.value });

function updateFormContactValide(state, action) {
    const newFormMailValide = state.formMailValide.map(a => a.code === action.code ? { ...a, valide: action.statut } : a);
    return updateObject(state, { formMailValide: newFormMailValide });
}

const reducer = (state = initialiseState, action) => {
    switch (action.type) {
    case actionsTypes.UPDATE_INSCRIPTION_ENTITE_FORMULAIRE:
        return updateObject(state, { inscriptionEntite: action.formulaire });

    case actionsTypes.UPDATE_INSCRIPTION_RL_FORMULAIRE:
        return updateObject(state, { inscriptionRL: action.formulaire });

    case actionsTypes.UPDATE_INSCRIPTION_RDP_FORMULAIRE:
        return updateObject(state, { inscriptionRdp: { ...action.formulaire, fonction: state.inscriptionRdp.fonction } });

    case actionsTypes.INIT_STATE_INSCRIPTION:
        return initStateInscription(state);
    case actionsTypes.CHANGE_HIDDEN_INPUT:
        return onChangeHiddenInput(state, action);
    case actionsTypes.SELECT_PROFIL_ON_DIALOGN:
        return onChangePorfilDialog(state, action);
    case actionsTypes.UPDATE_NUMERO_INSCRIPTION:
        return updateNumeroInscription(state, action);
    case actionsTypes.UPDATE_MESSAGE_CONFIRMATION:
        return updateMessageConfirmation(state, action);
    case actionsTypes.UPDATE_STATUT_SAVE_INSCRIPTION:
        return updateObject(state, { saveInscriptionOk: action.state });
    case actionsTypes.UPDATE_FORMULAIRE_MAIL_VALIDE:
        return updateFormContactValide(state, action);
    case actionsTypes.UPDATE_LOADING_INSCRIPTION:
        return ({
            ...state,
            loading: action.loading
        });
    case actionsTypes.UPDATE_INSCRIPTION_IS_FORMULAIRE_STARTED:
        return updateObject(state, { isFormulaireStarted: action.isFormulaireStarted });
    case actionsTypes.UPDATE_INSCRIPTION_IS_GENCOD_SELECTED:
        return updateObject(state, { isGencodSelected: action.isGencodSelected });
    default:
        return state;
    }
};

export default reducer;
