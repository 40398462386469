import { Trans } from "@lingui/macro";
import { makeStyles, Tooltip } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import TranslationMultipeKeyContainer from "../transalation/translationMultipeKey.container";

const useStylesDefault = makeStyles(theme => ({
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(15)
    }
}));

export default function StyledTooltip(props) {
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        if (props.keyMessage || (props.keyMessageArray && props.keyMessageArray.length > 0)) {
            setOpen(true);
        }
    };

    const renderTooltip = isUniqueKeyMessage => {
        const value = props.keyParam;
        if (isUniqueKeyMessage) {
            const keyMessage = props.keyMessage ? props.keyMessage.toString() : "";
            return <Trans id={keyMessage} values={{ value }}/>;
        }
        return <TranslationMultipeKeyContainer keyMessageArray={props.keyMessageArray}/>;
    };

    if (props.disableHoverListener && open === false) {
        handleOpen();
    }

    const classesDefault = useStylesDefault();
    return <Tooltip
        classes={props.classes ? props.classes : classesDefault}
        open={open && props.show}
        onOpen={handleOpen}
        onClose={handleClose}
        title={renderTooltip(props.isUniqueKeyMessage)}
        disableHoverListener={props.disableHoverListener}
        placement={props.placement}
        arrow>
        {props.children}
    </Tooltip>;
}

StyledTooltip.propTypes = {
    keyParam: PropTypes.string,
    classes: PropTypes.object,
    show: PropTypes.bool,
    disableHoverListener: PropTypes.bool,
    placement: PropTypes.string,
    isUniqueKeyMessage: PropTypes.bool,
    keyMessageArray: PropTypes.array
};
StyledTooltip.defaultProps = {
    show: true,
    disableHoverListener: false,
    placement: "bottom",
    isUniqueKeyMessage: true,
    keyMessageArray: []
};
