import { Grid, makeStyles, Slider } from "@material-ui/core";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles({
    spaceForLabel: {
        paddingTop: 38,
        paddingLeft: 15,
        paddingRight: 15
    }
});

export function AnneeFilter({ debut, fin, updatePeriod }) {
    const classes = useStyles();
    const [hasChangePeriod, setChangePeriod] = useState(false);
    const [debutState, changeDebut] = useState(debut);
    const [finState, changeFin] = useState(fin);

    const changePeriod = (event, value) => {
        changeDebut(value[0]);
        changeFin(value[1]);
        // Permet de savoir que l'utilisateur utilise le slider
        setChangePeriod(true);
    };

    const updateChangePeriod = () => {
        // Si l'utilisateur utilisait le slider
        if (hasChangePeriod) {
            // On déclanche le useEffect quand l'utilisateur relache la souris
            setChangePeriod(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mouseup", updateChangePeriod);
        return () => document.removeEventListener("mouseup", updateChangePeriod);
    });

    useEffect(() => {
        // Met à jour la période quel que soit l'endroit ou la souris est relachée
        if (!hasChangePeriod && (debutState !== debut || finState !== fin)) {
            updatePeriod([debutState, finState], debut, fin);
        }
    }, [hasChangePeriod, debutState, finState]);


    return (
        <>
            <Grid container>
                <Grid item xs={12} className={classes.spaceForLabel}>
                    <Slider
                        id={"slider-period"}
                        value={[debutState, finState]}
                        min={2013}
                        max={new Date().getUTCFullYear()}
                        onChange={changePeriod}
                        valueLabelDisplay="on"
                        aria-labelledby="annee-slider"
                    />
                </Grid>
            </Grid>
        </>
    );
}


export default AnneeFilter;
