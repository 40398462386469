import { call, put, select } from "redux-saga/effects";
import { push } from "connected-react-router";
import * as actions from "../../actions/index.action";
import { selectNotifications, selectUtilisateurGencod } from "../../../utils/selectors";
import { checkEcartDeclarationService, getAllNotificationsService, updateVueService } from "../../../services/notification.service";
import { PATH_LOGIN } from "../../../routes/paths/paths.util";
import { postNousContacter } from "../../../services/common.service";
import {
    ERREUR_ALL_NOTIFICATIONS,
    ERREUR_NOUS_CONTACTER_SEND,
    ERREUR_UPDATE_NOTIFICATIONS,
    ERREUR_VERIFIER_ECART,
    NOUS_CONTACTER_ENVOI_SNACKBAR
} from "../../../utils/libelleConstantes";


export function *checkIfEcartIsDetectedSaga({ gencod }) {
    try {
        const state = yield select(selectNotifications);
        const ecartNotifie = state.ecartDeclaration;
        const response = yield call(checkEcartDeclarationService, gencod);
        if (response.data) {
            if (!ecartNotifie) {
                // Si le back renvoi un écart et qu'il n'était pas encore notifié on l'ajoute
                yield put(actions.incrementNbNotifications(1));
            }
        } else if (ecartNotifie) {
            // Si le back ne renvoi pas d'écart et qu'il était notifié on l'enlève
            yield put(actions.decrementNbNotifications(1));
        }
        yield put(actions.updateEcartDetecte(response.data));
    } catch (error) {
        yield put(actions.openDialogNoRedirect(ERREUR_VERIFIER_ECART, error.message));
    }
}

export function *getAllNotificationsSaga({ gencod, avecEcart }) {
    yield put(actions.updateNotificationsLoading(true));
    try {
        // Le redux saga effect CALL se fait via 'getWithCancel' dans notification.service.js
        const response = yield getAllNotificationsService(gencod, avecEcart);
        yield put(actions.updateNotificationsSuccess(response.data, avecEcart));
    } catch (error) {
        yield put(actions.openDialogNoRedirect(ERREUR_ALL_NOTIFICATIONS + gencod, error.message));
    }
    yield put(actions.updateNotificationsLoading(false));
}

export function *updateVueSaga() {
    const gencodDeclarant = yield select(selectUtilisateurGencod);
    try {
        yield call(updateVueService, gencodDeclarant);
    } catch (error) {
        yield put(actions.openDialogNoRedirect(ERREUR_UPDATE_NOTIFICATIONS + gencodDeclarant, error.message));
    }
}

export function *sendMessageNousContacterSaga({ data }) {
    try {
        yield call(postNousContacter, data);
        yield put(actions.updateFlagShowAndMessageSnackbar(true, NOUS_CONTACTER_ENVOI_SNACKBAR));
    } catch (error) {
        yield put(actions.openDialogNoRedirect(ERREUR_NOUS_CONTACTER_SEND, error.message));
    }
    yield put(push(PATH_LOGIN));
}
