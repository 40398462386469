import React from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import { useDispatch, useSelector } from "react-redux";
import { LIBELLE_ANNUAIRE } from "../../utils/libelleConstantes";
import RechercherAssujettiCore from "../../components/rechercheAssujetti/rechercheAssujettiCore";
import * as actions from "../../store/actions/index.action";
import { ClosableDialogTitle } from "./ClosableDialogTitle.component";

export default function RechercheAnnuaireDialog({ open, close, disabledChoice = false }) {
    const enableClosePopup = useSelector(state => !state.rechercheGLN.loading);
    const closeIfEnabled = () => {
        if (enableClosePopup) {
            close();
        }
    };
    const dispatch = useDispatch();
    return <div>
        <Dialog open={open} fullWidth={true} maxWidth={"xl"} style={{ textAlign: "justify" }} >
            <ClosableDialogTitle onClose={closeIfEnabled}>
                <Trans id={LIBELLE_ANNUAIRE}/>
            </ClosableDialogTitle>
            <DialogContent>
                <RechercherAssujettiCore
                    updateAssujetti={assuj => {
                        dispatch(actions.onChangeLogin(assuj.gencod));
                        close();
                        window.scrollTo(0, 0);
                    }}
                    typeProfilConnected={""}
                    isCallFromDeclaration={false}
                    disabledChoice={disabledChoice}
                    onClose={close}
                />
            </DialogContent>
        </Dialog>
    </div>;
}
