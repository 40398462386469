import React from "react";
import { ROUTE_MENU_PROFIL_TAB } from "../../../routes/paths/paths.util";
import Guard from "../../guard/Guard";

export default function MonProfilTabGuard({ children, tab, tabs }) {
    return <Guard
        isParamValid={() => tabs.some(t => t === tab)}
        currentRoute={ROUTE_MENU_PROFIL_TAB}>
        {children}
    </Guard>;
}
