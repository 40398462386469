import axios from "axios";
import { PROFIL_BIBLIOTHEQUE, PROFIL_FOURNISSEUR } from "../utils/constantes";

function getUrl (typeProfil) {
    let url = "";
    if (typeProfil === PROFIL_FOURNISSEUR) {
        url = "/fournisseur/public/rechercheAssujetti";
    }
    if (typeProfil === PROFIL_BIBLIOTHEQUE) {
        url = "/bibliotheque/public/rechercheAssujetti";
    }
    return url;
}

export function getUrlForSearchVille (typeProfil) {
    let url = "";
    if (typeProfil === PROFIL_FOURNISSEUR) {
        url = "/fournisseur/public/getVilles";
    }
    if (typeProfil === PROFIL_BIBLIOTHEQUE) {
        url = "/bibliotheque/public/getVilles";
    }
    return url;
}

export function getUrlRechercheInscription (typeProfil) {
    let url = "";
    if (typeProfil === PROFIL_FOURNISSEUR) {
        url = "/fournisseur/public/rechercheInscription";
    }
    if (typeProfil === PROFIL_BIBLIOTHEQUE) {
        url = "/bibliotheque/public/rechercheInscription";
    }
    return url;
}

export const searchGLN = (typeProfil, filter) => axios.post(getUrl(typeProfil), filter);

export const searchListVilles = (typeProfil, filter) => axios.post(getUrlForSearchVille(typeProfil), filter);


export const searchGlnInscriptionFournisseur = filter => axios.post("/fournisseur/public/rechercheInscription", filter);
export const searchGlnInscriptionBibliotheque = filter => axios.post("/bibliotheque/public/rechercheInscription", filter);
export const searchGlnReferencementDeclarant = filter => axios.post("/declarant/rechercheAssujettiByGencod", filter);
