import _ from "lodash";
import * as actionsType from "../../actionTypes/actionTypes";
import { updateObject } from "../../../utils/utility";


export const initialState = {
    attestations: [],
    loading: false
};

function addLoadingToAttestations({ attestations }) {
    if (_.isEmpty(attestations)) {
        return [];
    }

    return attestations.map(attestation => ({
        ...attestation,
        loading: false
    }));
}

function setAttestationLoading(state, { annee, loading }) {
    const attestations = state.attestations;
    if (_.isEmpty(attestations)) {
        return [];
    }

    attestations.find(attestation => attestation.annee === annee).loading = loading;
    return [...attestations];
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionsType.ATTESTATION_FIN_GET_ATTESTATIONS_SUCCESS:
        return updateObject(state, { attestations: addLoadingToAttestations(action) });
    case actionsType.ATTESTATION_FIN_LOADING:
        return updateObject(state, { loading: action.loading });
    case actionsType.ATTESTATION_FIN_ANNEE_LOADING:
        return updateObject(state, { attestations: setAttestationLoading(state, action) });
    default:
        return state;
    }
};

export default reducer;
