import {
    CONFIRMATION_CONTENT,
    INSCRIPTION_CONFIRMATION,
    INSCRIPTION_ENTITE,
    INSCRIPTION_ENTITE_CONTENT,
    INSCRIPTION_RDP, INSCRIPTION_RECHERCHE_GLN,
    INSCRIPTION_RL, RECHERCHE_GLN_AVANCEE,
    RECHERCHE_PARTENAIRE_FORMULAIRE,
    REPRESENTANT_LEGAL_CONTENT,
    RESPONSABLE_DROIT_PRET_CONTENT
} from "./constantes";
import { LIBELLE_REFERENCEMENT_STEP_0 } from "./libelleConstantes";

export const steps_inscription = [
    {
        label: INSCRIPTION_ENTITE,
        component: INSCRIPTION_ENTITE_CONTENT
    },
    {
        label: INSCRIPTION_RL,
        component: REPRESENTANT_LEGAL_CONTENT
    },
    {
        label: INSCRIPTION_RDP,
        component: RESPONSABLE_DROIT_PRET_CONTENT
    },
    {
        label: INSCRIPTION_CONFIRMATION,
        component: CONFIRMATION_CONTENT
    }
];


export const steps_demande_referencement = [
    {
        label: LIBELLE_REFERENCEMENT_STEP_0,
        component: RECHERCHE_PARTENAIRE_FORMULAIRE
    },
    {
        label: INSCRIPTION_RECHERCHE_GLN,
        component: RECHERCHE_GLN_AVANCEE
    }
];
