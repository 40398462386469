import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import CustomAutoComplete from "../../../commonComponents/autocomplete/Autocomplete";
import { generateArrayOfYears } from "../../../utils/utility";
import { globalI18n } from "../../../i18n.conf";
import * as actions from "../../../store/actions/index.action";


const useStyle = makeStyles({
    autoComplete: {
        width: 180,
        paddingLeft: "20px",
        paddingBottom: "20px"
    }
});

function AnneeStatistiqueFiltre({ anneeCivile, initDataFiltres }) {
    const classes = useStyle();

    const changeAnneeCivile = (event, date) => {
        initDataFiltres(date);
    };

    return <CustomAutoComplete
        options={generateArrayOfYears()}
        onChange={changeAnneeCivile}
        getLabel={option => option}
        getOptionSelected={option => option}
        label={globalI18n._("annee")}
        value={anneeCivile}
        multiple={false}
        disableCloseOnSelect={false}
        className={classes.autoComplete}
    />;
}

const mapStateToProps = state => ({
    anneeCivile: state.statistiquesDeclarations.anneeCivile
});

const mapDispatchToProps = dispatch => ({
    initDataFiltres: annee => dispatch(actions.updateStoreAndLoadPartenairesAndFactures(annee))
});

export default connect(mapStateToProps, mapDispatchToProps)(AnneeStatistiqueFiltre);

