import {
    LIBELLE_ADMINISTRATIF,
    LIBELLE_AJOUTER_PARTENAIRE_BIB,
    LIBELLE_AJOUTER_PARTENAIRE_FOURNI,
    LIBELLE_ATTESTATION_FIN_DECALARATION,
    LIBELLE_COMMUNICATION_ACTUS,
    LIBELLE_COMMUNICATION_DDP,
    LIBELLE_DEMANDE_REFERENCEMENT,
    LIBELLE_ECARTS_BIB_FOUR,
    LIBELLE_MES_DECLARATIONS,
    LIBELLE_MES_FACTURES,
    LIBELLE_MES_PARTENAIRES_BIB,
    LIBELLE_MES_PARTENAIRES_FOURNI,
    LIBELLE_MES_STATISTIQUES,
    LIBELLE_MON_PROFIL,
    LIBELLE_NOUVELLE_DECLARATION,
    LIBELLE_PREMIERE_CONNEXION,
    LIBELLE_RECAPITULATIF_VENTES
} from "../../utils/libelleConstantes";

export const PATH_ADMIN = "/admin";
export const PATH_FOURNI_MENU = "/fourniMenu";
export const PATH_BIBLIO_MENU = "/biblioMenu";
export const PATH_BASE = "/";

export const getBaseMenu = isFourni => isFourni ? PATH_FOURNI_MENU : PATH_BIBLIO_MENU;

/* Pages accessibles sans connexion */
export const PATH_LOGIN = "/login";
export const PATCH_INSCRIPTION = "/inscription";
export const PATH_MDPOUBLIE = "/mdpOublie";
export const PATH_NOUS_CONTACTER = "/nous-contacter";
export const PATH_RESET_MDP = "/resetMdp/gencod/:gencod/token/:token";
export const PATH_MODIFICATION_MAIL_CONTACT =
    "/monProfil/checkMailToken/gencod/:gencod/fonction/:fonction/token/:token";

/* Première connexion */
export const PATH_PREMIERE_CONNEXION = `/${LIBELLE_PREMIERE_CONNEXION}`;
export const ROUTE_PREMIERE_CONNEXION = `/**${PATH_PREMIERE_CONNEXION}`;

/* Mon profil */
export const PATH_MON_PROFIL = `/${LIBELLE_MON_PROFIL}`;
export const ROUTE_MENU_PROFIL = `/**${PATH_MON_PROFIL}`;
export const ROUTE_MENU_PROFIL_TAB = `${ROUTE_MENU_PROFIL}/:tab`;

/* Mes partenaires */
export const PATH_PARTENAIRES_BIB = `/${LIBELLE_MES_PARTENAIRES_BIB}`;
export const PATH_PARTENAIRES_FOURNI = `/${LIBELLE_MES_PARTENAIRES_FOURNI}`;
export const PATH_PARTENAIRES_REFERENCEMENT_BIB = `${PATH_PARTENAIRES_BIB}/${LIBELLE_AJOUTER_PARTENAIRE_BIB}`;
export const PATH_PARTENAIRES_REFERENCEMENT_FOURNI = `${PATH_PARTENAIRES_FOURNI}/${LIBELLE_AJOUTER_PARTENAIRE_FOURNI}`;
export const ROUTE_MENU_PARTENAIRES_REFERENCES_BIB = `/**${PATH_PARTENAIRES_BIB}`;
export const ROUTE_MENU_PARTENAIRES_REFERENCES_FOURNI = `/**${PATH_PARTENAIRES_FOURNI}`;
export const ROUTE_MENU_PARTENAIRES_REFERENCEMENT_BIB = `/**${PATH_PARTENAIRES_REFERENCEMENT_BIB}`;
export const ROUTE_MENU_PARTENAIRES_REFERENCEMENT_FOURNI = `/**${PATH_PARTENAIRES_REFERENCEMENT_FOURNI}`;

export const getMenuPartenairePath = isFourni => isFourni ? PATH_PARTENAIRES_BIB : PATH_PARTENAIRES_FOURNI;

/* Nouvelle déclaration */
export const PATH_DECLARATION = `/${LIBELLE_NOUVELLE_DECLARATION}`;
/*
 * J'aurais préféré utiliser les variables de type mais elles renvoient undefined ?
 * export const PATH_DECLARATION_WEB = `${PATH_DECLARATION}/${TYPE_WEB}`;
 * export const PATH_DECLARATION_EXCEL = `${PATH_DECLARATION}/${TYPE_EXCEL}`;
 */
export const PATH_DECLARATION_WEB = `${PATH_DECLARATION}/WEB`;
export const PATH_DECLARATION_EXCEL = `${PATH_DECLARATION}/XLS`;
export const ROUTE_MENU_DECLARATION = `/**${PATH_DECLARATION}`;
export const ROUTE_MENU_DECLARATION_WEB = `/**${PATH_DECLARATION_WEB}`;
export const ROUTE_MENU_DECLARATION_EXCEL = `/**${PATH_DECLARATION_EXCEL}`;
export const ROUTE_MENU_DECLARATION_EXCEL_FICHIER = `${ROUTE_MENU_DECLARATION_EXCEL}/:id`;

/* Demande référencement */
export const PATH_DEMANDE_REFERENCEMENT = `/${LIBELLE_DEMANDE_REFERENCEMENT}`;
export const ROUTE_DEMANDE_REFERENCEMENT = `/**${PATH_DEMANDE_REFERENCEMENT}`;

/* Mes déclarations */
export const PATH_MENU_MES_DECLARATIONS = `/${LIBELLE_MES_DECLARATIONS}`;
export const ROUTE_MENU_MES_DECLARATIONS = `/**${PATH_MENU_MES_DECLARATIONS}`;
export const ROUTE_MENU_MES_DECLARATIONS_TYPE = `${ROUTE_MENU_MES_DECLARATIONS}/:type`;
export const REGEX_URL_MES_DECLARATIONS = new RegExp(`.+${LIBELLE_MES_DECLARATIONS}`);

/* Factures */
export const PATH_MENU_FACTURES = `/${LIBELLE_MES_FACTURES}`;
export const ROUTE_MENU_FACTURES = `${PATH_FOURNI_MENU}${PATH_MENU_FACTURES}`; // Uniquement pour le menu Founisseur

/* Administratif */
export const PATH_MENU_ADMINISTRATIF = `/${LIBELLE_ADMINISTRATIF}`;
export const PATH_MENU_ATTESTATION_FIN_DECLARATIONS = `${PATH_MENU_ADMINISTRATIF}/${LIBELLE_ATTESTATION_FIN_DECALARATION}`;
export const PATH_MENU_MES_STATISTIQUES = `${PATH_MENU_ADMINISTRATIF}/${LIBELLE_MES_STATISTIQUES}`;
export const PATH_MENU_ECARTS_DECLARATION = `${PATH_MENU_ADMINISTRATIF}/${LIBELLE_ECARTS_BIB_FOUR}`;
export const PATH_MENU_RECAPITULATIF_VENTES = `${PATH_MENU_ADMINISTRATIF}/${LIBELLE_RECAPITULATIF_VENTES}`;
export const ROUTE_MENU_ATTESTATION_FIN_DECLARATIONS = `*${PATH_MENU_ATTESTATION_FIN_DECLARATIONS}`;
export const ROUTE_MENU_MES_STATISTIQUES = `/**${PATH_MENU_MES_STATISTIQUES}`;
export const ROUTE_MENU_ECARTS_DECLARATION = `/**${PATH_MENU_ECARTS_DECLARATION}`;
export const ROUTE_MENU_RECAPITULATIF_VENTES = `${PATH_FOURNI_MENU}${PATH_MENU_RECAPITULATIF_VENTES}`; // Uniquement pour le menu Founisseur

/* Communication */
export const PATH_MENU_COMMUNICATION_DDP = `/${LIBELLE_COMMUNICATION_DDP}`;
export const PATH_MENU_COMMUNICATION_ACTUS = `/${LIBELLE_COMMUNICATION_ACTUS}`;
export const ROUTE_MENU_COMMUNICATION_DDP = `/**${PATH_MENU_COMMUNICATION_DDP}`;
export const ROUTE_MENU_COMMUNICATION_ACTUS = `/**${PATH_MENU_COMMUNICATION_ACTUS}`;
