import React, { Component } from "react";
import { Button, Paper, Typography, withStyles } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Save } from "@material-ui/icons";
import { PATH_LOGIN } from "../../../../routes/paths/paths.util";
import * as actions from "../../../../store/actions/index.action";
import { RechercheGlnResult } from "../../../rechercheAssujetti/rechercheGlnResult";
import { PROFIL_FOURNISSEUR } from "../../../../utils/constantes";
import { LIBELLE_CONTINUER } from "../../../../utils/libelleConstantes";
import { isNotEmptyArray } from "../../../../utils/utility";


const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        paddingRight: "15px"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    table: {
        minWidth: 700,
        width: 1500
    }
});

class RechercheGLN extends Component {
    state = {
        isSelected: false
    };

    onClickNext = () => {
        this.props.onClickNext();
    };

    onSelectItem = declarants => {
        const { onChangeInputGLN, history, updateIsGencodSelected } = this.props;
        const isNotEmptySelection = isNotEmptyArray(declarants);
        updateIsGencodSelected(isNotEmptySelection);
        if (isNotEmptySelection) {
            onChangeInputGLN(declarants[0].gencod);
            history.push({ pathname: PATH_LOGIN });
        }
    };

    componentWillUnmount() {
        this.props.updateIsGencodSelected(false);
    }

    validate = () => {
        const { onClickNext, sendInscription } = this.props;
        onClickNext();
        sendInscription();
    }

    render() {
        const { classes, profilDialog } = this.props;
        return (
            <div className={classes.root} align="center">
                <Paper>
                    <Typography variant="h6" component="h6" align="left" className="alert alert-warning">
                        <Trans id="entityAlreadyExists"/>
                    </Typography>
                </Paper>
                <RechercheGlnResult onSelect={this.onSelectItem} showForFournisseur={profilDialog === PROFIL_FOURNISSEUR} displayPagination={false}/>
                <div style={{ paddingTop: "20px" }}>
                    <Button
                        id="saveCompteUtilisateurButton"
                        type="click"
                        onClick={this.validate}
                        variant="contained"
                        endIcon={<Save/>}>
                        <Trans id={LIBELLE_CONTINUER}/>
                    </Button>
                </div>
            </div>

        );
    }
}

RechercheGLN.propTypes = {
    onClickNext: PropTypes.func.isRequired,
    onClickBack: PropTypes.func.isRequired,
    onChangeInputGLN: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    resultsGLN: PropTypes.arrayOf(PropTypes.shape({
        gencod: PropTypes.string,
        raisonSociale1: PropTypes.string,
        raisonSociale2: PropTypes.string,
        adresse1: PropTypes.string,
        adresse2: PropTypes.string,
        codePostal: PropTypes.string,
        ville: PropTypes.string
    }))
};

const mapDispatchToProps = dispatch => ({
    onChangeInputGLN: value => dispatch(actions.onChangeLogin(value)),
    sendInscription: () => dispatch(actions.saveInscription()),
    updateIsGencodSelected: value => dispatch(actions.updateIsGencodSelected(value))
});

const mapStateToProps = state => ({
    profilDialog: state.inscription.profilDialog
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(RechercheGLN)));

export { RechercheGLN };
