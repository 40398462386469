import axios from "axios";

export const enregistrerWrapperDto = body => axios.post("/declaration/enregistrerWrapperDto", body);

export const envoyerWrapperDto = body => axios.post("/declaration/envoyerWrapperDto", body);

export const valideOneDeclartionFournisseurEnEtat = numeroDeclaration => {
    const formData = new FormData();
    formData.append("numeroDeclaration", numeroDeclaration);
    return axios.post("/declaration/confirmerDeclarationFournisseurEnEtat", formData);
};

export const valideOneDeclartionBibliothequeEnEtat = numeroDeclaration => {
    const formData = new FormData();
    formData.append("numeroDeclaration", numeroDeclaration);
    return axios.post("/declaration/confirmerDeclarationBibliothequeEnEtat", formData);
};

export const modifierStatutDeclarationFournisseur = (numeroDeclarationList, toBeAbandon) => {
    const formData = new FormData();
    formData.append("numeroDeclarationList", numeroDeclarationList);
    formData.append("toBeAbandon", toBeAbandon);
    return axios.post("/declaration/modifierStatutDeclarationFournisseur", formData);
};

export const modifierStatutDeclarationBibliotheque = (numeroDeclarationList, toBeAbandon) => {
    const formData = new FormData();
    formData.append("numeroDeclarationList", numeroDeclarationList);
    formData.append("toBeAbandon", toBeAbandon);
    return axios.post("/declaration/modifierStatutDeclarationBibliotheque", formData);
};
