import { call, put, select } from "redux-saga/effects";
import { downloadFactureDocument, getMesFactures, openFactureDocument } from "../../../services/factureEntete.service";
import * as actions from "../../actions/index.action";
import { selectState } from "../../../utils/selectors";
import { SORT_NULL } from "../../../utils/constantes";
import { ERREUR_DOWNLOAD_FACTURE, ERREUR_LOAD_FACTURES } from "../../../utils/libelleConstantes";

export function *getMesFacturesSaga() {
    try {
        const state = yield select(selectState);
        const { gencod, preferenceSizePagination } = state.utilisateur.informations;
        const { anneeDebut, anneeFin, etatReglement } = state.mesFactures.filtre;
        const { page, sortColumns } = state.mesFactures;
        const filtre = {
            glnDeclarant: gencod,
            anneeDebut,
            anneeFin,
            etatReglement,
            page: page - 1,
            size: preferenceSizePagination,
            sortColumns: sortColumns.filter(column => column.direction !== SORT_NULL)
        };
        if (gencod !== "") {
            yield put(actions.updateLoadingMesFactures(true));
            const response = yield call(getMesFactures, filtre);
            // Met a jour la liste des factures, le total et retire le chargement de l'écran
            yield put(actions.getFacturesSuccess(response.data));
        }
    } catch (error) {
        // Vide la liste des factures, le total et retire le chargement de l'écran
        yield put(actions.getFacturesSuccess({ content: [], totalElements: 0 }));
        yield put(actions.openDialogNoRedirect(ERREUR_LOAD_FACTURES, error.message));
    }
}

export function *getFactureDocumentSaga(action) {
    yield put(actions.updateFactureLoading(action.numeroFacture, true));
    try {
        const service = action.openOnly ? openFactureDocument : downloadFactureDocument;
        yield call(service, action.numeroFacture);
    } catch (error) {
        yield put(actions.openDialogNoRedirect(ERREUR_DOWNLOAD_FACTURE, error.message));
    }
    yield put(actions.updateFactureLoading(action.numeroFacture, false));
}

export function *getFactureDocumentFromDeclarationSaga({ numeroFacture }) {
    try {
        yield call(downloadFactureDocument, numeroFacture);
    } catch (error) {
        yield put(actions.openDialogNoRedirect(ERREUR_DOWNLOAD_FACTURE, error.message));
    }
}

