import React, { useState } from "react";
import DoneIcon from "@material-ui/icons/Done";
import { Chip, IconButton, InputAdornment, makeStyles, TextField, withStyles } from "@material-ui/core";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import { Trans } from "@lingui/macro";
import { useDispatch } from "react-redux";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { useParams } from "react-router-dom";
import * as actions from "../../../../store/actions/index.action";
import { LabelInputTextWithChildren } from "../../../../commonComponents/input/fieldInput";
import { MonProfilLibelleInteractiveBoutonSauvegarde } from "../../MonProfilLibelleBoutonSauvegarde";
import { LIBELLE_MON_PROFIL_MDP_EXPLICATIONS } from "../../../../utils/libelleConstantes";

export const regexChiffre = /^(?=.*\d)/; // Au moins un chiffre
export const regexMajMin = /(?=.*[a-z])(?=.*[A-Z])/; // Une lettre maj une lettre min
export const regexSpecial = /(?=.*[&”"#’{(|`_^@)\]=}+°²¨$£¤ù%*µ!§:;.,?<>/\-[])/; // Caractère spécial
export const regexLongueur = /\S{12,}$/; // Pas d’espace et au moins 12 caractères
const chip = {
    width: "500px",
    padding: "3px 0",
    marginTop: "5px"
};
const green = "#8bc34a";
const purple = "#f50057";
const useStyles = makeStyles({
    parent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "25px",
        marginTop: "25px"
    },
    explications: {
        width: "800px"
    },
    inputFields: {
        width: "500px",
        padding: "3px 0"
    },
    chipGreen: {
        ...chip,
        color: green
    },
    chipPurple: {
        ...chip,
        color: purple
    },
    chips: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end"
    }
});

const ChipValidation = ({ criteria, label }) => {
    const classes = useStyles();
    const icon = criteria ? <DoneIcon style={{ color: green }}/> : <HourglassEmptyIcon style={{ color: purple }}/>;
    return <ChipRegle
        label={<Trans id={label}/>}
        variant="outlined"
        className={criteria ? classes.chipGreen : classes.chipPurple}
        icon={icon}
    />;
};

const ChipRegle = withStyles({
    root: {
        justifyContent: "flex-start"
    },
    outlined: {
        border: "none"
    }
})(Chip);

export const TextFieldStyled = props => {
    const classes = useStyles();
    const [show, setShow] = useState(false);
    const onClickButton = () => setShow(!show);
    return <TextField
        {...props}
        type={show ? "text" : "password"}
        variant={"outlined"}
        size={"small"}
        InputProps={{
            endAdornment:
                <InputAdornment position="end">
                    <IconButton aria-label="Toggle password visibility" onClick={onClickButton}>
                        {show ? <VisibilityOff/> : <Visibility/>}
                    </IconButton>
                </InputAdornment>
        }}
        className={classes.inputFields}
    />;
};

export default function MonProfilMotDePasse({ connected, premiereCo }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const match = useParams();
    const [value, setValue] = useState("");
    const [copie, setCopie] = useState("");
    // Regex validation state
    const [valid, setValid] = useState(false);
    const [identique, setIdentique] = useState(false);
    const [chiffre, setChiffre] = useState(false);
    const [majMin, setMajMin] = useState(false);
    const [special, setSpecial] = useState(false);
    const [longueur, setLongueur] = useState(false);

    const isValid = (v, c) => regexChiffre.test(v) &&
        regexMajMin.test(v) &&
        regexSpecial.test(v) &&
        regexLongueur.test(v) &&
        v === c;

    const onValueChange = event => {
        event.preventDefault();
        const v = event.target.value.replace(/ /g, "");
        setValue(v);
        setChiffre(regexChiffre.test(v));
        setMajMin(regexMajMin.test(v));
        setSpecial(regexSpecial.test(v));
        setLongueur(regexLongueur.test(v));
        setIdentique(v === copie);
        setValid(isValid(copie, v));
    };
    const onCopieChange = event => {
        event.preventDefault();
        const c = event.target.value.replace(/ /g, "");
        setCopie(c);
        setIdentique(value === c);
        setValid(isValid(c, value));
    };

    function reset() {
        setValue("");
        setCopie("");
    }

    const sendMdp = () => {
        if (connected) {
            dispatch(actions.changePwdConnected(value, premiereCo));
            reset();
        } else {
            // On récupère les informations dans l'url
            const { gencod, token } = match;
            dispatch(actions.changePwdUnconnected(gencod, token, value));
        }
    };

    const RegleValidation = () => (
        <div className={classes.chips}>
            <ChipValidation
                criteria={longueur}
                label={"monProfilMdpLongueur"}
            />
            <ChipValidation
                criteria={majMin}
                label={"monProfilMdpMajMin"}
            />
            <ChipValidation
                criteria={chiffre}
                label={"monProfilMdpChiffre"}
            />
            <ChipValidation
                criteria={special}
                label={"monProfilMdpSpecial"}
            />
            <ChipValidation
                criteria={identique}
                label={"monProfilMdpIdentique"}
            />
        </div>
    );

    return <div className={classes.parent}>
        <div className={classes.explications}>
            <Trans id={LIBELLE_MON_PROFIL_MDP_EXPLICATIONS}/>
        </div>
        <div>
            <LabelInputTextWithChildren labelKey={"monProfilMdpFirst"}>
                <TextFieldStyled
                    id="mdp"
                    label=""
                    onChange={onValueChange}
                    value={value}
                />
            </LabelInputTextWithChildren>
            <LabelInputTextWithChildren labelKey={"monProfilMdpConfirmation"}>
                <TextFieldStyled
                    id="mdp-copie"
                    label=""
                    onChange={onCopieChange}
                    value={copie}
                    onPaste={e => {
                        e.preventDefault();
                        return false;
                    }}
                />
            </LabelInputTextWithChildren>
            <RegleValidation/>
        </div>
        <MonProfilLibelleInteractiveBoutonSauvegarde premiereCo={premiereCo} disabled={!valid} handleClick={sendMdp}/>
    </div>;
}
