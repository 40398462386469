import { updateObject } from "../../../utils/utility";
import * as actionTypes from "../../actionTypes/actionTypes";
import { MES_FACTURES_STATUT_AUCUN, SORT_NULL } from "../../../utils/constantes";
import { getSort } from "../../../utils/bootstrapTableUtil";
import {
    DATE_ECHEANCE,
    DATE_FACTURE,
    MONTANT_RESTE_A_PAYER,
    NUMERO_FACTURE,
    STATUT,
    TOTAL_TTC
} from "../../../utils/libelleConstantes";

const initialState = {
    page: 1,
    factures: [],
    total: 0,
    filtre: {
        anneeDebut: 2013,
        anneeFin: new Date().getUTCFullYear(),
        etatReglement: MES_FACTURES_STATUT_AUCUN
    },
    sortColumns: [
        getSort(DATE_FACTURE, SORT_NULL),
        getSort(NUMERO_FACTURE, SORT_NULL),
        getSort(TOTAL_TTC, SORT_NULL),
        getSort(MONTANT_RESTE_A_PAYER, SORT_NULL),
        getSort(STATUT, SORT_NULL),
        getSort(DATE_ECHEANCE, SORT_NULL)
    ],
    loading: false,
    countNewFactures: 0
};
const updateSortColumns = (state, sortColumn) => {
    const index = state.sortColumns.findIndex(column => sortColumn.column === column.column);

    return {
        ...state,
        sortColumns: [
            ...state.sortColumns.slice(0, index),
            sortColumn,
            ...state.sortColumns.slice(index + 1)
        ]
    };
};

const updateFacturesResponse = (state, content, total) => {
    content.forEach((f, index) => {
        f.index = index;
        f.loading = false;
    });
    return updateObject(state, { factures: content, total, loading: false });
};

const updateFactureLoading = (state, numeroFacture, loading) => {
    const index = state.factures.findIndex(facture => numeroFacture === facture.numeroFacture);
    const facture = { ...state.factures[index],
        loading };
    return {
        ...state,
        factures: [
            ...state.factures.slice(0, index),
            facture,
            ...state.factures.slice(index + 1)
        ]
    };
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.UPDATE_MES_FACTURES_ANNEE_FILTRE:
        return updateObject(state, {
            filtre: {
                ...state.filtre,
                anneeDebut: action.anneeDebut,
                anneeFin: action.anneeFin
            },
            page: 1
        });

    case actionTypes.UPDATE_MES_FACTURES_ETAT_REGLEMENT_FILTRE:
        return updateObject(state, {
            filtre: {
                ...state.filtre,
                etatReglement: action.etatReglement
            },
            page: 1
        });

    case actionTypes.GET_MES_FACTURES_SUCCESS:
        return updateFacturesResponse(state, action.factures.content, action.factures.totalElements);

    case actionTypes.UPDATE_LOADING_MES_FACTURES:
        return updateObject(state, { loading: action.loading });

    case actionTypes.UPDATE_SORT_COLUMN_MES_FACTURES:
        return updateSortColumns(state, action.sortColumn);

    case actionTypes.UPDATE_TOTAL_MES_FACTURES:
        return updateObject(state, { total: action.total });

    case actionTypes.UPDATE_PAGE_MES_FACTURES:
        return updateObject(state, { page: action.page });

    case actionTypes.REINIT_FACTURES_FILTRE:
        return updateObject(state, { filtre: initialState.filtre });

    case actionTypes.UPDATE_FACTURE_LOADING:
        return updateFactureLoading(state, action.numeroFacture, action.loading);
    default:
        return state;
    }
};


export default reducer;
