import * as actionTypes from "../actionTypes/actionTypes";
import { GLOBAL_DIALOG_TYPES } from "../../utils/constantes";


export const openDialog = (message, redirect = false, cause = "", messageType = GLOBAL_DIALOG_TYPES.ERROR, message2 = "") => ({
    type: actionTypes.GLOBAL_DIALOG_OPEN,
    messageType,
    message,
    message2,
    redirect,
    cause
});
export const openDialogNoRedirect = (message, cause = "", messageType = GLOBAL_DIALOG_TYPES.ERROR, message2 = "") => ({
    type: actionTypes.GLOBAL_DIALOG_OPEN_NOT_REDIRECT,
    messageType,
    message,
    message2,
    cause
});

export const closeDialog = (redirect = false) => ({
    type: actionTypes.GLOBAL_DIALOG_CLOSE,
    redirect
});

export const closeDialogSuccess = () => ({
    type: actionTypes.GLOBAL_DIALOG_CLOSE_SUCCESS
});
