import * as actionTypes from "../actionTypes/actionTypes";

export const usurper = gencod => ({
    type: actionTypes.ADMIN_USURPER,
    gencod
});

export const usurperStart = () => ({
    type: actionTypes.ADMIN_USURPER_START
});

export const usurperSuccess = usurperRedirectPath => ({
    type: actionTypes.ADMIN_USURPER_SUCCESS,
    usurperRedirectPath
});

export const usurperFail = error => ({
    type: actionTypes.ADMIN_USURPER_FAIL,
    error
});

export const usurperUnauthorized = isUserUnauthorized => ({
    type: actionTypes.ADMIN_USURPER_UNAUTHORIZED,
    isUserUnauthorized
});

export const updateDisableButton = disableButton => ({
    type: actionTypes.UPDATE_DISABLE_BUTTON,
    disableButton
});
