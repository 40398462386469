import * as actionTypes from "../actionTypes/actionTypes";

export const setIntegrationsTableurCorrectionErreurLoading = loading => ({
    type: actionTypes.SET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_LOADING,
    loading
});

export const setIntegrationsTableurCorrectionErreurLoadingPercentage = percentage => ({
    type: actionTypes.SET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_LOADING_PERCENTAGE,
    percentage
});

export const getCorrectionErreurLignesByIdIntegration = idIntegration => ({
    type: actionTypes.GET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_LIGNES_BY_ID_INTEGRATION,
    idIntegration
});

export const getCorrectionErreurLignesByIdIntegrationResults = (erreursCalculees, erreursStats, entetesChoisies) => ({
    type: actionTypes.GET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_LIGNES_BY_ID_INTEGRATION_RESULT,
    erreursCalculees,
    erreursStats,
    entetesChoisies
});

export const getCorrectionErreurEntetesChoisiesByIdIntegration = idIntegration => ({
    type: actionTypes.GET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_ENTETES_CHOISIES_BY_ID_INTEGRATION,
    idIntegration
});

export const getCorrectionErreurEntetesChoisiesByIdIntegrationResults = entetesChoisies => ({
    type: actionTypes.GET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_ENTETES_CHOISIES_BY_ID_INTEGRATION_RESULT,
    entetesChoisies
});

export const saveAndGetLignesForIdIntegration = (lignes, idIntegration) => ({
    type: actionTypes.SAVE_GET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_LIGNES,
    lignes,
    idIntegration
});

export const saveAndGetLignesForIdIntegrationResult = (erreursCalculees, erreursStats) => ({
    type: actionTypes.SAVE_GET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_LIGNES_RESULT,
    erreursCalculees,
    erreursStats
});

export const deleteAndGetStatisticsForIdIntegration = (numerosLignesASupprimer, idIntegration) => ({
    type: actionTypes.DELETE_GET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_STATS,
    numerosLignesASupprimer,
    idIntegration
});

export const deleteAndGetStatisticsForIdIntegrationResult = erreursStats => ({
    type: actionTypes.DELETE_GET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_STATS_RESULT,
    erreursStats
});


export const setIntegrationsTableurCorrectionErreurSauvegardeLoading = sauvegardeLoading => ({
    type: actionTypes.SET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_SAUVEGARDE_LOADING,
    sauvegardeLoading
});

export const createDeclarationsForIdIntegration = (idIntegration, nomFichier) => ({
    type: actionTypes.CREATE_DECLARATIONS_FROM_FICHIER,
    idIntegration,
    nomFichier
});

export const getListeDeclarationsEnteteByIdIntegration = idIntegration => ({
    type: actionTypes.GET_LIST_DECLARATIONS_ENTETE_BY_INTEGRATION,
    idIntegration
});

export const displayErrorMessage = (response, messageCode) => ({
    type: actionTypes.DISPLAY_ERROR_MESSAGE,
    response,
    messageCode
});

export const openOrCloseBarProgressDialog = flag => ({
    type: actionTypes.OPEN_OR_CLORE_BAR_PROGRESS_DIALOG,
    flag
});

export const reinitDataCorrectionErreurs = () => ({
    type: actionTypes.REINIT_DATA_CORRECTION_ERREUR
});

export const updatePassToStepCorrectionErreur = flag => ({
    type: actionTypes.PASS_TO_STEP_CORRECTION_ERREUR,
    flag
});
