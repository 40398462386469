import { makeStyles } from "@material-ui/core";
import { LIST_CODE_PAYS_FRANCE } from "../../utils/paysConstantes";
import { isBlank, isNotBlank } from "../../utils/utility";
import { CONTACT_FONCTION } from "../../utils/constantes";
import {
    LIBELLE_MON_PROFIL_CHANGEMENT_MDP,
    LIBELLE_MON_PROFIL_INFORMATIONS_DFA,
    LIBELLE_MON_PROFIL_INFORMATIONS_ENTITE,
    LIBELLE_MON_PROFIL_INFORMATIONS_RDP,
    LIBELLE_MON_PROFIL_INFORMATIONS_RL,
    LIBELLE_MON_PROFIL_INFORMATIONS_SOFIA,
    LIBELLE_MON_PROFIL_OPTIONS_FACTURATION
} from "../../utils/libelleConstantes";

export const REGEX_URL_MON_PROFIL = /.+monProfil/;

export const tabsFournisseur = [LIBELLE_MON_PROFIL_INFORMATIONS_ENTITE, LIBELLE_MON_PROFIL_INFORMATIONS_RL, LIBELLE_MON_PROFIL_INFORMATIONS_RDP, LIBELLE_MON_PROFIL_INFORMATIONS_DFA, LIBELLE_MON_PROFIL_CHANGEMENT_MDP, LIBELLE_MON_PROFIL_OPTIONS_FACTURATION, LIBELLE_MON_PROFIL_INFORMATIONS_SOFIA];
export const tabsBibliotheque = [LIBELLE_MON_PROFIL_INFORMATIONS_ENTITE, LIBELLE_MON_PROFIL_INFORMATIONS_RL, LIBELLE_MON_PROFIL_INFORMATIONS_RDP, LIBELLE_MON_PROFIL_CHANGEMENT_MDP, LIBELLE_MON_PROFIL_INFORMATIONS_SOFIA];


export const MON_PROFIL_STEP_MDP = "MDP";
export const MON_PROFIL_STEP_ENTITE = "ENTITE";
export const MON_PROFIL_STEP_RDP = "RDP";
export const MON_PROFIL_STEP_DFA = "DFA";
export const MON_PROFIL_STEP_RL = "RL";
export const MON_PROFIL_STEP_OPTION_FACTURATION = "OF";
export const MON_PROFIL_STEP_MAIL_CONFIRMATION = "MAIL";
export const MON_PROFIL_STEP_FIN = "FIN";
export const MON_PROFIL_STEP_FORCAGE_FIN = "STEP_FORCAGE_FIN";


export const MON_PROFIL_STEPS_FOURNISSEUR = [MON_PROFIL_STEP_MDP, MON_PROFIL_STEP_ENTITE, MON_PROFIL_STEP_RDP, MON_PROFIL_STEP_DFA, MON_PROFIL_STEP_OPTION_FACTURATION, MON_PROFIL_STEP_MAIL_CONFIRMATION];
export const MON_PROFIL_STEPS_BIBLIOTHEQUE = [MON_PROFIL_STEP_MDP, MON_PROFIL_STEP_ENTITE, MON_PROFIL_STEP_RDP, MON_PROFIL_STEP_MAIL_CONFIRMATION];

export const entiteFormulaireTemplate = {
    gencod: "",
    raisonSociale1: "",
    raisonSociale2: "",
    numeroVoie: "",
    preAdresse: "",
    adresse1: "",
    adresse2: "",
    codePostal: "",
    ville: "",
    codePays: "",
    siret: "",
    ape: "",
    formeJuridique: "",
    formeJuridiqueAutre: "",
    dateCreation: null,
    telephone: "",
    courriel: "",
    site: "",
    ediSsii: "",
    siegeSocial: false,
    typeAutre: ""
};

export const inputFields = {
    width: "500px", padding: "3px 0"
};

export const useStyles = makeStyles(() => ({
    inputFields,
    alignLeft: {
        textAlign: "left"
    },
    disabledDiv: {
        opacity: 0.5,
        pointerEvents: "none"
    },
    sauvegarderButton: {
        textAlign: "center",
        padding: "20px 0px",
        display: "flex",
        justifyContent: "space-evenly"
    },
    sauvegarderButtonNoMargin: {
        textAlign: "center", padding: "25px 50px 20px 0px"
    },
    nextButton: {
        marginLeft: "45%"
    },
    affichageColonneCentree: {
        display: "flex", flexDirection: "column", alignItems: "center"
    },
    titreStepper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "aliceblue",
        margin: "20px 0",
        fontSize: "20pt",
        fontWeight: "500",
        borderRadius: "4px"
    },
    divContainerFacturation: { height: "100%", display: "flex", flexDirection: "column", gap: "20px" },
    divPeriodeFacturation: { marginLeft: "400px" },
    divContactFacturation: { display: "flex", flexDirection: "column", alignItems: "flex-start", textAlign: "justify" },
    divUpdateFacturation: { display: "flex", flexDirection: "column", paddingTop: "20px", paddingLeft: "10%" },
    spanAlerteFacturation: { fontStyle: "italic", padding: "20px 5px 5px 5px", fontSize: "0.8em", paddingLeft: "10%" },
    spanContactFacturation: { fontStyle: "italic", padding: "20px 0px 20px" }
}));


/**
 *  Méthode commune
 */
export function isPaysFrance(formulaire) {
    if (!formulaire || isBlank(formulaire.codePays)) {
        return undefined;
    }
    return LIST_CODE_PAYS_FRANCE.includes(formulaire.codePays);
}

export function isCodePaysFrance(codePays) {
    if (isBlank(codePays)) {
        return undefined;
    }
    return LIST_CODE_PAYS_FRANCE.includes(codePays);
}

export function isCodePostalFrancais(formulaire) {
    return isPaysFrance(formulaire) && formulaire.codePostal && formulaire.codePostal.length === 5;
}

export function codePostalChangedAndIsFrancais(prevState, formulaire) {
    return isNotBlank(prevState.codePostal) && formulaire.codePostal !== prevState.codePostal && isCodePostalFrancais(formulaire);
}

export function getField(formulaire, field) {
    return formulaire && formulaire[field] !== null ? formulaire[field] : "";
}

export function getDateField(formulaire, field) {
    // Pour le KeyboardDatePicker la valeur par défaut doit être nulle sinon on a un warning dans la console
    return formulaire && formulaire[field] !== null ? formulaire[field] : null;
}

export const readOnlyContactCoordonnee = formulaire => formulaire && [CONTACT_FONCTION.RESPONSABLE_SUIVI_FACTURES, CONTACT_FONCTION.REPRESENTANT_LEGAL].includes(formulaire.fonction) && !formulaire.contactValide;

export const readOnlyContactMail = formulaire => readOnlyContactCoordonnee(formulaire) && [CONTACT_FONCTION.RESPONSABLE_SUIVI_FACTURES, CONTACT_FONCTION.REPRESENTANT_LEGAL].includes(formulaire.fonction) && !formulaire.mailValide;

export const inputOnChange = (field, onChange) => e => onChange(field, e.target.value);

export const switchOnChange = (field, onChange) => e => onChange(field, e.target.checked);

export const autoCompleteOnChange = (field, onChange) => (e, v) => onChange(field, v ? v.code : null);
