import classNames from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import themes from "../../components/accueil/themes.module.css";
import * as actions from "../../store/actions/index.action";
import { checkIsBibliothequeConnected, isAdminRole } from "../../utils/utility";
import ContentConfirmDialog from "../dialog/confirmDialog/content/contentConfirmDialog.component";
import { PATH_LOGIN } from "../../routes/paths/paths.util";
import { selectLoginToken, selectShowMenu } from "../../utils/selectors";
import FilAriane from "./filAriane/filAriane.component";
import Header from "./header/header.component";
import classes from "./layout.module.css";
import BackDrop from "./menu/backDrop/backDrop.component";
import DrawerToggle from "./menu/drawerToggle/drawerToggle.component";
import Menu from "./menu/menu.component";
import ConnectedUser from "./connecte/connectedUser.component";
import Footer from "./footer/footer.component";


class Layout extends Component {
    state = {
        showDrawerAndBackdrop: false
    };

    signOut = () => {
        this.openConfirmDialog();
    };

    updateLocale = locale => {
        const { gencod, updateLocaleForUser } = this.props;
        updateLocaleForUser(gencod, locale);
    }

    confirmLogOut = () => {
        this.props.logout();
        this.props.history.push(PATH_LOGIN);
    };

    onMenuClick = () => {
        this.setState(prevState => ({ showDrawerAndBackdrop: !prevState.showDrawerAndBackdrop }));
    };

    setConfirmDialogRef = ref => {
        this.confirmDialogRef = ref;
    };

    openConfirmDialog = () => {
        this.confirmDialogRef.setOnAgreeClick(() => this.confirmLogOut());
        this.confirmDialogRef.openConfirmDialogWithDynamicContent("confirmLogOut");
    };

    render() {
        const { showDrawerAndBackdrop } = this.state;
        const { userInfos, roles, isAuthenticated, children, locale, showMenu } = this.props;

        const isAdmin = isAdminRole(userInfos.authorities);

        let theme;
        if (showMenu) {
            theme = checkIsBibliothequeConnected(roles)
                ? themes.bibliotheque
                : themes.fournisseur;
        }

        return (
            <React.Fragment>
                <ContentConfirmDialog ref={this.setConfirmDialogRef}/>
                <div className={classes.LayoutBodyContainer}>
                    <Header
                        isLogged={isAuthenticated}
                        isAdmin={isAdmin}
                        signOut={this.signOut}
                        locale={locale}
                        updateLocale={this.updateLocale}
                        {...this.props} />
                    {showMenu && <div className={classes.LayoutBodyTop}>
                        <DrawerToggle onMenuClick={this.onMenuClick}/>
                        <BackDrop show={showDrawerAndBackdrop} onClick={this.onMenuClick}/>
                        <FilAriane {...this.props}/>
                        <ConnectedUser/>
                    </div>}
                    <div className={classNames(classes.LayoutBodyCenter, theme)}>
                        {showMenu && showDrawerAndBackdrop && <div>
                            <Menu isLogged={isAuthenticated} showInDrawer={true} signOut={this.signOut} onClick={this.onMenuClick}/>
                        </div>}
                        <div style={{ width: "100%" }}>
                            {children}
                        </div>
                    </div>
                    <Footer/>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatToProps = state => ({
    isAuthenticated: selectLoginToken(state) !== null,
    userInfos: state.login.userInfos,
    roles: state.utilisateur.roles,
    locale: state.utilisateur.informations.locale,
    showMenu: selectShowMenu(state)
});

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(actions.logout()),
    updateLocaleForUser: (gencod, locale) => dispatch(actions.updateLocaleForUser(gencod, locale))
});

export default connect(mapStatToProps, mapDispatchToProps)(withRouter(Layout));
Layout.whyDidYouRender = true;
