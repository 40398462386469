import React from "react";
import { useSelector } from "react-redux";
import { AccountCircleRounded } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { selectMonProfilCompteUtilisateur, selectUtilisateurRoles } from "../../../utils/selectors";
import { PATH_MON_PROFIL } from "../../../routes/paths/paths.util";
import { getPrefixUrl } from "../../../utils/utility";

export default function ConnectedUser() {
    const { raisonSociale1, raisonSociale2, gencod } = useSelector(selectMonProfilCompteUtilisateur);
    const roles = useSelector(selectUtilisateurRoles);

    const history = useHistory();

    const prefixPath = getPrefixUrl(roles);
    const url = prefixPath + PATH_MON_PROFIL;

    return <div>
        <IconButton onClick={() => history.push(url)}>
            <AccountCircleRounded fontSize={"large"} color={"disabled"}/>
        </IconButton>
        {raisonSociale1} {raisonSociale2 !== raisonSociale1 ? raisonSociale2 : ""} ({gencod})
    </div>;
}
