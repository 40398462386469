import React, { useEffect } from "react";
import { Button } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../store/actions/index.action";
import { PATH_DECLARATION_WEB, PATH_LOGIN } from "../../../../routes/paths/paths.util";
import { selectCommon, selectRootPath } from "../../../../utils/selectors";
import { DEFAULT_MESSAGE_CONFIRMATION_INSCRIPTION } from "../../../../utils/constantes";

export function Confirmation() {
    const dispatch = useDispatch();
    const history = useHistory();
    const rootPath = useSelector(selectRootPath);
    const { disabledButton, messageConfirmation, numeroToMap, applyErrorStyle, isCalledFromInscription } = useSelector(selectCommon);

    const location = useLocation();
    const { state } = location;

    const mountFromDeclarationWeb = state && state.isCalledFromDeclaration;

    function backToLogin() {
        dispatch(actions.initStepInscription());
        history.push(PATH_LOGIN);
    }

    function backToSaisieEnLigne() {
        dispatch(actions.initStepReferencement());
        dispatch(actions.reinitializeDeclarationData());
        dispatch(actions.initRechercheGLN());
        history.push(rootPath + PATH_DECLARATION_WEB);
    }

    function remakeDemandeRef() {
        dispatch(actions.initStepReferencement());
    }

    function onClick() {
        if (isCalledFromInscription) {
            backToLogin();
        } else if (mountFromDeclarationWeb) {
            backToSaisieEnLigne();
        } else {
            remakeDemandeRef();
        }
    }

    // eslint-disable-next-line class-methods-use-this
    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {
            dispatch(actions.updateMessageConfirmation(DEFAULT_MESSAGE_CONFIRMATION_INSCRIPTION));
        };
    }, []);

    const messageColor = applyErrorStyle ? "red" : "black";
    let transId = "";
    if (isCalledFromInscription) {
        transId = "backToLogin";
    } else if (mountFromDeclarationWeb) {
        transId = "backToSaisieWeb";
    } else {
        transId = "remakeDemandeRef";
    }
    return (
        <div align="center" style={{ padding: "30px" }}>
            <h3 style={{ color: messageColor }}><Trans id={messageConfirmation} values={{ numero: numeroToMap }}/></h3>
            <div style={{ padding: "30px" }}>
                <Button variant="contained" disabled={disabledButton}
                    color="primary" onClick={onClick}>
                    <Trans id={transId}/>
                </Button>
            </div>
        </div>
    );
}

export default Confirmation;
