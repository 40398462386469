import axios from "axios";

// @PostMapping(value = "/getEntetesFournisseurByFiltreAndPage")
export const getEntetesFournisseurByFiltreAndPage = data => axios.post(
    "/mesDeclarations/getEntetesFournisseurByFiltreAndPage",
    data
);

// @PostMapping(value ="/getEntetesBibliothequeByFiltreAndPage")
export const getEntetesBibliothequeByFiltreAndPage = data => axios.post(
    "/mesDeclarations/getEntetesBibliothequeByFiltreAndPage",
    data
);

// @PostMapping(value = "/getNombreDeclarationsParTypeFournisseur")
export const getNombreDeclarationsParTypeFournisseur = data => axios.post(
    "/mesDeclarations/getNombreDeclarationsParTypeFournisseur",
    data
);

// @PostMapping(value ="/getNombreDeclarationsParTypeBibliotheque")
export const getNombreDeclarationsParTypeBibliotheque = data => axios.post(
    "/mesDeclarations/getNombreDeclarationsParTypeBibliotheque",
    data
);

export const getEntetesFournisseurWithNombresAndStatsByFiltre = data => axios.post(
    "/mesDeclarations/getEntetesFournisseurWithNombresAndStatsByFiltre",
    data
);

export const getEntetesBibliothequeWithNombresAndStatsByFiltre = data => axios.post(
    "/mesDeclarations/getEntetesBibliothequeWithNombresAndStatsByFiltre",
    data
);

export const getEntetesFournisseurWithNombresAndStatsWithoutPartenaireByFiltre = data => axios.post(
    "/mesDeclarations/getEntetesFournisseurWithNombresAndStatsWithoutPartenaireByFiltre",
    data
);

export const getEntetesBibliothequeWithNombresAndStatsWithoutPartenaireByFiltre = data => axios.post(
    "/mesDeclarations/getEntetesBibliothequeWithNombresAndStatsWithoutPartenaireByFiltre",
    data
);

export const sendAllDeclarationsFournisseur = data => axios.post(
    "/mesDeclarations/sendAllDeclarationsFournisseur",
    data
);

export const sendAllDeclarationsBibliotheque = data => axios.post(
    "/mesDeclarations/sendAllDeclarationsBibliotheque",
    data
);

export const getLitsDeclarationValidLignesByNumeroDeclaration = numeroDeclaration => axios.get("/mesDeclarations/getLitsDeclarationValidLignesByNumeroDeclaration", { params: { numeroDeclaration } });

export const invalidateDeclarationFournisseurByNumeroDeclaration = (numeroDeclaration, filtreDto) => {
    const formData = new FormData();
    formData.append("numeroDeclaration", numeroDeclaration);
    formData.append("filtreDto", JSON.stringify(filtreDto));
    return axios.post("/mesDeclarations/invalidateDeclarationFournisseurByNumeroDeclaration", formData);
};

export const invalidateDeclarationBibliothequeByNumeroDeclaration = (numeroDeclaration, filtreDto) => {
    const formData = new FormData();
    formData.append("numeroDeclaration", numeroDeclaration);
    formData.append("filtreDto", JSON.stringify(filtreDto));
    return axios.post("/mesDeclarations/invalidateDeclarationBibliothequeByNumeroDeclaration", formData);
};

export const rollBackDeclarationFournisseurByNumeroDeclaration = (numeroDeclaration, filtreDto) => {
    const formData = new FormData();
    formData.append("numeroDeclaration", numeroDeclaration);
    formData.append("filtreDto", JSON.stringify(filtreDto));
    return axios.post("/mesDeclarations/rollBackDeclarationFournisseurByNumeroDeclaration", formData);
};

export const rollBackDeclarationBibliothequeByNumeroDeclaration = (numeroDeclaration, filtreDto) => {
    const formData = new FormData();
    formData.append("numeroDeclaration", numeroDeclaration);
    formData.append("filtreDto", JSON.stringify(filtreDto));
    return axios.post("/mesDeclarations/rollBackDeclarationBibliothequeByNumeroDeclaration", formData);
};

export const sendDeclarationFournisseurByNumeroDeclaration = (numeroDeclaration, filtreDto) => {
    const formData = new FormData();
    formData.append("numeroDeclaration", numeroDeclaration);
    formData.append("filtreDto", JSON.stringify(filtreDto));
    return axios.post("/mesDeclarations/sendDeclarationFournisseurByNumeroDeclaration", formData);
};

export const sendDeclarationBibliothequeByNumeroDeclaration = (numeroDeclaration, filtreDto) => {
    const formData = new FormData();
    formData.append("numeroDeclaration", numeroDeclaration);
    formData.append("filtreDto", JSON.stringify(filtreDto));
    return axios.post("/mesDeclarations/sendDeclarationBibliothequeByNumeroDeclaration", formData);
};

export const modifyDeclarationFournisseurFacturee = numeroDeclaration => {
    const formData = new FormData();
    formData.append("numeroDeclaration", numeroDeclaration);
    return axios.post("/mesDeclarations/modifyDeclarationFournisseurFacturee", formData);
};

export const getStatistiquePartenaireDtoFournisseurByFiltre = data => axios.post(
    "/mesDeclarations/getStatistiquePartenaireDtoFournisseurByFiltre",
    data
);

export const getStatistiquePartenaireDtoBibliothequeByFiltre = data => axios.post(
    "/mesDeclarations/getStatistiquePartenaireDtoBibliothequeByFiltre",
    data
);

export const getStatistiqueMontantsMDDtoByFiltre = data => axios.post(
    "/mesDeclarations/getStatistiqueMontantsMDDtoByFiltre",
    data
);

