import axios from "axios";

export const onChangePwdUnconnected = (gencod, token, newPassword) => axios
    .post("/password/public/resetUnconnected", null, {
        params: {
            gencod,
            token,
            newPassword
        }
    });

export const onChangePwdConnected = (gencod, newPassword) => axios
    .post("/password/updateMotDePasseConnected", null, {
        params: {
            gencod,
            newPassword
        }
    });
