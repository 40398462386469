import * as actionTypes from "../actionTypes/actionTypes";

export const getLoadingPercentageByIdIntegration = idIntegration => ({
    type: actionTypes.GET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_LOADING_PERCENTAGE_SAGA,
    idIntegration
});

export const getListeDeclarationsEnteteByIdIntegrationResult = declarations => ({
    type: actionTypes.GET_LIST_DECLARATIONS_ENTETE_BY_INTEGRATION_RESULT,
    declarations
});

export const getListeDelcarationsLigneByNumeroDeclaration = (rowIndex, numeroDeclaration, showPopUp = false, reinitDeclaration = false) => ({
    type: actionTypes.GET_LIST_DECLARATIONS_LIGNE_BY_NUMERO_DECLARATION,
    rowIndex,
    numeroDeclaration,
    showPopUp,
    reinitDeclaration
});

export const getListeDelcarationsLigneByNumeroDeclarationResult = (rowIndex, lignes) => ({
    type: actionTypes.GET_LIST_DECLARATIONS_LIGNE_BY_NUMERO_DECLARATION_RESULT,
    rowIndex,
    lignes
});

export const closeDeclaration = () => ({
    type: actionTypes.CLOSE_DECLARATION
});

export const validateIntegrationEnEtat = idIntegration => ({
    type: actionTypes.VALIDATE_INTEGRATION_EN_ETAT,
    idIntegration
});

export const reinitDeclarationsData = () => ({
    type: actionTypes.REINIT_INTEGRATION_RECAP_DECLARATION
});

export const updateDeclarationEnteteStatus = (profil, numeroDeclarationList, toBeAbandon) => ({
    type: actionTypes.UPDATE_DECLARATION_ENTETE_STATUS,
    profil,
    numeroDeclarationList,
    toBeAbandon
});

export const updateDeclarationEnteteStatusSuccess = (numeroDeclaration, toBeAbandon) => ({
    type: actionTypes.UPDATE_DECLARATION_ENTETE_STATUS_SUCCESS,
    numeroDeclaration,
    toBeAbandon
});

export const updateDeclarationEnteteEtLignes = (entete, lignes, partenaire) => ({
    type: actionTypes.UPDATE_DECLARATION_ENTETE_ET_LIGNES,
    entete,
    lignes,
    partenaire
});

export const setIntegrationsTableurDeclarationLoading = loading => ({
    type: actionTypes.SET_INTEGRATION_TABLEUR_ENTETE_LOADING,
    loading
});

export const setSentIntegrationLoading = loading => ({
    type: actionTypes.SET_SENT_INTEGRATION_LOADING,
    loading
});

export const updateRowIndex = index => ({
    type: actionTypes.UPDATE_ROW_INDEX_INTEGRATION,
    index
});

export const updateIEOpenDetailDialog = open => ({
    type: actionTypes.UPDATE_IE_OPEN_DETAIL_DIALOG,
    open
});
