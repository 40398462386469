import React, { useEffect } from "react";
import { Button, Grid, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { Trans } from "@lingui/macro";
import * as actions from "../../../store/actions/index.action";
import { PATH_LOGIN } from "../../../routes/paths/paths.util";
import { selectMonProfil } from "../../../utils/selectors";
import { SpinnerOverlay } from "../../../commonComponents/spinner/spinnerOverlay.component";

const useStyles = makeStyles({
    parent: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexFlow: "column",
        paddingLeft: "125px",
        margin: "15%",
        fontSize: "large"
    },
    buttonLogin: {
        marginTop: "50px"
    }
});


export function ModificationMailContact({ match }) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { isMailChecked, loading } = useSelector(selectMonProfil);

    const { gencod, token, fonction, forcage } = match.params;

    useEffect(() => {
        dispatch(actions.checkMailToken(gencod, fonction, token, forcage));
    }, [dispatch, gencod, fonction, token, forcage]);

    const backToLogin = () => {
        dispatch(actions.onChangeLogin(gencod));
        history.push(PATH_LOGIN);
    };

    return <Grid item container direction="column" justify="flex-start">
        <SpinnerOverlay loading={loading}>
            {!loading &&
                <div className={classes.parent}>
                    {isMailChecked
                        ? <div className="flex-column">
                            <Trans>checkMailOk</Trans>
                        </div>
                        : <div className="flex-column">
                            <Trans>checkMailKo</Trans>
                        </div>
                    }
                    <div className={classes.buttonLogin}>
                        <Button variant="contained" color="primary" onClick={backToLogin}>
                            <Trans>backToLogin</Trans>
                        </Button>
                    </div>
                </div>
            }
        </SpinnerOverlay>
    </Grid>;
}

export default withRouter(ModificationMailContact);
