import { cloneDeep } from "lodash";
import { SAUVEGARDE } from "../../../../utils/constantes";

const newEnteteTemplate = {
    datePiece: {
        cellValue: "",
        doutes: [],
        erreur: null,
        erreurParam: null
    },
    numeroPiece: {
        cellValue: "",
        doutes: [],
        erreur: null,
        erreurParam: null
    },
    totalHt: {
        cellValue: "",
        doutes: [],
        erreur: null,
        erreurParam: null
    },
    totalTtc: {
        cellValue: "",
        doutes: [],
        erreur: null,
        erreurParam: null
    },
    livreHt: {
        cellValue: "",
        doutes: [],
        erreur: null,
        erreurParam: null
    },
    glnPartenaire: {
        cellValue: "",
        doutes: [],
        erreur: null,
        erreurParam: null
    },
    numeroDeclaration: {
        cellValue: null,
        doutes: [],
        erreur: null,
        erreurParam: null
    },
    statut: {
        cellValue: SAUVEGARDE,
        doutes: [],
        erreur: null,
        erreurParam: null
    },
    ddp: {
        cellValue: 0,
        doutes: [],
        erreur: null,
        erreurParam: null
    },
    dateRecap: {
        cellValue: "",
        doutes: [],
        erreur: null,
        erreurParam: null
    },
    tva: null
};

export function newDeclarationEntete() {
    return cloneDeep(newEnteteTemplate);
}
