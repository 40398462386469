import React from "react";
import { Trans } from "@lingui/macro";
import StyledSpan from "../commonComponents/span/styledSpan.container";
import CellFormaterTooltip from "../components/declaration/tableur/recapitulatifDeclaration/UtilComponent/cellFormaterTooltip.container";
import { LOW_PADDING_CELL_STYLE, SORT_ASC, SORT_DESC, SORT_NULL } from "./constantes";
import { isEnteteAbandonnee, isNotValidEntete } from "./utility";

// Direction peut être SORT_ASC, SORT_DESC ou par defaut SORT_NULL
export function getSort(column, direction = SORT_NULL) {
    return { column, direction };
}

// Permet de mettre à jour un state "sorts" qui accepte des tris multiples
export function updateMultipleSorts(sorts, sort) {
    const newSorts = [...sorts];
    const { column, direction } = sort;
    const existingSort = sorts.find(s => s.column === sort.column);
    if (existingSort) {
        if (direction === SORT_NULL) {
            newSorts.splice(sorts.indexOf(existingSort), 1);
        } else {
            existingSort.direction = direction;
        }
    } else {
        newSorts.push(getSort(column, direction));
    }
    return newSorts;
}

export function updateSingleSorts(sorts, sort) {
    const newSorts = [];
    const { column, direction } = sort;
    newSorts.push(getSort(column, direction));
    return newSorts;
}

// Un cycle de l'ordre pour le tri entre Asc Desc et pas de tri
const getNextOrder = order => {
    const cycle = [
        {
            // ASC => DESC
            key: SORT_ASC,
            value: SORT_DESC
        }, {
            // DESC => Plus de tri
            key: SORT_DESC,
            value: SORT_NULL
        }, {
            // Pas de tri => ASC
            key: SORT_NULL,
            value: SORT_ASC
        }
    ];
    return cycle.find(pair => pair.key === order).value;
};

function getSortInformations(order, columnName, updateSortColumn) {
    const column = {
        sort: true,
        onSort: () => {
            const nextOrder = getNextOrder(order);
            updateSortColumn(getSort(columnName, nextOrder));
        }
    };
    column.sortCaret = () => {
        const cssOrder = `order ${order !== SORT_NULL
            ? "active"
            : "inactive"}`;
        return (
            <span className={cssOrder}>
                {order !== SORT_ASC && <span className="dropdown"><span className="caret"></span></span>}
                {order !== SORT_DESC && <span className="dropup"><span className="caret"></span></span>}
            </span>
        );
    };
    return column;
}

export function getSortAttributes(columnName, sorts, handleSortChange) {
    const existingSort = sorts.find(pair => pair.column === columnName);
    const order = existingSort ? existingSort.direction : SORT_NULL;
    return getSortInformations(order, columnName, handleSortChange);
}

function customHeaderFormatter(col, colIndex, { sortElement }, transKey, params = null) {
    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            {<Trans id={transKey} values={params}/>}
            {sortElement}
        </div>
    );
}

export const getColumnWitParam = (dataField, formatter, transKey = null, params, align = null, style = LOW_PADDING_CELL_STYLE) => {
    const column = {
        text: "",
        formatter,
        dataField,
        style
    };
    if (transKey !== null) {
        column.headerFormatter = (col, colIndex, { sortElement }) => customHeaderFormatter(col, colIndex, { sortElement }, transKey, params);
    }
    if (align !== null) {
        column.align = align;
    }
    return column;
};

export const getColumn = (dataField, formatter, transKey = null, align = null, style = LOW_PADDING_CELL_STYLE) => {
    const column = {
        text: "",
        formatter,
        dataField,
        style
    };
    if (transKey !== null) {
        column.headerFormatter = (col, colIndex, { sortElement }) => customHeaderFormatter(col, colIndex, { sortElement }, transKey);
    }
    if (align !== null) {
        column.align = align;
    }
    return column;
};

export const getColumnSorted = (dataField, { order, columnName, updateSortColumn }, formatter, transKey = null, align = null) => ({
    ...getColumn(dataField, formatter, transKey, align),
    ...getSortInformations(order, columnName, updateSortColumn)
});


const formaterColumn = (isNotValid, cell, doutes) => {
    if (isNotValid) {
        return <StyledSpan style={{ color: "#ababbd", fontWeight: "bold", fontStyle: "oblique" }} cell={cell}/>;
    } else if (doutes.length > 0) {
        return <CellFormaterTooltip cell={cell} keyMessageArray={doutes}/>;
        // Si la toute la déclaration est abandonnée ou la ligne de détail est abandonnée
    }
    return <span>{cell}</span>;
};

const formaterColumnEntete = (cell, row, attribut) => {
    const isNotValid = row && isNotValidEntete(row.entete);
    return formaterColumn(isNotValid, cell, attribut.doutes);
};

const formaterColumnLigne = (cell, row, attribut, parentRow) => {
    const isAbandonne = (parentRow && isEnteteAbandonnee(parentRow.entete)) || row.valide !== true;
    return formaterColumn(isAbandonne, cell, attribut.doutes);
};

export const getColumnEntete = (key, transKey = null, align = null, formatCell = cell => cell) => {
    const formatter = (cell, row) => row && cell && formaterColumnEntete(formatCell(cell), row, row.entete[key]);
    return getColumn(`entete.${key}.cellValue`, formatter, transKey, align);
};

export const getColumnLigne = (key, parentRow, transKey = null, align = null, formatCell = cell => cell) => {
    const formatter = (cell, row) => row && cell && formaterColumnLigne(formatCell(cell), row, row.ligne[key], parentRow);
    return getColumn(`ligne.${key}.cellValue`, formatter, transKey, align);
};

export const getColumnEnteteSorted = (key, { order, columnName, updateSortColumn }, transKey = null, align = null, formatCell = cell => cell) => ({
    ...getColumnEntete(key, transKey, align, formatCell),
    ...getSortInformations(order, columnName, updateSortColumn)
});

/**
 * Désactive le tri coté front des composants BootstrapTable
 * @type {{sortFunc: (function(): number)}}
 */
export const disabledSortOption = {
    // Pas de tri par bootstrap table
    sortFunc: () => 0
};
