import axios from "axios";

export const isGlnFournisseur = gln => axios.get("/util/isGlnFournisseur", {
    params: {
        gln
    }
});

export const isGlnBibliotheque = gln => axios.get("/util/isGlnBibliotheque", {
    params: {
        gln
    }
});

export const getListeUrlAide = () => axios.get("/util/public/get-url-aide-page-utilisateur");
