import { updateObject } from "../../../utils/utility";
import * as actionsType from "../../actionTypes/actionTypes";
import { DEFAULT_SIZE_PER_PAGE } from "../../../utils/constantes";

const initialState = {
    loading: false,
    favoris: [],
    regroupements: [],
    informations: {
        gencod: "",
        assujettissementDDP: "",
        entiteRattachee: "",
        regroupe: false,
        deltaDetaille: false,
        canMakeDeclarationGlobale: false,
        canMakeDeclarationDetaillee: false,
        preferenceSizePagination: DEFAULT_SIZE_PER_PAGE,
        locale: null,
        entiteMere: null,
        premiereConnexion: {
            bibliothequeEtrangere: false,
            stepPremiereConnexion: null,
            npai: false,
            entiteValid: true,
            rdpValid: true,
            dfaValid: true,
            rdpMailValid: true,
            dfaMailValid: true,
            rdpModificationnMailValidated: true
        }
    },
    roles: []
};

function updateLocale(state, action) {
    return updateObject(state, {
        informations:
            {
                ...state.informations,
                locale: action.locale
            }
    });
}

function updateGencod(state, action) {
    return updateObject(state, {
        informations:
            {
                ...state.informations,
                gencod: action.gencod
            }
    });
}

function getInformationsSuccess(state, action) {
    return updateObject(state, {
        roles: action.roles,
        informations: action.informations,
        regroupements: action.regroupements
    });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionsType.UTILISATEUR_GET_INFORMATIONS_DECLARANT_SUCCESS:
        return getInformationsSuccess(state, action);
    case actionsType.UPDATE_LOCALE_FOR_USER:
        return updateLocale(state, action);
    case actionsType.UPDATE_GENCOD:
        return updateGencod(state, action);
    case actionsType.UTILISATEUR_UPDATE_PREFERENCE_SIZE_PAGINATION_SUCCESS:
        return updateObject(state, {
            informations: {
                ...state.informations,
                preferenceSizePagination: action.preferenceSizePagination
            }
        });
    case actionsType.UTILISATEUR_UPDATE_INFO_PREMIERE_CONNEXION_SUCCESS:
        return updateObject(state, {
            informations: {
                ...state.informations,
                premiereConnexion: {
                    ...state.informations.premiereConnexion,
                    ...action.payload
                }
            }
        });
    case actionsType.UTILISATEUR_UPDATE_FLAG_BIBLIOTHEQUE_ETRANGERE:
        return updateObject(state, {
            informations: {
                ...state.informations,
                premiereConnexion: {
                    ...state.informations.premiereConnexion,
                    bibliothequeEtrangere: action.isBibliothequeEtranger
                }
            }
        });
    case actionsType.UTILISATEUR_UPDATE_LOADING:
        return updateObject(state, { loading: action.loading });
    case actionsType.UPDATE_VUE_CROISEMENT_SUCCESS:
        return updateObject(state, {
            informations: {
                ...state.informations,
                deltaDetaille: action.canAccess
            }
        });
    default:
        return state;
    }
};

export default reducer;
