import { AsYouTypeFormatter, PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";
import React, { useEffect, useRef } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Trans } from "@lingui/macro";
import { makeStyles } from "@material-ui/core";
import { LIBELLE_CHAMP_OBLIGATOIRE, LIBELLE_ERREUR_TELEPHONE_FORMAT, LIBELLE_TELEPHONE, LIBELLE_TOOLTIP_TELEPHONE } from "../../../utils/libelleConstantes";
import { MAX_LENGTH_TELEPHONE } from "../../../utils/constantes";
import { LabelInputTextWithChildren, TextValidatorSmallOutlined } from "../fieldInput";
import { ISO_FRANCE } from "../../../utils/paysConstantes";
import StyledTooltip from "../../tooltip/styledTooltip.component";
import { formatInputTelephone, nth_regex_occurrence } from "../../../utils/utility";


function validatePhoneNumber(v) {
    if (v === "") {
        return true;
    }
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
        const number = phoneUtil.parseAndKeepRawInput(v, ISO_FRANCE);
        return phoneUtil.isValidNumber(number);
    } catch (e) {
        return false;
    }
}

const validatePhoneNumberFormatRuleName = "validatePhoneNumberFormat";
const getValidators = isRequired => isRequired ? ["required", validatePhoneNumberFormatRuleName] : [validatePhoneNumberFormatRuleName];
const getErrorMessages = isRequired => isRequired ? [
    <Trans id={LIBELLE_CHAMP_OBLIGATOIRE}/>,
    <Trans id={LIBELLE_ERREUR_TELEPHONE_FORMAT}/>
] : [<Trans id={LIBELLE_ERREUR_TELEPHONE_FORMAT}/>];

const useStyles = makeStyles(theme => ({
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 500,
        fontSize: theme.typography.pxToRem(15)
    }
}));

export const TelephoneFormattedTextInput = ({ value, toDisable, onChange, isRequired, labelKey = LIBELLE_TELEPHONE }) => {
    const textValidatorInputRef = useRef();
    const classes = useStyles();

    useEffect(() => {
        ValidatorForm.addValidationRule(
            validatePhoneNumberFormatRuleName,
            validatePhoneNumber
        );
        return () => {
            if (ValidatorForm.hasValidationRule && ValidatorForm.hasValidationRule(validatePhoneNumberFormatRuleName)) {
                ValidatorForm.removeValidationRule(validatePhoneNumberFormatRuleName);
            }
        };
    }, []);


    function formatAsYouTypePhoneNumber(phoneNumber) {
        let formatted = "";
        // La version formatée va contenir des espaces qu'il ne faut pas prendre en compte ici
        const onlyDigitAndPlus = phoneNumber.replace(/[^\d+]/g, "");
        const formatter = new AsYouTypeFormatter(ISO_FRANCE);
        onlyDigitAndPlus
            .split("")
            .forEach(c => (formatted = formatter.inputDigit(c)));
        return formatted;
    }

    function onChangeWithFormatter(e) {
        const newValue = e.target.value;
        let formattedNewValue = newValue;
        const cursor = e.target.selectionEnd;

        const newValueDigitAndPlusLength = newValue.replace(/[^\d+]/g, "").length;
        const valueDigitAndPlusLength = value.replace(/[^\d+]/g, "").length;

        // On évite de reformater si le numéro n'a pas changé (ce qui permet de supprimer les indicatifs)
        if (newValueDigitAndPlusLength !== valueDigitAndPlusLength) {
            formattedNewValue = formatAsYouTypePhoneNumber(newValue);

            // De plus on garde le curseur au bon endroit en gardant le même nombre de chiffre avant le curseur après le formatage
            if (cursor < value.length) {
                const numberOfDigits = newValue.substring(0, cursor).replace(/[^\d+]/g, "").length;
                const newCursor = numberOfDigits > 0 ? nth_regex_occurrence(formattedNewValue, /[\d+]/g, numberOfDigits) + 1 : 0;
                setTimeout(() => {
                    textValidatorInputRef.current.setSelectionRange(newCursor, newCursor);
                }, 50);
            }
        }
        onChange(formattedNewValue);
    }

    // Cette fonction est appelée directement sur la value reçue en props afin de la formatter au départ ainsi que lors de la saisie
    function formatPhoneNumber(phoneNumber, format) {
        let formatted = phoneNumber;
        const phoneUtil = PhoneNumberUtil.getInstance();
        try {
            const number = phoneUtil.parseAndKeepRawInput(phoneNumber, ISO_FRANCE);
            // On ne peut formatter que les numéros valides
            if (phoneUtil.isValidNumberForRegion(number, ISO_FRANCE)) {
                formatted = phoneUtil.format(number, format);
            }
        } catch (e) {
            /* Continue regardless of error */
        }
        return formatted;
    }

    function formatPhoneNumberNationally(phoneNumber) {
        return formatPhoneNumber(phoneNumber, PhoneNumberFormat.NATIONAL);
    }

    return <>
        <LabelInputTextWithChildren labelKey={labelKey} required={isRequired}>
            <StyledTooltip keyMessage={LIBELLE_TOOLTIP_TELEPHONE} placement="right" show={true} classes={classes}>
                <TextValidatorSmallOutlined
                    id={"telephoneInput"}
                    inputRef={textValidatorInputRef}
                    value={formatPhoneNumberNationally(value)}
                    onInput={formatInputTelephone}
                    onChange={onChangeWithFormatter}
                    disabled={toDisable}
                    inputProps={{ maxLength: MAX_LENGTH_TELEPHONE }}
                    validators={getValidators(isRequired)}
                    errorMessages={getErrorMessages(isRequired)}
                />
            </StyledTooltip>
        </LabelInputTextWithChildren>
    </>;
};
TelephoneFormattedTextInput.whyDidYouRender = true;
