import moment from "moment";
import { globalI18n } from "../i18n.conf";
import { LOWER_LIMIT_OF_DECLARATION } from "./constantes";
import {
    ERREUR_DATE_DEBUT_2013,
    LIBELLE_DATE_PIECE_OBLIGATORIE,
    LIBELLE_ERREUR_DATE_FUTURE
} from "./libelleConstantes";

export const getQueryString = data => Object.entries(data)
    .map(dataEntry => dataEntry.map(entry => encodeURIComponent(entry)).join("="))
    .join("&");

export function transformArrayToArrayOfObject(data) {
    const result = [];
    data.forEach(ligne => result.push({ ...ligne }));
    return result;
}

export function clone(originalObject) {
    if ((typeof originalObject !== "object") || originalObject === null) {
        throw new TypeError("originalObject parameter must be an object which is not null");
    }

    const deepCopy = JSON.parse(JSON.stringify(originalObject));

    // eslint-disable-next-line no-shadow
    function deepProto(originalObject, deepCopy) {
        // eslint-disable-next-line no-proto
        deepCopy.__proto__ = Object.create(originalObject.constructor.prototype);
        for (const attribute in originalObject) {
            if (typeof originalObject[attribute] === "object" && originalObject[attribute] !== null) {
                deepProto(originalObject[attribute], deepCopy[attribute]);
            }
        }
    }

    deepProto(originalObject, deepCopy);

    return deepCopy;
}

export const arrayNotEmpty = array => Boolean(array && array.length && array.length > 0);

const minDate = new Date(2013, 0, 1);
const epoch = new Date(1970, 0, 1);
const maxDate = new Date();
export const defaultDatePickerProps = disableMinDate => ({
    placeholder: "JJ/MM/AA",
    minDate: disableMinDate ? epoch : minDate,
    minDateMessage: globalI18n._(ERREUR_DATE_DEBUT_2013),
    maxDate,
    maxDateMessage: globalI18n._(LIBELLE_ERREUR_DATE_FUTURE),
    invalidDateMessage: globalI18n._(LIBELLE_DATE_PIECE_OBLIGATORIE),
    format: "dd/MM/yy",
    autoOk: true
});

export const isValidDeclarationDate = dateString => {
    const date = moment(dateString, "DD/MM/YY");
    if (!date.isValid()) {
        return false;
    }
    const isDateAfter = date.isSameOrAfter(LOWER_LIMIT_OF_DECLARATION);
    const isDateBefore = date.isBefore(moment());
    return isDateAfter && isDateBefore;
};
