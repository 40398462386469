import { call, put } from "@redux-saga/core/effects";
import { select } from "redux-saga/effects";
import * as actions from "../../actions/index.action";
import { selectState } from "../../../utils/selectors";
import { formatDate } from "../../../utils/utility";
import { exportRecapitulatifFournisseurService } from "../../../services/documentDeclaration.service";
import { DATE_FORMAT_YEAR_MONTH } from "../../../utils/constantes";
import { ERROR_EXPORT_RECAPITULATIF_VENTE } from "../../../utils/libelleConstantes";


export function *exportRecapitulatifVenteSaga(action) {
    yield put(actions.setLoading(true));
    const state = yield select(selectState);
    const filtre = {
        gencodDeclarant: state.utilisateur.informations.gencod,
        annee: action.annee,
        moisAnneeDebut: formatDate(action.moisAnneeDebut, DATE_FORMAT_YEAR_MONTH),
        moisAnneeFin: formatDate(action.moisAnneeFin, DATE_FORMAT_YEAR_MONTH)
    };
    try {
        yield call(exportRecapitulatifFournisseurService, filtre);
        yield put(actions.setLoading(false));
    } catch (error) {
        yield put(actions.setLoading(false));
        yield put(actions.openDialogNoRedirect(ERROR_EXPORT_RECAPITULATIF_VENTE, error.message));
    }
}
