import axios from "axios/index";
import _ from "lodash";
import { SessionVariablesEnum } from "../../utils/constantes";
import { BG_TOKEN } from "./bg-config";

export default function setupAxiosInterceptors() {
    axios.defaults.baseURL = "/services";
    axios.defaults.timeout = 300000;

    // Surcharge la requête
    axios.interceptors.request.use(config => {
        /*
         * Vide le token d'authentification si présent et expiré
         * Afin d'éviter d'envoyer un jeton expiré au back qui renvera une erreur d'authentification
         */
        const accessToken = sessionStorage.getItem(SessionVariablesEnum.TOKEN_NAME);
        const userInfos = JSON.parse(sessionStorage.getItem(SessionVariablesEnum.USER_INFOS));
        if (!_.isNil(accessToken) && userInfos.exp < new Date().getTime() / 1000) {
            // Vide le store
            sessionStorage.removeItem(SessionVariablesEnum.TOKEN_NAME);
            sessionStorage.removeItem(SessionVariablesEnum.USER_INFOS);
        }

        if (accessToken !== null) {
            config.headers.authorization = `Bearer ${accessToken}`;
        }
        const bgToken = BG_TOKEN;
        if (bgToken) {
            config.headers["bg-token"] = bgToken;
        }

        return config;
    }, error => {
        error.promise.reject(error);
    });

    // Surcharge la réponse
    axios.interceptors.response.use(response => response, error => {
        const status = error.response.status;
        // Si on reçoit un code de réponse 401 c'est que le back essaye de nous renvoyer sur le login
        if (status === 401) {
            // Vide le store
            sessionStorage.removeItem(SessionVariablesEnum.TOKEN_NAME);
            sessionStorage.removeItem(SessionVariablesEnum.USER_INFOS);
            window.location.href = "/login";
            return Promise.reject(error);
        }
        if (status === 404) {
            throw new Error(`${error.config.url} not found`);
        }
        throw error;
    });
}
