import React, { useEffect, useRef, useState } from "react";
import { Step, StepLabel, Stepper } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Trans } from "@lingui/macro";
import * as actions from "../../store/actions/index.action";
import { ButtonBackStep, initBeforeUnLoad } from "../../utils/utility";
import { selectInscriptionIsFormulaireStartedState, selectInscriptionState, selectStepState } from "../../utils/selectors";
import { CONTACT_FONCTION } from "../../utils/constantes";
import { PromptDataNotSavedDialog } from "../../commonComponents/dialog/Prompt/PromptDataNotSavedDialog.component";
import { LIBELLE_INSCRIPTION_ALERT_DATA_NOT_SAVED_INSIDE_RESULT_GLN } from "../../utils/libelleConstantes";
import InscriptionEntite from "./stepper/entite/inscriptionEntite.component";
import RepresentantLegal from "./stepper/representantLegal/representantLegal.component";
import ResponsableDroitPret from "./stepper/responsableDroitPret/responsableDroitDePret.component";
import Confirmation from "./stepper/confirmation/confirmation.component";
import RechercheGLN from "./stepper/rechercheGLN/rechercheGLN.component";

export default function Inscription() {
    const dispatch = useDispatch();
    const { saveInscriptionOk, isGencodSelected } = useSelector(selectInscriptionState);
    const isFormulaireStarted = useSelector(selectInscriptionIsFormulaireStartedState);
    const { activeStep, steps } = useSelector(selectStepState);
    const [keyMessage, setKeyMessage] = useState(null);


    // eslint-disable-next-line func-names
    window.onload = function () {
        initBeforeUnLoad(isFormulaireStarted);
    };

    // Gérer le cas où on ferme l'onglet ou appuie sur F5
    useEffect(() => {
        initBeforeUnLoad(isFormulaireStarted);
    }, [isFormulaireStarted]);


    function handleNext() {
        dispatch(actions.nextStep());
    }

    function reinit() {
        dispatch(actions.updateFormulaireMailValide(CONTACT_FONCTION.REPRESENTANT_LEGAL, false));
        dispatch(actions.updateFormulaireMailValide(CONTACT_FONCTION.RESPONSABLE_DROIT_DE_PRET, false));
    }

    useEffect(() => {
        reinit();
        return () => {
            window.scrollTo(0, 0);
            dispatch(actions.initRechercheGLN());
            dispatch(actions.initStepInscription());
            dispatch(actions.onChangeProfilDialog(""));
        };
    }, []);


    const Components = {
        Step1Content: InscriptionEntite,
        Step2Content: RepresentantLegal,
        Step3Content: ResponsableDroitPret,
        Step4Content: RechercheGLN,
        Step5Content: Confirmation
    };

    const stepsContent = [];
    let i = 0;
    steps.map(step => {
        const component = React.createElement(
            Components[step.component],
            {
                label: step.label,
                key: step.label + i++,
                onClickNext: handleNext
            }
        );
        return stepsContent.push(component);
    });

    const prevProps = useRef({ activeStep }).current;
    useEffect(() => {
        // À chaque fois que l'on change d'étape, on remonte le curseur en haut de la page
        if (prevProps.activeStep !== activeStep) {
            window.scrollTo(0, 0);
        }
    }, [activeStep, prevProps.activeStep]);


    useEffect(() => {
        const isResultGLnStep = stepsContent[activeStep].type === RechercheGLN;
        if (isResultGLnStep && isGencodSelected) {
            setKeyMessage(LIBELLE_INSCRIPTION_ALERT_DATA_NOT_SAVED_INSIDE_RESULT_GLN);
        } else {
            setKeyMessage(null);
        }
    }, [isGencodSelected, activeStep]);

    function agreeAction() {
        dispatch(actions.updateIsFormulaireStarted(false));
    }

    const hiddenButtonBackStep = (activeStep === 0 || activeStep === steps.length - 1) && saveInscriptionOk;


    return (
        <div>
            <PromptDataNotSavedDialog isDirty={isFormulaireStarted} keyMessage={keyMessage} onAgree={agreeAction}/>
            <Stepper activeStep={activeStep}>
                {steps.map((step, index) => (
                    <Step key={index} completed={index < activeStep || activeStep === steps.length - 1}>
                        <StepLabel><Trans id={step.label}/></StepLabel>
                    </Step>
                ))}
            </Stepper>
            <ButtonBackStep hiddenButton={hiddenButtonBackStep}/>
            <div style={{ textAlign: "center" }}>
                {stepsContent[activeStep]}
            </div>
        </div>
    );
}

