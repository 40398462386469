import Autosuggest from "react-autosuggest";
import { MenuItem, Paper, withStyles } from "@material-ui/core";
import React from "react";
import _ from "lodash";

const styles = theme => ({
    container: {
        position: "relative"
    },
    suggestionsContainerOpen: {
        position: "absolute",
        width: "600px",
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0
    },
    suggestion: {
        display: "block"
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: "none"
    },
    divider: {
        height: theme.spacing(2)
    },
    paper: {
        width: "600px"
    }
});


function CodeAndVilleAutoSuggestBase({
    suggestions,
    setSuggestions,
    classes,
    changeCodePostalAndVille,
    InputComponent,
    inputProps: initialProps,
    onSuggestionsFetchRequested,
    shouldRenderSuggestions
}) {
    function renderInputComponent(inputProps) {
        return <InputComponent {...inputProps}/>;
    }

    const villes = _.uniqBy(
        // Ajoute dans les suggesttions les valeurs des colonnes "nom ou "colonne"
        suggestions.flatMap(sug => [
            { codePostal: sug.codePostal, nom: sug.nom },
            { codePostal: sug.codePostal, nom: sug.commune }
        ]),
        "nom" // Retire les doublons selon l'attribut ci-devant
    );

    function renderSuggestion(ville, { isHighlighted }) {
        return (
            <MenuItem selected={isHighlighted} component="div">
                <span style={{ fontWeight: 600 }}>{ville.codePostal} - {ville.nom}</span>
            </MenuItem>
        );
    }

    function onChange(event) {
        initialProps.onChange(event);
    }

    function onSuggestionsClearRequested() {
        setSuggestions([]);
    }

    function onSuggestionSelected(event, { suggestion }) {
        /*
         * On est obligé d'updater en même temps le codePostal et le nom car des appels par propriété ne prendraient en compte que le dernier appel
         * (car les deux vont appeler le onChange du formulaire du composant monProfilFormulaireEntite mais avec des valeurs de ...formulaire différentes)
         */
        changeCodePostalAndVille(suggestion.codePostal, suggestion.nom);
    }


    function getSuggestionValue(suggestion) {
        return suggestion.nom.toUpperCase();
    }

    return (
        <Autosuggest
            renderInputComponent={renderInputComponent}
            suggestions={villes}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            shouldRenderSuggestions={shouldRenderSuggestions}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            onSuggestionSelected={onSuggestionSelected}
            focusInputOnSuggestionClick={false} // Corrige un warning dans la console
            inputProps={{
                ...initialProps,
                onChange
            }}
            theme={{
                container: classes.container,
                suggestionsContainerOpen: classes.suggestionsContainerOpen,
                suggestionsList: classes.suggestionsList,
                suggestion: classes.suggestion
            }}
            renderSuggestionsContainer={options => <Paper className={classes.paper} {...options.containerProps} square style={{ width: "350px", zIndex: "90" }}>
                {options.children}
            </Paper>}/>
    );
}

export default withStyles(styles)(CodeAndVilleAutoSuggestBase);
