import { isNil } from "lodash";

export const contactStrings = (chaine1, chaine2) => `${chaine1} ${chaine2}`;

export function isNotEmpty(value) {
    return !isNil(value) && value !== "";
}

export function isEmptyOrNullOrUndefined(value) {
    return !value || value === "";
}

export function removeZeroFirstChar(str) {
    let strTemp = str;
    while (strTemp && strTemp.length && strTemp.length > 1 && strTemp.slice(0, 1) === "0" && strTemp.slice(1, 2) !== ".") {
        strTemp = strTemp.substring(1, strTemp.length);
    }
    return strTemp;
}
