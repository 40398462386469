import React, { useEffect, useRef, useState } from "react";
import { Trans } from "@lingui/macro";
import { useDispatch } from "react-redux";
import { isNil } from "lodash";
import { ValidatorForm } from "react-material-ui-form-validator";
import { LabelInputTextWithChildren, TextValidatorSmallOutlined } from "../input/fieldInput";
import {
    LIBELLE_CHAMP_OBLIGATOIRE,
    LIBELLE_COURRIEL,
    LIBELLE_FORMAT_INCORRECT,
    LIBELLE_NO_IDENTIQUE_MAIL,
    LIBELLE_NOUVEAU_COURRIEL_CONFIRME
} from "../../utils/libelleConstantes";
import { MAIL_CONTACT, MAX_LENGTH_MAIL } from "../../utils/constantes";
import { disableAction, isBlank } from "../../utils/utility";
import { REQUIRED } from "../../utils/validatorUtil";

export function isSameMail(v, mail) {
    if (isNil(v) || isNil(mail)) {
        return false;
    }
    return v.toUpperCase() === mail.toUpperCase();
}


export const MailLabelInputText = ({ value, onChange }) => <LabelInputTextWithChildren labelKey={LIBELLE_COURRIEL} required>
    <TextValidatorSmallOutlined
        value={value}
        onChange={onChange}
        onCopy={disableAction}
        onPaste={disableAction}
        inputProps={{ maxLength: MAX_LENGTH_MAIL }}
        validators={[REQUIRED, "isEmail"]}
        errorMessages={[
            <Trans id={LIBELLE_CHAMP_OBLIGATOIRE}/>,
            <Trans id={LIBELLE_FORMAT_INCORRECT}/>
        ]}/>
</LabelInputTextWithChildren>;


const isConfirmationMailNotMatch = "isConfirmationMailNotMatch";
export const MailConfirmationInput = ({ mail, value, onChange }) => {
    const confirmationRef = useRef();

    function confirmIsSameMail(v) {
        return v === null || isBlank(v) || isSameMail(v, mail);
    }

    useEffect(() => {
        ValidatorForm.addValidationRule(isConfirmationMailNotMatch, confirmIsSameMail);
        confirmationRef.current.validate(value);
        return () => {
            if (ValidatorForm.hasValidationRule && ValidatorForm.hasValidationRule(isConfirmationMailNotMatch)) {
                ValidatorForm.removeValidationRule(isConfirmationMailNotMatch);
            }
        };
    }, [mail]);

    return <>
        <LabelInputTextWithChildren
            labelKey={LIBELLE_NOUVEAU_COURRIEL_CONFIRME} required>
            <TextValidatorSmallOutlined
                ref={confirmationRef}
                value={value}
                onChange={onChange}
                onCopy={disableAction}
                onPaste={disableAction}
                inputProps={{ maxLength: MAX_LENGTH_MAIL }}
                validators={[REQUIRED, isConfirmationMailNotMatch]}
                errorMessages={[<Trans id={LIBELLE_CHAMP_OBLIGATOIRE}/>, <Trans id={LIBELLE_NO_IDENTIQUE_MAIL}/>]}
            />
        </LabelInputTextWithChildren>
    </>;
};


export default function ContactMailFormulaireComponent({ formulaire, mailFromStore, onChange, showConfirmationInput = false }) {
    const [confirmation, setConfirmation] = useState("");
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [oldMail, setOldMail] = useState(mailFromStore);
    const dispatch = useDispatch();

    const displayConfirmation = showConfirmationInput || showConfirmation;

    useEffect(() => {
        /*
         * On RAZ les attributs locaux dans le cas où
         * - On change d'onglet entre les contacts dans la première connexion
         * - On sauvegarde le formulaire dans mon profil classique
         */
        setShowConfirmation(false);
        setConfirmation("");
        /*
         * On set la prop local "oldMail" par la dernière valeur du champ mail MAJ dans le store
         */
        setOldMail(mailFromStore);
    }, [formulaire && formulaire.fonction, mailFromStore]);


    function onChangeMail(e) {
        const { value } = e.target;
        setShowConfirmation(value !== oldMail);
        onChange(MAIL_CONTACT, value, value !== oldMail);
    }

    const onChangeConfirmation = e => {
        const { value } = e.target;
        setConfirmation(value);
    };

    return <div>
        <MailLabelInputText
            value={formulaire && formulaire.email}
            onChange={onChangeMail}/>
        {displayConfirmation && <MailConfirmationInput
            mail={formulaire && formulaire.email}
            value={confirmation}
            dispatch={dispatch}
            onChange={onChangeConfirmation}/>}
    </div>;
}
