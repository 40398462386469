import * as actions from "../actionTypes/actionTypes";

export const updateMesFacturesEtatReglement = etatReglement => ({
    type: actions.UPDATE_MES_FACTURES_ETAT_REGLEMENT_FILTRE,
    etatReglement
});


export const updateMesFacturesAnnee = (anneeDebut, anneeFin) => ({
    type: actions.UPDATE_MES_FACTURES_ANNEE_FILTRE,
    anneeDebut,
    anneeFin
});

export const getFactures = () => ({
    type: actions.GET_MES_FACTURES
});

export const getFacturesSuccess = factures => ({
    type: actions.GET_MES_FACTURES_SUCCESS,
    factures
});

export const updateLoadingMesFactures = loading => ({
    type: actions.UPDATE_LOADING_MES_FACTURES,
    loading
});

export const updateSortColumnMesFactures = sortColumn => ({
    type: actions.UPDATE_SORT_COLUMN_MES_FACTURES,
    sortColumn
});

export const updatePageMesFactures = page => ({
    type: actions.UPDATE_PAGE_MES_FACTURES,
    page
});
export const getFactureDocument = (numeroFacture, openOnly = false) => ({
    type: actions.DOWNLOAD_FACTURE,
    numeroFacture,
    openOnly
});

export const getFactureDocumentFromDeclaration = numeroFacture => ({
    type: actions.DOWNLOAD_FACTURE_FROM_DECLARATION,
    numeroFacture
});

export const getCountNouvellesFactures = glnDeclarant => ({
    type: actions.GET_COUNT_MES_NOUVELLES_FACTURES,
    glnDeclarant
});


export const getAllFacturesNotifications = glnDeclarant => ({
    type: actions.GET_ALL_FACTURES_NOTIFICATIONS,
    glnDeclarant
});


export const reinitFacturesFiltre = () => ({
    type: actions.REINIT_FACTURES_FILTRE
});

export const updateFactureLoading = (numeroFacture, loading) => ({
    type: actions.UPDATE_FACTURE_LOADING,
    numeroFacture,
    loading
});
