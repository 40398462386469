import React from "react";
import { IMG_CONNEXION_BIBLIOTHEQUE, IMG_CONNEXION_LIBRAIRIE } from "../../utils/imageConstantes";
import classes from "./loginPanel.module.css";
import Login from "./login/login.container";
import LoginControls from "./loginControls/loginControls.container";

const LoginPanel = () => (
    <div className={classes.LoginContainer}>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <img src={IMG_CONNEXION_LIBRAIRIE} alt="" className={classes.LoginImage}/>
            <img src={IMG_CONNEXION_BIBLIOTHEQUE} alt="" className={classes.LoginImage}/>
        </div>
        <div className={classes.LoginPanelContainer}>
            <Login/>
        </div>
        <LoginControls/>
    </div>
);

export default LoginPanel;
