import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, createMuiTheme, makeStyles, MuiThemeProvider, Tab, Tabs } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { selectLoginUserInfosAuthorities, selectMonProfilDataNotSaved, selectUtilisateurGencod, selectUtilisateurRoles } from "../../utils/selectors";
import { checkIsFournisseurConnected, initBeforeUnLoad, isAdminRole, isNullOrUndefined } from "../../utils/utility";
import * as actions from "../../store/actions/index.action";
import {
    LIBELLE_MON_PROFIL_CHANGEMENT_MDP,
    LIBELLE_MON_PROFIL_INFORMATIONS_DFA,
    LIBELLE_MON_PROFIL_INFORMATIONS_ENTITE,
    LIBELLE_MON_PROFIL_INFORMATIONS_RDP,
    LIBELLE_MON_PROFIL_INFORMATIONS_RL,
    LIBELLE_MON_PROFIL_INFORMATIONS_SOFIA,
    LIBELLE_MON_PROFIL_OPTIONS_FACTURATION
} from "../../utils/libelleConstantes";
import { PromptDataNotSavedDialog } from "../../commonComponents/dialog/Prompt/PromptDataNotSavedDialog.component";
import { MON_PROFIL_STEP_DFA, MON_PROFIL_STEP_RDP, MON_PROFIL_STEP_RL, REGEX_URL_MON_PROFIL, tabsBibliotheque, tabsFournisseur } from "./monProfilUtil";
import MonProfilFormulaireEntiteComponent from "./formulaires/entite/monProfilFormulaireEntite.component";
import MonProfilFormulaireContactComponent from "./formulaires/contact/monProfilFormulaireContact.component";
import MonProfilFormulaireOptionsFacturationComponent from "./formulaires/optionFacturation/monProfilFormulaireOptionsFacturation.component";
import MonProfilFormulaireInfosSofiaComponent from "./formulaires/infoSofia/monProfilFormulaireInfosSofia.component";
import MonProfilMotDePasse from "./formulaires/motDePasse/monProfilMotDePasse.component";
import MonProfilTabGuard from "./guard/monProfilTabGuard";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: "flex",
        height: "100%"
    },
    tabs: {
        width: "380px",
        borderRight: `2px solid ${theme.palette.divider}`
    },
    tabPanel: {
        width: "100%"
    }
}));

const theme = createMuiTheme({
    overrides: {
        MuiTab: {
            root: {
                maxWidth: "380px",
                height: "60px",
                fontSize: "1rem",
                padding: "50px 15px",
                "&:hover": {
                    backgroundColor: "#E2E0E0FF"
                },
                borderBottom: "1px solid #E2E0E0FF"
            },
            textColorInherit: {
                "&$selected": {
                    color: "#c40505",
                    backgroundColor: "#eddfdf"
                }
            },
            wrapper: {
                justifyContent: "flex-start",
                alignItems: "flex-start",
                textAlign: "left",
                textTransform: "none",
                padding: "0 15px"
            }
        }
    }
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            style={{ width: "100%" }}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

/**
 * On affiche une alerte si l'utilisateur quitte l'écran avant de sauvegarder ses modifications sauf dans le cas d'une première connexion
 */
export function handleNotificationWithoutSave(premiereCo, dispatch, isNotEqualObject) {
    if (!premiereCo && !isNullOrUndefined(isNotEqualObject)) {
        dispatch(actions.updateMonProfilDataNotSaved(isNotEqualObject));
    }
}

export default function MonProfilComponent() {
    const match = useParams();
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const dataNotSaved = useSelector(selectMonProfilDataNotSaved);
    const gencod = useSelector(selectUtilisateurGencod);
    const roles = useSelector(selectUtilisateurRoles);
    const isFournisseur = checkIsFournisseurConnected(roles);
    const isAdmin = isAdminRole(useSelector(selectLoginUserInfosAuthorities));

    const [tabs] = useState(isFournisseur ? tabsFournisseur : tabsBibliotheque);
    const [index, setIndex] = useState(0);
    const [setRedirect] = useState(false);

    const baseUrl = REGEX_URL_MON_PROFIL.exec(location.pathname)[0];


    // eslint-disable-next-line func-names
    window.onload = function () {
        initBeforeUnLoad(dataNotSaved);
    };

    // Gère les URL
    useEffect(() => {
        const tabName = match.tab;
        if (tabName) {
            const tabIndex = tabs.findIndex(v => v === tabName);
            setIndex(tabIndex);
        } else {
            // Gère le cas ou on attaque /monProfil sans préciser de /tab, on renvoie alors sur l'entité
            history.push({
                pathname: `${baseUrl}/${tabs[0]}`
            });
        }
        /*
         * On appelle le service à chaque changement d'onglet de mon profil pour chercher si les mails sont confirmé
         */
        dispatch(actions.getMonProfil(gencod));
    }, [setIndex, tabs, match, setRedirect, isFournisseur, history, baseUrl]);


    // Gérer le cas où on ferme l'onglet ou appuie sur F5
    useEffect(() => {
        initBeforeUnLoad(dataNotSaved);
    }, [dataNotSaved]);


    function handleChange(event, newValue) {
        const tabName = tabs[newValue];
        history.push({
            pathname: `${baseUrl}/${tabName}`
        });
    }

    function getLabel(key) {
        return <span>
            <Trans id={key}/>
        </span>;
    }

    function agreeAction() {
        dispatch(actions.updateMonProfilDataNotSaved(false));
    }

    return (
        <MonProfilTabGuard tab={match.tab} tabs={tabs}>
            <PromptDataNotSavedDialog isDirty={dataNotSaved} onAgree={agreeAction}/>
            <div className={classes.root}>
                <MuiThemeProvider theme={theme}>
                    <Tabs
                        orientation="vertical"
                        value={index}
                        onChange={handleChange}
                        className={classes.tabs}>
                        <Tab label={getLabel(LIBELLE_MON_PROFIL_INFORMATIONS_ENTITE)}/>
                        <Tab label={getLabel(LIBELLE_MON_PROFIL_INFORMATIONS_RL)}/>
                        <Tab label={getLabel(LIBELLE_MON_PROFIL_INFORMATIONS_RDP)}/>
                        {isFournisseur && <Tab label={getLabel(LIBELLE_MON_PROFIL_INFORMATIONS_DFA)}/>}
                        <Tab label={getLabel(LIBELLE_MON_PROFIL_CHANGEMENT_MDP)}/>
                        {isFournisseur && <Tab label={getLabel(LIBELLE_MON_PROFIL_OPTIONS_FACTURATION)}/>}
                        {isAdmin && <Tab label={getLabel(LIBELLE_MON_PROFIL_INFORMATIONS_SOFIA)}/>}
                    </Tabs>
                    <TabPanel value={index} index={0}>
                        <MonProfilFormulaireEntiteComponent/>
                    </TabPanel>
                    <TabPanel value={index} index={1}>
                        <MonProfilFormulaireContactComponent code={MON_PROFIL_STEP_RL} premiereCo={false} isFournisseur={isFournisseur}/>
                    </TabPanel>
                    <TabPanel value={index} index={2}>
                        <MonProfilFormulaireContactComponent code={MON_PROFIL_STEP_RDP} premiereCo={false} isFournisseur={isFournisseur}/>
                    </TabPanel>
                    {isFournisseur && <TabPanel value={index} index={isFournisseur ? 3 : 99}>
                        <MonProfilFormulaireContactComponent code={MON_PROFIL_STEP_DFA} premiereCo={false}/>
                    </TabPanel>}
                    <TabPanel value={index} index={isFournisseur ? 4 : 3}>
                        <MonProfilMotDePasse connected={true}/>
                    </TabPanel>
                    {isFournisseur && <TabPanel value={index} index={isFournisseur ? 5 : 99}>
                        <MonProfilFormulaireOptionsFacturationComponent/>
                    </TabPanel>}
                    {isAdmin && <TabPanel value={index} index={isFournisseur ? 6 : 4}>
                        <MonProfilFormulaireInfosSofiaComponent/>
                    </TabPanel>}
                </MuiThemeProvider>
            </div>
        </MonProfilTabGuard>
    );
}
MonProfilComponent.whyDidYouRender = true;
