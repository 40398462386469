import React, { useEffect, useState } from "react";
import { DialogContent } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import { Prompt, useHistory } from "react-router-dom";
import { LIBELLE_ALERT_DATA_NOT_SAVED, LIBELLE_ATTENTION } from "../../../utils/libelleConstantes";
import ConfirmDialog from "../confirmDialog/confirmDialog.component";

export function PromptDataNotSavedDialog({ isDirty, keyMessage, onAgree }) {
    const [openPopup, setOpenPopup] = useState(false);
    const [lastLocation, setLastLocation] = useState(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            // Navigate to the previous blocked location
            history.push(lastLocation.pathname);
        }
    }, [confirmedNavigation, lastLocation]);

    function openDialog(location) {
        setOpenPopup(true);
        setLastLocation(location);
    }

    function closeDialog() {
        setOpenPopup(false);
    }

    function handleBlockedNavigation(nextLocation) {
        if (!confirmedNavigation && isDirty) {
            openDialog(nextLocation);
            return false;
        }
        return true;
    }

    function handleConfirmNavigationClick() {
        onAgree && onAgree();
        setOpenPopup(false);
        setConfirmedNavigation(true);
    }

    return <>
        <Prompt
            when={isDirty}
            message={handleBlockedNavigation}/>
        <ConfirmDialog
            open={openPopup}
            title={LIBELLE_ATTENTION}
            onDisagreeClick={closeDialog}
            onAgreeClick={handleConfirmNavigationClick}>
            <DialogContent>
                <Trans id={keyMessage ? keyMessage : LIBELLE_ALERT_DATA_NOT_SAVED}/>
            </DialogContent>
        </ConfirmDialog>
    </>;
}
