import React, { Component } from "react";
import { Trans } from "@lingui/macro";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import { isNil } from "lodash";
import { LIBELLE_CHAMP_OBLIGATOIRE } from "../../../utils/libelleConstantes";
import * as actions from "../../../store/actions/index.action";
import { LIST_CODE_PAYS_FRANCE } from "../../../utils/paysConstantes";
import { REQUIRED } from "../../../utils/validatorUtil";

export class NumeroSiretInput extends Component {
    constructor(props) {
        super(props);
        this.validationRuleSiret = this.validationRuleSiret.bind(this);
        this.validationLengthSiret = this.validationLengthSiret.bind(this);
        this.siretRef = React.createRef();
    }

    validationRuleSiret() {
        const { isErrorSiret } = this.props;
        return !isErrorSiret;
    }

    // eslint-disable-next-line class-methods-use-this
    validationLengthSiret(value) {
        return !(value && value.length < 14);
    }

    componentWillUnmount() {
        // Remet la variable isErrorSiret à sa valeur initiale
        this.props.initErrorSiret();
    }

    componentDidMount() {
        const { checkSiret, codePays, value } = this.props;
        if (!isNil(value) && value.length === 14 && LIST_CODE_PAYS_FRANCE.includes(codePays)) {
            checkSiret(value);
        }
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.isErrorSiret !== this.props.isErrorSiret)) {
            this.siretRef.current.validate();
        }
    }

    handleInput = e => {
        const newValue = e.target.value.trim();
        e.target.value = newValue.replace(/[^0-9\\]+/g, "").slice(0, 14);
        const { checkSiret, codePays } = this.props;
        if (!isNil(e.target.value) && e.target.value.length === 14 && LIST_CODE_PAYS_FRANCE.includes(codePays)) {
            checkSiret(e.target.value);
        }
    }


    render() {
        // eslint-disable-next-line no-unused-vars
        const { value, isErrorSiret, checkSiret, initErrorSiret, codePays, ...restProps } = this.props;

        if (ValidatorForm.hasValidationRule && ValidatorForm.hasValidationRule("isErrorSiret")) {
            ValidatorForm.removeValidationRule("isErrorSiret");
        }
        if (ValidatorForm.hasValidationRule && ValidatorForm.hasValidationRule("isErrorLength")) {
            ValidatorForm.removeValidationRule("isErrorLength");
        }
        ValidatorForm.addValidationRule("isErrorSiret", this.validationRuleSiret);
        ValidatorForm.addValidationRule("isErrorLength", v => this.validationLengthSiret(v));

        return (
            <TextValidator
                id="numeroSiret"
                ref={this.siretRef}
                onInput={this.handleInput}
                validators={[REQUIRED, "isErrorSiret", "isErrorLength"]}
                errorMessages={[<Trans id={LIBELLE_CHAMP_OBLIGATOIRE}/>, <Trans id="invalidNumeroSiret"/>, <Trans id="minLengthNumeroSiret"/>]}
                value={value}
                {...restProps}
            />
        );
    }
}

const mapStatToProps = state => ({
    isErrorSiret: state.common.isErrorSiret
});

const mapDispatchToProps = dispatch => ({
    checkSiret: siret => dispatch(actions.checkSiret(siret)),
    initErrorSiret: () => {
        dispatch(actions.checkSiretSuccess(true));
    }
});

export default connect(mapStatToProps, mapDispatchToProps)(NumeroSiretInput);
