import * as actionTypes from "../actionTypes/actionTypes";

export const getEchantillonEntetesIdentifieesEtAttenduesByIdIntegration = idIntegration => ({
    type: actionTypes.GET_ECHANTILLON_ENTETE_IDENTIFIEES_ET_ATTENDUES_BY_ID_INTEGRATION,
    idIntegration
});

export const getEchantillonEntetesIdentifieesEtAttenduesByIdIntegrationResults = (echantillon, entetesIdentifiees, entetesAttendues) => ({
    type: actionTypes.GET_ECHANTILLON_ENTETE_IDENTIFIEES_ET_ATTENDUES_BY_ID_INTEGRATION_RESULT,
    echantillon,
    entetesIdentifiees,
    entetesAttendues
});

export const confirmEntetes = (entetesSelectionnees, idIntegration, nomFichier) => ({
    type: actionTypes.CONFIRM_CHOIX_ENTETE,
    entetesSelectionnees,
    idIntegration,
    nomFichier
});

export const setIntegrationsTableurChoixEntetesLoading = loading => ({
    type: actionTypes.SET_INTEGRATION_TABLEUR_ENTETE_LOADING,
    loading
});

export const updateConfimerEnteteLoading = flag => ({
    type: actionTypes.UPDATE_CONFIRM_ENTETE_LOADING,
    flag
});
