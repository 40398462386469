import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import React from "react";
import { selectMonProfil } from "../../../../utils/selectors";
import { useStyles } from "../../monProfilUtil";
import { LabelInputTextWithChildren } from "../../../../commonComponents/input/fieldInput";
import { MonProfilLibelleBoutonNext } from "../../MonProfilLibelleBoutonSauvegarde";
import { CONTACT_FONCTION } from "../../../../utils/constantes";
import {
    LIBELLE_COURRIEL,
    LIBELLE_MON_PROFIL_INFO_CONTACT_DFA,
    LIBELLE_MON_PROFIl_MODE_FACTURATION,
    LIBELLE_MON_PROFIL_MODE_FACTURATION_PREMIERE_CO,
    LIBELLE_MON_PROFIL_MODE_FACTURATION_PREMIERE_CO2,
    LIBELLE_MON_PROFIL_OPTION_FACTURATION_ALERTE,
    LIBELLE_MON_PROFIL_OPTIONS_FACTURATION,
    LIBELLE_MON_PROFIL_UPDATE_INFO_DFA,
    LIBELLE_NOM,
    LIBELLE_PRENOM
} from "../../../../utils/libelleConstantes";
import * as actions from "../../../../store/actions/index.action";
import LinkToLegiFrance from "../../../../commonComponents/link/linkToLegiFrance";

export default function MonProfilFormulaireOptionsFacturationComponent({ premiereCo }) {
    const dispatch = useDispatch();
    const { contacts } = useSelector(selectMonProfil);
    const contactDFA = contacts.find(c => c.fonction === CONTACT_FONCTION.RESPONSABLE_SUIVI_FACTURES);
    const classes = useStyles();
    const libelleFacturationPremiereCo = <div><p><Trans id={LIBELLE_MON_PROFIL_MODE_FACTURATION_PREMIERE_CO}/></p><p><Trans
        id={LIBELLE_MON_PROFIL_MODE_FACTURATION_PREMIERE_CO2} components={[<LinkToLegiFrance/>]}/></p></div>;

    function agreeAction() {
        dispatch(actions.updateMonProfilLastStepPremiereConnexionFournisseur());
    }

    return <div className={classes.divContainerFacturation}>
        <div className={classes.titreStepper}>
            <Trans id={LIBELLE_MON_PROFIL_OPTIONS_FACTURATION}/>
        </div>
        <div className={classes.divContactFacturation}>
            {premiereCo
                ? libelleFacturationPremiereCo
                : <Trans id={LIBELLE_MON_PROFIl_MODE_FACTURATION}/>
            }
        </div>
        <div className={classes.divContactFacturation}>
            <span className={classes.spanContactFacturation}><Trans id={LIBELLE_MON_PROFIL_INFO_CONTACT_DFA}/></span>
            <LabelInputTextWithChildren labelKey={LIBELLE_NOM}>
                <span>{contactDFA && contactDFA.nom}</span>
            </LabelInputTextWithChildren>
            <LabelInputTextWithChildren labelKey={LIBELLE_PRENOM}>
                <span>{contactDFA && contactDFA.prenom}</span>
            </LabelInputTextWithChildren>
            <LabelInputTextWithChildren labelKey={LIBELLE_COURRIEL}>
                <span>{contactDFA && contactDFA.email}</span>
            </LabelInputTextWithChildren>
        </div>
        {premiereCo
            ? <div className={classes.nextButton}>
                <Button
                    id="saveOptionsFacturation"
                    onClick={agreeAction}
                    variant="contained">
                    <MonProfilLibelleBoutonNext/>
                </Button>
            </div>
            : <>
                <span className={classes.divUpdateFacturation}>
                    <Trans id={LIBELLE_MON_PROFIL_UPDATE_INFO_DFA}/>
                </span>
                <span className={classes.spanAlerteFacturation}>
                    <Trans id={LIBELLE_MON_PROFIL_OPTION_FACTURATION_ALERTE}/>
                </span>
            </>
        }
    </div>;
}
