import * as actionsType from "../actionTypes/actionTypes";

export const exportMensualisation = gencodDeclarant => ({
    type: actionsType.EXPORT_MENSUALISATION,
    gencodDeclarant
});

export const exportLignesEan = () => ({
    type: actionsType.EXPORT_LIGNES_EAN
});


export const updateAnneeCivile = anneeCivile => ({
    type: actionsType.UPDATE_ANNEE_CIVILE,
    anneeCivile
});

export const updateSelectedPartenaires = selectedPartenaires => ({
    type: actionsType.UPDATE_SELECTED_PARTENAIRES,
    selectedPartenaires
});

export const loadPartenairesAndFactures = gencodDeclarant => ({
    type: actionsType.LOAD_PARTENAIRES_AND_FACTURES,
    gencodDeclarant
});

export const updateFiltresPartenairesAndFactures = data => ({
    type: actionsType.UPDATE_FILTRES_PARTENAIRES_FACTURES,
    data
});

export const updateLoadingPartenairesFactures = loading => ({
    type: actionsType.UPDATE_LOADING_STATITSTIQUES_PARTENAIRES_FACTURES,
    loading
});

export const updateAnneeDebut = annee => ({
    type: actionsType.UPDATE_STATISTIQUES_MENSUELLES_ANNEE_DEBUT,
    annee
});

export const updateAnneeFin = annee => ({
    type: actionsType.UPDATE_STATISTIQUES_MENSUELLES_ANNEE_FIN,
    annee
});

export const reinitStatistiquesView = () => ({
    type: actionsType.REINIT_STATISTIQUES_VIEW
});


export const updateSelectedFactures = selectedFactures => ({
    type: actionsType.UPDATE_SELECTED_FACTURES,
    selectedFactures
});

export const loadFactures = gencodDeclarant => ({
    type: actionsType.LOAD_FACTURES,
    gencodDeclarant
});

export const updateFiltresFactures = (factures, displayHelper) => ({
    type: actionsType.UPDATE_FILTRES_FACTURES,
    factures,
    displayHelper
});

export const updateLoadingFactures = loading => ({
    type: actionsType.UPDATE_LOADING_STATITSTIQUES_FACTURES,
    loading
});


export const updateDisableFiltrePartenaire = disabled => ({
    type: actionsType.UPDATE_DISABLE_PARTENAIRE_FILTRE,
    disabled
});

export const updateDisableFiltreFacture = disabled => ({
    type: actionsType.UPDATE_DISABLE_FACTURE_FILTRE,
    disabled
});

export const updateStoreAndLoadPartenairesAndFactures = annee => ({
    type: actionsType.UPDATE_STORE_AND_GET_PARTENAIRES_FACTURES,
    annee
});

export const reinitAllFiltre = () => ({
    type: actionsType.REINIT_ALL_FILTRE
});

export const getCountExportLigneEan = () => ({
    type: actionsType.EXPORT_EAN_GET_COUNT_LIGNE
});

export const updateCountExportLigneEan = count => ({
    type: actionsType.EXPORT_EAN_UPDATE_COUNT_LIGNE,
    count
});

export const updateLoadingCountExportLigneEan = loading => ({
    type: actionsType.EXPORT_EAN_UPDATE_LOADING_COUNT_LIGNE,
    loading
});
