import { Card, CardContent, CardHeader } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import React from "react";
import classes from "./CardContainer.module.css";

export default function CardContainer({ title, titleInfo, children, applyCardHeader = true, titreParams }) {
    const titleLibelle = <Trans id={title} values={titreParams}/>;
    const titleComponent = <div className={classes.divHeader}>{titleLibelle} {titleInfo}</div>;
    return (
        <Card className={classes.cardStyle}>
            {applyCardHeader &&
                <CardHeader className={classes.cardHeader} titleTypographyProps={{ variant: "subtitle1" }}
                    title={titleComponent}/>
            }
            <CardContent className={classes.cardContent}>
                {children}
            </CardContent>
        </Card>
    );
}
