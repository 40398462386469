import { updateObject } from "../../../utils/utility";
import * as actionsTypes from "../../actionTypes/actionTypes";
import { demandeReferencement, recherchePartenaire } from "../../../utils/model";


export const initialState = {
    demandeFormulaire: { ...demandeReferencement },
    rechercheFormulaire: { ...recherchePartenaire },
    gencodPartenaire: "",
    saveDemandeReferencementOk: true,
    loadingSelected: false,
    showSaveDemande: true,
    dataNotSaved: false
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionsTypes.UPDATE_FORMULAIRE_REFERENCEMENT:
        return updateObject(state, { demandeFormulaire: { ...state.demandeFormulaire, ...action.formulaire } });
    case actionsTypes.UPDATE_STATE_SAVE_DEMANDE_REFERENCEMENT:
        return updateObject(state, { saveDemandeReferencementOk: action.state });
    case actionsTypes.INIT_FORMULAIRE_REFERENCEMENT:
        return initialState;
    case actionsTypes.UPDATE_LOADING_FAVORIS_SELECTED:
        return ({
            ...state,
            loadingSelected: action.loading
        });
    case actionsTypes.UPDATE_SHOW_SAVE_DEMANDE: {
        return updateObject(state, { showSaveDemande: action.state });
    }
    case actionsTypes.UPDATE_FORMULAIRE_RECHERCHE:
        return updateObject(state, { rechercheFormulaire: action.formulaire });
    case actionsTypes.UPDATE_GENCOD_PARTENAIRE:
        return updateObject(state, { gencodPartenaire: action.gencod });
    case actionsTypes.UPDATE_DEMANDE_REFERENCEMENT_NOT_SAVED:
        return updateObject(state, { dataNotSaved: action.state });
    default:
        return state;
    }
};

export default reducer;
