import * as actionTypes from "../actionTypes/actionTypes";

export const getAttestations = gencod => ({
    type: actionTypes.ATTESTATION_FIN_GET_ATTESTATIONS,
    gencod
});

export const getAttestationsSuccess = attestations => ({
    type: actionTypes.ATTESTATION_FIN_GET_ATTESTATIONS_SUCCESS,
    attestations
});

export const setAttestationsLoading = loading => ({
    type: actionTypes.ATTESTATION_FIN_LOADING,
    loading
});

export const setAttestationAnneeLoading = (annee, loading) => ({
    type: actionTypes.ATTESTATION_FIN_ANNEE_LOADING,
    annee,
    loading
});

export const downloadAttestation = (annee, gencod) => ({
    type: actionTypes.ATTESTATION_FIN_DOWNLOAD_ATTESTATION,
    gencod,
    annee
});

export const sendAttestation = (annee, gencod, functionContact) => ({
    type: actionTypes.ATTESTATION_FIN_SEND_ATTESTATION,
    gencod,
    annee,
    functionContact
});
