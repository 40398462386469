import React, { useEffect } from "react";
import { Trans } from "@lingui/macro";
import { makeStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/index.action";
import { SpinnerOverlay } from "../../../commonComponents/spinner/spinnerOverlay.component";
import {
    selectUtilisateur,
    selectUtilisateurRoles
} from "../../../utils/selectors";
import {
    LIBELLE_MON_PROFIL_VALIDATION_MAIL_BIB,
    LIBELLE_MON_PROFIL_VALIDATION_MAIL_FOUR
} from "../../../utils/libelleConstantes";
import ImageAttention from "../../../assets/icons/ImageAttention.png";
import { checkIsBibliothequeConnected } from "../../../utils/utility";
import NousContacterDialog from "../../../commonComponents/dialog/nousContacterDialog";

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        whiteSpace: "pre-line",
        gap: "20px"
    },
    explications: {
        width: "800px"
    }
});

export const PremiereConnexionCheckMailStep = ({ gencod }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { loading } = useSelector(selectUtilisateur);
    const roles = useSelector(selectUtilisateurRoles);
    const isBibliotheque = checkIsBibliothequeConnected(roles);

    // Vérification si l’email RDP a déjà été validé
    useEffect(() => {
        dispatch(actions.getInformationsDeclarant(gencod));
    }, []);

    const styles = {
        responsiveImage: {
            height: 54
        }
    };
    const messageId = isBibliotheque
        ? LIBELLE_MON_PROFIL_VALIDATION_MAIL_BIB
        : LIBELLE_MON_PROFIL_VALIDATION_MAIL_FOUR;
    return (
        <SpinnerOverlay loading={loading}>
            {!loading && (
                <div className={classes.root}>
                    <img
                        src={ImageAttention}
                        alt=""
                        style={styles.responsiveImage}
                    />
                    <div className={classes.explications}>
                        <Trans id={messageId} components={[<NousContacterDialog fromPC={true}/>]}/>
                    </div>
                </div>
            )}
        </SpinnerOverlay>
    );
};
