import BorderWrapper from "react-border-wrapper";
import React from "react";
import { Trans } from "@lingui/macro";
import { makeStyles } from "@material-ui/core";
import { isBlank } from "../../utils/utility";

export const useStyles = makeStyles(() => ({
    parentWrapper: {
        color: "rgba(0, 0, 0, 0.54)",
        "& > div:nth-of-type(1)": {
            width: "100%"
        },
        "& > div:first-child > div:nth-child(2) > div:nth-child(2)": {
            width: "100%"
        }

    },
    spanStyle: {
        padding: "0",
        fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
        fontSize: "small",
        fontWeight: "400",
        lineHeight: "1",
        whiteSpace: "nowrap",
        letterSpacing: "0.00938em"
    }
}));

export default function FiltreBorderWrapper({ children, topElementKey, width, ...otherProps }) {
    const classes = useStyles();

    const topElement = <span className={classes.spanStyle}><Trans id={topElementKey}/></span>;

    return <div className={classes.parentWrapper} style={width}>
        <BorderWrapper
            borderColour="rgba(181, 181, 181, 0.87)"
            borderWidth="1px"
            borderRadius="4px"
            innerPadding="0 10px"
            borderType="solid"
            topPosition={0.012}
            topOffset="15px"
            topGap={isBlank(topElementKey) ? 0 : "4px"}
            topElement={topElement}
            {...otherProps}>
            {children}
        </BorderWrapper>
    </div>;
}
