import accueilLibrairieImage from "../assets/images/accueilLibrairie.png";
import accueilBibliothequeImage from "../assets/images/accueilBibliotheque.png";
import connexionLibrairieImage from "../assets/images/connexion-lib.png";
import connexionBibliothequeImage from "../assets/images/connexion-bib.png";
import contactImage from "../assets/images/contact.png";

export const IMG_ACCUEIL_LIBRAIRIE = accueilLibrairieImage;
export const IMG_ACCUEIL_BIBLIOTHEQUE = accueilBibliothequeImage;

export const IMG_CONNEXION_LIBRAIRIE = connexionLibrairieImage;

export const IMG_CONNEXION_BIBLIOTHEQUE = connexionBibliothequeImage;

export const IMG_CONTACTEZ_NOUS = contactImage;

