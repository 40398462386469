import { useHistory } from "react-router-dom";
import { Link, makeStyles } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { formatDate_DDMMYYYY } from "../../../../utils/utility";
import { selectRootPath } from "../../../../utils/selectors";


const useStyles = makeStyles({
    DetectionNotification: {
        textAlign: "center"
    },
    DetectionNotificationLabel: {
        marginLeft: 10,
        fontWeight: "bold",
        fontSize: "12px",
        color: props => props.color
    },
    MouseHover: {
        "&:hover": {
            cursor: "auto"
        }
    }
});


export default function BaseNotification({ suffixPath, icon, label, color, callBack, toRedirect = true, dateCreation }) {
    const history = useHistory();
    const rootPath = useSelector(selectRootPath);

    const handleClick = () => {
        if (toRedirect) {
            history.push(`${rootPath}${suffixPath}`);
            callBack();
        }
    };

    const classes = useStyles({ color });
    return <div className={classes.DetectionNotification}>
        <Link
            id="detectionEcart"
            onClick={handleClick}
            className={toRedirect ? "" : classes.MouseHover}
        >
            {icon}
            <span className={classes.DetectionNotificationLabel}>
                {dateCreation && <span style={{ marginRight: "10px" }}>{formatDate_DDMMYYYY(dateCreation)}</span>}
                {label}
            </span>
        </Link></div>;
}
