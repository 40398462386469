import React from "react";
import Pagination from "@material-ui/lab/Pagination";
import { connect } from "react-redux";
import { Trans } from "@lingui/macro";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField, withStyles } from "@material-ui/core";
import * as actions from "../../store/actions/index.action";
import classes from "./pagination.module.css";
// eslint-disable-next-line no-unused-vars

export const TotalPaginationFooter = ({ libellePagination, totalSize }) => (
    <span style={{ fontWeight: "bold", paddingLeft: "20px" }}>
        <Trans id={libellePagination}/> {totalSize}
    </span>
);


export function PaginationRounded(props) {
    return <Pagination count={props.count} page={props.currentPage} onChange={props.onChange} showFirstButton showLastButton/>;
}

const NoPaddingAutocomplete = withStyles({
    inputRoot: {
        "&&[class*=\"MuiOutlinedInput-root\"] $input": {
            padding: "1px 3px"
        }
    },
    input: {}
})(Autocomplete);

export const SizePerPageList = props => {
    const { value, max50, onChange } = props;
    const currentValue = value && max50 && value > 50 ? 50 : value;
    const inputValue = currentValue ? currentValue.toString() : "";
    const options = max50 ? [10, 20, 30, 50] : [10, 20, 30, 50, 100];
    return (<NoPaddingAutocomplete
        id="size-pagination"
        multiple={false}
        limitTags={1}
        inputValue={inputValue}
        value={currentValue}
        onChange={onChange}
        disableClearable
        options={options}
        getOptionLabel={option => option ? option.toString() : ""}
        style={{ width: "70px" }}
        renderInput={params => (
            <TextField {...params} variant="outlined" InputProps={{ ...params.InputProps, readOnly: true }}/>
        )}/>);
};


function PaginationFooter({
    currentPage,
    totalSize,
    preferenceSizePagination,
    pageSize = preferenceSizePagination,
    glnDeclarant,
    handleChangePage,
    handleChangeSizePerPage,
    updatePreferenceSizePagination,
    libellePagination = "total",
    withCreationLine = false,
    table = false,
    max50 = false
}) {
    const actualTotal = withCreationLine ? totalSize + 1 : totalSize;

    const onChangeSizePerPage = (event, value) => {
        updatePreferenceSizePagination(value, glnDeclarant);
        if (handleChangeSizePerPage) {
            handleChangeSizePerPage(value);
        }
    };

    const render = <>
        <div>
            <TotalPaginationFooter libellePagination={libellePagination} totalSize={totalSize} />
        </div>
        <div>
            <SizePerPageList value={pageSize} onChange={onChangeSizePerPage} max50={max50}/>
        </div>
        <div className={classes.footerTableRight}>
            {actualTotal > pageSize &&
                <PaginationRounded count={Math.ceil(actualTotal / pageSize)} currentPage={currentPage} onChange={handleChangePage} />}
        </div>
    </>;

    const classicRendering = <div className={classes.root}>{render}</div>;
    const tableRendering = <td className={classes.root}>{render}</td>;
    return table ? tableRendering : classicRendering;
}


PaginationFooter.propTypes = {
    handleChangePage: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    glnDeclarant: state.utilisateur.informations.gencod,
    preferenceSizePagination: state.utilisateur.informations.preferenceSizePagination
});

const mapDispatchToProps = dispatch => ({
    updatePreferenceSizePagination: (size, glnDeclarant) => dispatch(actions.updatePreferenceSizePagination(glnDeclarant, size))
});

export default connect(mapStateToProps, mapDispatchToProps)(PaginationFooter);
PaginationFooter.whyDidYouRender = true;
