import * as actionsType from "../actionTypes/actionTypes";

export const updateFilepondStore = filepond => ({
    type: actionsType.UPDATE_FILEPOND,
    filepond
});

export const integrerFichierExcelFromFilepond = (glnDeclarant, file, fileName, pathname) => ({
    type: actionsType.INTEGRER_EXCEL_FROM_FILEPOND,
    glnDeclarant,
    file,
    fileName,
    pathname
});
