import { call, put, select } from "redux-saga/effects";
import {
    createDeclarationsForIdIntegration,
    deleteAndGetStatisticsForIdIntegration,
    getEntetesChoisiesByIdIntegration,
    getLignesByIdIntegration,
    saveAndGetLignesForIdIntegration
} from "../../../../services/integrationTableur.service";
import * as actions from "../../../actions/index.action";
import { getPercentageByIdIntegration } from "../../../../services/common.service";
import { selectIntegrationDeclarations } from "../../../../utils/selectors";
import { sleep } from "../../../../utils/utility";
import {
    ERREUR_INTEGRATION_CORRECTION_CREATE_DECLARATION, ERREUR_INTEGRATION_CORRECTION_DELETEGET_STATS,
    ERREUR_INTEGRATION_CORRECTION_GET_ENTETES_CHOISIES,
    ERREUR_INTEGRATION_CORRECTION_GET_LIGNES, ERREUR_INTEGRATION_CORRECTION_SAVEETGET_LIGNES, ERREUR_INTEGRATION_GET_PERCENTAGE
} from "../../../../utils/libelleConstantes";


export function *getCorrectionErreurEntetesChoisiesByIdIntegrationSaga(action) {
    yield put(actions.setIntegrationsTableurCorrectionErreurLoading(true));
    try {
        const response = yield call(getEntetesChoisiesByIdIntegration, action.idIntegration);
        yield put(actions.getCorrectionErreurEntetesChoisiesByIdIntegrationResults(response.data));
    } catch (error) {
        yield put(actions.openDialogNoRedirect(ERREUR_INTEGRATION_CORRECTION_GET_ENTETES_CHOISIES, error.cause));
    }
    yield put(actions.setIntegrationsTableurCorrectionErreurLoading(false));
}

export function *getCorrectionErreurLignesByIdIntegrationSaga(action) {
    yield put(actions.setIntegrationsTableurCorrectionErreurLoading(true));
    try {
        const response = yield call(getLignesByIdIntegration, action.idIntegration);
        const nombreErreurs = Object.values(response.data.erreursStatistiques).reduce((e1, e2) => e1 + e2);
        if (nombreErreurs === 0) {
            yield put(actions.updatePassToStepCorrectionErreur(false));
            yield put(actions.updateConfimerEnteteLoading(false));
            // eslint-disable-next-line no-use-before-define
            yield call(createDeclarationsForIdIntegrationSaga, action);
        } else {
            yield put(actions.getCorrectionErreurLignesByIdIntegrationResults(response.data.erreursCalculees, response.data.erreursStatistiques));
            yield put(actions.updateConfimerEnteteLoading(false));
            yield put(actions.updatePassToStepCorrectionErreur(true));
        }
    } catch (error) {
        yield put(actions.openDialogNoRedirect(ERREUR_INTEGRATION_CORRECTION_GET_LIGNES, error.cause));
    }
    yield put(actions.setIntegrationsTableurCorrectionErreurLoading(false));
}

export function *saveAndGetLignesForIdIntegrationSaga(action) {
    yield put(actions.setIntegrationsTableurCorrectionErreurSauvegardeLoading(true));
    try {
        const response = yield call(saveAndGetLignesForIdIntegration, action.lignes, action.idIntegration);
        yield put(actions.saveAndGetLignesForIdIntegrationResult(response.data.erreursCalculees, response.data.erreursStatistiques));
    } catch (error) {
        yield put(actions.openDialogNoRedirect(ERREUR_INTEGRATION_CORRECTION_SAVEETGET_LIGNES, error.cause));
    }
    yield put(actions.setIntegrationsTableurCorrectionErreurSauvegardeLoading(false));
}

export function *deleteAndGetStatisticsForIdIntegrationSaga(action) {
    yield put(actions.setIntegrationsTableurCorrectionErreurSauvegardeLoading(true));
    try {
        const response = yield call(deleteAndGetStatisticsForIdIntegration, action.numerosLignesASupprimer, action.idIntegration);
        yield put(actions.deleteAndGetStatisticsForIdIntegrationResult(response.data.erreursStatistiques));
    } catch (error) {
        yield put(actions.openDialogNoRedirect(ERREUR_INTEGRATION_CORRECTION_DELETEGET_STATS, error.cause));
    }
    yield put(actions.setIntegrationsTableurCorrectionErreurSauvegardeLoading(false));
}

export function *createDeclarationsForIdIntegrationSaga(action) {
    let response;
    yield put(actions.openOrCloseBarProgressDialog(true));
    try {
        response = yield createDeclarationsForIdIntegration(action.idIntegration);
        if (response && response.data) {
            yield put(actions.openOrCloseBarProgressDialog(false));
            yield put(actions.setIntegrationStatut(response.data.data.integrationStatus));
        }
    } catch (error) {
        response = error.response;
        yield put(actions.openOrCloseBarProgressDialog(false));
        yield put(actions.displayErrorMessage(response, ERREUR_INTEGRATION_CORRECTION_CREATE_DECLARATION));
    }
}

export function *getPercentageSaga(action) {
    let updatePercentage = true;
    while (updatePercentage) {
        try {
            const response = yield call(getPercentageByIdIntegration, action.idIntegration);

            yield put(actions.setIntegrationsTableurCorrectionErreurLoadingPercentage(response.data));
            const declarations = yield select(selectIntegrationDeclarations);
            if (response.data === 100 || (declarations.entetes && declarations.entetes.length > 0)) {
                updatePercentage = false;
            }
        } catch (error) {
            updatePercentage = false;
            yield put(actions.openDialogNoRedirect(ERREUR_INTEGRATION_GET_PERCENTAGE, error.cause));
        }
        yield sleep(2000);
    }
}

