import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, makeStyles, TextField } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import SaveIcon from "@material-ui/icons/Save";
import YesNoRadioButton from "../../../../commonComponents/input/YesNoRadioButton";
import { checkIsBibliothequeConnected, checkIsFournisseurConnected } from "../../../../utils/utility";
import * as actions from "../../../../store/actions/index.action";
import { LabelInputTextWithChildren } from "../../../../commonComponents/input/fieldInput";

export const classNames = {
    parent: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginLeft: "50px",
        gap: "50px"
    },
    labelText: {
        width: "320px"
    },
    inputFields: {
        width: "500px",
        padding: "3px 0"
    },
    radio: {
        display: "flex",
        flexDirection: "row"
    }
};

const useStyles = makeStyles(classNames);


function YesNoLabelRadio({ labelKey, yes, disabled = true }) {
    const classes = useStyles();
    return <LabelInputTextWithChildren labelKey={labelKey} classNames={classes}>
        <YesNoRadioButton yes={Boolean(yes)} disabled={disabled} className={classes.radio} disable={disabled}/>
    </LabelInputTextWithChildren>;
}


function MonProfilFormulaireInfosSofiaComponent({
    assujettissementDDP,
    regroupe,
    entiteGerante,
    gestionnaire,
    contentieux,
    npai,
    deltaDetaille,
    roles, updateVueCroisement
}) {
    const classes = useStyles();
    const isBibliothque = checkIsBibliothequeConnected(roles);
    const isFournisseur = checkIsFournisseurConnected(roles);
    const [hasAccesCroisement, setHasAccesCroisement] = useState(Boolean(deltaDetaille));
    const changeCroisement = e => {
        setHasAccesCroisement(e.target.value === "true");
    };
    const submitCroisement = () => updateVueCroisement(hasAccesCroisement);
    useEffect(() => {
        setHasAccesCroisement(deltaDetaille);
    }, [deltaDetaille]);
    return <>
        <h3><Trans id={"InfoSofiaLabel"}/></h3>
        <YesNoLabelRadio labelKey={"assujettissement"} yes={assujettissementDDP}/>
        {isBibliothque &&
            <YesNoLabelRadio labelKey={"monProfilEntiteGestionnaire"} yes={gestionnaire}/>
        }
        {regroupe &&
            <LabelInputTextWithChildren labelKey={"monProfilGlnResponsable"} classNames={classes}>
                <TextField
                    type={"text"}
                    value={entiteGerante}
                    variant={"outlined"}
                    size={"small"}
                    disabled
                    className={classes.inputFields}
                />
            </LabelInputTextWithChildren>
        }
        {isFournisseur &&
            <YesNoLabelRadio labelKey={"monProfilContentieux"} yes={contentieux}/>
        }
        <YesNoLabelRadio labelKey={"monProfilNPAI"} yes={npai}/>
        <LabelInputTextWithChildren labelKey={"monProfilCroisement"} classNames={classes}>
            <YesNoRadioButton yes={hasAccesCroisement} handleChange={changeCroisement} className={classes.radio}/>
            <Button id="saveCroisementButton" type="submit" variant="contained" onClick={submitCroisement} size={"small"}>
                <Trans id={"monProfilEnvoiCroisement"}/>
                <SaveIcon/>
            </Button>
        </LabelInputTextWithChildren>
    </>;
}


const mapStatToProps = state => ({
    assujettissementDDP: state.monProfil.infoSofia.assujettissementDDP,
    contentieux: state.monProfil.infoSofia.contentieux,
    deltaDetaille: state.monProfil.infoSofia.deltaDetaille,
    entiteGerante: state.monProfil.infoSofia.entiteGerante,
    gestionnaire: state.monProfil.infoSofia.gestionnaire,
    regroupe: state.monProfil.infoSofia.regroupe,
    npai: state.monProfil.infoSofia.npai,
    roles: state.utilisateur.roles
});

const mapDispatchToProps = dispatch => ({
    updateVueCroisement: hasAcces => dispatch(actions.updateVueCroisement(hasAcces))
});

export default connect(mapStatToProps, mapDispatchToProps)(MonProfilFormulaireInfosSofiaComponent);
