import React, { useEffect, useRef, useState } from "react";
import { Trans } from "@lingui/macro";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getField, useStyles } from "../../../monProfil/monProfilUtil";
import * as actionCreator from "../../../../store/actions/inscription.action";
import { ChooseProfilDialog } from "../../../../commonComponents/dialog/confirmCompteDialog/chooseProfilDialog.component";
import { isEmptyOrNullOrUndefined } from "../../../../utils/stringUtil";
import { EntiteFormulaire } from "../../../../commonComponents/formulaire/EntiteFormulaire.component";
import { INSCRIPTION_ENTITE, MAIL_DEMANDEUR, MAX_LENGTH_MAIL, PROFIL_FOURNISSEUR } from "../../../../utils/constantes";
import { selectInscriptionState } from "../../../../utils/selectors";
import { LabelInputTextWithChildren, TextValidatorSmallOutlined } from "../../../../commonComponents/input/fieldInput";
import {
    LIBELLE_CHAMP_OBLIGATOIRE,
    LIBELLE_FORMAT_INCORRECT,
    LIBELLE_INSCRIPTION_INFO_DECLARANT,
    LIBELLE_MAIL_DEMANDEUR
} from "../../../../utils/libelleConstantes";
import InscriptionSubmitButton from "../inscriptionSubmitButton";
import { REQUIRED } from "../../../../utils/validatorUtil";
import { enableInstantValidate, isEqualObjects } from "../../../../utils/utility";
import * as actions from "../../../../store/actions/index.action";
import * as model from "../../../../utils/model";

function InscriptionEntite({ onClickNext }) {
    const { inscriptionEntite, profilDialog } = useSelector(selectInscriptionState);
    const [formulaire, setFormulaire] = useState(inscriptionEntite);
    const [open, setOpen] = useState(isEmptyOrNullOrUndefined(profilDialog));
    const isFournisseur = profilDialog === PROFIL_FOURNISSEUR;
    const classes = useStyles();
    const formReference = useRef();
    const dispatch = useDispatch();

    function onSubmit(e) {
        e.preventDefault();
        if (isFournisseur) {
            formulaire.dateCreation = moment(formulaire.dateCreation).format("YYYY-MM-DD");
        }
        dispatch(actionCreator.updateInscriptionEntiteFormulaire(formulaire));
        onClickNext();
    }

    function updateMailDemandeur(e) {
        setFormulaire({
            ...formulaire,
            mailDemandeur: e.target.value
        });
    }

    function closeProfilDialog() {
        setOpen(false);
    }

    useEffect(() => {
        // Une fois que le formulaire a été commencé, on met le flag à true et on le laisse ainsi même lorsque l'on passe sur les autres étapes
        const isFormulaireModified = !isEqualObjects(formulaire, model.inscriptionEntite);
        dispatch(actions.updateIsFormulaireStarted(isFormulaireModified));
    }, [formulaire]);

    return (<ValidatorForm onSubmit={onSubmit} instantValidate={false} ref={formReference}>
        <ChooseProfilDialog open={open} handleClose={closeProfilDialog}/>
        <div className={classes.titreStepper}>
            <Trans id={INSCRIPTION_ENTITE}/>
        </div>
        <div className={classes.alignLeft}>
            <LabelInputTextWithChildren labelKey={LIBELLE_MAIL_DEMANDEUR} required>
                <TextValidatorSmallOutlined
                    value={getField(formulaire, MAIL_DEMANDEUR)}
                    onChange={updateMailDemandeur}
                    inputProps={{ maxLength: MAX_LENGTH_MAIL }}
                    validators={[REQUIRED, "isEmail"]}
                    errorMessages={[
                        <Trans id={LIBELLE_CHAMP_OBLIGATOIRE}/>,
                        <Trans id={LIBELLE_FORMAT_INCORRECT}/>
                    ]}/>
            </LabelInputTextWithChildren>
        </div>
        <div style={{ margin: "10px 0" }}>
            <LabelInputTextWithChildren labelKey={LIBELLE_INSCRIPTION_INFO_DECLARANT}/>
        </div>
        <EntiteFormulaire formulaire={formulaire} setFormulaire={setFormulaire} isFournisseur={isFournisseur} fromInscription={true}/>
        <InscriptionSubmitButton id="saveCompteUtilisateurButton" onClick={() => enableInstantValidate(formReference)}/>
    </ValidatorForm>);
}

export default InscriptionEntite;
